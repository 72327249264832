import { Box, Card, Container, Divider, Grid, Toolbar } from "@mui/material";
import AccountNavigationsListAdapter from "./components/AccountNavigationsListAdapter";
import { useRef, useState } from "react";
import AccountComponentTitle from "./components/AccountComponentTitle";
import GeneralSettings from "./subpages/GeneralSettings";
import PricingPlans from "./subpages/PricingPlans";
import OrderHistory from "./subpages/OrderHistory";
import AddNewCard from "./subpages/AddNewCard";
import CardSettings from "./subpages/CardSettings";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from 'react-redux-firebase';
import CustomProgress from "../../../cidgets/CustomProgress";
import AddLinkedAccount from './subpages/AddLinkedAccount'
import SubscriptionSetting from "./subpages/SubscriptionSetting";
const AccountDashboardController = (props) => {
    const { appBarHeight, companyData, userData, companyOrdersHistory, companyPricingData } = props;
    const { uid } = userData;
    const bodyComponentHeight = useRef();
    const height = `calc(100vh - ${appBarHeight}px)`
    const bodyHeight = `calc(100vh - ${appBarHeight}px - 0px)`
    const subscriptionsList = companyPricingData ? Object.values(companyPricingData.subscriptions).filter(subscription => Object.keys(subscription).length > 0)
        : null;
    const [currentNavKey, setCurrentNavKey] = useState('0')
    const handleNavigationSelection = (navigationObj) => {
        // //////console.log(navigationObj)
        setCurrentNavKey(navigationObj.key)
    }
    if (companyOrdersHistory === undefined || companyOrdersHistory === null) {
        return (
            <>
                <Toolbar />

                <Box sx={{ height: height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CustomProgress />
                </Box>
            </>

        )
    }
    if (companyData === undefined || companyData === null) {
        return <></>
    }
    const { creatorUid, stripeCusId, stripeDefaultPmid } = companyData;
    const isCreator = creatorUid === uid;
    const navigationBodyComponents = [
        <GeneralSettings companyData={companyData} />, <PricingPlans />, <OrderHistory companyOrdersHistory={companyOrdersHistory} />, isCreator ? <CardSettings /> : <></>, <AddNewCard />
        , isCreator ? <AddLinkedAccount /> : <></>, subscriptionsList && subscriptionsList.length > 0 && <SubscriptionSetting bodyComponentHeight={bodyComponentHeight?.current?.clientHeight ?? 0} subscriptionsList={subscriptionsList} />
    ]
    const cardStyle = {
        "border": "0.5px solid rgba(0,0,0,0.2)",
        "borderRadius": "8px",
        "backgroundColor": '#fff',
    }
    return (
        <div style={{ backgroundColor: '' }}>
            {/* Toolbar is required */}
            <Toolbar />
            {/* Your work starts here */}

            <Box sx={{ height: height }}>
                {/* <Container sx={{ height: height }}> */}
                <Grid container spacing={0} sx={{ height: height }}>
                    <Grid item xs={3} md={3} lg={2} sx={{ backgroundColor: '' }}>
                        <Box sx={{ height: '100%', paddingBlock: '15px', paddingLeft: '15px' }}>
                            <Card sx={{ border: "0.5px solid rgba(0,0,0,0.2)", borderRadius: "8px", "backgroundColor": 'transparent', height: '100%' }} elevation={1}>
                                <AccountNavigationsListAdapter stripeCusId={stripeCusId} stripeDefaultPmid={stripeDefaultPmid} isCreator={isCreator} handleNavigationSelection={handleNavigationSelection} />
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={9} md={9} lg={10} sx={{ backgroundColor: '', height: bodyHeight }}>
                        <Box sx={{ height: '100%', paddingBlock: '15px', paddingInline: '15px' }}>
                            <Card ref={bodyComponentHeight} sx={{ ...cardStyle, height: '100%', padding: '0px' }} elevation={1}>
                                {/* #fff' */}
                                <Container  sx={{ height: '100%', backgroundColor: '#fff' }}>
                                    <Box sx={{ paddingBlock: '20px', height: '13%', backgroundColor: '' }}>
                                        <AccountComponentTitle currentNavKey={stripeCusId && stripeDefaultPmid ? currentNavKey : '4'} companyOrdersHistory={companyOrdersHistory} />
                                    </Box>
                                    <Box  sx={{ height: '87%', backgroundColor: "" }}>{navigationBodyComponents[stripeCusId && stripeDefaultPmid ? currentNavKey : '4']}</Box>
                                </Container>
                                {/* <Container sx={{ height: '5%', backgroundColor: 'red' }}        
                             </Container> */}
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                {/* </Container> */}
            </Box>
        </div>
    );

}
const mapStateToProps = (state) => {
    return {
        // companyData: state.firestore.data.Company,
        companyOrdersHistory: state.firestore.ordered.CompanyOrdersHistory,
        userData: state.firebase.profile,
        companyPricingData: state.firestore.data.CompanyPricingData,
    };
};
const enhance = compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        // //////console.log(props,"props")
        // const { coid } = props?.companyData;
        const { userData } = props;
        return [
            {
                collection: `ProductOrders`,
                where: [['coid', '==', `${userData.coid}`]],
                // limit: 10,
                orderBy: ['timestamp', 'desc'],
                storeAs: "CompanyOrdersHistory",
            },
        ];
    })
);
export default enhance(AccountDashboardController);