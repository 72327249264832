import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import PrimaryAppBar from "./laptopDrawer/PrimaryAppBar";
import { getDataInfoV2, getAssetDataV2, getDistressData, getSafetyData, getBasicImagingData, getAccessManagementData } from "../../../../../store/actions/datastoreAction";
import { useLocation } from "react-router-dom";
import releaseNotes from '../../../../../constants/releaseNotes.json'
import CustomProgress from "../../../../cidgets/CustomProgress";
import { useAccessControlContext } from "../../../../../contexts/AccessControlProvider";


const SignedInDrawer = (props) => {
  const { permissions } = useAccessControlContext();
  const location = useLocation();
  const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
  const possibleProid = getLastItem(location.pathname)
  const isProid = possibleProid.includes('webproj')
  const { Child, isEmailVerified, isSignedIn, themeRed, company, userData } = props;
  const isLinkedAccount = userData.linkedToUid ?? false;
  useEffect(() => {
    props.getDataInfoV2();
    props.getAssetDataV2();
    props.getDistressData();
    props.getSafetyData();
    props.getBasicImagingData();
    props.getAccessManagementData();
  }, [])

  return (
    company !== null && company !== undefined && permissions !== null ? <PrimaryAppBar isLinkedAccount={isLinkedAccount} proid={possibleProid} Child={Child} isEmailVerified={isEmailVerified} isSignedIn={isSignedIn} themeRed={themeRed} />
      : <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CustomProgress text={"Loading"} /></div>
  )
};

const mapStateToProps = (state) => {
  return {
    project: state.project,
    userData: state.firebase.profile,
    themeRed: state.themeRed,
    company: state.firestore.data.Company
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAssetDataV2: () => dispatch(getAssetDataV2()),
    getDataInfoV2: () => dispatch(getDataInfoV2()),
    getDistressData: () => dispatch(getDistressData()),
    getSafetyData: () => dispatch(getSafetyData()),
    getBasicImagingData: () => dispatch(getBasicImagingData()),
    getAccessManagementData: () => dispatch(getAccessManagementData()),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const { userData } = props;
    // TODO: for search to work, enable this
    // const ueid = props.userData?.uid + props.userData?.employeeid;
    // const coid = props.userData?.coid;
    const coid = props.userData.coid ?? '-1';
    return [
      {
        collection: "Configurations",
        doc: "OutputPredictions",
        storeAs: "OutputPredictions",
      },
      {
        collection: `SuperSections`,
        where: [['coid', '==', `${coid}`]],
        orderBy: [['creationTime', 'desc']],
      },
      {
        collection: "Configurations",
        doc: "Charges",
        storeAs: "Charges",
      },
      {
        collection: "Configurations",
        doc: "ChargesV3",
        storeAs: "ChargesV3",
      },
      {
        collection: "Store",
        where: [['testProduct', "==", releaseNotes.dev]],
        storeAs: "Store",
      },
      {
        collection: "CompanyPricing",
        storeAs: "CompanyPricingData",
        doc: `${userData.coid}`,
      },
      {
        collection: "Companies",
        storeAs: "Company",
        doc: `${userData.coid}`,
      },
      {
        collection: "Defects",
        where: [['coid', '==', userData?.coid ?? -1]],
        orderBy: [['dateOfCreation', 'desc']],
        storeAs: "Defects"
      },
      {
        collection: "Notifications",
        where: [['uid', '==', userData?.uid ?? '-1']],
        // where: [['seachables', 'in', [userData?.uid??'-1', proid]]],
        orderBy: [['dateCreated', 'desc']],
        storeAs: "GenNotifs",
        limit: 10
      }
    ];
  })
)(SignedInDrawer);
