import React from 'react';
import { Card, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import CircleIcon from '@mui/icons-material/Circle';
import colors from '../../../../../../constants/colors.json'

const BasicImagingOverviewChart = (props) => {
    const { detections, basicImagingDataInfo } = props;
    //////console.log(basicImagingDataInfo.status, "basicImagingDataInfo")
    let countMap = { '0': 0, '1': 0, '2': 0, '3': 0, '-3': 0 };
    const augmentedPredictions = [...detections].map((eachDetection, i) => {
        const obj = {
            pred: eachDetection,
        }
        countMap[eachDetection.status] = countMap[eachDetection.status] + 1
        return obj
    });
    const totalFeatures = augmentedPredictions.length;
    Object.entries(countMap).map(([key, value]) => {
        countMap[key] = {
            class: key,
            count: value,
            percentage: (value / totalFeatures) * 100,
            percentageToShow: Number((value / totalFeatures) * 100).toFixed(1),
            outOfOne: (value / totalFeatures),
            pieChartAngle: (value / totalFeatures) * 360,
            pieChartRadians: ((value / totalFeatures) * 360 * Math.PI) / 180,
            name: basicImagingDataInfo.status[key === '-3' ? 4 : key]['name'],
            nameToBeShown: `${basicImagingDataInfo.status[key === '-3' ? 4 : key]['name']}`.toUpperCase(),
            color: colors.colors[key]
        }
    });
    const sortedCountArray = Object.values(countMap).sort((a, b) => a.class - b.class)
    const chartData = {
        labels: sortedCountArray.map((e) => (`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')),
        datasets: [{
            label: 'Summary',
            data: sortedCountArray.map((e) => e.count),
            backgroundColor: sortedCountArray.map((e) => e.color),
            hoverOffset: 4
        }]
    }

    const chartOptions = {
        legend: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
                labels: {
                    font: {
                        size: 16
                    }
                }
            }
        },
        layout: {
            padding: 0
        },
    }
    const cardLightStyle = {
        "border": "0px solid grey",
        "borderRadius": "8px",
        "padding": "8px",
        "backgroundColor": '#f5f1f1'
    }

    const wnpStyle = { fontSize: '15px' }
    return <>
        <Grid container>

            <Grid xs={5} item>
                <List>
                    {sortedCountArray.map((e) => {
                        return <ListItem key={e.class} disablePadding>
                            <ListItemIcon>
                                <CircleIcon sx={{ color: e.color }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={(`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')}
                                secondary={`${e.percentageToShow}% (${e.count})`}
                            />
                        </ListItem>
                    })}
                </List>
            </Grid>
            <Grid xs={7} item>
                <Doughnut data={chartData} options={chartOptions} />
            </Grid>
        </Grid>
        <Grid container>
            <Grid xs={12} item>
                <Card sx={{ ...cardLightStyle, marginTop: '30px' }} elevation={0}>
                    <Typography variant="p" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                        ⚡️ Quick bits
                    </Typography>
                    <div style={{ margin: '14px' }}></div>
                    <div style={wnpStyle}>Total {totalFeatures} features</div>
                </Card>
            </Grid>
        </Grid>
    </>
}

export default BasicImagingOverviewChart