import React, { useEffect, useState } from 'react';
import { Stage, Layer, Rect, Transformer, Image, Group, Line, Circle, Text } from "react-konva";
import { Button, Typography } from "@mui/material";
import { useGenerateSignedUrl } from '../../../../../chooks/useGenerateSignedUrl';
import { useId } from 'react';


const PolygonAnnotation = (props) => {
    const {
        cusKey,
        points,
        fill,
        flattenedPoints,
        defect,
        isFinished,
        handlePointDragMove,
        handleGroupDragEnd,
        handleMouseOverStartPoint,
        handleMouseOutStartPoint,
        onValueChange,
        scaleFactor
    } = props;

    const vertexRadius = 1.5;
    const [text, setText] = useState('')

    const [stage, setStage] = useState();
    const handleGroupMouseOver = (e) => {

        let defectName;

        switch (defect) {
            case 0:
                defectName = 'Pothole';
                break;
            case 1:
                defectName = 'Crack';
                break;

            case 3:
                defectName = 'Ravelling';
                break;
            case 4:
                defectName = 'Rutting';
                break;

            case 10:
                defectName = 'Polished aggregate';
                break;
            default:
                defectName = '';
        }
        setText(defectName)
        if (!isFinished) return;
        e.target.getStage().container().style.cursor = "move";
        setStage(e.target.getStage());
    };
    const handleGroupMouseOut = (e) => {

        e.target.getStage().container().style.cursor = "default";
    };
    const [minMaxX, setMinMaxX] = useState([0, 0]); //min and max in x axis
    const [minMaxY, setMinMaxY] = useState([0, 0]); //min and max in y axis
    const handleGroupDragStart = (e) => {
        let arrX = points.map((p) => p[0]);
        let arrY = points.map((p) => p[1]);
        setMinMaxX(minMax(arrX));
        setMinMaxY(minMax(arrY));
    };
    const groupDragBound = (pos) => {
        let { x, y } = pos;
        const sw = stage.width();
        const sh = stage.height();
        if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
        if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
        if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
        if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
        return { x, y };
    };

    const dragBoundFunc = (stageWidth, stageHeight, vertexRadius, pos) => {
        let x = pos.x;
        let y = pos.y;
        if (pos.x + vertexRadius > stageWidth) x = stageWidth;
        if (pos.x - vertexRadius < 0) x = 0;
        if (pos.y + vertexRadius > stageHeight) y = stageHeight;
        if (pos.y - vertexRadius < 0) y = 0;
        return { x, y };
    };
    const minMax = (points) => {
        return points.reduce((acc, val) => {
            acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
            acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
            return acc;
        }, []);
    };

    const handleBoundingBoxChange = () => {
        //////console.log('clicked')
        // onValueChange(points)
    }
    if (points.some(point => point === null || isNaN(point[0]) || isNaN(point[1]))) {
        return; // Return null if points contain null or NaN values
    }
    return (
        <Group
            key={cusKey}
            name="polygon"
            draggable={isFinished}
            onDragStart={handleGroupDragStart}
            onDragEnd={handleGroupDragEnd}
            dragBoundFunc={groupDragBound}
            onMouseOver={handleGroupMouseOver}
            onMouseOut={handleGroupMouseOut}
            onMouseLeave={(e) => {
                setText('');
            }}
            onClick={() => {
                handleBoundingBoxChange();
            }}
        >

            <Line
                points={flattenedPoints}
                // stroke="#60EE00"
                stroke={fill}
                strokeWidth={1}
                closed={isFinished}
                // fill="#60EE00"
                fill={fill}
            />

            {points.map((point, index) => {
                const x = point[0]
                const y = point[1]
                const startPointAttr =
                    index === 0
                        ? {
                            hitStrokeWidth: 12,
                            onMouseOver: handleMouseOverStartPoint,
                            onMouseOut: handleMouseOutStartPoint,
                        }
                        : null;
                return (
                    <Circle
                        key={index}
                        x={x}
                        y={y}
                        radius={vertexRadius}
                        // fill="#60EE00"
                        // stroke="#60EE00"
                        stroke={fill}
                        fill={fill}
                        strokeWidth={1}
                        // draggable
                        // onDragMove={handlePointDragMove}
                        // dragBoundFunc={(pos) =>
                        //     dragBoundFunc(stage.width(), stage.height(), vertexRadius, pos)
                        // }

                        {...startPointAttr}
                    />
                );
            })}
            <Text
                x={points[0][0]}
                y={points[0][1]}
                //   width={100}
                //   height={100}
                text={text}
                fill="white"
                fontFamily="sans-serif"
                fontSize={15}
            />
        </Group>
    );
};




const ImageAnnotatorV3 = ({ distressPinned = null, defaultHeight = 100, defaultPadding = 0, isEdit = false, imageUrl, imageHeight, imageWidth, drawMap, editBox, editableBbox = [], nonEditableBbox = [], onValueChange = (value = []) => { } }) => {
    // //////console.log(nonEditableBbox, "nonEditableBbox")
    // //////console.log(nonEditableBbox, 'grap')
    const unqId = useId();
    const [image, setImage] = useState(null);
    const [originalWidth, setOriginalWidth] = useState(0);
    const [originalHeight, setOriginalHeight] = useState(0);
    // //////console.log(text, 'shamp12')
    const { status, error, data } = useGenerateSignedUrl(imageUrl)
    const isFetched = status === 'fetched'
    const distressPinnedSvgColor = `rgba(57, 1, 120,1)`

    const [points, setPoints] = useState(editableBbox);
    const [scaledPoints, setScaledPoints] = useState([]);
    const [flattenedPoints, setFlattenedPoints] = useState();
    const [scaledFlattenedPoints, setScaledFlattenedPoints] = useState();

    const [position, setPosition] = useState([0, 0]);
    const [isMouseOverPoint, setMouseOverPoint] = useState(false);
    const [isPolyComplete, setPolyComplete] = useState(false);

    // const newPoints = nonEditableBbox.map(obj => [obj.x, obj.y]);
    // //////console.log(newPoints,'newPoints')

    const getMousePos = (stage) => {
        return [stage.getPointerPosition().x, stage.getPointerPosition().y];
    };
    //drawing begins when mousedown event fires.
    const handleMouseDown = (e) => {
        if (isPolyComplete) return;
        const stage = e.target.getStage();
        const mousePos = getMousePos(stage);
        if (isMouseOverPoint && points.length >= 3) {
            setPolyComplete(true);
        } else {
            setPoints([...points, mousePos]);
        }
    };
    const handleMouseMove = (e) => {
        const stage = e.target.getStage();
        const mousePos = getMousePos(stage);
        setPosition(mousePos);
    };
    const handleMouseOverStartPoint = (e) => {
        if (isPolyComplete || points.length < 3) return;
        e.target.scale({ x: 3, y: 3 });
        setMouseOverPoint(true);
    };
    const handleMouseOutStartPoint = (e) => {
        e.target.scale({ x: 1, y: 1 });
        setMouseOverPoint(false);
    };
    const handlePointDragMove = (e) => {
        const stage = e.target.getStage();
        const index = e.target.index - 1;
        const pos = [e.target._lastPos.x, e.target._lastPos.y];
        if (pos[0] < 0) pos[0] = 0;
        if (pos[1] < 0) pos[1] = 0;
        if (pos[0] > stage.width()) pos[0] = stage.width();
        if (pos[1] > stage.height()) pos[1] = stage.height();
        setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
    };

    useEffect(() => {
        setFlattenedPoints(
            points
                .concat(isPolyComplete ? [] : position)
                .reduce((a, b) => a.concat(b), [])
        );
    }, [points, isPolyComplete, position]);

    // useEffect(() => {
    //     setFlattenedPoints(
    //         nonEditableBbox
    //             .concat(isPolyComplete ? [] : position)
    //             .reduce((a, b) => a.concat(b), [])
    //     );
    // }, [nonEditableBbox, isPolyComplete, position]);

    const undo = () => {
        setPoints(points.slice(0, -1));
        setPolyComplete(false);
        setPosition(points[points.length - 1]);
    };
    const reset = () => {
        setPoints([]);
        setPolyComplete(false);
    };
    const handleGroupDragEnd = (e) => {

        if (e.target.name() === "polygon") {
            let result = [];
            let copyPoints = [...points];
            copyPoints.map((point) =>
                result.push([point[0] + e.target.x(), point[1] + e.target.y()])
            );
            e.target.position({ x: 0, y: 0 }); //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
            setPoints(result);
        }
    };


    useEffect(() => {
        const img = new window.Image();
        img.src = data;
        img.onload = () => {
            setImage(img);
            setOriginalWidth(imageWidth);
            setOriginalHeight(imageHeight);
        };
    }, [data]);

    useEffect(() => {
        // window.addEventListener("load", calculateAspectRatio())
        window.addEventListener("resize", getVidWindowSize);
    }, [])

    const getVidWindowSize = () => {
        const img = new window.Image();
        img.src = data;
        img.onload = () => {
            setImage(img);
            setOriginalWidth(imageWidth);
            setOriginalHeight(imageHeight);
        };
    };

    const calculateAspectRatio = () => {
        if (image) {
            const stage = document.getElementById('stage');
            const divHeight = stage.clientHeight;
            const divWidth = stage.clientWidth;
            const imageHeight = originalHeight
            const imageWidth = originalWidth
            // //////console.log(divHeight, divWidth, imageHeight, imageWidth, 'chalo')
            const asDiv = (divHeight / divWidth)
            const asImg = (imageHeight / imageWidth)
            const ratio = asDiv / asImg
            let svgWidth = 0
            let svgHeight = 0
            if (ratio >= 1) {
                svgWidth = divWidth
                svgHeight = asImg * divWidth
            } else {
                svgHeight = divHeight
                svgWidth = (1 / asImg) * divHeight
            }
            // //////console.log(svgWidth, svgHeight, 'depth')

            return { width: svgWidth, height: svgHeight };
        }
        return { width: 0, height: 0 };
    };

    const aspectRatio = calculateAspectRatio();

    const scaleFactor = originalHeight / aspectRatio.height;
    // //////console.log(scaleFactor, 'scaleFactor')
    // //////console.log(originalHeight, 'originalHeight')
    // //////console.log(aspectRatio.height, 'height12')
    // //////console.log(aspectRatio.width, 'width12')


    // const scaledPoint = [[1697, 0],[1710, 0],[1710, 159],[1697, 159]].map((eachPoint) => {
    //     //////console.log(eachPoint,'eachPoint')
    //     const xScaled = eachPoint[0] / scaleFactor
    //     const yScaled = eachPoint[1] / scaleFactor
    //     return [xScaled, yScaled]
    // })

    function getBoundingBoxFromCorners(corners) {
        // Find min and max coordinates
        const xs = corners.map(pair => pair[0]);
        const ys = corners.map(pair => pair[1]);
        const minX = Math.min(...xs);
        const minY = Math.min(...ys);
        const maxX = Math.max(...xs);
        const maxY = Math.max(...ys);

        // Calculate width and height
        const width = maxX - minX;
        const height = maxY - minY;

        // Return x, y, width, height
        return { x: minX, y: minY, width, height };
    }


    const scaledPoint = nonEditableBbox.map((item) => {
        //////console.log(item.pairs, 'johny')
        const bboxCoords = getBoundingBoxFromCorners(item.pairs);
        //////console.log(bboxCoords, 'bboxCoords')


        if (distressPinned !== null) {
            if (distressPinned.isRectangle === false) {

                // for (let i = 0; i < distressPinned.bbox.length; i += 2) {
                //     const x = distressPinned.bbox[i];
                //     const y = distressPinned.bbox[i + 1];
                //     const pointMatched = item.pairs.some(([px, py]) => Math.round(px) === Math.round(x) && Math.round(py) === Math.round(y));
                //     if (pointMatched) {
                //         isBboxSame = true;
                //         item.fill = distressPinnedSvgColor;
                //         break;
                //     }
                // }

                const flattenedPairs = item.pairs.flatMap(pair => pair);
                //////console.log(flattenedPairs, 'flattenedPairs')
                let isSame = true;

                if (flattenedPairs.length === distressPinned.bbox.length) {
                    for (let i = 0; i < flattenedPairs.length; i++) {
                        if (flattenedPairs[i] !== distressPinned.bbox[i]) {
                            isSame = false;
                            break;
                        }
                    }
                } else {
                    isSame = false;
                }

                if (isSame) {
                    item.fill = distressPinnedSvgColor;
                }

            }

            else {
                let isBboxSame = false;

                if (
                    Math.round(bboxCoords.x) === Math.round(distressPinned.bbox[0]) &&
                    Math.round(bboxCoords.y) === Math.round(distressPinned.bbox[1]) &&
                    Math.round(bboxCoords.width) === Math.round(distressPinned.bbox[2]) &&
                    Math.round(bboxCoords.height) === Math.round(distressPinned.bbox[3])
                ) {
                    isBboxSame = true;
                    item.fill = distressPinnedSvgColor
                }
            }
        }


        let coordinates = item.pairs.map(eachCoord => {
            const xScaled = eachCoord[0] / scaleFactor;
            const yScaled = eachCoord[1] / scaleFactor;
            return [xScaled, yScaled];
        }
        )
        return {
            defectName: item.defectName,
            fill: item.fill,
            pairs: [...coordinates, coordinates[0]]
        };
    });


    // const scaledPoint = [
    //     [[1697, 0], [1710, 0], [1710, 159], [1697, 159]],
    //     [[1000, 1310], [1059, 1310], [1059, 1465], [1000, 1465]]
    //   ].flat().map(coords => {
    //     const xScaled = coords[0] / scaleFactor;
    //     const yScaled = coords[1] / scaleFactor;
    //     return [xScaled, yScaled];
    //   });




    // //////console.log(scaledPoint, 'scaledPoint')
    const scaledFlattenedPoint = scaledPoint.flat()

    useEffect(() => {
        // //////console.log("first")
        // updateScaledPoints();
    }, [scaleFactor])

    const updateScaledPoints = () => {
        // //////console.log('updatecalled')
        if (!isNaN(scaleFactor)) {

            const scaledPoint = points.flat().map(coords => {
                const xScaled = coords[0] / scaleFactor;
                const yScaled = coords[1] / scaleFactor;
                return [xScaled, yScaled];
            });
            const scaledFlattenedPoint = scaledPoints.flat()
            setScaledPoints(scaledPoint)
            setScaledFlattenedPoints(scaledFlattenedPoint)
        }
    }

    return (
        <>
            {
                editBox ?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: ''
                        }}
                    >
                        <Button onClick={undo}>Undo</Button>
                        <Button onClick={reset}>Reset</Button>

                    </div>

                    :
                    <> </>
            }

            <div id='stage' style={{ height: `calc(${defaultHeight}vh - ${defaultPadding}px)`, width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '', cursor: isEdit ? 'pointer' : '' }}>

                <Stage
                    width={aspectRatio.width}
                    height={aspectRatio.height}
                    onMouseMove={handleMouseMove}
                    onMouseDown={handleMouseDown}
                >
                    {isFetched ? (

                        <Layer>

                            <Image
                                image={image}
                                width={aspectRatio.width} height={aspectRatio.height}
                            />
                            {
                                editBox ?

                                    <PolygonAnnotation
                                        // cusKey={unqId}
                                        points={points}
                                        flattenedPoints={flattenedPoints}
                                        handlePointDragMove={handlePointDragMove}
                                        handleGroupDragEnd={handleGroupDragEnd}
                                        handleMouseOverStartPoint={handleMouseOverStartPoint}
                                        handleMouseOutStartPoint={handleMouseOutStartPoint}
                                        isFinished={isPolyComplete}
                                        onValueChange={onValueChange}
                                        scaleFactor={scaleFactor}
                                    />

                                    :
                                    scaledPoint.map((point, index) => {
                                        return (
                                            <>
                                                <PolygonAnnotation
                                                    cusKey={index}
                                                    points={point.pairs}
                                                    fill={point.fill}
                                                    flattenedPoints={point.pairs.flat()}
                                                    defect={point.defectName}
                                                />
                                            </>
                                        )
                                    })

                                // <PolygonAnnotation
                                //     points={scaledPoint[0]}
                                //     flattenedPoints={scaledPoint[0].flat()}
                                // />

                            }
                        </Layer>
                    ) : null}

                </Stage>

            </div>



        </>
    );
};

export default ImageAnnotatorV3;