import React, { createContext, useState, useEffect, useContext } from 'react';

const accessControlContext = createContext();
export const useAccessControlContext = () => {
    return useContext(accessControlContext);
};

const AccessControlProvider = ({ children }) => {
    const [role, setRole] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [accessManagementData, setAccessManagementData] = useState(null);
    const fetchDefaultPermissions = async () => {
        try {
            const response = await fetch('https://us-central1-deeprowserverless.cloudfunctions.net/userAccessControlCallableV2-getUACLegends');
            const data = await response.json();
            const responseMsg = data.responseMsg;
            setAccessManagementData(responseMsg);
            setPermissions(responseMsg.defaultPermissions);
        } catch (error) {
            console.error('Error fetching default permissions:', error);
        }
    };
    useEffect(() => {
        fetchDefaultPermissions();
    }, []);

    const setUserType = (userType) => {
        let newPermissions = {};
        switch (userType) {
            case 0:
                newPermissions = accessManagementData?.permissions[userType];
                break;
            case 1:
                newPermissions = accessManagementData?.permissions[userType];
                break;
            case 2:
                newPermissions = accessManagementData?.permissions[userType];
                break;
            case 3:
                newPermissions = accessManagementData?.permissions[userType];
                break;
            case -1:
                newPermissions = accessManagementData?.ownerPermissions;
                break;
            default:
                newPermissions = accessManagementData?.defaultPermissions;
        }
        setRole(userType);
        setPermissions(newPermissions);
    };

    return (
        <accessControlContext.Provider value={{ role, permissions, setUserType }}>
            {children}
        </accessControlContext.Provider>
    );
};
export default AccessControlProvider;