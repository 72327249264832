import OrdersListAdapter from "./orderHistoryComponents/OrdersListAdapter";
import { connect } from "react-redux";
import { dateConverter } from "./../components/helper/Helper"
import firebase from './../../../../../config/firebaseConfig'
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "@mui/material";


const OrderHistory = (props) => {
    const { companyOrdersHistory } = props;
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
    const isLargeScreen = useMediaQuery('(min-width:961px)');
    const limit = isSmallScreen ? 2 : isMediumScreen ? 3 : isLargeScreen ? 3 : 4;
    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };

    useEffect(() => {
        const fetchData = async () => {
            await firebase.firestore().collection('ProductOrders')
                .orderBy('timestamp', 'desc')
                .where('coid', '==', `${props.companyData.coid}`)
                .limit(limit)
                .onSnapshot(function (querySnapshot) {
                    var items = [];
                    querySnapshot.forEach(function (doc) {
                        items.push({ key: doc.id, ...doc.data() });
                    });
                    // //////console.log('first item ', items[0])
                    setList(items);
                })
        };
        fetchData();
    }, []);

    const showNext = ({ item }) => {
        if (list.length === 0) {
            alert("Thats all we have for now !")
        } else {
            const fetchNextData = async () => {
                await firebase.firestore().collection('ProductOrders')
                    .orderBy('timestamp', 'desc')
                    .where('coid', '==', `${props.companyData.coid}`)
                    .limit(limit)
                    .startAfter(item.timestamp)
                    .onSnapshot(function (querySnapshot) {
                        const items = [];
                        querySnapshot.forEach(function (doc) {
                            items.push({ key: doc.id, ...doc.data() });
                        });
                        setList(items);
                        setPage(page + 1)
                        // //////console.log(page)
                    })
            };
            fetchNextData();
        }
    };

    const showPrevious = ({ item }) => {
        const fetchPreviousData = async () => {
            await firebase.firestore().collection('ProductOrders')
                .orderBy('timestamp', 'desc')
                .where('coid', '==', `${props.companyData.coid}`)
                .endBefore(item.timestamp)
                .limitToLast(limit)
                .onSnapshot(function (querySnapshot) {
                    const items = [];
                    querySnapshot.forEach(function (doc) {
                        items.push({ key: doc.id, ...doc.data() });
                    });
                    setList(items);
                    setPage(page - 1)
                    // //////console.log(page)
                })
        };
        fetchPreviousData();
    };

    if (!companyOrdersHistory) {
        return <div>Loading...</div>;
    }

    const ordersList = Object.values(list).map((eachOrder, index) => (
        {
            credits: eachOrder.credits,
            itemName: eachOrder.itemName,
            date: dateConverter(eachOrder.timestamp.seconds * 1000).parsedDate,
            orderId: eachOrder.orderId
        }));
    // //////console.log(ordersList, 'orderlist');
    if (ordersList.length === 0) {
        return <>No orders available!</>
    }


    return (
        <>
            <OrdersListAdapter totalDataLength={companyOrdersHistory.length} limit={limit} ordersList={ordersList} list={list} page={page} showPrevious={showPrevious} showNext={showNext} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        companyData: state.firestore.data.Company,
        payment: state.payment,
    };
};
export default connect(mapStateToProps)(OrderHistory);
