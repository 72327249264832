
import { cloudFunction } from '../../config/firebaseConfig';
// README: NETWORK FUNCTIONS
export const createNetwork = (networkTitle, projectType, ssList, proidList, typeOfRoad) => {
    return async (dispatch) => {
        dispatch({
            type: "CREATE_NETWORK_CALL_INIT",
            createNetworkMessage: "",
            createNetworkLoader: true,
            createNetworkIsDoneAndSent: null
        });

        await cloudFunction.httpsCallable('networks-createNetwork')(
            {
                "networkTitle": networkTitle,
                "projectType": Number(projectType),
                "ssList": JSON.stringify(ssList),
                "proidList": JSON.stringify(proidList),
                "typeOfRoad": typeOfRoad,
            })
            .then((res) => {
                // console.log(res, "res")
                dispatch({
                    type: "CREATE_NETWORK_CALL_COMPLETED",
                    createNetworkMessage: res.data.responseMsg,
                    createNetworkLoader: false,
                    createNetworkIsDoneAndSent: res.data.isDoneAndSent,
                });
            }).catch((error) => {
                // console.log(error, "error")
                dispatch({
                    type: "CREATE_NETWORK_CALL_ERROR",
                    createNetworkMessage: "API failed to be called",
                    createNetworkLoader: false,
                    createNetworkIsDoneAndSent: false,
                });
            });
    }
};
export const clearCreateNetworkAndMsg = () => {
    return async (dispatch) => {
        dispatch({
            type: "CLEAR_CREATE_NETWORK_ERROR_AND_MSG"
        });
    }
}
export const updateNetwork = (networkTitle, networkId) => {
    return async (dispatch) => {
        dispatch({
            type: "UPDATE_NETWORK_CALL_INIT",
            updateNetworkMessage: "",
            updateNetworkLoader: true,
            updateNetworkIsDoneAndSent: null
        });

        await cloudFunction.httpsCallable('networks-modifyNetwork')(
            {
                "networkTitle": networkTitle,
                "networkId": networkId
            })
            .then((res) => {
                //console.log(res, "res")
                dispatch({
                    type: "UPDATE_NETWORK_CALL_COMPLETED",
                    updateNetworkMessage: res.data.responseMsg,
                    updateNetworkLoader: false,
                    updateNetworkIsDoneAndSent: res.data.isDoneAndSent,
                });
            }).catch((error) => {
                // //console.log(error, "error")
                dispatch({
                    type: "UPDATE_NETWORK_CALL_ERROR",
                    updateNetworkMessage: "API failed to be called",
                    updateNetworkLoader: false,
                    updateNetworkIsDoneAndSent: false,
                });
            });
    }
};
export const clearUpdateNetworkAndMsg = () => {
    return async (dispatch) => {
        dispatch({
            type: "CLEAR_UPDATE_NETWORK_ERROR_AND_MSG"
        });
    }
}
export const archiveNetwork = (networkId) => {
    return async (dispatch) => {
        dispatch({
            type: "ARCHIVE_NETWORK_CALL_INIT",
            archiveNetworkMessage: "",
            archiveNetworkLoader: true,
            archiveNetworkIsDoneAndSent: null
        });

        await cloudFunction.httpsCallable('networks-archiveNetwork')(
            {
                "networkId": networkId
            })
            .then((res) => {
                //console.log(res, "res")
                dispatch({
                    type: "ARCHIVE_NETWORK_CALL_COMPLETED",
                    archiveNetworkMessage: res.data.responseMsg,
                    archiveNetworkLoader: false,
                    archiveNetworkIsDoneAndSent: res.data.isDoneAndSent,
                });
            }).catch((error) => {
                // //console.log(error, "error")
                dispatch({
                    type: "ARCHIVE_NETWORK_CALL_ERROR",
                    archiveNetworkMessage: "API failed to be called",
                    archiveNetworkLoader: false,
                    archiveNetworkIsDoneAndSent: false,
                });
            });
    }
};
export const clearArchiveNetworkAndMsg = () => {
    return async (dispatch) => {
        dispatch({
            type: "CLEAR_ARCHIVE_NETWORK_ERROR_AND_MSG"
        });
    }
}
export const setupNetwork = (networkId, ssList, proidList) => {
    return async (dispatch) => {
        dispatch({
            type: "SETUP_NETWORK_CALL_INIT",
            setupNetworkMessage: "",
            setupNetworkLoader: true,
            setupNetworkIsDoneAndSent: null
        });

        await cloudFunction.httpsCallable('networks-adjustNetworkParameters')(
            {
                "networkId": networkId,
                "ssList": JSON.stringify(ssList),
                "proidList": JSON.stringify(proidList)
            })
            .then(async (res) => {
                dispatch({
                    type: "SETUP_NETWORK_CALL_COMPLETED",
                    setupNetworkMessage: res.data.responseMsg,
                    setupNetworkLoader: false,
                    setupNetworkIsDoneAndSent: res.data.isDoneAndSent,
                });
            }).catch((error) => {
                //console.log(error, "error")
                dispatch({
                    type: "SETUP_NETWORK_CALL_ERROR",
                    setupNetworkMessage: "API failed to be called",
                    setupNetworkLoader: false,
                    setupNetworkIsDoneAndSent: false,
                });
            });
    }
};
export const clearSetupNetworkAndMsg = () => {
    return async (dispatch) => {
        dispatch({
            type: "CLEAR_SETUP_NETWORK_ERROR_AND_MSG"
        });
    }
}
export const cciCalculation = (networkId, weightages, bais) => {
    return async (dispatch) => {

        dispatch({
            type: "CCI_CALC_START",
            cciCalcMessage: "",
            cciCalcLoader: true,
            cciCalcIsDoneAndSent: null,
        });

        cloudFunction.httpsCallable('networks-requestPciCalculation')(
            {
                "networkId": networkId,
                "weightages": JSON.stringify(weightages),
                "bais": JSON.stringify(bais)
            })
            .then((res) => {
                //console.log(res, "res")
                dispatch({
                    type: "CCI_CALC_COMPLETED",
                    cciCalcMessage: res.data.responseMsg,
                    cciCalcIsDoneAndSent: res.data.isDoneAndSent,
                    cciCalcLoader: false,
                });
            }).catch((error) => {
                console.error(error);
                dispatch({
                    type: "CCI_CALC_ERROR",
                    cciCalcMessage: "API failed to be called",
                    cciCalcIsDoneAndSent: false,
                    cciCalcLoader: false,
                });
            });
    }
}

export const clearCciCalcState = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: "CLEAR_CCI_CALC",
        });
    }
}
export const superSectionSetting = (conditionMethod, networkId) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        await firestore.collection('DatabaseInstances').doc(networkId).update({
            conditionMethod: conditionMethod
        });
    };
}

// README: SUPERSECTION FUNCTIONS
export const createSuperSection = (routePlannerData, superSectionTitle) => {
    return async (dispatch) => {
        dispatch({
            type: "CREATE _SUPERSECTION_START",
            createSuperSectionMessage: "",
            createSuperSectionLoader: true,
            createSuperSectionIsDoneAndSent: null,
        });
        cloudFunction.httpsCallable('networks-createSupersection')(
            {
                "routePlannerData": routePlannerData,
                "superSectionTitle": superSectionTitle
            })
            .then((res) => {
                //console.log(res.data, "response")
                dispatch({
                    type: "CREATE _SUPERSECTION_COMPLETED",
                    createSuperSectionMessage: res.data.responseMsg,
                    createSuperSectionIsDoneAndSent: res.data.isDoneAndSent,
                    createSuperSectionLoader: false,
                });
            }).catch((error) => {
                dispatch({
                    type: "CREATE _SUPERSECTION_ERROR",
                    createSuperSectionMessage: "API failed to be called",
                    createSuperSectionIsDoneAndSent: false,
                    createSuperSectionLoader: false,
                });
            });
    }
}
export const clearCreateSuperSectionErrorAndMsg = () => {
    return async (dispatch) => {
        dispatch({
            type: "CLEAR_SUPERSECTION_ERROR_AND_MSG"
        });
    }
}