import React from 'react'
import { Paper, Typography, Box, TextField } from '@mui/material'
const ControlRoomResources = ({ pipelineResources }) => {
    return (
        <div style={{ marginTop: "20px" }}>
            <Paper>
                <Box p={4} >
                    <Typography variant="h5">Assigned Reources</Typography>
                    <Box display="flex" alignItems="center" sx={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ textAlign: 'left', width: "130px" }}>CPU:</Typography>
                        <TextField disabled value={pipelineResources.assignedResource.CPU} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            marginLeft: "10px"
                        }} size='small' />
                        <Typography variant='h6'
                            sx={{ width: "70px", marginLeft: '15px', textAlign: 'left', padding: "2px" }}
                        >DISK:</Typography>
                        <TextField
                            disabled
                            value={pipelineResources.assignedResource.DISK}
                            sx={{
                                marginLeft: '10px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },

                            }}
                            size='small'
                        />

                    </Box>
                    <Box display="flex" alignItems="center" sx={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ textAlign: 'left', width: "130px" }}>MEMORY:</Typography>
                        <TextField disabled value={pipelineResources.assignedResource.MEMORY} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            marginLeft: "10px"
                        }} size='small' />
                        <Typography variant='h6'
                            sx={{ width: "70px", marginLeft: '15px', textAlign: 'left', padding: "2px" }}
                        >VM:</Typography>
                        <TextField
                            disabled
                            value={pipelineResources.assignedResource.VM}
                            sx={{
                                marginLeft: '10px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },

                            }}
                            size='small'
                        />

                    </Box>
                    <Typography variant="h5" sx={{ marginTop: "20px" }}>Reserved Resource:</Typography>
                    <Box display="flex" alignItems="center" sx={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ textAlign: 'left', width: "130px" }}>CPU:</Typography>
                        <TextField disabled value={pipelineResources.reservedResource.CPU} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            marginLeft: "10px"
                        }} size='small' />
                        <Typography variant='h6'
                            sx={{ width: "70px", marginLeft: '15px', textAlign: 'left', padding: "2px" }}
                        >DISK:</Typography>
                        <TextField
                            disabled
                            value={pipelineResources.reservedResource.DISK}
                            sx={{
                                marginLeft: '10px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },

                            }}
                            size='small'
                        />

                    </Box>
                    <Box display="flex" alignItems="center" sx={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ textAlign: 'left', width: "130px" }}>MEMORY:</Typography>
                        <TextField disabled value={pipelineResources.reservedResource.MEMORY} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            marginLeft: "10px"
                        }} size='small' />
                        <Typography variant='h6'
                            sx={{ width: "70px", marginLeft: '15px', textAlign: 'left', padding: "2px" }}
                        >VM:</Typography>
                        <TextField
                            disabled
                            value={pipelineResources.reservedResource.VM}
                            sx={{
                                marginLeft: '10px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },

                            }}
                            size='small'
                        />

                    </Box>
                    <Typography variant="h5" sx={{ marginTop: "20px" }}>Projects:</Typography>
                    <Box display="flex" alignItems="center" sx={{ marginTop: "20px" }}>
                        <Typography variant="h6" sx={{ textAlign: 'left', width: "210px" }}>No of Active projects:</Typography>
                        <TextField disabled value={pipelineResources.activeProjects.length} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            marginLeft: "10px"
                        }} size='small' />
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ marginTop: "20px" }}>
                        <Typography variant='h6'
                            sx={{ width: "210px", textAlign: 'left' }}
                        >No of Queued projects:</Typography>

                        <TextField
                            disabled
                            value={pipelineResources.queuedProjects.length}
                            sx={{
                                marginLeft: '10px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },

                            }}
                            size='small'
                        />
                    </Box>

                </Box>
            </Paper>
        </div>
    )
}

export default ControlRoomResources