import { List } from "@mui/material";
import navigationMap from './navigationMap.json'
import AccountNavigationItem from "./AccountNavigationItem";
import { Divider } from "@mui/material";
import { Typography } from '@mui/material';

const AccountNavigationsListAdapter = (props) => {
    const { handleNavigationSelection, isCreator, stripeCusId, stripeDefaultPmid } = props

    // const needToAttachCard = true;
    const needToAttachCard = stripeDefaultPmid && stripeCusId ? stripeDefaultPmid !== '' && stripeCusId !== '' ? false : true : true;
    const navigationObjectList = Object.values(navigationMap.navigations)
    var navigationList = !needToAttachCard ? navigationObjectList.filter((eachNavigationItem) => eachNavigationItem.key !== '4') : navigationObjectList.filter((eachNavigationItem) => eachNavigationItem.key === '4')
    navigationList = !isCreator ? navigationList.filter((eachNavigationItem) => eachNavigationItem.key !== '3') : navigationList;
    return (
        <div style={{}}>

            <Typography variant="h5" style={{ padding: '15px 10px 10px 15px', fontWeight: '650', fontSize: "17px", marginBottom: '5px' }}>
                Account settings
            </Typography>

            <Divider />
            <List disablePadding>
                {navigationList.map((eachNavigationItem, i) => {
                    return <AccountNavigationItem key={eachNavigationItem.key} navigationItem={eachNavigationItem} handleNavigationSelection={handleNavigationSelection} />
                })}
            </List>
        </div>
    );
}

export default AccountNavigationsListAdapter;