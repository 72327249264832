import { Card, Grid, Paper, Typography, styled } from '@mui/material'
import React from 'react'

const PinnedDistressFields = (props) => {
    const { dataInfo, distressPinned, drProjectData } = props;
    // //////console.log(distressPinned,"distressPinnedabdullah")
    const { defectName, profile, severity, transversePosition, type, wheelPath } = dataInfo;

    const showAreaFieldList = ['0', '1', '2', '5', '10'];
    const showDepthFieldList = ['0', '4'];
    const showTransverseSpanFieldList = ['1', '2', '3', '5', '10'];
    const showLongitudinalSpanFieldList = ['1', '2', '3', '5', '10'];
    const showTypeFieldList = ['1'];
    const showSeverityFieldList = ['3', '10'];
    const showProfileFieldList = ['2'];
    const showWidthFieldList = ['4'];
    const showWheelPathFieldList = ['4'];
    const showNegativeStatusFieldList = ['-1'];
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));
    const fieldStyle = {
        "border": "2px solid grey",
        "borderRadius": "5px",
        "padding": '5px',
        "fontSize": '0.8rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
    };
    return (
        <Grid container>
            <Grid item xs={6} >
                <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
                    <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Defect category:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {`${defectName[distressPinned?.defectName]['name']}`}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item>
                    {showAreaFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.area}`) ? <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Area (m<sup>2</sup>):
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {Number(distressPinned?.area).toFixed(2)}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item> : <></>}
                    <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Transverse position:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {`${transversePosition[distressPinned?.transversePosition]['name']}`}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item>
                    {showTypeFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.type}`) ? <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Type:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {`${type[distressPinned?.type]['name']}`}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item> : <></>}
                    {showSeverityFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.severity}`) ? <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Severity:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {`${severity[distressPinned?.severity]['name']}`}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item> : <></>}
                    {showProfileFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.profile}`) ? <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Profile:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {`${profile[distressPinned?.profile]['name']}`}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item> : <></>}
                    {showWidthFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.width}`) ? <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Width:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {Number(distressPinned?.width).toFixed(2)}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item> : <></>}
                </div>
            </Grid>
            {/* Section 2 */}
            <Grid item xs={6} >
                <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
                    <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    GPS location:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {`${distressPinned?.geo[0]},${distressPinned?.geo[1]}`}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item>
                    {showTransverseSpanFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.transverseSpan}`) ? <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Transverse span:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {Number(distressPinned?.transverseSpan).toFixed(2)}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item> : <></>}
                    {showLongitudinalSpanFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.longitudinalSpan}`) ? <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Longitudinal span:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {Number(distressPinned?.longitudinalSpan).toFixed(2)}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item> : <></>}
                    {showDepthFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.depth}`) ? <Item>
                        {
                            drProjectData.projectType === 4 ?
                                <></>
                                :
                                <Grid container>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }} >
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            Depth (mm):
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Card sx={fieldStyle}>
                                            <Typography variant="inherit">
                                                {Number(distressPinned?.depth * 1000).toFixed(2)}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                </Grid>
                        }
                    </Item> : <></>}
                    {showWheelPathFieldList.includes(`${distressPinned.defectName}`) && !showNegativeStatusFieldList.includes(`${distressPinned?.wheelPath}`) ? <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Wheel path:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {`${wheelPath[distressPinned?.wheelPath]['name']}`}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item> : <></>}
                </div>
            </Grid>
        </Grid>
    )
}

export default PinnedDistressFields