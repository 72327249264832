import { useEffect, useRef, useState } from "react";
import {
    Player, BigPlayButton, ControlBar, PlayToggle, PlaybackRateMenuButton, Shortcut,
    VolumeMenuButton, ReplayControl, ForwardControl, CurrentTimeDisplay, FullscreenToggle,
} from 'video-react';
import POSTER from '../../../../../../assets/bg/POSTER1.png'
const AddNewAssetVideoPlayer = ({ shouldOffset = true, startTime = 0, link, height, control = true, fps = 24, disableControlsFully = false }) => {
    const [videoPlayerState, setVideoPlayerState] = useState(null)
    const [currentFrame, setCurrentFrame] = useState('');
    const playerRef = useRef();
    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });

    useEffect(() => {
        const canvas = document.createElement('canvas');
        const video = playerRef?.current?.video.video;
        // video.autoplay = true;
        // video.muted = true;

        video.addEventListener('loadedmetadata', () => {
            const ctx = canvas.getContext('2d');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            // //////console.log(canvas.width, canvas.height,"canvas")
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            // video.pause();
            setCurrentFrame(canvas.toDataURL("image/png"));
        });
    }, [playerRef])
    // //////console.log(currentFrame, "dataaa")
    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const handleBind = (state) => {
        setVideoPlayerState(state);
    }

    useEffect(() => {
        if (!videoPlayerState?.isActive) {
            playerRef.current?.play()
            playerRef.current?.pause()
        }
    }, videoPlayerState)

    return (
        <>
            {/* {currentFrame !== '' ? <img src={currentFrame} alt="current frame" height="50px" width="50px" /> : <p>nothing</p>} */}
            <Player ref={playerRef} startTime={shouldOffset ? (startTime + (1 / fps)) : startTime}
                preload="auto" autoPlay={false} muted={true} src={link} poster={POSTER} fluid={false} height={height} width="auto" playsInline={false}>
                <Shortcut clickable={true} />
                <BigPlayButton position="center" />
                {/* <PlayToggle/> */}
                <ControlBar className="video-styler" autoHide={false} disableDefaultControls={control} disableCompletely={disableControlsFully} />

            </Player>
        </>
    )
}

export default AddNewAssetVideoPlayer