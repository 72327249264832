import React, { useState, useEffect, useMemo } from "react";
import AfterImageInferenceDashboard from "./AfterImageInferenceDashboard";

const VisualizerDashboardWrapper = (props) => {
    const { proid, visualizerProjectPredictions, filteredGpsData360, link, drProjectData } = props;
    const [segmentIndex, setSegmentIndex] = useState(0);
    const [markerData, setMarkerData] = useState([]);
    const sectionsArray = useMemo(() => Object.values(visualizerProjectPredictions), [visualizerProjectPredictions]);
    const markerDetections = useMemo(() => {
        return sectionsArray.flatMap((section) => Object.values(section.markerData))
            .sort((a, b) => (a.sectionId === b.sectionId ? a.frameNumber - b.frameNumber : a.sectionId - b.sectionId));
    }, [sectionsArray]);

    const mergeAndSortMarkerData = (sections) => {
        let combinedMarkerData = [];

        Object.keys(sections).forEach(sectionKey => {
            const section = sections[sectionKey];
            const markerData = section.markerData;

            Object.values(markerData).forEach(marker => {
                combinedMarkerData.push(marker);
            });
        });

        combinedMarkerData.sort((a, b) => a.frameNumber - b.frameNumber);

        return combinedMarkerData;
    };

    useEffect(() => {
        const data = mergeAndSortMarkerData(visualizerProjectPredictions);
        setMarkerData(data);
    }, [visualizerProjectPredictions]);


    const option = useMemo(() => ({
        autoplay: true,
        controls: true,
        responsive: true,
        muted: true,
        fluid: true,
        preload: "auto",
        sources: [
            {
                src: link,
                type: "video/mp4",
            },
        ],
    }), []);


    return (
        <AfterImageInferenceDashboard
            proid={proid}
            filteredGpsData360={filteredGpsData360}
            option={option}
            markerDetections={markerDetections}
            segmentIndex={segmentIndex}
            markerData={markerData}
        />
    );
};

export default VisualizerDashboardWrapper;
