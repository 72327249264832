import React from 'react';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import './index.css';
import releaseNotes from "./constants/releaseNotes.json"
import App from './App';
// import 'materialize-css';
// import 'materialize-css/dist/css/materialize.min.css';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider, useSelector } from 'react-redux';
import mainReducer from './store/reducers/mainReducer';
import {
  reduxFirestore,
  getFirestore,
  createFirestoreInstance
} from "redux-firestore";
import {
  ReactReduxFirebaseProvider,
  getFirebase,
  isLoaded
} from "react-redux-firebase";
import firebaseConfig from './config/firebaseConfig';
import firebase from "firebase/app";
import thunk from 'redux-thunk';
import { Box, CircularProgress, Container } from '@mui/material';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
// import LOGO from '../src/assets/bg/deepRoadLogo1.png'
import LOGO from '../src/assets/bg/roadGaugeLogo2.png'

import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CustomProgress from './components/cidgets/CustomProgress';
import AccessControlProvider from './contexts/AccessControlProvider'
import NetworkProvider from './contexts/NetworkProvider';
import TooltipProvider from './contexts/TooltipContext';
// Creating store with middleware
const store = createStore(
  mainReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirestore, getFirebase })),
    reduxFirestore(firebaseConfig)
  )
);

const isTestApp = releaseNotes.dev;
const stripePromise = loadStripe(
  // strada imaging
  // 'pk_test_51IFPQsBIUqosrDFivcG44UteqGhIbq6TMaDdsxx6mgytMhY2V4dJFrZ4PH6bXcOADzZkIVfJoC7Yr3EEBRndS2ha00RPgxdDyi' // testing
  // 'pk_live_51IFPQsBIUqosrDFieKgPqMSyTnMCw44IWmZAxuQqOKLUvSxYNuMWpUeDd0auBVcpvkaO4w204GZRTD3LnsAtYdj200YYuyW9sN' // production
  // axibuild
  // 'pk_test_51JVJ0MGOQiaX4onHu178pWMRRGnnHWOi7KMM8AlRegtsTRKWcQVBZQY4x0nyOkuAddP1vYU1lcRduXtNNz73UVaL009l1XsGMY' // testing
  // 'pk_live_51JVJ0MGOQiaX4onHIKU1jOuyE4Ma6DqaH7Qv7HWfjpOUGe6Xeb2Fru6qHr1EmgL67Mqe40AgZ2N5P5qO9yQ5NkQ100pglw2MHU' // production

  // isTestApp === true ? 'pk_test_51IFPQsBIUqosrDFivcG44UteqGhIbq6TMaDdsxx6mgytMhY2V4dJFrZ4PH6bXcOADzZkIVfJoC7Yr3EEBRndS2ha00RPgxdDyi' :
  //   'pk_live_51IFPQsBIUqosrDFieKgPqMSyTnMCw44IWmZAxuQqOKLUvSxYNuMWpUeDd0auBVcpvkaO4w204GZRTD3LnsAtYdj200YYuyW9sN'
  isTestApp === true ? 'pk_test_51OO94WFkhkjEm2EtoAnK5Tvi61adCFYP4kRVOjguhKTz5oIpeEq5hoW9i5tzDIe0HAGKiz3WBeBtEM3l8CHbXDsV00Mhv8Bulv' :
    'pk_live_51OO94WFkhkjEm2EtcrCXKpJj91KNNTKnbqoDZ1IYBpXwd4l7pSqPoIgXvQBT2VmiXAlpTBGPAYMU1AG3QTZOl58p00jE933bdD'
);

// Adding persistence to store
const persistor = persistStore(store);

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiBox: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
  },
});
// Read user profile from here
const rrfConfig = {
  userProfile: 'Users',
  useFirestoreForProfile: true
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};


function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  // //////console.log = () => { }
  if (!isLoaded(auth)) {
    // if (true) {
    return (
      <Box display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh" className="loading-screen">
        <Container fixed sx={{ textAlign: 'center' }}>
          <img src={LOGO} alt="CyclePath" width="150px" height="auto" />
          <br />
          <br />
          <p className='font-link' style={{ color: '#141414', fontSize: '30px' }}><b>{releaseNotes.name}</b></p>
          <CustomProgress />
          <br />
          <br />
          <p className='font-link' style={{ color: '#141414', fontSize: '18px' }}>A product of <u><a href={releaseNotes.company.website} style={{ color: "#141414", textDecoration: 'none' }}>{releaseNotes.company.name}</a></u></p>
        </Container>
      </Box>
    )
  };
  return children
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Elements stripe={stripePromise}>
              <div className='App-SignedIn'>
                <ThemeProvider theme={theme}>
                  <AccessControlProvider>
                    <NetworkProvider>
                      <TooltipProvider>
                        <App />
                      </TooltipProvider>
                    </NetworkProvider>
                  </AccessControlProvider>
                </ThemeProvider>
              </div>
            </Elements>
          </BrowserRouter>
        </PersistGate>
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
