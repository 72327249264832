import OrderDetailsCard from "./OrderDetailsCard";
import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ButtonGroup } from '@mui/material';
import Pagination from '@mui/material/Pagination';

const OrdersListAdapter = (props) => {
    const { ordersList, list, showPrevious, showNext, totalDataLength, limit } = props;
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const handleChange = (event, value) => {
        if (value > currentPage) {
            handleNext(value)
        }
        else if (value < currentPage) {
            handlePrevious(value)
        }
        setCurrentPage(value);
        setPage(value);
    };
    const handleNext = (value) => {
        showNext({ item: list[list.length - 1] })
    }
    const handlePrevious = (value) => {
        showPrevious({ item: list[0] })

    }
    return (
        <div style={{height:'100%',background:''}}>
        <div style={{height:'85%',background:''}}>
        {ordersList?.map((eachOrder, i) => {
                return <OrderDetailsCard orderDetails={eachOrder} key={eachOrder.id} />
            })}
        </div>
          <div style={{height:'15%',background:''}}>
          <Pagination count={Math.ceil(totalDataLength / limit)} page={page} onChange={handleChange}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }} />
          </div>
        </div>
    );
}


export default OrdersListAdapter;