import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import moment from 'moment';
import React from 'react';
import DateParser from '../../../../helpers/DateParser';
// import ReactAnimatedWeather from 'react-animated-weather';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        // maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
    },
    weatherIcon: {
        width: '30px',
        height: '30px',
        marginRight: '10px',
    },
    temperatureContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    temperature: {
        fontSize: '12px',
        color: '#f36f6f',
        margin: 0,
        marginRight: '10px',
    },
}));
const SubRouteList = ({ routesData, showSubRoute }) => {
    const classes = useStyles();
    // Sample list data with objects containing headings and descriptions
    if (routesData === null || routesData === undefined) {
        return <> No subroutes found</>
    }
    // function mapWeatherToPackage(weatherDescription) {
    //     const mapping = {
    //         "Sunny": "CLEAR_DAY",
    //         "Clear sky": "CLEAR_DAY",
    //         "Light clouds": "PARTLY_CLOUDY_DAY",
    //         "Partly cloudy": "PARTLY_CLOUDY_DAY",
    //         "Cloudy": "CLOUDY",
    //         "Rain": "RAIN",
    //         "Rain and snow / sleet": "RAIN",
    //         "Snow": "SNOW",
    //         "Rain shower": "RAIN",
    //         "Snow shower": "SNOW",
    //         "Sleet shower": "SLEET",
    //         "Light Fog": "FOG",
    //         "Dense fog": "FOG",
    //         "Freezing rain": "RAIN",
    //         "Thunderstorms": "RAIN",
    //         "Drizzle": "RAIN",
    //         "Sandstorm": "SAND"
    //     };

    //     return mapping[weatherDescription] || "UNKNOWN_PACKAGE_CODE";
    // }
    return (
        <div className={classes.root}>
            <List component="nav" sx={{ height: '100%', overflow: 'auto' }}>
                {routesData.filter(route => route.score > 50 && route.scoreRev > 50).map((item, index) => (
                    <ListItem button key={index} onClick={() => showSubRoute(item)} className={classes.listItem}>

                        {/* <ListItemIcon sx={{ background: '', display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                            <ReactAnimatedWeather
                                icon={mapWeatherToPackage(item.weather.condition)}
                                color={'black'}
                                size={50}
                                animate={true}
                            />
                        </ListItemIcon> */}
                        <ListItemText
                            // sx={{ marginLeft: '10px' }}
                            primary={<Typography variant="body1" sx={{ fontWeight: 'bold', color: '#000000' }}>
                                {DateParser(item.date, 0)}
                            </Typography>}

                            secondary={
                                <Typography variant="body2" sx={{ color: '#000000' }}>
                                    {`${item.startLocalTime ? DateParser(item.startLocalTime, 7) : DateParser(item.startTime, 5)}
                                    - ${item.endLocalTime ? DateParser(item.endLocalTime, 7) : DateParser(item.endTime, 5)}
                                    `}
                                    {/* {`${DateParser(item.startLocalTime ? item.startLocalTime : item.startTime, item.startLocalTime ? 7 : 5)} - ${DateParser(item.endLocalTime ? item.endLocalTime : item.endTime, item.endLocalTime ? 7 : 5)}`} */}
                                    {/* <br />
                                    {`Weather:  ${item.weather.condition}`} */}
                                </Typography>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </div >
    );
}

export default SubRouteList 