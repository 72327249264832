import { Card, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';
const PotholeEditFields = ({ isQuickEdit, handleChange, basicImagingDataInfo, fieldValues, pinned }) => {
    // const allowedVegetationProblemsType = ['0'];
    // const allowedCarriagewayDefectsType = ['1'];
    // const allowedDepthProperty = ['1'];

    const fieldStyle = {
        "border": "2px solid grey",
        "borderRadius": "5px",
        "padding": '5px',
        "fontSize": '0.8rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
    }
    const divFieldStyles = {
        "border": '1px solid rgba(0, 0, 0, 0.23)',
        "borderRadius": "4px",
        "padding": '10px 8px',
        "fontSize": '0.95rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
        // "backgroundColor": '#f5f5f5',
        "color": 'rgba(0,0,0,1)',

    };
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));

    // const sideMap = {
    //     "map": (() => {
    //         const usefulKeys = ['0', '1'];
    //         return usefulKeys.reduce((result, key) => {
    //             result[key] = basicImagingDataInfo['side'][key]['name'];
    //             return result;
    //         }, {});
    //     })(),
    //     "keys": Object.keys(basicImagingDataInfo['side']),
    //     "values": Object.values(basicImagingDataInfo['side']).map(value => value.name),
    //     "usefulKeys": ['0', '1'],
    // }
    const basicImagingClassesMap = {
        "map": (() => {
            const usefulKeys = ['0'];
            return usefulKeys.reduce((result, key) => {
                result[key] = basicImagingDataInfo['defectName'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(basicImagingDataInfo['defectName']),
        "values": Object.values(basicImagingDataInfo['defectName']).map(value => value.name),
        "usefulKeys": ['0'],
        // 'BasicImagingClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
        // "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
        // "trafficSign": ['2'],
    }
    // const lateralPositionMap = {
    //     "map": (() => {
    //         const usefulKeys = ['0', '1', '2', '3', '4', '5'];
    //         return usefulKeys.reduce((result, key) => {
    //             result[key] = basicImagingDataInfo['lateralPosition'][key]['name'];
    //             return result;
    //         }, {});
    //     })(),
    //     "keys": Object.keys(basicImagingDataInfo['lateralPosition']),
    //     "values": Object.values(basicImagingDataInfo['lateralPosition']).map(value => value.name),
    //     "usefulKeys": ['0', '1', '2', '3', '4', '5'],
    // }
    const priorityMap = {
        "map": (() => {
            const usefulKeys = ['0', '1', '2'];
            return usefulKeys.reduce((result, key) => {
                result[key] = basicImagingDataInfo['priority'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(basicImagingDataInfo['priority']),
        "values": Object.values(basicImagingDataInfo['priority']).map(value => value.name),
        "usefulKeys": ['0', '1', '2'],
    }
    // const lateralPositionClasses = lateralPositionMap.keys.filter(e => lateralPositionMap.usefulKeys.includes(e)).map((e, i) => {
    //     return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
    //         {`${lateralPositionMap.values[i]}`.replace('_', ' ')}
    //     </MenuItem>
    // })
    const priorityClasses = priorityMap.keys.filter(e => priorityMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${priorityMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })
    // const sideClasses = sideMap.keys.filter(e => sideMap.usefulKeys.includes(e)).map((e, i) => {
    //     return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
    //         {`${sideMap.values[i]}`.replace('_', ' ')}
    //     </MenuItem>
    // })
    const BasicImagingClasses = basicImagingClassesMap.keys.filter(e => basicImagingClassesMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${basicImagingClassesMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })

    return (
        <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
            <Item>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }} >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Feature category:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        {isQuickEdit ? <TextField
                            id="asset class"
                            select
                            value={fieldValues.basicImagingClassState}
                            onChange={handleChange("basicImagingClassState")}
                            size="small"
                            sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                        >
                            {BasicImagingClasses}
                        </TextField> : <Card sx={fieldStyle}>
                            <Typography variant="inherit">
                                {`${basicImagingClassesMap.map[fieldValues.basicImagingClassState]}`.replace('_', ' ')}
                            </Typography>
                        </Card>}

                    </Grid>
                </Grid>
            </Item>
            <Item>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }} >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Priority:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        {isQuickEdit ? <TextField
                            id="side class"
                            select
                            value={fieldValues.priorityState}
                            onChange={handleChange("priorityState")}
                            size="small"
                            sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                        >
                            {priorityClasses}
                        </TextField> : <Card sx={fieldStyle}>
                            <Typography variant="inherit">
                                {`${priorityMap.map[fieldValues.priorityState]}`}
                            </Typography>
                        </Card>}

                    </Grid>
                </Grid>
            </Item>
            {/* {allowedVegetationProblemsType.includes(`${fieldValues.basicImagingClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Side:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {isQuickEdit ? <TextField
                                id="side class"
                                select
                                value={fieldValues.sideState}
                                onChange={handleChange("sideState")}
                                size="small"
                                sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                            >
                                {sideClasses}
                            </TextField> : <Card sx={fieldStyle}>
                                <Typography variant="inherit">
                                    {`${sideMap.map[fieldValues.sideState]}`.replace('_', ' ')}
                                </Typography>
                            </Card>}

                        </Grid>
                    </Grid>
                </Item>
                : <></>} */}
            {/* {allowedVegetationProblemsType.includes(`${fieldValues.basicImagingClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Clearance height (m):
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {isQuickEdit ? <div style={divFieldStyles}>
                                <Typography variant="inherit">
                                    {pinned.pred.clearanceHeight ?? "To be calculated"}
                                </Typography>
                            </div>
                                : <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {pinned.pred.clearanceHeight ?? "To be calculated"}
                                    </Typography>
                                </Card>}
                        </Grid>
                    </Grid>
                </Item>
                : <></>} */}
            {/* {allowedVegetationProblemsType.includes(`${fieldValues.basicImagingClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Distance from the lane edge (m):
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {isQuickEdit ? <div style={divFieldStyles}>
                                <Typography variant="inherit">
                                    {pinned.pred.distanceFromTheLaneEdge ?? "To be calculated"}
                                </Typography>
                            </div> :
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {pinned.pred.distanceFromTheLaneEdge ?? "To be calculated"}
                                    </Typography>
                                </Card>}
                        </Grid>
                    </Grid>
                </Item>
                : <></>} */}
            {/* {allowedCarriagewayDefectsType.includes(`${fieldValues.basicImagingClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Type:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {isQuickEdit ? <TextField
                                id="type"
                                select
                                value={fieldValues.priorityState}
                                onChange={handleChange("priorityState")}
                                size="small"
                                sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                            >
                                {priorityClasses}
                            </TextField> : <Card sx={fieldStyle}>
                                <Typography variant="inherit">
                                    {`${priorityMap.map[fieldValues.priorityState]}`.replace('_', ' ')}
                                </Typography>
                            </Card>}

                        </Grid>
                    </Grid>
                </Item>
                : <></>} */}
            {/* {allowedCarriagewayDefectsType.includes(`${fieldValues.basicImagingClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Lateral position:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {isQuickEdit ? <TextField
                                id="type"
                                select
                                value={fieldValues.lateralPositionState}
                                onChange={handleChange("lateralPositionState")}
                                size="small"
                                sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                            >
                                {lateralPositionClasses}
                            </TextField> : <Card sx={fieldStyle}>
                                <Typography variant="inherit">
                                    {`${lateralPositionMap.map[fieldValues.lateralPositionState]}`.replace('_', ' ')}
                                </Typography>
                            </Card>}

                        </Grid>
                    </Grid>
                </Item>
                : <></>} */}

            {/* {allowedCarriagewayDefectsType.includes(`${fieldValues.basicImagingClassState}`) ?
                allowedDepthProperty.includes(`${fieldValues.priorityState}`) ?
                    <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Depth (m):
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                {isQuickEdit ? <div style={divFieldStyles}>
                                    <Typography variant="inherit">
                                        {pinned.pred.depth ?? "To be calculated"}
                                    </Typography>
                                </div> :
                                    <Card sx={fieldStyle}>
                                        <Typography variant="inherit">
                                            {pinned.pred.depth ?? "To be calculated"}
                                        </Typography>
                                    </Card>}
                            </Grid>
                        </Grid>
                    </Item>
                    : <></>
                : <></>} */}

            {/* {allowedCarriagewayDefectsType.includes(`${fieldValues.basicImagingClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Length (m):
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {isQuickEdit ? <div style={divFieldStyles}>
                                <Typography variant="inherit">
                                    {pinned.pred.length ?? "To be calculated"}
                                </Typography>
                            </div> :
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {pinned.pred.length ?? "To be calculated"}
                                    </Typography>
                                </Card>}
                        </Grid>
                    </Grid>
                </Item>
                : <></>} */}
            {/* {allowedCarriagewayDefectsType.includes(`${fieldValues.basicImagingClassState}`) ?
                <Item>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Width (m):
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            {isQuickEdit ? <div style={divFieldStyles}>
                                <Typography variant="inherit">
                                    {pinned.pred.width ?? "To be calculated"}
                                </Typography>
                            </div> :
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {pinned.pred.width ?? "To be calculated"}
                                    </Typography>
                                </Card>}
                        </Grid>
                    </Grid>
                </Item>
                : <></>} */}

            <Item>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                        sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            GPS location:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                        {isQuickEdit ? <div style={divFieldStyles}>
                            <Typography variant="inherit">
                                {pinned.pred.geo[0]},{pinned.pred.geo[1]}
                            </Typography>
                        </div> :
                            < Card sx={fieldStyle}>
                                <Typography variant="inherit">
                                    {pinned.pred.geo[0]},{pinned.pred.geo[1]}
                                </Typography>
                            </Card>}
                    </Grid>
                </Grid>
            </Item>
        </div>
    )
}

export default PotholeEditFields