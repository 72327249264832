import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SmartUtils from '../../../../../helpers/SmartUtils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(sectionName, startingGPS, endingGPS, condition, rating) {
    return { sectionName, startingGPS, endingGPS, condition, rating };
}
function newCreateData(sectionName, startingGPS, endingGPS, edgeDeformation, leftRutting, rightRutting, condition, rating, lpv10, lpv3) {
    return { sectionName, startingGPS, endingGPS, edgeDeformation, leftRutting, rightRutting, condition, rating, lpv10, lpv3 };
}
function createAdvanceTypeData(sectionName, startingGPS, endingGPS) {
    return { sectionName, startingGPS, endingGPS };
}


const DistressOverviewTable = (props) => {
    const { data, drProjectData, conditionMethod } = props;
    const { projectType, roadSide, typeOfRoad } = drProjectData;
    const isAdvanceProjectType = projectType === 4 ? true : false;
    const cciInverted = drProjectData.cciInverted ? drProjectData.cciInverted : false;
    //////console.log(data, 'data')
    const allObjectsContainSectionalParameters = data.every(obj => 'sectionalParameters' in obj);

    const advanceTypeRows = Object.values(data).map((eachSectionData, i) => createData(i + 1,
        `${eachSectionData.info.startingPoint.lat},${eachSectionData.info.startingPoint.lng}`,
        `${eachSectionData.info.endingPoint.lat},${eachSectionData.info.endingPoint.lng}`,
        conditionMethod === 1 ?
            eachSectionData.info.defaultCondition ?? eachSectionData.info.condition !== -1 ? eachSectionData.info.defaultCondition : 'N.A.'
            : eachSectionData.info.weightedCondition ?? eachSectionData.info.condition !== -1 ? eachSectionData.info.weightedCondition : 'N.A.',
        conditionMethod === 1 ?
            eachSectionData.info.defaultCondition ?? eachSectionData.info.condition !== -1 ? SmartUtils.scoreTextPCI(Math.round(eachSectionData.info.defaultCondition)) : 'N.A.'
            : eachSectionData.info.weightedCondition ?? eachSectionData.info.condition !== -1 ? SmartUtils.scoreTextCCI(Math.round(eachSectionData.info.weightedCondition), cciInverted) : 'N.A.'
    ))

    const rows = Object.values(data).map((eachSectionData, i) => createData(i + 1,
        `${eachSectionData.info.startingPoint.lat},${eachSectionData.info.startingPoint.lng}`,
        `${eachSectionData.info.endingPoint.lat},${eachSectionData.info.endingPoint.lng}`,
        conditionMethod === 1 ?
            Number(eachSectionData.info.defaultCondition ?? eachSectionData.info.condition).toFixed(2) :
            conditionMethod === 2 ?
                Number(eachSectionData.info.weightedCondition ?? eachSectionData.info.condition).toFixed(2) :
                Number(eachSectionData.info.condition).toFixed(2),

        conditionMethod === 1 ?
            SmartUtils.scoreTextPCI(Math.round(eachSectionData.info.defaultCondition ?? eachSectionData.info.condition)) :
            conditionMethod === 2 ?
                SmartUtils.scoreTextCCI(Math.round(eachSectionData.info.weightedCondition ?? eachSectionData.info.condition), cciInverted)
                : SmartUtils.scoreText(eachSectionData.info.condition, typeOfRoad)))

    const newRows = allObjectsContainSectionalParameters ? Object.values(data).map((eachSectionData, i) => newCreateData(i + 1,
        `${eachSectionData.info.startingPoint.lat},${eachSectionData.info.startingPoint.lng}`,
        `${eachSectionData.info.endingPoint.lat},${eachSectionData.info.endingPoint.lng}`, isNaN(eachSectionData.sectionalParameters.edge_deformation[roadSide === '0' ? 'right' : 'left']) ? 'N.A' : Number(eachSectionData.sectionalParameters.edge_deformation[roadSide === '0' ? 'right' : 'left'] * 1000).toFixed(1),
        isNaN(eachSectionData.sectionalParameters.rutting['left']) ? 'N.A' : Number(eachSectionData.sectionalParameters.rutting['left'] * 1000).toFixed(1),
        isNaN(eachSectionData.sectionalParameters.rutting['right']) ? 'N.A' : Number(eachSectionData.sectionalParameters.rutting['right'] * 1000).toFixed(1),

        conditionMethod === 1 ?
            Number(eachSectionData.info.defaultCondition ?? eachSectionData.info.condition).toFixed(2) :
            conditionMethod === 2 ?
                Number(eachSectionData.info.weightedCondition ?? eachSectionData.info.condition).toFixed(2) :
                Number(eachSectionData.info.condition).toFixed(2),

        conditionMethod === 1 ?
            SmartUtils.scoreTextPCI(Math.round(eachSectionData.info.defaultCondition ?? eachSectionData.info.condition)) :
            conditionMethod === 2 ?
                SmartUtils.scoreTextCCI(Math.round(eachSectionData.info.weightedCondition ?? eachSectionData.info.condition), cciInverted)
                : SmartUtils.scoreText(eachSectionData.info.condition, typeOfRoad),
        isNaN(eachSectionData.sectionalParameters.longitudinal_variance?.['10m']) ? 'N.A' : Number(eachSectionData.sectionalParameters.longitudinal_variance['10m'] * 1000).toFixed(1),
        isNaN(eachSectionData.sectionalParameters.longitudinal_variance?.['3m']) ? 'N.A' : Number(eachSectionData.sectionalParameters?.longitudinal_variance?.['3m'] * 1000).toFixed(1)

    )) : []
    //////console.log(newRows,'newRows')
    return (
        <>

            {conditionMethod === -1 ? <></> : <TableContainer>
                <Table sx={{ width: '60%', border: '1px solid grey' }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell colSpan={2} >Legends</StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell>Rating</StyledTableCell>
                            <StyledTableCell align="left">Range</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    {conditionMethod === 1 ?
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Serious
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    0 - 20
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Poor
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    21 - 40
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Fair
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    41 - 60
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Satisfactory
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    61 - 80
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Good
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    81 - 100
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody> :
                        conditionMethod === 2 ?
                            cciInverted ?
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Good
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            0 - 20
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Satisfactory
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            21 - 40
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Fair
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            41 - 60
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Poor
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            61 - 80
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Serious
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            81 - 100
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                                :
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Serious
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            0 - 20
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Poor
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            21 - 40
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Fair
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            41 - 60
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Satisfactory
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            61 - 80
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            Good
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            81 - 100
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            :
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        Excellent
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {
                                            typeOfRoad === 'cycleTracks' ?
                                                '0 - 17.8'
                                                : '0 - 30.5'
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        Good
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {typeOfRoad === 'cycleTracks' ?
                                            '17.8 - 44.4'
                                            : '30.5 - 76.2'
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        Poor
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {typeOfRoad === 'cycleTracks' ?
                                            '44.4 - 500'
                                            : '76.2 - 500'
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                    }
                </Table>
            </TableContainer>}


            <div style={{ marginTop: '30px' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Section number</StyledTableCell>
                                <StyledTableCell align="left">Starting GPS</StyledTableCell>
                                <StyledTableCell align="left">Ending GPS</StyledTableCell>
                                {!isAdvanceProjectType && allObjectsContainSectionalParameters ?
                                    <>
                                        <StyledTableCell align="left">Edge deformation depth</StyledTableCell>
                                        <StyledTableCell align="left">10m Moving Average Longitudinal Profile Variance (LV10)</StyledTableCell>
                                        <StyledTableCell align="left">3m Moving Average Longitudinal Profile Variance (LV3)</StyledTableCell>

                                        {
                                            typeOfRoad === 'carriageWay' ? (
                                                <>
                                                    <StyledTableCell align="left">Left wheelpath ruttting</StyledTableCell>
                                                    <StyledTableCell align="left">Right wheelpath rutting</StyledTableCell>
                                                </>
                                            )
                                                :
                                                <> </>
                                        }

                                    </> : <></>}
                                {conditionMethod === -1 ? <></> :
                                    <>
                                        <StyledTableCell align="left">Condition</StyledTableCell>
                                        <StyledTableCell align="left">Rating</StyledTableCell>
                                    </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isAdvanceProjectType ?
                                advanceTypeRows.map((row) => (
                                    <StyledTableRow key={row.sectionName}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.sectionName}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.startingGPS}</StyledTableCell>
                                        <StyledTableCell align="left">{row.endingGPS}</StyledTableCell>
                                        {conditionMethod === 2 ? <>
                                            <StyledTableCell align="left">{row.condition}</StyledTableCell>
                                            <StyledTableCell align="left">{row.rating}</StyledTableCell></>
                                            : <></>}
                                    </StyledTableRow>
                                ))
                                : allObjectsContainSectionalParameters ? newRows.map((row) => (
                                    <StyledTableRow key={row.sectionName}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.sectionName}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.startingGPS}</StyledTableCell>
                                        <StyledTableCell align="left">{row.endingGPS}</StyledTableCell>

                                        <StyledTableCell align="left">{row.edgeDeformation === 'N.A' ? row.edgeDeformation : `${row.edgeDeformation} mm`}</StyledTableCell>

                                        <StyledTableCell align="left">{row.lpv10 === 'N.A' ? row.lpv10 : `${row.lpv10} mm`} </StyledTableCell>
                                        <StyledTableCell align="left">{row.lpv3 === 'N.A' ? row.lpv3 : `${row.lpv3} mm`}</StyledTableCell>


                                        {
                                            typeOfRoad === 'carriageWay' ? (
                                                <>
                                                    <StyledTableCell align="left">{row.leftRutting === 'N.A' ? row.leftRutting : `${row.leftRutting} mm`} </StyledTableCell>
                                                    <StyledTableCell align="left">{row.rightRutting === 'N.A' ? row.rightRutting : `${row.rightRutting} mm`}</StyledTableCell>
                                                </>
                                            ) :
                                                <></>
                                        }
                                        {conditionMethod === 0 || conditionMethod === 1 || conditionMethod === 2 ?
                                            <>
                                                <StyledTableCell align="left">{row.condition}</StyledTableCell>
                                                <StyledTableCell align="left"
                                                    sx={{
                                                        color: conditionMethod === 1 ? `${SmartUtils.pciColor(row.condition)}` :
                                                            conditionMethod === 2 ? `${SmartUtils.cciColor(row.condition, cciInverted)}`
                                                                : `${SmartUtils.rciColor(row.condition, typeOfRoad)}`
                                                    }}
                                                >{row.rating}</StyledTableCell>
                                            </>
                                            :
                                            <></>
                                        }
                                    </StyledTableRow>
                                )) : rows.map((row) => (
                                    <StyledTableRow key={row.sectionName}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.sectionName}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.startingGPS}</StyledTableCell>
                                        <StyledTableCell align="left">{row.endingGPS}</StyledTableCell>

                                        {conditionMethod === 0 || conditionMethod === 1 || conditionMethod === 2 ?
                                            <>
                                                <StyledTableCell align="left">{row.condition}</StyledTableCell>
                                                <StyledTableCell align="left" sx={{
                                                    color: conditionMethod === 1 ? `${SmartUtils.pciColor(row.condition)}` :
                                                        conditionMethod === 2 ? `${SmartUtils.cciColor(row.condition, cciInverted)}`
                                                            : `${SmartUtils.rciColor(row.condition, typeOfRoad)}`
                                                }}>{row.rating}</StyledTableCell>
                                            </>
                                            :
                                            <></>
                                        }
                                    </StyledTableRow>
                                ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

export default DistressOverviewTable