import { Autocomplete, Button, Checkbox, CircularProgress, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SimpleDialogBox from '../../../cidgets/SimpleDialogBox'
import { connect } from 'react-redux';
import { clearCreateNetworkAndMsg, createNetwork } from '../../../../store/actions/networkActions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useNavigate } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateNetwork = (props) => {
    const navigate = useNavigate();
    const { network, superSections, allProjects } = props;
    const { createNetworkLoader, createNetworkIsDoneAndSent, createNetworkMessage } = network;
    const options = [{ 'name': "Distress - 3D Scan", "type": "1" }, { 'name': "Distress - Advanced", "type": "4" },
    { 'name': "Distress - Basic", "type": "5" }];
    const roadTypes = [
        { label: 'Carriageway', value: 'carriageWay' },
        { label: 'Cycleway', value: 'cycleTracks' },
    ];
    const [selectedProjectType, setSelectedProjectType] = useState(null);
    const [openNetworkModal, setOpenNetworkModal] = useState(false);
    const [networkTitle, setNetworkTitle] = useState('');
    const [showTitleAlert, setShowTitleAlert] = useState(false);
    const [showTypeAlert, setShowTypeAlert] = useState(false);
    const [showSSAlert, setShowSSAlert] = useState(false);
    const [superSectionList, setSuperSectionList] = useState([]);
    const [selectedProjectList, setSelectedProjectList] = useState([]);
    const [showProjectAlert, setShowProjectAlert] = useState(false);
    const [typeOfRoad, setTypeOfRoad] = useState(null);
    const [typeOfRoadAlert, setTypeOfRoadAlert] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const handleNetworkTitleChange = (e) => {
        setShowTitleAlert(false);
        setNetworkTitle(e.target.value);
    }

    const handleChangeTypeOfRoad = (event, newValue) => {
        setTypeOfRoadAlert(false);
        setTypeOfRoad(newValue);
    }
    const handleChangeProjectType = (event, newValue) => {
        setShowTypeAlert(false);
        if (newValue.type !== '1') {
            setTypeOfRoad(null);
        }
        setSelectedProjectList([]);
        setSelectedProjectType(newValue);
    }
    const handleConfirmCreateNetwork = () => {
        setShowTitleAlert(false);
        setShowTypeAlert(false);
        setShowSSAlert(false);
        setShowProjectAlert(false);
        if (!selectedProjectType && networkTitle.trim() === ''
            // && superSectionList.length === 0 && selectedProjectList.length === 0
        ) {
            setShowTitleAlert(true);
            setShowTypeAlert(true);
            // setShowSSAlert(true);
            // setShowProjectAlert(true);
        }
        else if (networkTitle.trim() === '') {
            setShowTitleAlert(true);
        }
        else if (!selectedProjectType) {
            setShowTypeAlert(true);
        }
        else if (!typeOfRoad && selectedProjectType?.type === "1") {
            setTypeOfRoadAlert(true);
        }


        // else if (superSectionList.length === 0) {
        //     setShowSSAlert(true);
        // }
        // else if (selectedProjectList.length === 0) {
        //     setShowProjectAlert(true);
        // }
        else {
            const ssList = superSectionList.map((each) => each.superSectionId);
            const proidList = selectedProjectList.map((each) => each.proid);
            props.createNetwork(networkTitle, selectedProjectType.type, ssList, proidList, typeOfRoad?.value?? null);
        }
    }
    console.log(projectList)
    useEffect(() => {
        if (allProjects) {
            if (selectedProjectType === null) {
                setProjectList(allProjects);
            }
            else {
                if (selectedProjectType.type === '1') {
                    const filteredProjectList = allProjects.filter(eachProj => Number(selectedProjectType.type) === eachProj.projectType && typeOfRoad?.value === eachProj.typeOfRoad);
                    setProjectList(filteredProjectList);
                }
                else {
                    const filteredProjectList = allProjects.filter(eachProj => Number(selectedProjectType.type) === eachProj.projectType);
                    setProjectList(filteredProjectList);
                }
            }
        }
    }, [allProjects, selectedProjectType, typeOfRoad])
    console.log(selectedProjectList, "selected", typeOfRoad)
    const handleClearState = () => {
        setNetworkTitle("");
        setShowTitleAlert(false);
        setShowTypeAlert(false);
        setShowSSAlert(false);
        setTypeOfRoadAlert(false);
        setSelectedProjectType(null);
        setSuperSectionList([]);
        setSelectedProjectList([]);
        setShowProjectAlert(false);
        setTypeOfRoad(null);
        props.clearCreateNetworkAndMsg();
    }
    useEffect(() => {
        if (createNetworkIsDoneAndSent === true) {
            handleClearState();
            setOpenNetworkModal(false);
        }
    }, [createNetworkIsDoneAndSent])

    const handleOpenModal = () => {
        handleClearState();
        setOpenNetworkModal(true);
    }

    const handleNavigate = () => {
        navigate('/home/super-section');
        setOpenNetworkModal(false);
    }
    return (
        <>
            <SimpleDialogBox
                open={openNetworkModal}
                onClose={() => setOpenNetworkModal(false)}
                title="Create network"
                shouldTitleAppear={true}
                showError={createNetworkIsDoneAndSent === false ? true : false}
                errorMessage={createNetworkIsDoneAndSent === false ? createNetworkMessage : ''}
                bodyWidget={
                    <>
                        <TextField
                            InputProps={{
                                inputMode: 'text',
                            }}
                            error={showTitleAlert}
                            helperText={showTitleAlert ? "Please enter network title" : ""}
                            disabled={createNetworkLoader}
                            fullWidth label="Network title" id="network" value={networkTitle}
                            onChange={handleNetworkTitleChange} />


                        <Autocomplete
                            disabled={createNetworkLoader}
                            value={selectedProjectType}
                            onChange={handleChangeProjectType}
                            id="project-type-selection"
                            options={options}
                            isOptionEqualToValue={(option, value) => option && value && option.type === value.type && option.name === value.name}
                            getOptionLabel={(option) => option.name}
                            fullWidth
                            sx={{ marginTop: '16px' }}
                            renderInput={(params) => <TextField {...params} label="Project type" error={showTypeAlert}
                                helperText={showTypeAlert ? "Please select project type" : ""} />}
                        />
                        <Autocomplete
                            disabled={createNetworkLoader || selectedProjectType === null || selectedProjectType?.type !== '1'}
                            id="road-type"
                            value={typeOfRoad}
                            onChange={handleChangeTypeOfRoad}
                            options={roadTypes}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            sx={{ marginTop: '16px' }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type of road"
                                    error={typeOfRoadAlert}
                                    helperText={typeOfRoadAlert ? "Please make sure to select type of road" : ""}
                                />
                            )}
                        />
                        <Autocomplete
                            multiple
                            disabled={createNetworkLoader || selectedProjectType === null || (typeOfRoad === null && selectedProjectType.type === '1')}
                            limitTags={4}
                            id="projectList"
                            options={projectList}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.projectTitle}
                            getOptionSelected={(option, value) => option.proid === value.proid}
                            sx={{ marginTop: '16px' }}
                            value={selectedProjectList}
                            onChange={(event, newValue) => {
                                setShowProjectAlert(false);
                                setSelectedProjectList(newValue);
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }} checked={selected} />
                                    {option.projectTitle}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select projects"
                                    error={showProjectAlert}
                                    helperText={showProjectAlert ? "Please make sure to select at least one project" : "Optional"}
                                />
                            )}
                        />
                        <div>
                            {superSections ?
                                <Autocomplete
                                    disabled={createNetworkLoader}
                                    multiple
                                    limitTags={4}
                                    id="ssList"
                                    options={superSections}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.superSectionTitle}
                                    getOptionSelected={(option, value) => option.superSectionId === value.superSectionId}
                                    sx={{ marginTop: '16px' }}
                                    value={superSectionList}
                                    onChange={(event, newValue) => {
                                        setShowSSAlert(false);
                                        setSuperSectionList(newValue);
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }} checked={selected} />
                                            {option.superSectionTitle}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select super section"
                                            error={showSSAlert}
                                            helperText={showSSAlert ? "Please make sure to select at least one super section" : "Optional"}
                                        />
                                    )}
                                /> : <></>}


                        </div>


                    </>

                }
                width={900}
                showPositive
                positiveButtonWidget={
                    <>
                        {createNetworkLoader ?
                            <>
                                <Button variant="contained" disabled sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    "&.Mui-disabled": {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>
                                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Creating...</span>
                                </Button>
                            </>
                            :
                            <Button size="medium" variant="contained"
                                onClick={handleConfirmCreateNetwork}
                                sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>Create</Button>
                        }
                        {superSections ? <></> :
                            <Button size="medium" variant="contained"
                                onClick={handleNavigate}
                                sx={{
                                    marginLeft: '10px',
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>Create super section</Button>
                        }
                    </>}
            />
            <Button
                size="small" variant="contained"
                sx={{
                    whiteSpace: 'nowrap',
                    textTransform: "none",
                    paddingInline: "18px",
                    marginInline: "10px",
                    display: { xs: "none", sm: "none", md: "flex" },
                    backgroundColor: "#025196",
                    ":hover": {
                        backgroundColor: "#025196",
                    },
                }}
                onClick={handleOpenModal}
            >
                Create network
            </Button>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userData: state.firebase.profile,
        network: state.network,
        superSections: state.firestore.ordered.SuperSections,
        allProjects: state.firestore.ordered.Projects,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        createNetwork: (networkTitle, projectType, ssList, proidList, typeOfRoad) => dispatch(createNetwork(networkTitle, projectType, ssList, proidList, typeOfRoad)),
        clearCreateNetworkAndMsg: () => dispatch(clearCreateNetworkAndMsg()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNetwork);
