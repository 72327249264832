import React, { useEffect, useState } from 'react';
import { Circle, LayerGroup, LayersControl, MapContainer, Polyline, TileLayer, Tooltip, useMap } from 'react-leaflet';
import { connect } from 'react-redux';
import { convertEncodedGeometry } from '../../../../../store/actions/projectActions';
import { cloudFunction, storage } from '../../../../../config/firebaseConfig';
import SimpleDialogBox from '../../../../cidgets/SimpleDialogBox';
import { Tooltip as MuiTooltip } from '@mui/material'
const SuperSectionDefectsMap = (props) => {
  const { allNetworkDefects, drNetworkData, superSections, defectPinned, datastore, setDefectPinned } = props;
  const { distressData } = datastore;
  const [superSectionList, setSuperSectionList] = useState([]);
  const [superSectionCoordinatesList, setSuperSectionCoordinatesList] = useState([]);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [mapKey, setMapKey] = useState(0);
  const [defectImageURLs, setDefectImageURLs] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');


  const handleDialogOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const generateDefectImageURLs = async () => {
      const urls = {};
      await Promise.all(allNetworkDefects.map(async (defect) => {
        const { defectId, defectImagePath } = defect;
        try {
          const storageRef = storage.ref(defectImagePath);
          const downloadURL = await storageRef.getDownloadURL();
          urls[defectId] = downloadURL;
        } catch (error) {
          // console.error(`Error fetching download URL for defect with ID ${defectId}:`, error);
          urls[defectId] = null;
        }
      }));
      setDefectImageURLs(urls);
    };

    generateDefectImageURLs();
  }, [allNetworkDefects]);

  useEffect(() => {
    if (drNetworkData && superSections) {
      if (drNetworkData.associatedSuperSections) {
        const superSectionList = superSections.filter((ss) => drNetworkData.associatedSuperSections.includes(ss.id));
        setSuperSectionList(superSectionList);
      }
    }
  }, [drNetworkData, superSections]);

  useEffect(() => {
    if (defectPinned) {
      setMapCenter([defectPinned.lat, defectPinned.lng]);
    } else {
      if (superSectionList.length <= 0 && allNetworkDefects.length > 0) {
        setMapCenter([allNetworkDefects[0].lat, allNetworkDefects[0].lng]);
      }
    }
  }, [defectPinned, superSectionList, allNetworkDefects]);

  useEffect(() => {
    const fetchEncodedGeometries = async () => {
      if (superSectionList.length === 0) return;

      const encodedGeometries = await Promise.all(superSectionList.map(async (superSection) => {
        try {
          const response = await cloudFunction.httpsCallable('routePlanner-geometryCoversion')({
            encodedGeometry: superSection.encodedGeometry,
          });
          return response.data.coordinates; // Assuming the response contains the converted geometry
        } catch (error) {
          console.error('Error converting geometry:', error);
          return null; // Or handle error as required
        }
      }));
      setSuperSectionCoordinatesList(encodedGeometries);

      if (encodedGeometries.length > 0 && encodedGeometries[0]?.coordinatesArray.length > 0) {
        const firstCoords = encodedGeometries[0].coordinatesArray[0];
        setMapCenter([firstCoords[0], firstCoords[1]]);
        setMapKey(mapKey + 1);
      }
    };

    fetchEncodedGeometries();
  }, [superSectionList]);

  if (distressData?.length === 0) {
    return <>Loading...</>;
  }

  const { defectNames, dataInfo } = distressData;
  if (dataInfo === undefined || dataInfo === null) {
    return <></>;
  }

  const { type } = dataInfo;

  const Recenter = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng]);
    }, [lat, lng]);
    return null;
  };

  const handlePinned = (data) => {
    setDefectPinned(data);
  };

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <MapContainer key={mapKey} attributionControl={false} center={mapCenter} zoom={16} scrollWheelZoom={true} maxZoom={19}>
        <TileLayer attribution='' maxZoom={19} url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Recenter lat={mapCenter[0]} lng={mapCenter[1]} />
        <LayersControl position="bottomright">
          {superSectionCoordinatesList.length > 0 && (
            <LayersControl.Overlay name={"All super sections"} checked={true}>
              <LayerGroup key={mapKey}>
                {superSectionCoordinatesList.map((coords, index) => (
                  <Polyline key={index} positions={coords.coordinatesArray} color={"green"} weight={5} />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          )}
          <LayersControl.Overlay name="Each section distress" checked={true}>
            <LayerGroup>
              {allNetworkDefects.map((e, i) => (
                <Circle
                  key={i}
                  eventHandlers={{
                    click: () => {
                      handlePinned(e);
                    },
                  }}
                  center={[e.lat, e.lng]}
                  pathOptions={{
                    fillColor: defectPinned?.defectId === e.defectId ? 'rgba(57, 1, 120,0.9)' : '#025196',
                    color: defectPinned?.defectId === e.defectId ? 'rgba(57, 1, 120,0.9)' : '#025196',
                    fillOpacity: 1,
                    stroke: true,
                    fill: true,
                  }}
                  radius={1}
                >
                  <Tooltip>
                    {Number(e.defectName) === 1 ? (Number(e.type) === -1 || e.type?.length === 0 ? '' : type[e.type]?.name) : ''}
                    {e.defectName === -1 ? null : defectNames[e.defectName]}
                  </Tooltip>
                  {defectPinned && defectPinned.defectId === e.defectId && defectImageURLs[e?.defectId] && (
                    <div className="inset-image-container">
                      <MuiTooltip
                        title={"See full image"}
                        arrow
                      >
                        <img
                          src={defectImageURLs[e.defectId]}
                          alt="Defect"
                          className="inset-image"
                          onClick={() => handleDialogOpen(defectImageURLs[e.defectId])}

                        />
                      </MuiTooltip>
                    </div>
                  )}
                </Circle>
              ))}
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>

      <SimpleDialogBox
        open={dialogOpen} onClose={handleDialogClose}
        width={900}
        title="Defect image"
        bodyWidget={<>
          <img src={selectedImage} alt="Defect Full" style={{ width: '100%', height: 'auto' }} />
        </>}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    datastore: state.datastore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    convertEncodedGeometry: (encodedGeometry) => dispatch(convertEncodedGeometry(encodedGeometry)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperSectionDefectsMap);
