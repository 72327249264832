import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "videojs-vr";
import "video.js/dist/video-js.css";
import POSTER from '../../../../../assets/bg/POSTER1.png';
import DateParser from "../../../../../helpers/DateParser";
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import { IconButton, Tooltip } from "@mui/material";

const VideoJS = (props) => {
  const videoRef = useRef(null);
  const {
    isMarkerSelected, currentTime, options, onReady, onValueChange, fps, playerRef, cusKey, cusHeight, segmentIndex, drProjectData
  } = props;
  let prevTime = 0;
  const { dateCreated, proid } = drProjectData;

  const date = dateCreated ? DateParser(dateCreated, 0) : DateParser(dateCreated, 2);
  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const player = (playerRef.current = videojs(videoElement, options, () => {
      onReady && onReady(player);
    }));

    player.one('play', () => {
      player.pause();
    });

    player.vr({ projection: "360" });

    player.currentTime(currentTime);

    if (isMarkerSelected === false) {
      player.currentTime(currentTime);
    }

    if (isMarkerSelected) {
      player.getChild('controlBar').hide();
      const videoNode = videoRef.current;
      videoNode.addEventListener('dblclick', (event) => {
        event.preventDefault();
        event.stopPropagation();
      });
      player.pause();
      player.currentTime(currentTime);
    }

    player.on("timeupdate", () => {
      const currentTime = player.currentTime();
      if (Math.abs(prevTime - currentTime) > 1 / fps) {
        onValueChange && onValueChange(currentTime);
        prevTime = currentTime;
      }
    });

    const zoomHandlerWeb = (event) => {
      event.preventDefault();
      const vr = player.vr();
      const isWheelUp = event.deltaY < 0;
      const zoomStep = 10;  // Adjust this value to increase/decrease zoom sensitivity
      let fov = vr.camera.fov - (isWheelUp ? -zoomStep : zoomStep);

      if (fov < 30) {
        fov = 30;
      }

      if (fov > 115) {
        fov = 115;
      }

      vr.camera.fov = fov;
      vr.camera.updateProjectionMatrix();
    };

    const $video = player.el();
    $video.addEventListener('wheel', zoomHandlerWeb);

    return () => {
      $video.removeEventListener('wheel', zoomHandlerWeb);
    };
  }, [videoRef, segmentIndex, isMarkerSelected]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!isMarkerSelected) {
      const player = playerRef.current;
      player.one('play', () => {
        player.pause();
      });
      player.currentTime(currentTime);
      player.getChild('controlBar').show();
    }
  }, [isMarkerSelected]);

  const handleClick = () => {
    const url = `/home/pr/${proid}/2/fvp`;
    window.open(url, '_blank');
  };

  return (
    <>
      <div data-vjs-player key={cusKey} style={{ height: `${cusHeight}px`, position: 'relative' }}>
        <video
          poster={POSTER}
          height={cusHeight}
          ref={videoRef}
          className="video-js vjs-big-play-centered"
          controlsList="nodownload"
          crossOrigin="anonymous"
          style={{ padding: '0px', height: `${cusHeight}px` }}
        />
        <div
          className="overlay"
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px'
          }}
        >
          {date}
          <Tooltip title="View the video in fullscreen" arrow >
            <IconButton
              aria-label="info"
              onClick={handleClick}
              style={{ color: 'white', marginLeft: '10px' }}
            >
              <FeaturedVideoIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div >
    </>
  );
};

export default VideoJS;
