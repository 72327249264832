import { useRef, useState, useEffect } from "react";
import { styled } from "@mui/system";

const Container = styled("div")`
  position: relative;
  overflow: auto;
  cursor: grab;
  touch-action: pinch-zoom;
`;

const Trackbar = styled("input")`
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  width: 80%;
`;

const InteractiveViewer = ({ children }) => {
    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);
    const [scale, setScale] = useState(1);
    const [lastDistance, setLastDistance] = useState(0);

    const handleMouseDown = (event) => {
        if (event.button !== 0) return; // Ignore right-click and other buttons
        setIsDragging(true);
        setStartX(event.clientX);
        setStartY(event.clientY);
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;
        const offsetX = event.clientX - startX;
        const offsetY = event.clientY - startY;
        setTranslateX(translateX + offsetX);
        setTranslateY(translateY + offsetY);
        setStartX(event.clientX);
        setStartY(event.clientY);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleWheel = (event) => {
        event.preventDefault();
        const delta = event.deltaY > 0 ? -0.1 : 0.1;
        const newScale = scale + delta;
        setScale(newScale < 0.5 ? 0.5 : newScale);
    };

    const handleTouchStart = (event) => {
        if (event.touches.length === 2) {
            const touch1 = event.touches[0];
            const touch2 = event.touches[1];
            const distance = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );
            setLastDistance(distance);
        }
    };

    const handleTouchMove = (event) => {
        if (event.touches.length === 2) {
            const touch1 = event.touches[0];
            const touch2 = event.touches[1];
            const distance = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );
            const delta = distance - lastDistance;
            const newScale = scale + delta * 0.01;
            setScale(newScale < 0.5 ? 0.5 : newScale);
            setLastDistance(distance);
        }
    };

    const handleTrackbarChange = (event) => {
        const newScale = parseFloat(event.target.value);
        setScale(newScale);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <Container
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onWheel={handleWheel}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            style={{
                transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
                transformOrigin: "top left",
            }}
        >
            {children}
            <Trackbar
                sx={{ color: 'transparent', display: 'none' }}
                type="range"
                min="0.5"
                max="2"
                step="0.1"
                value={scale}
                onChange={handleTrackbarChange}
            />
        </Container>
    );
};

export default InteractiveViewer;
