import { Chip, Tooltip } from "@mui/material";
import AdjustIcon from '@mui/icons-material/Adjust';

const ProjectStatusIndicator = ({ status = 999, empty = false, projectCard }) => {
    const isHalted = projectCard.isHalted ? projectCard.isHalted : false;//false
    const isHaltInProcess = projectCard.isHaltInProcess ? projectCard.isHaltInProcess : false;
    const statusMap = {
        "0": "Project created",
        "1": "Video uploading...",
        "-1": "Video uploading failed",
        "2": "Video uploaded",
        "-2": "Video upload failed",
        "3": "GPS processing...",
        "-3": "GPS processing failed",
        "4": "GPS uploaded",
        "-4": "Need GPS file",
        "5": "GPS approved",
        "-5": "GPS not approved",
        "6": "Analysis request created",
        "-6": "Analysis request failed",
        "7": `${isHaltInProcess ? "Halting in progress" : isHalted ? "Halted" : "Analyzing..."}`,
        "-7": "Analysis failed",
        "8": `${isHaltInProcess ? "Halting in progress" : isHalted ? "Halted" : "Generating results..."}`,
        "-8": "Analysis failed",
        "9": "Analysis complete",
        "-9": "Analysis failed",
        "10": "Analysis interrupted",
        "-10": "Analysis interrupted",
        "11": "GPS uploading",
        '-11': "GPS uploading failed",
        "-14": "Video order/GPS failed",
        "999": ""
    }

    if (empty) {
        return <Tooltip title={statusMap[`${status}`]}><AdjustIcon sx={status < 0 ? { color: '#C70202' } : { color: '#041256' }} /></Tooltip>
    }
    return <Chip variant="outlined" label={empty ? "" : statusMap[`${status}`]}
        sx={{
            borderRadius: '', fontSize: '12px', ...status < 0 ?
                { backgroundColor: '#ffffff', color: '#C70202', fontWeight: 'bold', border: '1px solid #C70202' } :
                { backgroundColor: '#ffffff', color: '#041256', fontWeight: 'bold', border: '1px solid #041256' }
        }}
        size="small"
    />
}

export default ProjectStatusIndicator;