import { firestore } from "../config/firebaseConfig";

class CustomFirebaseFunctions {
  static async getProjectData(proid) {
    try {
      const docRef = firestore.collection('Projects').doc(proid);
      //console.log(docRef,'docRef')
      const docSnapshot = await docRef.get();
  
      if (!docSnapshot.exists) {
        throw new Error('Document not found.');
      }
  
      return docSnapshot.data();
    } catch (error) {
      console.error('Error getting document:', error);
      throw error;
    }}
}
export default CustomFirebaseFunctions;