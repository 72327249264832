import moment from "moment";

class DistanceTimeCalcHelper {
    constructor() { }

    static formatDistance(value, unit) {
        return `${Number(value).toFixed(2)} ${unit}`;
    }

    static metersToKilometers(meters) {
        const kilometers = meters / 1000;
        return this.formatDistance(kilometers, 'km');
    }

    static calculateDistance(distance) {
        return distance > 1000 ? this.metersToKilometers(distance) : this.formatDistance(distance, 'm');
    }

    static convertSecondsToMinutes(seconds) {
        const duration = moment.duration(seconds, 'seconds');
        return duration.humanize();
    }
}

export default DistanceTimeCalcHelper;
