const initState = {
    coid: null,
  
    /// README: NEW COMPANY PAGE
    isCompanyCreating: false,
    isFilesAdded: false,
    isCompanyCreated: false,
  
    /// README: COMPANY PROFILE MODAL
    isCompanyUpdating: false,
    isPictureAdded: false,
    isCompanyUpdated: false,
  
    /// README: COMPANY API CALL
    capiMessage: "",
    showCapiLoader: false,
    capid: -1,
    isModalCalled: false,
  
    isDetailsUpdating: false,
    isDetailsUpdated: false,
  };
  
  const companyReducer = (state = initState, action) => {
    /// README: COMPANY SETTING AND INITIALIZE
    if (action.type === "SET_CURRENT_COMPANY_ID") {
      return {
        ...state,
        coid: action.coid,
      };
    }
  
    /// README: NEW COMPANY CREATION
    else if (action.type === "OPEN_NEW_COMPANY_PAGE") {
      return {
        ...state,
        isCompanyCreating: false,
        isFilesAdded: false,
        isCompanyCreated: false,
        coid: null,
      };
    } else if (action.type === "ADD_NEW_COMPANY") {
      return {
        ...state,
        isModalCalled: false,
        isCompanyCreating: true,
      };
    } else if (action.type === "COMPANY_DP_ADDED") {
      return {
        ...state,
        isFilesAdded: true,
      };
    } else if (action.type === "NEW_COMPANY_ADDED") {
      return {
        ...state,
        isModalCalled: true,
        isCompanyCreating: false,
        isFilesAdded: false,
        isCompanyCreated: true,
        coid: action.coid,
      };
    }
  
    /// README: COMPANY UPDATING
    else if (action.type === "OPEN_COMPANY_UPDATE_MODAL") {
      return {
        ...state,
        isCompanyUpdating: false,
        isPictureAdded: false,
        isCompanyUpdated: false,
      };
    } else if (action.type === "UPDATE_COMPANY") {
      return {
        ...state,
        isCompanyUpdating: true,
      };
    } else if (action.type === "COMPANY_DP_UPDATED") {
      return {
        ...state,
        isPictureAdded: true,
      };
    } else if (action.type === "COMPANY_UPDATED") {
      return {
        ...state,
        isCompanyUpdating: false,
        isPictureAdded: false,
        isCompanyUpdated: true,
      };
    }
  
    /// README: COMPANY API CALLS
    else if (action.type === "CAPI_CALL_INIT") {
      // //////console.log(action.capiMessage)
      // //////console.log(action.showCapiLoader)
      return {
        ...state,
        capiMessage: action.capiMessage,
        showCapiLoader: action.showCapiLoader,
        capid: action.capid,
      };
    } else if (
      action.type === "CAPI_CALL_COMPLETED" ||
      action.type === "CAPI_CALL_ERROR"
    ) {
      // //////console.log(action.capiMessage)
      // //////console.log(action.showCapiLoader)
      return {
        ...state,
        capiMessage: action.capiMessage,
        showCapiLoader: action.showCapiLoader,
        capid: action.capid,
        isModalCalled: true,
      };
    } else if (action.type === "OPEN_COMPANY_DASHBOARD_MODAL") {
      return {
        ...state,
        capiMessage: "",
      };
    } else if (action.type === "CLOSE_COMPANY_DASHBOARD_MODAL") {
      return {
        ...state,
        isModalCalled: false,
      };
    } else if (action.type === "OPEN_COMPANY_MODAL") {
      return {
        ...state,
        isModalCalled: false,
      };
    }
    else if (action.type === "START_UPDATE_DETAILS") {
      return {
          ...state,
          isDetailsUpdating: true,
          isDetailsUpdated: false
      }
  }
  else if (action.type === "DETAILS_UPDATED") {
      return {
          ...state,
          isDetailsUpdated: true,
          isDetailsUpdating: false
      }
  }
  
    return state;
  };
  
  export default companyReducer;