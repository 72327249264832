import React from 'react';
import { Button, List, ListItem, ListItemText } from '@mui/material';

const VideoSelectorList = ({ handleUpdateVideoSelDisOut, disabledRange, setDisabledRange, setStoredValues, storedValues, FPS }) => {
    const convertTime = (seconds) => {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = Number(seconds % 60).toFixed(0);
        return `${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes} mins` : ''} ${remainingSeconds > 0 ? `${remainingSeconds} secs` : '0 sec'
            }`;
    };
    const handleRemove = (setIndex) => {
        const newRange = [...disabledRange];
        newRange.splice(setIndex, 1);
        setDisabledRange(newRange);
        const updatedStoredValues = newRange.reduce((acc, obj) => [...acc, {
            id: acc.length + 1,
            startFrame: obj[0] * FPS,
            endFrame: obj[1] * FPS,
            startTimeMillis: obj[0] * 1000,
            endTimeMillis: obj[1] * 1000
        }], []);
        setStoredValues(updatedStoredValues);
        handleUpdateVideoSelDisOut(updatedStoredValues);
    };
    return (
        <div>

            {disabledRange.map((eachRange, setIndex) => (
                <List >
                    <ListItem key={setIndex}>
                        <ListItemText primary={`Start time (S${setIndex + 1}) : ${convertTime(eachRange[0])}  -  End time (E${setIndex + 1}) : ${convertTime(eachRange[1])}`} />
                        <Button
                            sx={{
                                border: `1px solid #1F0851`,
                                outline: 'none',
                                '&:focus': {
                                    color: '#1F0851',
                                    border: `1px solid #1F0851`,
                                },
                                '&:hover': {
                                    color: '#1F0851',
                                    border: `1px solid #1F0851`,
                                },
                                textTransform: 'none',
                                color: '#1F0851',

                            }}
                            variant="outlined"
                            size='small'
                            onClick={() => handleRemove(setIndex)}
                        >
                            Remove
                        </Button>
                    </ListItem>
                </List>
            ))}
        </div>
    );
};

export default VideoSelectorList;
