import colors from '../../../../../constants/colors.json'
import { Card, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import CircleIcon from '@mui/icons-material/Circle';
import DistressOverviewTable from './DistressOverviewTable';
import { useApi } from '../../../../../chooks/useApi';
import CustomProgress from '../../../../cidgets/CustomProgress'

ChartJS.register(ArcElement, Legend);

const DistressOverviewChart = ({ drProjectData, startEndLocation, distressProjectPredictions, datastore, estimatedDistanceKm = 0, conditionMethod }) => {
  const { status, error, data } = useApi(`https://api.openrouteservice.org/v2/directions/driving-car?api_key=5b3ce3597851110001cf624834d47bd5d59747d8bb2e4f065df3d60d&start=${startEndLocation.startLng},${startEndLocation.startLat}&end=${startEndLocation.endLng},${startEndLocation.endLat}`)
  const { typeOfRoad } = drProjectData;

  const extractDistanceMetric = (data) => {
    const distance = !(data?.features) ? 0 : data?.features[0]?.properties?.summary?.distance ?? 0
    return distance
  }
  if (distressProjectPredictions === null || datastore === null) {
    return <></>
  }

  const { distressData } = datastore;

  if (distressData === null || distressData === undefined) {
    return <CustomProgress centerInLayout={true} text={"Loading results overview"} />
  }

  const { defectNames } = distressData;
  if (defectNames === null || defectNames === undefined) {
    return <CustomProgress text={"Loading results overview"} />
  }
  const allSectionsDetectionObjectList = Object.values(distressProjectPredictions).map((eachDet) => eachDet.detections);
  const allSectionDetectionList = allSectionsDetectionObjectList.flatMap(obj => Object.values(obj));
  let countMap = {
    '0': 0,
    '1': 0,
    // '2': 0,
    // '3': 0,
    // '4': 0,
    '5': 0,
    '6': 0,
    '7': 0,
    // '8': 0,
    // '9': 0,
    // '10': 0,
    '11': 0
  };
  // 'versioning' in eachData ? 'defectName' in eachData['versioning'] ? eachData['versioning']['defectName'] : eachData['defectName'] : eachData['defectName'],
  const augmentedPredictions = [...allSectionDetectionList].map((eachDetection, i) => {
    countMap['versioning' in eachDetection ? 'defectName' in eachDetection['versioning'] ? Number(eachDetection['versioning']['defectName']) : Number(eachDetection['defectName']) : Number(eachDetection['defectName'])] = countMap['versioning' in eachDetection ? 'defectName' in eachDetection['versioning'] ? Number(eachDetection['versioning']['defectName']) : Number(eachDetection['defectName']) : Number(eachDetection['defectName'])] + 1
    return eachDetection;
  })
  const totalDistress = augmentedPredictions.length;
  Object.entries(countMap).map(([key, value]) => {
    const eachValueOverTotalDistress = totalDistress !== 0 ? value / totalDistress : 0;
    countMap[key] = {
      class: key,
      count: value,
      percentage: (eachValueOverTotalDistress) * 100,
      percentageToShow: Number((eachValueOverTotalDistress) * 100).toFixed(1),
      outOfOne: (eachValueOverTotalDistress),
      pieChartAngle: (eachValueOverTotalDistress) * 360,
      pieChartRadians: ((eachValueOverTotalDistress) * 360 * Math.PI) / 180,
      name: defectNames[key],
      nameToBeShown: defectNames[key],
      color: colors.colors[key]
    }
  });

  const sortedCountArray = Object.values(countMap).sort((a, b) => a.class - b.class);
  const chartData = {
    labels: sortedCountArray.map((e) => (`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')),
    datasets: [{
      label: 'Summary',
      data: sortedCountArray.map((e) => e.count),
      backgroundColor: sortedCountArray.map((e) => e.color),
      hoverOffset: 4
    }]
  }
  const chartOptions = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: 16
          }
        }
      }
    },
    layout: {
      padding: 0
    },
  }
  const conditionCounts = distressProjectPredictions.map(section => section.info.condition);

  /*
  const conditionCountMap = {
    excellent: conditionCounts.filter(condition => condition >= 0 && condition <= 17.8).length,
    good: conditionCounts.filter(condition => condition > 17.8 && condition <= 44.4).length,
    poor: conditionCounts.filter(condition => condition > 44.4).length
  };
*/

  const conditionCountMap = {
    excellent: typeOfRoad === 'cycleTracks' ?
      conditionCounts.filter(condition => condition >= 0 && condition <= 17.8).length :
      conditionCounts.filter(condition => condition >= 0 && condition <= 30.5).length,
    good: typeOfRoad === 'cycleTracks' ?
      conditionCounts.filter(condition => condition > 17.8 && condition <= 44.4).length :
      conditionCounts.filter(condition => condition > 30.5 && condition <= 76.2).length,
    poor: typeOfRoad === 'cycleTracks' ?
      conditionCounts.filter(condition => condition > 44.4).length :
      conditionCounts.filter(condition => condition > 76.2).length
  };


  const totalCount = conditionCounts.length;
  const percentages = {
    excellent: (conditionCountMap.excellent / totalCount) * 100,
    good: (conditionCountMap.good / totalCount) * 100,
    poor: (conditionCountMap.poor / totalCount) * 100
  };

  // Doughnut chart data
  const chartConditionData = {
    labels: ['Excellent', 'Good', 'Poor'],
    datasets: [{
      label: 'Condition Distribution',
      data: [percentages.excellent, percentages.good, percentages.poor],
      backgroundColor: [
        'green',
        'darkorange',
        'red'
      ],
      hoverOffset: 4
    }]
  };

  // const chartOptions = {
  //   plugins: {
  //     tooltip: {
  //       mode: 'label',
  //       callbacks: {
  //         label: () => null // This function will prevent labels from showing in the tooltips
  //       }
  //     }
  //   },
  //   legend: {
  //     display: false
  //   }
  // };
  const cardLightStyle = {
    "border": "0px solid grey",
    "borderRadius": "8px",
    "padding": "8px",
    "backgroundColor": '#f5f1f1'
    // "backgroundColor": '#f5f1f1'
  }

  const wnpStyle = { fontSize: '15px' };
  const actualRoadLength = extractDistanceMetric(data);
  const isFetched = status === 'fetched'
  return (
    <>
      <Grid container>
        <Grid xs={6} item>
          <Card sx={{ ...cardLightStyle, marginTop: '30px' }} elevation={0}>
            <Typography variant="p" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
              ⚡️ Quick bits
            </Typography>
            <div style={{ margin: '14px' }}></div>
            <div style={wnpStyle}>Total {totalDistress} distress</div>
            {/* <div style={wnpStyle}><CircleIcon sx={{ color: 'blue', fontSize: '10px', marginRight: '10px' }} />{totalAssetLeft} assets on the left side of the road</div> */}
            {/* <div style={wnpStyle}><CircleIcon sx={{ color: 'red', fontSize: '10px', marginRight: '10px' }} />{totalAssetRight} assets on the right side of the road</div> */}
          </Card>

        </Grid>
        <Grid xs={1} item></Grid>
        <Grid xs={5} item>
          <Card sx={{ marginTop: '30px', textAlign: 'end', padding: '15px', backgroundColor: 'transparent' }} elevation={0}>
            <div style={{ fontSize: '45px', fontWeight: 'normal', color: 'rgba(0,0,0,1)' }}>{!isFetched ? <CircularProgress sx={{ color: '#1F0851' }} size={30} /> : `${estimatedDistanceKm === 0 ? Number(actualRoadLength).toFixed(2) : (estimatedDistanceKm * 1000).toFixed(2)} m`}</div>
            <Tooltip title="Accurate to 0.01 meters" arrow>
              <Typography variant="p" sx={{ fontWeight: 'normal', fontSize: '16px', cursor: 'pointer' }}>
                {!isFetched ? 'Calculating road length...' : 'Actual road length'}
              </Typography>
            </Tooltip>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={5} item>
          <List>
            {sortedCountArray.map((e) => {
              return <ListItem key={e.class} disablePadding>
                <ListItemIcon>
                  <CircleIcon sx={{ color: e.color }} />
                </ListItemIcon>
                <ListItemText
                  primary={(`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')}
                  secondary={`${e.percentageToShow}% (${e.count})`}
                />
              </ListItem>
            })}
          </List>
        </Grid>
        <Grid xs={7} item sx={{ padding: '25px' }}>
          {/* {
            conditionMethod === -1 ?
              <Doughnut data={chartData} options={chartOptions} />

              :
              <Doughnut data={chartConditionData} options={chartOptions} />
          } */}
          <Doughnut data={chartData} options={chartOptions} />
        </Grid>
      </Grid>



      <Grid container sx={{ marginTop: '20px' }}>
        <Grid xs={12} item>
          <DistressOverviewTable data={distressProjectPredictions} drProjectData={drProjectData} conditionMethod={conditionMethod} />
        </Grid>
      </Grid>
    </>
  );
};

export default DistressOverviewChart; 
