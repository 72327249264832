import React from "react";
import { Line } from "react-chartjs-2";

export default function LineGraph(props) {
    const { currentTime, filteredGpsData, cardHeight } = props;
    const speedArray = filteredGpsData?.map(item => item.speed);
    const currentTimeNumber = parseFloat(currentTime);

    const getCurrentPosition = () => {
        let belowArray = filteredGpsData?.filter(each => each.frameTime <= currentTimeNumber);
        let aboveArray = filteredGpsData?.filter(each => each.frameTime >= currentTimeNumber);
        return belowArray.length > 0 ? belowArray.pop() : aboveArray[0];
    }
    const data = {
        labels: filteredGpsData?.map(item => Number(item.frameTime).toFixed(2)),
        datasets: [
            {
                label: 'Current speed',
                fill: false,
                lineTension: 0.1,
                borderColor: 'black',
                borderWidth: 1,
                pointBackgroundColor: ['green'],
                pointRadius: 5,
                data: [
                    { x: getCurrentPosition()?.frameTime, y: getCurrentPosition()?.speed },
                ],
            },
            {
                label: "Speed",
                data: speedArray,
                backgroundColor: '#041256',
                borderColor: '#041256',
                fill: false,
                pointRadius: 0,
                borderWidth: 1,

            }
        ]
    };

    const options = {
        plugins: {
            legend: {
                display: false,
                position: 'top',
            }
        },
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Time (s)',
                },
            },
            y: {
                type: 'linear',
                position: 'left',
                title: {
                    display: true,
                    text: 'Speed (km/h)',
                },
            },
        },
    };


    return (
        <Line options={options} data={data} width={`100%`} style={{ height: cardHeight }} />
    );
}
