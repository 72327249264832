import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Card, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SimpleDialogBox from '../../../../cidgets/SimpleDialogBox';
import DateParser from '../../../../../helpers/DateParser';

const SubscriptionTabs = ({ value, setValue }) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="subscriptions tabs"
        sx={{
          '& .Mui-selected': {
            color: '#025196', // Active tab color
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#025196', // Active tab indicator color
          },
        }}
      >
        <Tab value="active" label="Active subscriptions" />
        <Tab value="expired" label="Expired subscriptions" />
      </Tabs>
    </Box>
  );
}
const SubscriptionComponent = ({ subscription }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);

  };
  const cardStyle = {
    "border": "0.5px solid grey",
    "borderRadius": "8px",
    "backgroundColor": '#fafafa',
    "marginBlock": '10px',
  }
  const projectType = ['Asset', '3D Scanning', '360 Tagger', 'Safety', 'Advanced Imaging', 'Basic Imaging'];
  return (
    <>
      <SimpleDialogBox
        open={openDialog}
        onClose={handleCloseDialog}
        title={`${projectType[subscription.projectType]} subscription detail`}
        width={800}
        bodyWidget={
          <>
            <li>Total projects: {subscription.allowedProjects}</li>
            <li>Remaining projects: {subscription.remainingProjects}</li>
            <li>Maximum distance: {subscription.maximumKM} km</li>
            <li>Activation date: {DateParser(subscription.activationTime)}</li>
            <li>Expiry date: {DateParser(subscription.expiryDate)}</li>
          </>
        } />
      <Card sx={cardStyle} elevation={0}>
        <ListItem
          dense
          disablePadding
          secondaryAction={
            <ListItemIcon title={""}>
              <IconButton
                onClick={() => { handleOpenDialog() }}
              >
                <ChevronRightIcon />
              </IconButton>
            </ListItemIcon>
          }
        >
          <ListItemButton
            sx={{ paddingBlock: '8px', }}
            onClick={() => { handleOpenDialog() }}
          >
            <ListItemText
              primary={
                <Typography variant="p" sx={{ fontWeight: 'bold' }}>
                  {projectType[subscription.projectType]}
                </Typography>
              }
              secondary={
                <>
                  <span style={{ display: 'block' }}>Remaining projects: {subscription.remainingProjects}</span>
                  <span style={{ display: 'block' }}>Expiry date: {DateParser(subscription.expiryDate)}</span>
                </>
              }
            />
          </ListItemButton>
        </ListItem>
      </Card>
    </>
  );
};

const SubscriptionSetting = (props) => {
  const { subscriptionsList, bodyComponentHeight } = props;
  const [value, setValue] = useState('active');
  return (
    <div>
      <SubscriptionTabs value={value} setValue={setValue} />
      {value === 'expired' ? <div style={{ display: 'flex', justifyContent: 'center', height: `${bodyComponentHeight / 2}px`, alignItems: 'center' }}>No subscriptions found!</div> : subscriptionsList.map((subscriptionGroup) => {
        return Object.keys(subscriptionGroup).map(subKey => {
          return (
            <SubscriptionComponent
              key={subKey}
              subscription={subscriptionGroup[subKey]}
            />
          );
        });
      })}
    </div>
  )
}

export default SubscriptionSetting