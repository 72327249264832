import { storage, cloudFunction } from "../../config/firebaseConfig";

// SECTION 1: Adding or updating payment method
////////////////////////////////////////////////////////////////////////////////
export const openStripePaymentMethodModal = (coid) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "OPEN_STRIPE_PM_ACT_REQUEST",
      stripeSetupIntent: "",
      paymentMessage: "",
      stripeSetupIntentLoader: false,
    });

    cloudFunction
      .httpsCallable("stripeGateway-createCustomerSetupIntent")({
        coid: coid,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "OPEN_STRIPE_PM_ACT_COMPLETE",
          stripeSetupIntentLoader: true,
          stripeSetupIntent: res.data.isDoneAndSent
            ? res.data.responseMsg
            : "-1",
          paymentMessage: res.data.isDoneAndSent ? "" : res.data.responseMsg,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "OPEN_STRIPE_PM_ACT_FAILED",
          stripeSetupIntentLoader: false,
          stripeSetupIntent: "-1",
          paymentMessage: "Some internal error has occured!",
        });
      });
  };
};

export const attachPaymentMethodToCustomer = (coid, paymentMethodId) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    // //////console.log(coid);
    // //////console.log(paymentMethodId);
    dispatch({
      type: "STRIPE_PM_ACTING",
    });

    cloudFunction
      .httpsCallable("stripeGateway-attachPaymentMethodToCustomer")({
        coid: coid,
        paymentMethodId: paymentMethodId,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "STRIPE_PM_ACTED",
          paymentMessage: res.data.responseMsg,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "STRIPE_PM_ACT_FAILED",
          paymentMessage: "Payment method adding failed",
        });
      });
  };
};

export const changeCardDetailsOnStripe = (cardDetailsObject, coid) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "STRIPE_PM_ACTING",
    });

    cloudFunction
      .httpsCallable("stripeGateway-changeCardDetailsOnStripe")({
        cardDetailsObject: cardDetailsObject,
        coid: coid,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "STRIPE_PM_ACTED",
          paymentMessage: res.data.responseMsg,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "STRIPE_PM_ACT_FAILED",
          paymentMessage: "Payment method updating failed",
        });
      });
  };
};

// SECTION 2: Purchasing a subscription or a product
////////////////////////////////////////////////////////////////////////////////
export const openStripeBuyProductModal = () => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "OPEN_STRIPE_PROD_ACT",
      requiresAction: false,
      stripePaymentIntent: "",
      buyProdActMessage: "",
    });
  };
};


// export const requestOrderInvoice = (orderId, coid) => {
//   return async (dispatch, getState) => {
//     /// dispatch to indicate initiation of request
//     dispatch({
//       type: "INVOICE_PROD_ACTING",
//     });

//     cloudFunction
//       .httpsCallable("stripeGateway-requestOrderInvoice")({
//         orderId: orderId,
//         coid: coid,
//       })
//       .then((res) => {
//         /// dispatch to indicate successful request
//         dispatch({
//           type: "INVOICE_PROD_ACTED",
//           buyProdActMessage: res.data.responseMsg,
//         });
//       })

//       .catch((error) => {
//         /// dispatch to indicate unsuccessful request
//         dispatch({
//           type: "INVOICE_PROD_ACT_FAILED",
//           buyProdActMessage: `An unknown error has occurred: ${error}`,
//         });
//       });
//   };
// };
export const requestReceiptGeneration = (orderId) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "RECEIPT_GENERATING",
      receiptGenerationMessage: "",
      receiptGenerationLoader: true,
      receiptGenerationIsDoneAndSent: null,
    });

    cloudFunction.httpsCallable("stripeGateway-receiptGeneration")({
      orderId: orderId,
    })
      .then((res) => {
        /// dispatch to indicate successful request
        if (res.data.isDoneAndSent === true) {
          // //////console.log(res.data.responseMsg, "res")
          window.open(res.data.responseMsg, '_blank');
        }
        dispatch({
          type: "RECEIPT_GENERATED",
          receiptGenerationMessage: res.data.responseMsg,
          receiptGenerationIsDoneAndSent: res.data.isDoneAndSent,
          receiptGenerationLoader: false,

        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "RECEIPT_GENERATION_FAILED",
          receiptGenerationMessage: `An unknown error has occurred: ${error}`,
          receiptGenerationIsDoneAndSent: false,
          receiptGenerationLoader: false,
        });
      });
  };
};
export const clearReceiptRequest = () => {
  return {
    type: "CLEAR_RECEIPT_REQUEST",
  };
};
export const clearProductRequest = () => {
  return {
    type: "CLEAR_PRODUCT_REQUEST",
  };
};
export const customerBuyConsumable = (productId, credits, proid) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "STRIPE_PROD_ACTING",
      stripePaymentIntent: "",
      buyProdActMessage: "",
    });

    cloudFunction
      .httpsCallable("stripeGateway-customerBuyConsumable")({
        productId: productId,
        credits: credits,
        transferToProid: proid,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "STRIPE_PROD_ACTED",
          stripePaymentIsDoneAndSent: res.data.isDoneAndSent,
          stripePaymentMessage: res.data.responseMsg,
          stripePaymentIntent: res.data.requiresAction
            ? res.data.responseMsg
            : "-1",
          buyProdActMessage: res.data.requiresAction
            ? "Manual action is required"
            : res.data.responseMsg,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "STRIPE_PROD_ACT_FAILED",
          stripePaymentIsDoneAndSent: false,
          stripePaymentMessage: "API failed to be called",
          stripePaymentIntent: "-1",
          buyProdActMessage: `An unknown error has occurred: ${error}`,
        });
      });
  };
};

export const transferCreditsToProjectJar = (proid) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request
    dispatch({
      type: "TRANSFER_CREDITS_TO_PROJECTJAR_ACTING",
      transferingCreditsLoader: true,
      transferingCreditsMessage: "",
      transferingCreditsIsDoneAndSent: null
    });

    cloudFunction
      .httpsCallable("productV2-transferCreditsToProjectJarV2")({
        transferToProid: proid,
      })
      .then((res) => {
        /// dispatch to indicate successful request
        dispatch({
          type: "TRANSFER_CREDITS_TO_PROJECTJAR_ACTED",
          transferingCreditsLoader: false,
          transferingCreditsMessage: res.data.responseMsg,
          transferingCreditsIsDoneAndSent: res.data.isDoneAndSent
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "TRANSFER_CREDITS_TO_PROJECTJAR_ACT_FAILED",
          transferingCreditsLoader: false,
          transferingCreditsMessage: "API failed to be called",
          transferingCreditsIsDoneAndSent: false
        });
      });
  };
};
export const removeTransferCreditsToProjectJarErrorAndMessage = () => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: "CLEAR_TRANSFER_CREDITS_TO_PROJECTJAR_ERROR_MSG"
    })
  }
}
export const confirmConsumablePurchase = (orderId) => {
  return async (dispatch, getState) => {
    /// dispatch to indicate initiation of request

    // //////console.log(orderId, "orderId");
    dispatch({
      type: "STRIPE_PROD_ACTING",
      buyProdActMessage: "",
    });

    cloudFunction
      .httpsCallable("stripeGateway-confirmConsumablePurchase")({
        orderId: orderId,
      })
      .then((res) => {
        //////console.log(res,"res-confirmConsumablePurchase")
        /// dispatch to indicate successful request
        dispatch({
          type: "STRIPE_PROD_ACTED",
          buyProdActMessage: res.data.responseMsg,
        });
      })
      .catch((error) => {
        /// dispatch to indicate unsuccessful request
        dispatch({
          type: "STRIPE_PROD_ACT_FAILED",
          buyProdActMessage: `An unknown error has occurred: ${error}`,
        });
      });
  };
};
