import { Autocomplete, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, Grid, TextField } from '@mui/material';
import { makeStyles } from "@mui/styles"
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Error from '../../../cidgets/Error';
import { clearSetupNetworkAndMsg, setupNetwork } from '../../../../store/actions/networkActions';
import { useNavigate } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
    header: {
        // backgroundColor: 'rgba(0,0,0,0.03)',
        // color: 'black',
        backgroundColor: '#025196',
        color: '#ffffff',
        borderBottom: `1px solid transparent`
    },
    cardContent: {
        paddingTop: theme.spacing(2)
    }
}));
const SetupNetwork = (props) => {
    let navigate = useNavigate();
    const { drNetworkData, superSections, allProjects, network, netid } = props;
    const { setupNetworkLoader, setupNetworkMessage, setupNetworkIsDoneAndSent } = network;
    const classes = useStyles();
    const [superSectionList, setSuperSectionList] = useState([]);
    const [selectedProjectList, setSelectedProjectList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [showProjectAlert, setShowProjectAlert] = useState(false);
    const [showSuperSectionAlert, setShowSuperSectionAlert] = useState(false);

    useEffect(() => {
        if (drNetworkData && superSections) {
            if (drNetworkData.associatedSuperSections) {
                const superSectionList = superSections.filter(ss => drNetworkData.associatedSuperSections.includes(ss.id));
                setSuperSectionList(superSectionList);
            }
        }
    }, [drNetworkData, superSections])
    useEffect(() => {
        if (drNetworkData && allProjects) {
            if (drNetworkData.typeOfRoad) {
                const filteredProjectList = allProjects.filter(eachProj => drNetworkData.projectType === eachProj.projectType && drNetworkData.typeOfRoad === eachProj.typeOfRoad);
                setProjectList(filteredProjectList);
            }
            else {
                const filteredProjectList = allProjects.filter(eachProj => drNetworkData.projectType === eachProj.projectType);
                setProjectList(filteredProjectList);
            }
            if (drNetworkData.associatedProjects) {
                const selectedProjectList = allProjects.filter(eachProj => drNetworkData.associatedProjects.includes(eachProj.proid));
                setSelectedProjectList(selectedProjectList);
            }
        }
    }, [drNetworkData, allProjects])
    useEffect(() => {
        props.clearSetupNetworkAndMsg();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowProjectAlert(false);
        setShowSuperSectionAlert(false);
        props.clearSetupNetworkAndMsg();

        if (superSectionList.length <= 0 && selectedProjectList.length <= 0) {
            setShowProjectAlert(true);
            setShowSuperSectionAlert(true);
        }
        // else if (superSectionList.length <= 0) {
        //     setShowSuperSectionAlert(true);
        // }
        // else if (selectedProjectList.length <= 0) {
        //     setShowProjectAlert(true);
        // }
        else {
            const ssList = superSectionList.map((each) => each.superSectionId);
            const proidList = selectedProjectList.map((each) => each.proid);
            props.setupNetwork(netid, ssList, proidList);
        }

    };
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
            <Grid item xs={10} sm={8} md={6} lg={4}>
                <Card>
                    <CardHeader
                        className={classes.header}
                        // sx={{ backgroundColor: 'rgba(0,0,0,0.03)' }}
                        title="Network setting"
                        titleTypographyProps={{ fontWeight: 'bold', fontSize: '18px', display: 'flex', justifyContent: 'center' }}
                    />
                    <CardContent className={classes.cardContent}>
                        <form
                            onSubmit={handleSubmit}
                        >
                            <Autocomplete
                                multiple
                                limitTags={4}
                                id="projectList"
                                options={projectList}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.projectTitle}
                                getOptionSelected={(option, value) => option.proid === value.proid}
                                sx={{ marginTop: '16px' }}
                                value={selectedProjectList}
                                onChange={(event, newValue) => {
                                    setShowProjectAlert(false);
                                    setSelectedProjectList(newValue);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }} checked={selected} />
                                        {option.projectTitle}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select projects"
                                        error={showProjectAlert}
                                        helperText={showProjectAlert ? "Please make sure to select at least one project" : ""}
                                    />
                                )}
                            />
                            {superSections ? <Autocomplete
                                multiple
                                limitTags={4}
                                id="ssList"
                                options={superSections}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.superSectionTitle}
                                getOptionSelected={(option, value) => option.superSectionId === value.superSectionId}
                                sx={{ marginTop: '16px' }}
                                value={superSectionList}
                                onChange={(event, newValue) => {
                                    setShowSuperSectionAlert(false);
                                    setSuperSectionList(newValue);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }} checked={selected} />
                                        {option.superSectionTitle}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select super section"
                                        error={showSuperSectionAlert}
                                        helperText={showSuperSectionAlert ? "Please make sure to select at least one super section" : ""}
                                    />
                                )}
                            /> : <></>}
                            <Button type="submit" variant="contained"
                                onClick={() => { navigate('/home/dashboard') }}
                                sx={{
                                    marginTop: '10px',
                                    marginRight: '5px',
                                    textTransform: 'initial',
                                    backgroundColor: '#025196',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#ffffff'
                                    }
                                }}>
                                Back
                            </Button>
                            {setupNetworkLoader ?
                                <>
                                    <Button variant="contained" disabled sx={{
                                        marginTop: '10px',
                                        textTransform: 'initial',
                                        backgroundColor: '#025196',
                                        color: '#ffffff',
                                        "&.Mui-disabled": {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        }
                                    }}>
                                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Processing...</span>
                                    </Button>
                                </>
                                :
                                <Button type="submit" variant="contained" sx={{
                                    marginTop: '10px',
                                    textTransform: 'initial',
                                    backgroundColor: '#025196',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#ffffff'
                                    }
                                }}>
                                    Process
                                </Button>
                            }
                            {superSections ? <></> :
                                <Button size="medium" variant="contained"
                                    onClick={() => { navigate('/home/super-section') }}
                                    sx={{
                                        marginTop: '10px',
                                        marginLeft: '10px',
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#FFFFFF'
                                        }
                                    }}>Create super section</Button>
                            }
                            {setupNetworkIsDoneAndSent === true &&
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>{setupNetworkMessage}</div>
                            }
                            {setupNetworkIsDoneAndSent === false &&
                                <div style={{ marginTop: '10px' }}><Error errorMessage={setupNetworkMessage} /></div>
                            }
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    )
}
const mapStateToProps = (state) => {
    return {
        network: state.network,
        superSections: state.firestore.ordered.SuperSections,
        allProjects: state.firestore.ordered.Projects,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setupNetwork: (networkId, ssList, proidList) => dispatch(setupNetwork(networkId, ssList, proidList)),
        clearSetupNetworkAndMsg: () => dispatch(clearSetupNetworkAndMsg()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupNetwork);