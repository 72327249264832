import { Grid, TextField, Typography, FormHelperText, Box } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from "react-redux";
import { updateCompanyDetails } from '../../../../../../store/actions/companyActions';
import { changePassword, removeChangePasswordMessage } from '../../../../../../store/actions/authActions';
import { useGenerateSignedUrl } from '../../../../../../chooks/useGenerateSignedUrl';
import StyledOutlineButton from '../../../../../cidgets/StyledOutlineButton';
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Divider from '@mui/material/Divider';

function UpdateDetails(props) {
    const { handleClearState, handleClearStateFields, companyData, userData, company, setShowImg, setValues, setFileName, fullName, accountTitle, fullNameError, companyNameError, setFullName, setFullNameError, setAccountTitle, setCompanyNameError, setCurrentPassword, setCurrentPasswordError, setNewPassword, setPasswordError, handleSubmit, showImg, imageError, currentPassword, currentPasswordError, newPassword, passwordError } = props;
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const { status, error, data } = useGenerateSignedUrl(companyData.imagePath)
    useEffect(() => {
        props.removeChangePasswordMessage();
        handleClearState();
        handleClearStateFields();
    }, [])
    useEffect(() => {
        if (data) {
            setShowImg(data);
        }
    }, [data]);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setValues((prevValues) => ({
                    ...prevValues,
                    image: file,
                }));
                setFileName(file.name);
                setShowImg(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
        },
        maxFiles: 1,
        onDrop,
    });


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Full name
                    </Typography>
                    <TextField
                        fullWidth
                        id="full name"
                        size="small"
                        variant="outlined"
                        value={fullName}
                        onChange={(event) => {
                            setFullName(event.target.value);
                            setFullNameError("");
                        }}
                        required
                        error={fullNameError}
                    />
                    {!fullNameError && (

                        <FormHelperText>Full name should not exceed 30 characters</FormHelperText>
                    )}

                    {fullNameError && (
                        <>
                            {fullNameError === "required" && (
                                <FormHelperText error>Please enter your full name</FormHelperText>
                            )}

                            {fullNameError === "invalidCharacters" && (
                                <FormHelperText error>Please enter valid characters</FormHelperText>
                            )}

                            {fullNameError === "maxLength" && (
                                <FormHelperText error>Full name cannot exceed 30 characters</FormHelperText>
                            )}
                        </>
                    )}
                </Grid>
                <Grid item xs={6} >
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Organization name
                    </Typography>
                    <TextField
                        fullWidth
                        id="company name"
                        size="small"
                        variant="outlined"
                        value={accountTitle}
                        onChange={(event) => {
                            setAccountTitle(event.target.value);
                            setCompanyNameError("");

                        }}
                        required
                        error={companyNameError}
                    />

                    {!companyNameError && (

                        <FormHelperText>Organization name should not exceed 30 characters</FormHelperText>
                    )}

                    {companyNameError && (
                        <>
                            {companyNameError === "required" && (
                                <FormHelperText error>Please enter your Organization name</FormHelperText>
                            )}

                            {companyNameError === "invalidCharacters" && (
                                <FormHelperText error>Please enter valid characters</FormHelperText>
                            )}

                            {companyNameError === "maxLength" && (
                                <FormHelperText error>Organization name cannot exceed 30 characters</FormHelperText>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                <Grid item xs={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Current Password
                    </Typography>
                    <TextField
                        fullWidth
                        id="current-password"
                        size="small"
                        variant="outlined"
                        type={showCurrentPassword ? 'text' : 'password'}
                        value={currentPassword}
                        onChange={(event) => {
                            setCurrentPassword(event.target.value);
                            setCurrentPasswordError('')
                        }}
                        required
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            )
                        }}
                    />
                    {currentPasswordError !== '' ? <FormHelperText error>{currentPasswordError}</FormHelperText> :
                        <FormHelperText>Optional</FormHelperText>
                    }

                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        New Password
                    </Typography>
                    <TextField
                        fullWidth
                        id="new-password"
                        size="small"
                        variant="outlined"
                        type={showNewPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(event) => {
                            setNewPassword(event.target.value);
                            setPasswordError('');
                        }}
                        required
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            )
                        }}
                    />
                    {passwordError !== '' ? <FormHelperText error>{passwordError}</FormHelperText> :
                        <FormHelperText>Optional</FormHelperText>
                    }
                </Grid>

            </Grid>

            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Attach company logo (optional) (Only *.jpg, *.jpeg and *.png image will be accepted)</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div {...getRootProps()} style={{ display: 'inline-block' }}>
                            <input {...getInputProps()} style={{ display: 'none' }} />
                            <StyledOutlineButton size='medium' text={isDragActive ? 'Drop the file here' : 'Attach'} />
                        </div>

                        <div style={{ marginLeft: "30px" }}>
                            {showImg && <img src={showImg} alt="Uploaded Image" style={{ maxWidth: '100%', maxHeight: '50px', objectFit: 'contain' }} />}
                        </div>
                        {/* {fileName && (
                            <Typography variant="subtitle2" style={{ marginLeft: '10px' }}>
                                {fileName}
                            </Typography>
                        )} */}
                    </div>
                    {imageError && <FormHelperText error>Please select an image</FormHelperText>}
                </Grid>
            </Grid>

            {/* <Box sx={{ marginTop: '20px' }}>
                <Divider />
            </Box> */}

            {/* <Box sx={{ marginTop: '20px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: "17px" }}>
                    Update password
                </Typography>
            </Box> */}


        </>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        project: state.project,
        company: state.company,
        projNotifs: state.firestore.ordered.ProjNotifs,
        userData: state.firebase.profile,
        companyData: state.firestore.data.Company,
        datastore: state.datastore,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCompanyDetails: (coid, image, companyName, fullName, uid) => dispatch(updateCompanyDetails(coid, image, companyName, fullName, uid)),
        changePassword: (currentPassword, newPassword) => dispatch(changePassword(currentPassword, newPassword)),
        removeChangePasswordMessage: () => dispatch(removeChangePasswordMessage()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDetails);
