import { Grid } from '@mui/material';
import React, { useState } from 'react';
import AddNewSuperSectionOption from './AddNewSuperSectionOption';
import AddNewSuperSectionMap from './AddNewSuperSectionMap';
import { useMapEvents } from 'react-leaflet';
import { connect } from 'react-redux';
import { removeRouteCalculationErrorAndMessage, routeCalculation } from '../../../../../store/actions/projectActions';
const AddNewSuperSectionController = (props) => {
    const { onClose, onSpecificRouteLocation, goToCenterOfUserLocation } = props;
    const { userLocation, isUserLocation } = props;

    const [fields, setFields] = useState([{ id: 1, value: { lat: '', lng: '' } }, { id: 2, value: { lat: '', lng: '' } }]);
    const [markerPositions, setMarkerPositions] = useState([]);
    const [markerID, setMarkerID] = useState(0);
    const [draggable, setDraggable] = useState(false);


    const onChangeFields = (newFields) => {
        setFields(newFields);
    }
    const onDrag = () => {
        setDraggable(!draggable)
    }
    const onDelete = (id) => {
        const updatedMarkers = markerPositions.filter((marker) => marker.id !== id);
        setMarkerPositions(updatedMarkers);
        const updateFields = fields.filter((feild) => feild.id !== id);
        setFields(updateFields);
        props.removeRouteCalculationErrorAndMessage();
    };

    const onClear = () => {
        setMarkerID(0);
        setMarkerPositions([]);
        setFields([{ id: 1, value: { lat: '', lng: '' } }, { id: 2, value: { lat: '', lng: '' } }])
        props.removeRouteCalculationErrorAndMessage();
    }
    const onAddNewFeild = () => {
        const newField = { id: markerID + 1, value: { lat: '', lng: '' } };
        setFields([...fields, newField]);
    };

    const OnTap = () => {
        useMapEvents({
            // click: draggable || !isCreatingRoute ? <></> : onAddMarker,
            click: draggable ? <></> : onAddMarker,
        });
        return null;
    };

    const handleAddFieldWithData = (lat, lng) => {
        if (markerPositions.length === 25 && fields.length === 25) {
            alert("up to 25 longitude,latitude waypoints.")
        }
        else {
            const newField = { id: markerID + 1, value: { lat: lat, lng: lng } };
            const newMarkerPositions = [...markerPositions, { id: markerID + 1, lat: lat, lng: lng }];
            setFields([...fields, newField]);
            setMarkerPositions(newMarkerPositions);
            setMarkerID(markerID + 1)
        }
    };
    const handleChangeWithFieldData = (id, event) => {
        const newFields = fields.map(field => {
            if (field.id === id) {
                return { ...field, value: event.target.value };
            }
            return field;
        });
        setFields(newFields);
    }
    const onAddMarker = (e) => {
        if (markerPositions.length === 25 && fields.length === 25) {
            alert("up to 25 longitude,latitude waypoints.")
        }
        else {
            if (markerPositions.length === fields.length) {
                handleAddFieldWithData(e.latlng.lat, e.latlng.lng)
            }
            else {
                const newMarkerPositions = [...markerPositions, { id: markerID + 1, lat: e.latlng.lat, lng: e.latlng.lng }];
                setMarkerPositions(newMarkerPositions);
                handleChangeWithFieldData(markerID + 1, { target: { value: { lat: e.latlng.lat, lng: e.latlng.lng } } })
                setMarkerID(markerID + 1)
            }

        }
    };

    const handleMarkerPositionChange = (id, newPosition) => {
        const updatedMarkers = markerPositions.map((marker) => (marker.id === id ? { id: id, lat: newPosition.lat, lng: newPosition.lng } : marker));
        const updateFields = fields.map(field => {
            if (field.id === id) {
                return { ...field, value: { lat: newPosition.lat, lng: newPosition.lng } };
            }
            return field;
        });
        setMarkerPositions(updatedMarkers);
        setFields(updateFields);
    };

    const onDragEnd = (e, id) => {
        handleMarkerPositionChange(id, e.target._latlng);
    };

    function sortByID(a, b) {
        return a.id - b.id;
    }
    const calculateRoute = async () => {
        const sortedLngLatArray = [];
        markerPositions.sort(sortByID);
        for (const item of markerPositions) {
            sortedLngLatArray.push([item.lng, item.lat]);
        }
        props.routeCalculation(JSON.stringify(sortedLngLatArray));
    }

    return (
        <>
            <Grid container>
                <Grid item xs={4}>
                    <AddNewSuperSectionOption
                        // {state props}
                        fields={fields}
                        markerPositions={markerPositions}
                        draggable={draggable}
                        // {functions props}
                        onClose={onClose}
                        onSpecificRouteLocation={onSpecificRouteLocation}
                        onChangeFields={onChangeFields}
                        onDelete={onDelete}
                        goToCenterOfUserLocation={goToCenterOfUserLocation}
                        onClear={onClear}
                        onAddNewFeild={onAddNewFeild}
                        onDrag={onDrag}
                        calculateRoute={calculateRoute}
                    />
                </Grid>
                <Grid item xs={8}>
                    <AddNewSuperSectionMap
                        // {state props}
                        userLocation={userLocation}
                        isUserLocation={isUserLocation}
                        draggable={draggable}
                        markerPositions={markerPositions}
                        // {functions props}
                        OnTap={OnTap}
                        onDragEnd={onDragEnd}
                    />
                </Grid>
            </Grid>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        routeCalculation: (coordinatesList) => dispatch(routeCalculation(coordinatesList)),
        removeRouteCalculationErrorAndMessage: () => dispatch(removeRouteCalculationErrorAndMessage()),
        // convertEncodedGeometry: (encodedGeometry) => dispatch(convertEncodedGeometry(encodedGeometry)),
        // deleteRoute: (coid, id) => dispatch(deleteRoute(coid, id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewSuperSectionController);