import React, { createRef, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Stack from '@mui/material/Stack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Divider } from "@mui/material";
import VideoPreviewPlayer from '../../../components/VideoPreviewPlayer';
import { removeUploadVideoErrorAndMessage, uploadVideo } from '../../../../../../../store/actions/projectActions';
import { connect } from "react-redux";
import { Button } from '@mui/material'
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";
import { get } from "lodash";
import SmartUtils from '../../../../../../../helpers/SmartUtils';
import CustomProgress from '../../../../../../cidgets/CustomProgress';
import Error from '../../../../../../cidgets/Error';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} color="success" sx={{ backgroundColor: '#E1E3E2' }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const UploadVideoStep = (props) => {

  const [videoFiles, setVideoFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [link, setLink] = useState('');
  const { project, themeColors, charges, proid, drProjectData } = props;
  const { status, thumbnail, videoMessage, isVideoUploadingOnDB, gpsApprovalMessage } = drProjectData;
  const { uploadVideoStatus } = project;
  const [videoDuration, setVideoDuration] = useState(null);
  const assumedFps = 30;
  const ratePerFrame = charges?.inferencePerFrame / 1;
  const ratePerStoredGb = charges?.storageRatePerGb / 1;

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      'video/mp4': ['.mp4'],
      'video/x-msvideo': ['.avi'],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles => {
      setVideoFiles(acceptedFiles.map(file => Object.assign(file, {
        videoFiles: URL.createObjectURL(file)
      })));
    }
  });

  const removeFile = file => () => {
    const newFiles = [...videoFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setVideoFiles(newFiles)
  }

  const removeAll = () => {
    setVideoFiles([])
  }

  const convertSizeToConvention = (byteSize, index = 0) => {
    const prefixList = ['', 'K', 'M', 'G', 'T', 'P']
    let size = parseInt(byteSize) / 1024
    // //////console.log(byteSize,size,"sizebyte")
    if (parseInt(byteSize) < 1024 || index === (prefixList.length - 1)) {
      return `${byteSize.toFixed(2)} ${prefixList[index]}B`
    } else {
      return convertSizeToConvention(size, index + 1)
    }
  }
  useEffect(() => {
    props.removeUploadVideoErrorAndMessage();
  }, [])

  const uploadVideo = (proid, videoFile) => {
    // const thumbnail = image;
    // //////console.log(thumbnail)
    // props.uploadVideo(proid, videoFile, thumbnail);
    props.uploadVideo(proid, videoFile);
  }

  useEffect(() => {
    if (status === -1) {
      // //////console.log("status -1")
      setVideoFiles([])
    }
  }, [status])

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      var video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        acceptedFiles[0].duration = duration;
        // //////console.log(duration, "dur", acceptedFiles)
        setVideoDuration(duration)
      }
      video.src = URL.createObjectURL(acceptedFiles[0]);
    }
  }, [acceptedFiles])

  // useEffect(() => {
  //   if (acceptedFiles.length > 0) {
  //     const canvas = document.createElement("canvas");
  //     const video = document.createElement("video");

  //     // this is important
  //     video.autoplay = true;
  //     video.muted = true;
  //     video.src = URL.createObjectURL(acceptedFiles[0]);

  //     video.onloadeddata = () => {
  //       let ctx = canvas.getContext("2d");

  //       canvas.width = video.videoWidth;
  //       canvas.height = video.videoHeight;

  //       ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //       video.pause();
  //       setImage(canvas.toDataURL("image/png"));
  //     };
  //   }
  // }, [acceptedFiles])

  const videoProgress = proid in uploadVideoStatus ? uploadVideoStatus[proid]['progress'] : null
  const isVideoUploading = proid in uploadVideoStatus ? uploadVideoStatus[proid]['isVideoUploading'] : null
  const errorMessage = proid in uploadVideoStatus ? uploadVideoStatus[proid]['errorMessage'] : null
  const estimatedTimeRemaining = proid in uploadVideoStatus ? uploadVideoStatus[proid]['estimatedTimeRemaining'] : null
  // //////console.log(videoFiles, "files", videoFiles.length !== 0 ? true : false, videoProgress)
  const su = new SmartUtils();
  const files = videoFiles.map(file => (
    <Stack spacing={1} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
      <span style={{ width: 'fit-content' }}><b>Name:</b> {file.name}</span>
      <span style={{ width: 'fit-content' }}><b>Duration:</b> {new Date(videoDuration * 1000).toISOString().slice(11, 19)}</span>
      <span style={{ width: 'fit-content' }}><b>Size:</b> {su._formatBytes(`${file.size}`)}</span>
      {/* <Divider ligth sx={{ width: '80%' }} />
      <span style={{ width: '80%' }}><b>Inference cost:</b> {Number(videoDuration * assumedFps * ratePerFrame).toFixed(4)} GBP <a href="#!">(Rate {ratePerFrame} GBP/frame)</a></span>
      <span style={{ width: '80%' }}><b>Storage cost:</b> {Number((file.size / 1024 / 1024 / 1024) * ratePerStoredGb).toFixed(4)} GBP <a href="#!">(Rate {ratePerStoredGb} GBP/GB)</a></span> */}
      {/* <span style={{ width: '50%' }}><b>Rate applied:</b> {ratePerFrame} GBP/frame</span> */}
      {/* <button onClick={removeFile(file)}>Remove File</button> */}
      {!isVideoUploading ? <><Divider ligth sx={{ width: 'fit-content'}} />
        <span style={{ width: 'fit-content'}}><a style={{ color: 'inherit' }} href="#!" onClick={removeFile(file)}><b>Attach a different video</b></a></span></> : <></>}
      {/* <img src={image}/> */}
    </Stack>
  ));
      //////console.log(uploadVideoStatus[proid],"estimatedTimeRemaining")
  const boxHeight = '35vh'
  return (
    <Grid container >
      <Grid item xs={12} style={{ margin: '40px' }}>
        <div style={{ backgroundColor: '', height: 'calc(100vh - 20vh - 80px)', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
          <div style={{ height: 'calc(100vh - 20vh -80px)' }} {...getRootProps({ className: 'dropzone' })}>
            <Box
              sx={{
                padding: '30px',
                minWidth: '500px',
                maxWidth: '1000px',
                width: '100vw',
                height: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '',
                cursor: status !== 1 ? 'pointer' : isVideoUploadingOnDB || videoFiles.length > 0 ? '' : 'pointer',
                border: '2px dashed grey',
                borderRadius: '20px',
                color: 'grey'
              }}
              onClick={isVideoUploadingOnDB || videoFiles.length > 0 ? null : open}
            >
              {status === 1 || videoFiles.length !== 0 ?
                <div style={{ height: `calc(100% - 4px)`, width: '100%', backgroundColor: 'transparent', padding: '30px' }}>
                  <Grid container spacing={0}>
                    {/* <Grid item xs={4} sx={{ height: `calc(${boxHeight} - 4px)`, backgroundColor: 'transparent', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}>
                      <img src={image ?? thumbnail} width='100%' height='100%' style={{ objectFit: 'cover', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }} />
                    </Grid> */}
                    <Grid item xs={12} sx={{ height: `calc(100% - 4px)`, backgroundColor: '', display: videoMessage ? 'flex' : '', justifyContent: videoMessage ? 'center' : '', alignItems: videoMessage ? 'center' : '' }}>
                      {videoMessage ? <CustomProgress text={"Video uploading"} /> : files}
                    
                    </Grid>
                  </Grid>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {status === 1 || isVideoUploading ?
                      <Box sx={{ width: '90%', marginTop: '40px' }}>
                         <p style={{display:'flex',justifyContent:'start'}}>About {SmartUtils.formatTime(Number(estimatedTimeRemaining))} remaining</p>
                        {videoProgress === null ?
                          // <LinearProgress />
                          <></>
                          : <LinearProgressWithLabel value={videoProgress} />
                        }
                      </Box>
                      : <>
                        <Button size="large" variant="contained" onClick={() => { uploadVideo(proid, videoFiles[0]) }} sx={{
                          marginTop: '20px',
                          textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                          '&:hover': {
                            backgroundColor: '#025196',
                            color: '#ffffff'
                          }
                        }}>
                          {/* Upload the video */}
                          Upload
                        </Button>
                      </>
                    }
                  </div>

                </div> :
                <>
                  <input {...getInputProps()} />
                  <Stack>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {/* <CloudUploadIcon sx={{ fontSize: '75px' }} /> */}
                    </div>

                    <p>Drag and drop a video file here, or click to select a file</p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <em>(Only *.mp4 and *.avi video will be accepted)</em>
                    </div>
                    {/* <p style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>{status === -1 ? <Error errorMessage={gpsApprovalMessage} /> : <></>}</p> */}
                  </Stack>
                </>}
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>


  )
}
const mapStateToProps = (state, ownProps) => {
  const { proid } = ownProps
  return {
    drProjectData: get(
      state.firestore.data,
      `DrProject.${proid}`
    ),
    themeColors: state.themeRed,
    project: state.project,
    charges: state.firestore.data.Charges,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadVideo: (proid, videoFile, thumbnail) => dispatch(uploadVideo(proid, videoFile, thumbnail)),
    removeUploadVideoErrorAndMessage: () => dispatch(removeUploadVideoErrorAndMessage())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadVideoStep);