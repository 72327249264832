import React, { useRef } from "react";
import { connect } from 'react-redux';
import { Grid, Stack, Typography, Toolbar, Skeleton } from '@mui/material';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import CreateNewFolderTwoToneIcon from '@mui/icons-material/CreateNewFolderTwoTone';
import CustomCard from "../../../cidgets/CustomCard";
import * as ROUTES from "../../../../constants/routes";
const AllNetwork = (props) => {

  const data = props.allNetwoks ? props.allNetwoks : null;
  const cardRef = useRef();

  if (!data) {
    return (
      <Grid container spacing={2} sx={{ padding: '20px' }}>
        {[...Array(12)].map((_, index) => (
          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            key={index}
          >
            <Skeleton variant="rounded" width={350} height={200} />
          </Grid>
        ))}
      </Grid>
    )
  }
  if (data.length === 0) {
    return (
      <div style={{ width: '100%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stack direction="column">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CreateNewFolderTwoToneIcon sx={{ fontSize: '100px', filter: 'invert(5%) sepia(58%) saturate(2690%) hue-rotate(221deg) brightness(93%) contrast(116%)', }} />
          </div>
          <Toolbar>
            <Typography align="center" style={{ fontSize: '18px', padding: '40px' }}>
              To begin, you must create a project as there are no existing projects found
            </Typography>
          </Toolbar>
        </Stack>
      </div >
    );
  }


  return (
    <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'center', padding: '20px' }}>
      <Grid container rowSpacing={3} sx={{ backgroundColor: '' }} spacing={2}>

        {data.map((eachData, index) => (
          <Grid sx={{ justifyContent: 'center', display: 'flex' }}
            key={index}
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
          >
            <CustomCard isNetwork={true} cardRef={cardRef} title={eachData.title} data={eachData} date={eachData.creationTime} path={`/home/${ROUTES.HOME_NETWORK}/${eachData.dbInstanceId}`} />
          </Grid>
        ))}
      </Grid>

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allNetwoks: state.firestore.ordered.DatabaseInstances,
    userData: state.firebase.profile,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    var coid = props.userData.coid;
    return [
      {
        collection: `DatabaseInstances`,
        where: [['coid', '==', `${coid}`], ['archived', '==', false]],
        orderBy: [['creationTime', 'desc']],
      }
    ]
  })
)(AllNetwork);