import React, { useEffect, useState } from 'react';
import { clearUpdateProjectState, updateProject, updateProjectProcessingTime, confirmDetailsBack, confirmDetailsNext, deleteProcessingTime } from '../../../../../../../store/actions/projectActions';
import ProjectInfo from '../../../surfaceEvaluationComponents/ProjectInfo';
import { connect } from "react-redux";
import { useAccessControlContext } from '../../../../../../../contexts/AccessControlProvider';
import AccessControlAccountError from '../../../../../../cidgets/AccessControlAccountError';
import SmartUtils from '../../../../../../../helpers/SmartUtils';

const UpdateDetailsStep = (props) => {
    const { permissions, role } = useAccessControlContext();
    const { canShowProjectInformation } = permissions;
    const { proid, drProjectData, projectType, setActiveStep, project, confirmDetailsBack, confirmDetailsNext, companyPricingData, chargesV3 } = props
    const { isProjectUpdating, isProjectUpdated } = project;
    const [customCategories, setCustomCategories] = useState(drProjectData.categories);
    const [error, setError] = useState(false);
    const [drivingSideError, setDrivingSideError] = useState(false);
    const [surveyDateError, setSurveyDateError] = useState(false);
    const [processingTimeError, setProcessingTimeError] = useState(false);
    const [typeOfRoadError, setTypeOfRoadError] = useState(false);
    const [processingTime, setProcessingTime] = useState(drProjectData.processingTime ?? '');
    const [values, setValues] = useState({
        projectName: drProjectData.projectTitle,
        drivingSide: drProjectData.roadSide,
        typeOfRoad: drProjectData.typeOfRoad ?? "",
        projectType: drProjectData.projectType,
        cameraPosition: "",
        cameraDirection: "",
        cameraMountingHeight: "",
        cameraInclination: "",
        surveyDate: drProjectData.surveyDate ?? "",
        // processingTime: drProjectData.processingTime ?? "",
        identificationNo: drProjectData.identificationNo ?? "",
        clientName: drProjectData.clientName ?? "",
        streetName: drProjectData.streetName ?? "",
        startingAddress: drProjectData.startingAddress ?? "",
        endingAddress: drProjectData.endingAddress ?? "",
        direction: drProjectData.direction ?? "",
        totalLanes: drProjectData.totalLanes ?? "",
        laneNumber: drProjectData.laneNumber ?? "",
    });

    useEffect(() => {
        setProcessingTime(drProjectData.processingTime ?? '')
        setValues({
            projectName: drProjectData.projectTitle,
            drivingSide: drProjectData.roadSide,
            typeOfRoad: drProjectData.typeOfRoad ?? "",
            projectType: drProjectData.projectType,
            cameraPosition: "",
            cameraDirection: "",
            cameraMountingHeight: "",
            cameraInclination: "",
            surveyDate: drProjectData.surveyDate ?? "",
            // processingTime: drProjectData.processingTime ?? "",
            identificationNo: drProjectData.identificationNo ?? "",
            clientName: drProjectData.clientName ?? "",
            streetName: drProjectData.streetName ?? "",
            startingAddress: drProjectData.startingAddress ?? "",
            endingAddress: drProjectData.endingAddress ?? "",
            direction: drProjectData.direction ?? "",
            totalLanes: drProjectData.totalLanes ?? "",
            laneNumber: drProjectData.laneNumber ?? "",
        });
    }, [drProjectData])
    // useEffect(()=>{
    //     props.clearUpdateProjectState();
    // },[])
    // useEffect(() => {
    //     if (isProjectUpdated === true) {
    //         if (projectType === 2) {
    //             // props.updateProjectProcessingTime();
    //             setActiveStep(5);
    //             props.clearUpdateProjectState();
    //         } else {
    //             // props.updateProjectProcessingTime();
    //             setActiveStep(SmartUtils.supportCCIProject(projectType) ? 7 : 6);
    //             props.clearUpdateProjectState();
    //         }
    //     }
    // }, [isProjectUpdated]);

    const handleSubmit = (e) => {
        // //////console.log(e,'done')

        if (projectType === 1 || projectType === 3 || projectType === 4 || projectType === 5) {
            if (values.projectName.length === 0) {
                setError(true);
                return;
            }
            else if (values.drivingSide === "") {
                setDrivingSideError(true);
                return;
            }
            else if (processingTime === "") {
                setProcessingTimeError(true);
                return;
            }

            else if (values.surveyDate === "") {
                setSurveyDateError(true);
                return;
            }


            else {
                // //////console.log("project data: ", values, projectType)
                e.preventDefault();
                props.updateProject(
                    proid,
                    {
                        identificationNo: values.identificationNo,
                        clientName: values.clientName,
                        streetName: values.streetName,
                        startingAddress: values.startingAddress,
                        endingAddress: values.endingAddress,
                        direction: values.direction,
                        totalLanes: values.totalLanes,
                        laneNumber: values.laneNumber,
                        projectTitle: values.projectName,
                        roadSide: values.drivingSide,
                        processingTime: processingTime,
                        surveyDate: values.surveyDate,
                        typeOfRoad: values.typeOfRoad,
                        projectType: values.projectType,
                        categories: customCategories,
                    });
            }
        }

        else {
            if (values.projectName.length === 0) {
                setError(true);
            }
            else {
                e.preventDefault();
                props.updateProject(
                    proid,
                    {
                        identificationNo: values.identificationNo,
                        clientName: values.clientName,
                        streetName: values.streetName,
                        startingAddress: values.startingAddress,
                        endingAddress: values.endingAddress,
                        direction: values.direction,
                        totalLanes: values.totalLanes,
                        laneNumber: values.laneNumber,
                        projectTitle: values.projectName,
                        roadSide: values.drivingSide,
                        processingTime: processingTime,
                        surveyDate: values.surveyDate,
                        typeOfRoad: values.typeOfRoad,
                        projectType: values.projectType,
                        categories: customCategories,
                    });

            }


        }


        // projectType === 2 ?
        //     setActiveStep(5)
        //     :
        //     setActiveStep(6)

        confirmDetailsNext(proid);
        projectType === 2 ?
            setActiveStep(5)
            :
            setActiveStep(SmartUtils.supportCCIProject(projectType) ? 7 : 5)

    };

    const handleSkip = () => {
        confirmDetailsNext(proid);
        projectType === 2 || projectType === 0 ?
            setActiveStep(5)
            :
            setActiveStep(SmartUtils.supportCCIProject(projectType) ? 7 : 5)
    }
    const handleStepChange = () => {
        confirmDetailsBack(proid, projectType);

        projectType === 2 ?
            setActiveStep(3)
            :
            setActiveStep(SmartUtils.supportCCIProject(projectType) ? 5 : 3)

    }

    return (
        !canShowProjectInformation ?
            <AccessControlAccountError access={role} />
            :
            <ProjectInfo isProjectUpdating={isProjectUpdating} drProjectData={drProjectData} proid={proid} projectType={projectType} error={error}
                setError={setError}
                drivingSideError={drivingSideError}
                setDrivingSideError={setDrivingSideError}
                surveyDateError={surveyDateError}
                setSurveyDateError={setSurveyDateError}
                processingTimeError={processingTimeError}
                setProcessingTimeError={setProcessingTimeError}
                typeOfRoadError={typeOfRoadError}
                setTypeOfRoadError={setTypeOfRoadError}
                values={values}
                setValues={setValues}
                customCategories={customCategories}
                setCustomCategories={setCustomCategories}
                handleSubmit={handleSubmit}
                handleSkip={handleSkip}
                handleStepChange={handleStepChange}
                companyPricingData={companyPricingData}
                chargesV3={chargesV3}
                deleteProcessingTime={props.deleteProcessingTime}
                processingTime={processingTime}
                setProcessingTime={setProcessingTime}
            />
    );
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        companyPricingData: state.firestore.data.CompanyPricingData,
        chargesV3: state.firestore.data.ChargesV3,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProject: (proid, projectData) => dispatch(updateProject(proid, projectData)),
        updateProjectProcessingTime: (proid) => dispatch(updateProjectProcessingTime(proid)),
        clearUpdateProjectState: () => dispatch(clearUpdateProjectState()),
        confirmDetailsBack: (proid, projectType) => dispatch(confirmDetailsBack(proid, projectType)),
        confirmDetailsNext: (proid) => dispatch(confirmDetailsNext(proid)),
        deleteProcessingTime: (proid, projectType) => dispatch(deleteProcessingTime(proid, projectType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDetailsStep);