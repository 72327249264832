import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Grid, Pagination } from '@mui/material';
import SuperSectionDefectsMap from './SuperSectionDefectsMap';
import ProjectDefectListView from './ProjectDefectListView';
import SmartUtils from '../../../../../helpers/SmartUtils';
import Topbar from './Topbar';
import CustomProgress from '../../../../cidgets/CustomProgress';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import SuperSectionController from './supersections/SuperSectionController';

const AfterCalibratingDashboard = (props) => {
  const { drNetworkData, netid, superSections, allNetworkDefects } = props;
  const headerRef = useRef();
  const mapRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [mapDivDimen, setMapDivDimen] = useState({ height: 1, width: 1 });
  const [headerDivDimen, setHeaderDivDimen] = useState({ height: 1, width: 1 });
  const [defectPinned, setDefectPinned] = useState(null);
  const [showSSView, setShowSSView] = useState(false);
  const [page, setPage] = useState(0);
  const [limit] = useState(500);

  const su = new SmartUtils();
  const overallCustomBorderPadding = 16;
  const cardWhiteStyle = { borderRadius: '15px' };

  const totalPages = Math.ceil((allNetworkDefects?.length || 0) / limit);

  useEffect(() => {
    const updateMapDimensions = () => {
      setMapDivDimen({
        height: mapRef.current?.clientHeight || 1,
        width: mapRef.current?.clientWidth || 1,
      });
    };

    updateMapDimensions();
    window.addEventListener('resize', updateMapDimensions);

    return () => {
      window.removeEventListener('resize', updateMapDimensions);
    };
  }, []);

  useEffect(() => {
    const updateHeaderDimensions = () => {
      setTimeout(() => {
        setHeaderDivDimen({
          height: headerRef.current?.clientHeight || 1,
          width: headerRef.current?.clientWidth || 1,
        });
        setHeaderHeight(headerRef.current?.clientHeight || 0);
      }, 100); // Add a delay to ensure the component has fully rendered
    };

    updateHeaderDimensions();
    window.addEventListener('resize', updateHeaderDimensions);
    return () => {
      window.removeEventListener('resize', updateHeaderDimensions);
    };
  }, [showSSView]);

  const handleSSViewChange = (e) => {
    setShowSSView(e.target.checked);
  };

  const handleChange = (event, value) => {
    setPage(value - 1);
  };

  const startIndex = page * limit;
  const endIndex = startIndex + limit;

  const sortedNetworkDefects = useMemo(() => {
    if (!allNetworkDefects) return [];
    return allNetworkDefects.slice(startIndex, endIndex).sort((a, b) => a.frameNumber - b.frameNumber);
  }, [allNetworkDefects, startIndex, endIndex]);

  return (
    <div style={{ height: '100vh' }}>
      {showSSView ? (
        <SuperSectionController
          drNetworkData={drNetworkData}
          superSections={superSections}
          allNetworkDefects={allNetworkDefects}
          netid={netid}
          handleSSViewChange={handleSSViewChange}
          showSSView={showSSView}
        />
      ) : (
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <div ref={headerRef}>
              <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
                <Topbar
                  handleSSViewChange={handleSSViewChange}
                  showSSView={showSSView}
                  drNetworkData={drNetworkData}
                  netid={netid}
                  allNetworkDefects={allNetworkDefects}
                />
              </div>
            </div>
            {headerHeight === 0 ? (
              <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomProgress text={"Loading map"} />
              </div>
            ) : (
              <div style={{ height: `calc(100vh - ${headerHeight}px)` }}>
                <div style={{ height: '100%', padding: su._createPadding(overallCustomBorderPadding, 'trbl') }}>
                  <div ref={mapRef} style={{ height: '100%' }}>
                    <Card sx={{ ...cardWhiteStyle, height: '100%' }}>
                      <SuperSectionDefectsMap
                        setDefectPinned={setDefectPinned}
                        allNetworkDefects={sortedNetworkDefects}
                        drNetworkData={drNetworkData}
                        superSections={superSections}
                        defectPinned={defectPinned}
                      />
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'tbr'), height: `calc(100vh - ${0 * 2}px)` }}>
              <Card sx={{ borderRadius: '15px', height: '100%' }}>
                <div style={{ height: allNetworkDefects && allNetworkDefects.length > limit ? '90%' : '100%', overflow: 'auto' }}>
                  <ProjectDefectListView
                    page={page}
                    limit={limit}
                    defectPinned={defectPinned}
                    setDefectPinned={setDefectPinned}
                    allNetworkDefects={sortedNetworkDefects}
                    drNetworkData={drNetworkData}
                  />
                </div>
                {allNetworkDefects && allNetworkDefects.length > limit && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10%' }}>
                    <Pagination variant="outlined" shape="rounded" count={totalPages} page={page + 1} onChange={handleChange} />
                  </div>
                )}
              </Card>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allNetworkDefects: state.firestore.ordered.NetworkDefects,
    superSections: state.firestore.ordered.SuperSections,
    userData: state.firebase.profile,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const { netid } = props;
    const coid = props.userData.coid ?? '-1';
    return [
      {
        collection: `NetworkDefects`,
        where: [['networkId', '==', netid]],
        orderBy: ['frameNumber'],
      },
      {
        collection: `SuperSections`,
        where: [['coid', '==', `${coid}`]],
        orderBy: [['creationTime', 'desc']],
      }
    ];
  })
)(AfterCalibratingDashboard);
