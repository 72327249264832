import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography, Divider, Card, Tooltip, Button, Dialog } from "@mui/material";
import { moveAssetToArchive, resetAsset, updateAsset, resetAddDistressDialog } from "../../../../../store/actions/projectActions";
import { connect } from "react-redux";
import DistressTable from "./DistressTable";
import PinnedDistress from "./PinnedDistress";
import commonNames from '../../../../../constants/commonNames.json'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from '@mui/material/IconButton';
import EditorDistressMap from "./EditorDistressMap";
import DistressTopbar from "./DistressTopbar";
import ImageAnnotatorV2 from "./ImageAnnotatorV2";
import ImageAnnotatorV3 from "./ImageAnnotatorV3";
import AddNewDistress from "../subpages/afterInference/AddNewDistress";
import ArchivedDistressesViewer from '../subpages/afterInference/ArchivedDistressesViewer';
import AddIcon from '@mui/icons-material/Add';
import { useAccessControlContext } from "../../../../../contexts/AccessControlProvider";
import SmartUtils from "../../../../../helpers/SmartUtils";


const DistressDashboardV2 = ({ conditionMethod, handleSeekTo, isGroupData = false, distressPinned, setDistressPinned, resetAddDistressDialog,
    defectNameList, showMaskImage, drProjectData,
    predictions, handleSegmentChange, segmentIndex, originalVideoHeight,
    originalVideoWidth, link, proid, playerRef, handleCloseDialog, datastore, segmentPinned, fps, selectedSegment }) => {
    const { permissions } = useAccessControlContext();
    const { canAddDefectDistress, canUnarchiveDefectDistress } = permissions;
    const overallCustomBorderPadding = 16;
    const { distressData } = datastore;
    const { defectNames, dataInfo } = distressData;
    // const [distressPinned, setDistressPinned] = useState(null);
    const [bboxPinned, setBboxPinned] = useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [topbarHeaderHeight, setTopbarHeaderHeight] = useState({ height: 1, width: 1 })
    const headerRef = useRef(null);
    const headerHeight = headerRef?.current?.clientHeight ?? 0
    const fieldAreaMargin = 15;
    const customPadding = 16;
    const imageSectionHeight = 100;
    const mapSectionHeight = 25;
    const pinnedUiHeight = distressPinned === null ? 0 : 55;
    const tableHeight = 85 - pinnedUiHeight;
    let detectionsBBox = [];
    let defectNamesList = [];
    let rectangleList = [];

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        resetAddDistressDialog()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        window.addEventListener("load", setTopbarHeaderHeight(headerRef.current.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, []);
    const getVidWindowSize = () => {
        const newHeight = headerRef?.current?.clientHeight;
        const newWidth = headerRef?.current?.clientWidth;
        setTopbarHeaderHeight({
            height: newHeight,
            width: newWidth
        });
    };
    let originalDetections = [];
    const localDetections = selectedSegment ? Object.values(selectedSegment?.detections).map((eachData) => {
        // //////console.log('versioning' in eachData, 'versioning' in eachData ? 'defectName' in eachData['versioning'] ? eachData['versioning']['defectName'] : 'bye' : 'hello', eachData['defectName'], "defectNameData")
        return {
            ...eachData,
            area: 'versioning' in eachData ? 'area' in eachData['versioning'] ? eachData['versioning']['area'] : eachData['area'] : eachData['area'],
            bbox: 'versioning' in eachData ? 'bbox' in eachData['versioning'] ? eachData['versioning']['bbox'] : eachData['bbox'] : eachData['bbox'],
            defectName: 'versioning' in eachData ? 'defectName' in eachData['versioning'] ? eachData['versioning']['defectName'] : eachData['defectName'] : eachData['defectName'],
            depth: 'versioning' in eachData ? 'depth' in eachData['versioning'] ? eachData['versioning']['depth'] : eachData['depth'] : eachData['depth'],
            geo: 'versioning' in eachData ? 'geo' in eachData['versioning'] ? eachData['versioning']['geo'] : eachData['geo'] : eachData['geo'],
            profile: 'versioning' in eachData ? 'profile' in eachData['versioning'] ? eachData['versioning']['profile'] : eachData['profile'] : eachData['profile'],
            severity: 'versioning' in eachData ? 'severity' in eachData['versioning'] ? eachData['versioning']['severity'] : eachData['severity'] : eachData['severity'],
            type: 'versioning' in eachData ? 'type' in eachData['versioning'] ? eachData['versioning']['type'] : eachData['type'] : eachData['type'],
            volume: 'versioning' in eachData ? 'volume' in eachData['versioning'] ? eachData['versioning']['volume'] : eachData['volume'] : eachData['volume'],
            wheelPath: 'versioning' in eachData ? 'wheelPath' in eachData['versioning'] ? eachData['versioning']['wheelPath'] : eachData['wheelPath'] : eachData['wheelPath'],
            width: 'versioning' in eachData ? 'width' in eachData['versioning'] ? eachData['versioning']['width'] : eachData['width'] : eachData['width'],
            longitudinalSpan: 'versioning' in eachData ? 'longitudinalSpan' in eachData['versioning'] ? eachData['versioning']['longitudinalSpan'] : eachData['longitudinalSpan'] : eachData['longitudinalSpan'],
            transversePosition: 'versioning' in eachData ? 'transversePosition' in eachData['versioning'] ? eachData['versioning']['transversePosition'] : eachData['transversePosition'] : eachData['transversePosition'],
            transverseSpan: 'versioning' in eachData ? 'transverseSpan' in eachData['versioning'] ? eachData['versioning']['transverseSpan'] : eachData['transverseSpan'] : eachData['transverseSpan'],
        }
    })
        :
        [];
    originalDetections = [...originalDetections, ...localDetections];
    let detections = originalDetections.filter(eachDetection => Object.keys(eachDetection).length > 0).filter((eachDetection) => {
        return eachDetection.archived ? eachDetection.archived === "0" : eachDetection
    })
    detections.sort((a, b) => {
        return isGroupData ? a.groupFrameNumber - b.groupFrameNumber : a.frameNumber - b.frameNumber
    })
    detections.forEach((eachData) => {
        detectionsBBox = [...detectionsBBox, eachData.bbox]
        defectNamesList = [...defectNamesList, eachData.defectName]
        rectangleList = [...rectangleList, eachData.isRectangle ?? true]
        // const isRectangle = eachData.isRectangle;
        // const rectangleCondCheck = isRectangle === undefined || isRectangle === true ? true : false;
        // if (rectangleCondCheck) {
        //     detectionsBBox = [...detectionsBBox, eachData.bbox]
        //     defectNamesList = [...defectNamesList, eachData.defectName]
        // }
    });
    // useEffect(() => {
    //     if (distressPinned) {
    //         let newPred = Object.values(selectedSegment.detections).filter((e) => e.defectId === distressPinned.defectId)[0];
    //         if (newPred) {
    //             newPred['area'] = 'versioning' in newPred ? 'area' in newPred['versioning'] ? newPred['versioning']['area'] : newPred['area'] : newPred['area'];
    //             newPred['bbox'] = 'versioning' in newPred ? 'bbox' in newPred['versioning'] ? newPred['versioning']['bbox'] : newPred['bbox'] : newPred['bbox'];
    //             newPred['defectName'] = 'versioning' in newPred ? 'defectName' in newPred['versioning'] ? newPred['versioning']['defectName'] : newPred['defectName'] : newPred['defectName'];
    //             newPred['depth'] = 'versioning' in newPred ? 'depth' in newPred['versioning'] ? newPred['versioning']['depth'] : newPred['depth'] : newPred['depth']
    //             newPred['geo'] = 'versioning' in newPred ? 'geo' in newPred['versioning'] ? newPred['versioning']['geo'] : newPred['geo'] : newPred['geo']
    //             newPred['longitudinalSpan'] = 'versioning' in newPred ? 'longitudinalSpan' in newPred['versioning'] ? newPred['versioning']['longitudinalSpan'] : newPred['longitudinalSpan'] : newPred['longitudinalSpan']
    //             newPred['profile'] = 'versioning' in newPred ? 'profile' in newPred['versioning'] ? newPred['versioning']['profile'] : newPred['profile'] : newPred['profile']
    //             newPred['severity'] = 'versioning' in newPred ? 'severity' in newPred['versioning'] ? newPred['versioning']['severity'] : newPred['severity'] : newPred['severity']
    //             newPred['transversePosition'] = 'versioning' in newPred ? 'transversePosition' in newPred['versioning'] ? newPred['versioning']['transversePosition'] : newPred['transversePosition'] : newPred['transversePosition']
    //             newPred['transverseSpan'] = 'versioning' in newPred ? 'transverseSpan' in newPred['versioning'] ? newPred['versioning']['transverseSpan'] : newPred['transverseSpan'] : newPred['transverseSpan']
    //             newPred['type'] = 'versioning' in newPred ? 'type' in newPred['versioning'] ? newPred['versioning']['type'] : newPred['type'] : newPred['type']
    //             newPred['volume'] = 'versioning' in newPred ? 'volume' in newPred['versioning'] ? newPred['versioning']['volume'] : newPred['volume'] : newPred['volume']
    //             newPred['wheelPath'] = 'versioning' in newPred ? 'wheelPath' in newPred['versioning'] ? newPred['versioning']['wheelPath'] : newPred['wheelPath'] : newPred['wheelPath']
    //             newPred['width'] = 'versioning' in newPred ? 'width' in newPred['versioning'] ? newPred['versioning']['width'] : newPred['width'] : newPred['width']

    //             setDistressPinned(newPred);
    //         }
    //         else {
    //             setDistressPinned(null)
    //         }
    //     }

    //     // setPinned(null);
    // }, [selectedSegment])
    useEffect(() => {
        if (distressPinned) {
            let newPred = Object.values(selectedSegment.detections).find((e) => e.defectId === distressPinned.defectId);

            if (newPred) {

                newPred = { ...newPred };

                newPred['area'] = 'versioning' in newPred ? 'area' in newPred['versioning'] ? newPred['versioning']['area'] : newPred['area'] : newPred['area'];
                newPred['bbox'] = 'versioning' in newPred ? 'bbox' in newPred['versioning'] ? newPred['versioning']['bbox'] : newPred['bbox'] : newPred['bbox'];
                newPred['defectName'] = 'versioning' in newPred ? 'defectName' in newPred['versioning'] ? newPred['versioning']['defectName'] : newPred['defectName'] : newPred['defectName'];
                newPred['depth'] = 'versioning' in newPred ? 'depth' in newPred['versioning'] ? newPred['versioning']['depth'] : newPred['depth'] : newPred['depth']
                newPred['geo'] = 'versioning' in newPred ? 'geo' in newPred['versioning'] ? newPred['versioning']['geo'] : newPred['geo'] : newPred['geo']
                newPred['longitudinalSpan'] = 'versioning' in newPred ? 'longitudinalSpan' in newPred['versioning'] ? newPred['versioning']['longitudinalSpan'] : newPred['longitudinalSpan'] : newPred['longitudinalSpan']
                newPred['profile'] = 'versioning' in newPred ? 'profile' in newPred['versioning'] ? newPred['versioning']['profile'] : newPred['profile'] : newPred['profile']
                newPred['severity'] = 'versioning' in newPred ? 'severity' in newPred['versioning'] ? newPred['versioning']['severity'] : newPred['severity'] : newPred['severity']
                newPred['transversePosition'] = 'versioning' in newPred ? 'transversePosition' in newPred['versioning'] ? newPred['versioning']['transversePosition'] : newPred['transversePosition'] : newPred['transversePosition']
                newPred['transverseSpan'] = 'versioning' in newPred ? 'transverseSpan' in newPred['versioning'] ? newPred['versioning']['transverseSpan'] : newPred['transverseSpan'] : newPred['transverseSpan']
                newPred['type'] = 'versioning' in newPred ? 'type' in newPred['versioning'] ? newPred['versioning']['type'] : newPred['type'] : newPred['type']
                newPred['volume'] = 'versioning' in newPred ? 'volume' in newPred['versioning'] ? newPred['versioning']['volume'] : newPred['volume'] : newPred['volume']
                newPred['wheelPath'] = 'versioning' in newPred ? 'wheelPath' in newPred['versioning'] ? newPred['versioning']['wheelPath'] : newPred['wheelPath'] : newPred['wheelPath']
                newPred['width'] = 'versioning' in newPred ? 'width' in newPred['versioning'] ? newPred['versioning']['width'] : newPred['width'] : newPred['width']

                setDistressPinned(newPred);
            } else {
                setDistressPinned(null)
            }
        }
    }, [selectedSegment])


    function getCorner(pivotX, pivotY, diffX, diffY) {
        const distance = Math.sqrt(diffX * diffX + diffY * diffY);

        const angle = Math.atan2(diffY, diffX);

        const x = pivotX + distance * Math.cos(angle);
        const y = pivotY + distance * Math.sin(angle);

        return [x, y]
    }

    const bbox = detectionsBBox.filter(coords => Object.keys(coords ?? {}).length > 0).map((coords, index) => {
        //////console.log(coords, 'pak12')
        let fill;
        const defectName = defectNameList[index];
        const isRectangle = rectangleList[index];
        //////console.log(isRectangle, 'isRectangle')
        fill = SmartUtils.detectionColor(defectName);

        if (isRectangle === true) {
            const x = coords[0];
            const y = coords[1];
            const width = coords[2];
            const height = coords[3];

            const topLeft = getCorner(x, y, 0, 0);
            const topRight = getCorner(x, y, width, 0);
            const bottomRight = getCorner(x, y, width, height);
            const bottomLeft = getCorner(x, y, 0, height);

            const pairs = [topLeft, topRight, bottomRight, bottomLeft];

            return { defectName, fill, pairs };
        }
        else {
            const pairs = [];
            for (let i = 0; i < coords.length; i += 2) {
                pairs.push([coords[i], coords[i + 1]]);
                //////console.log(pairs, 'jack12')
            }
            return { defectName, fill, pairs };
        }
    });

    //////console.log(bbox, 'roomi')
    //////console.log(rectangleList, 'neon')

    // const bbox = detectionsBBox.filter(coords => Object.keys(coords ?? {}).length > 0).map((coords, index) => {
    //     let fill;
    //     const defectName = defectNameList[index];

    //     switch (defectName) {
    //         case 0:
    //             fill = 'rgba(255, 0, 0, 0.1)';
    //             break;
    //         case 1:
    //             fill = 'rgba(0, 255, 0, 0.1)';
    //             break;
    //         case 2:
    //             fill = 'rgba(255, 0, 255, 0.1)';
    //             break;
    //         case 3:
    //             fill = 'rgba(0, 0, 255, 0.1)';
    //             break;
    //         case 4:
    //             fill = 'rgba(255, 255, 255, 0.1)';
    //             break;
    //         default:
    //             fill = 'rgba(0, 0, 0, 0.1)';
    //     }

    //     return {
    //         x: coords[0],
    //         y: coords[1],
    //         width: coords[2],
    //         height: coords[3],
    //         fill: fill,
    //         defectName: defectName,
    //     };
    // });

    const cardWhiteStyle = {
        "borderRadius": "15px",
    }
    const handleEditorClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleEditorCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleDistressPinned = (data) => {
        // const isRectangle = data.isRectangle;
        // const rectangleCondCheck = isRectangle === undefined || isRectangle === true ? true : false;
        // if (!rectangleCondCheck) {
        //     delete data.bbox
        // }
        const check = data === null ? false : distressPinned === null ? false : data.defectId === distressPinned.defectId
        playerRef.current?.pause()

        if (data) {
            handleSeekTo(Number((isGroupData ? data.groupFrameNumber / fps : data.frameNumber / fps)).toFixed(5))
        }
        setDistressPinned(check ? null : data)
    }
    // const handleSeekTo = (timeSeek) => {
    //     playerRef.current.seek(timeSeek + (1 / fps).toFixed(5))
    // }
    const index = (element) => element.defectId === distressPinned?.defectId;
    var distressIndex = detections.findIndex(index);

    const nextDistress = () => {
        if (distressIndex < detections.length) {
            distressIndex = distressIndex + 1;
            const nextPred = detections[distressIndex];
            handleDistressPinned(nextPred);
        }

    }
    const previousDistress = () => {

        if (distressIndex >= 0) {
            distressIndex = distressIndex - 1;
            const nextPred = detections[distressIndex];
            handleDistressPinned(nextPred);
        }
    }
    const handleNextSection = () => {
        setDistressPinned(null);
        handleSegmentChange(true)
    }
    const handlePreviousSection = () => {
        setDistressPinned(null);
        handleSegmentChange(false)
    }

    const handleBoxChange = (newValue) => {
        // //////console.log(newValue, "bbox coordinates");
    };

    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#E1E3E2' }}>
            <Grid container spacing={0}>
                <Grid item xs={7} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)`, padding: '' }}>
                    <div ref={headerRef} style={{ padding: `${overallCustomBorderPadding}px 0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`, backgroundColor: '' }}>
                        <DistressTopbar proid={proid} handleCloseDialog={handleCloseDialog} segmentIndex={segmentIndex + 1} />
                    </div>
                    <div style={{ height: `calc(75vh - ${headerHeight}px)`, backgroundColor: '' }}>
                        <div style={{ height: `calc(75vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)`, paddingLeft: `${overallCustomBorderPadding}px`, paddingBottom: `${overallCustomBorderPadding}px`, backgroundColor: '' }}>
                            <Card elevation={4} sx={{ ...cardWhiteStyle, height: `calc(75vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)` }}>
                                <div style={{ height: `${pinnedUiHeight}%`, backgroundColor: '', overflow: 'auto' }}>
                                    {distressPinned === null ? <></> :
                                        <PinnedDistress
                                            isGroupData={isGroupData}
                                            setDistressPinned={setDistressPinned}
                                            predictions={predictions}
                                            currentSection={selectedSegment}
                                            segmentIndex={segmentIndex}
                                            handleSegmentChange={handleSegmentChange}
                                            originalVideoHeight={originalVideoHeight}
                                            originalVideoWidth={originalVideoWidth} playerRef={playerRef} proid={proid} dataList={detections} handleEditorClickOpenDialog={handleEditorClickOpenDialog} openDialog={openDialog} handleEditorCloseDialog={handleEditorCloseDialog}
                                            previousDistress={previousDistress} distressIndex={distressIndex} nextDistress={nextDistress}
                                            handleDistressPinned={handleDistressPinned} fps={fps} realFps={fps} link={link}
                                            distressPinned={distressPinned}
                                            defectNames={defectNames} dataInfo={dataInfo}
                                            distressData={distressData}
                                            drProjectData={drProjectData}
                                            handleCloseDialog={handleCloseDialog}
                                        />}
                                </div>
                                <div style={{ height: `${tableHeight}%`, backgroundColor: '', overflow: 'auto' }}>
                                    <DistressTable isGroupData={isGroupData} drProjectData={drProjectData} disableHeader={false} fps={fps} distressPinned={distressPinned} dataList={detections} handleDistressPinned={handleDistressPinned} distressData={selectedSegment} defectNames={defectNames} dataInfo={dataInfo} bboxPinned={bboxPinned} />
                                </div>
                                <Divider light />
                                <div style={{ height: `15%`, backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', overflow: 'auto' }}>
                                    <Grid container>
                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', backgroundColor: '' }}>
                                            <IconButton onClick={handlePreviousSection} disabled={segmentIndex === 0 ? true : false}>
                                                <Tooltip title={`Previous section`}><KeyboardArrowLeftIcon sx={{ whiteSpace: 'nowrap', fontSize: { xs: '16px', sm: '16px', md: '24px' }, color: segmentIndex === 0 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                                            </IconButton>
                                            <Typography variant="h6" component="span" sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '16px' }, padding: `0px ${15 + 10}px 0px ${15 + 10}px` }}>
                                                {commonNames.Section} {segmentIndex + 1} / {Object.values(predictions).length}
                                            </Typography>
                                            <IconButton onClick={handleNextSection} disabled={segmentIndex === Object.values(predictions).length - 1 ? true : false}>
                                                <Tooltip title="Next section"><KeyboardArrowRightIcon sx={{ whiteSpace: 'nowrap', fontSize: { xs: '16px', sm: '16px', md: '24px' }, color: segmentIndex === Object.values(predictions).length - 1 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', backgroundColor: '' }}>
                                            {/* <Button sx={{ marginRight: '10px', textTransform: 'none' }} onClick={handleClickOpen}>+ Add new distress</Button> */}
                                            {canAddDefectDistress ? <Tooltip title='Add new distress' sx={{ display: { xs: "flex", sm: "flex", md: 'none' }, }}>
                                                <AddIcon
                                                    sx={{
                                                        fontSize: { xs: '16px', sm: '16px', md: '24px' },
                                                        display: { xs: "flex", sm: "flex", md: 'none' },
                                                        color: "#1E1E1E",
                                                        marginRight: "25px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={handleClickOpen}
                                                />
                                            </Tooltip> : <></>}
                                            <Button
                                                disabled={!canAddDefectDistress}
                                                onClick={handleClickOpen}
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    display: { xs: "none", sm: "none", md: 'flex' },
                                                    marginRight: { lg: '20px', md: '10px' },
                                                    cursor: "pointer",
                                                    textTransform: "none",
                                                    // color: "black",
                                                    fontSize: '14px'

                                                }}
                                            >
                                                + Add new distress
                                            </Button>

                                            <Dialog open={open} onClose={handleClose} fullScreen>

                                                <AddNewDistress drProjectData={drProjectData} onClose={handleClose} handleCloseDialog={handleCloseDialog} proid={proid} distressPinned={distressPinned} currentSection={selectedSegment} distressData={distressData} imageUrl1={selectedSegment?.stitchPath} imageUrl2={selectedSegment?.depthMapPath} imageHeight={selectedSegment?.HRHeight} imageWidth={selectedSegment?.HRWidth} editableBbox={bbox} drawMap={true} editBox={false} dataInfo={dataInfo} />

                                            </Dialog>
                                            {canUnarchiveDefectDistress ? <ArchivedDistressesViewer selectedSegment={selectedSegment} distressData={distressData} detections={originalDetections} proid={proid} /> : <></>}
                                        </Grid>
                                    </Grid>

                                </div>
                            </Card>
                        </div>
                    </div>
                    <div id='asset-map' style={{ padding: `0px ${0}px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`, height: `calc(${mapSectionHeight}vh - ${overallCustomBorderPadding * 1}px)`, backgroundColor: '' }}>
                        <Card elevation={4} sx={cardWhiteStyle}>
                            <div style={{ height: `calc(${mapSectionHeight}vh - ${overallCustomBorderPadding * 1}px)` }}>
                                <EditorDistressMap drProjectData={drProjectData} conditionMethod={conditionMethod} isGroupData={isGroupData} distressPinned={distressPinned}
                                    handleSeekTo={handleSeekTo}
                                    FPS={fps}
                                    //  handleSeekTo={handleSegmentSeekTo} handlePinned={handleSegmentPinned}
                                    distressProjectPredictions={predictions}
                                    zoom={18}
                                    pinned={segmentPinned}
                                    setDistressPinned={setDistressPinned} />
                            </div>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={5} sx={{ backgroundColor: '', height: `calc(100vh - ${0}px)` }}>
                    <div style={{ padding: `${overallCustomBorderPadding}px`, height: `calc(${imageSectionHeight}vh - ${0 * 2}px)`, backgroundColor: '' }}>
                        <Card elevation={4} sx={{ ...cardWhiteStyle, height: `calc(${imageSectionHeight}vh - ${overallCustomBorderPadding * 2}px)` }}>
                            {drProjectData.projectType == 4 ?
                                <Grid container spacing={0} sx={{ height: `calc(${imageSectionHeight}vh - ${0 * 2}px)` }}>
                                    <Grid item xs={12} style={{ height: `calc(${imageSectionHeight}vh - ${0 * 2}px)` }}>
                                        {/* <EditorStitchNormalmage distressPinned={distressPinned} imageSectionHeight={imageSectionHeight} defectNameList={defectNamesList} sectionBBox={detectionsBBox} selectedSegment={selectedSegment} overallCustomBorderPadding={overallCustomBorderPadding * 2} numHeightOfHeader={0} /> */}
                                        <ImageAnnotatorV3 imageUrl={showMaskImage ? selectedSegment?.maskPath : selectedSegment?.stitchPath} imageHeight={selectedSegment?.HRHeight} imageWidth={selectedSegment?.HRWidth} drawMap={showMaskImage ? false : true} editBox={false} onValueChange={(value) => handleBoxChange(value)} nonEditableBbox={bbox} distressPinned={distressPinned} setBboxPinned={setBboxPinned} />

                                    </Grid>

                                </Grid>
                                :
                                <Grid container spacing={0} sx={{ height: `calc(${imageSectionHeight}vh - ${0 * 2}px)` }}>
                                    <Grid item xs={6} style={{ height: `calc(${imageSectionHeight}vh - ${0 * 2}px)` }}>
                                        {/* <EditorStitchNormalmage distressPinned={distressPinned} imageSectionHeight={imageSectionHeight} defectNameList={defectNamesList} sectionBBox={detectionsBBox} selectedSegment={selectedSegment} overallCustomBorderPadding={overallCustomBorderPadding * 2} numHeightOfHeader={0} /> */}
                                        <ImageAnnotatorV3 imageUrl={showMaskImage ? selectedSegment.maskPath : selectedSegment?.stitchPath} imageHeight={selectedSegment?.HRHeight} imageWidth={selectedSegment?.HRWidth} drawMap={showMaskImage ? false : true} editBox={false} onValueChange={(value) => handleBoxChange(value)} nonEditableBbox={bbox} distressPinned={distressPinned} setBboxPinned={setBboxPinned} />

                                    </Grid>
                                    <Grid item xs={6} style={{ height: `calc(${imageSectionHeight}vh - ${0 * 2}px)` }}>
                                        <ImageAnnotatorV3 imageUrl={selectedSegment?.depthMapPath} imageHeight={selectedSegment?.HRHeight} imageWidth={selectedSegment?.HRWidth} drawMap={false} editBox={false} />
                                        {/* <EditorDepthMapImage sectionBBox={detectionsBBox} imageSectionHeight={imageSectionHeight} selectedSegment={selectedSegment} overallCustomBorderPadding={overallCustomBorderPadding * 2} numHeightOfHeader={0} /> */}
                                    </Grid>
                                </Grid>
                            }
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        moveAssetToArchive: (proid, sectionId, detectionId) => dispatch(moveAssetToArchive(proid, sectionId, detectionId)),
        resetAsset: (proid, sectionId, detectionId) => dispatch(resetAsset(proid, sectionId, detectionId)),
        updateAsset: (proid, sectionId, detectionId, roadSide, assetId) => dispatch(updateAsset(proid, sectionId, detectionId, roadSide, assetId)),
        resetAddDistressDialog: () => dispatch(resetAddDistressDialog())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DistressDashboardV2);