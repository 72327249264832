import { Box, Card, Divider, Grid, IconButton, MenuItem, Paper, Stack, TextField, Tooltip, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { connect } from 'react-redux';
import ImageAnnotator from "./ImageAnnotator";
import DistressPinOnMap from './DistressPinOnMap';
import DepthMapImage from './DepthMapImage';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DistressEditorDepthMapImage from './DistressEditorDepthMapImage';
import commonNames from '../../../../../constants/commonNames.json';
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from '@mui/material'
import MoreIcon from '@mui/icons-material/MoreVert';

const DistressPredictionEditor = (props) => {
  const { handleCloseDialog, dataInfo, setDistressPinned, currentSection, predictions, segmentIndex, handleSegmentChange, previousDistress, distressIndex, nextDistress, dataList,
    handleEditorClickOpenDialog, openDialog, handleEditorCloseDialog, handleQuality,
    qualities, proid, handleDistressPinned, fps, realFps, link, distressPinned, playerRef,
    originalVideoHeight, originalVideoWidth } = props;
  const [fieldValues, setFieldValues] = useState({
    defectNameState: distressPinned?.defectName ?? -1,
    transversePositionState: distressPinned?.transversePosition ?? -1,
    gpsCoordinatesState: `${distressPinned?.geo[0]},${distressPinned?.geo[1]}` ?? -1,
    typeState: distressPinned?.type ?? -1,
    severityState: distressPinned?.severity ?? -1,
    frameNumberState: distressPinned?.frameNumber ?? -1,
    areaState: distressPinned?.area ?? -1,
    depthState: distressPinned?.depth ?? -1,
  });

  const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/deeprowserverless.appspot.com/o/Projects%2Fwebproj_webcom_pCoSls1iFGYTL2KlelCaKRqytTU2_zVDW4u0onPc4BniWhGir%2Fsection0%2Fstiched%2Fimages%2F00000000.png?alt=media&token=f1c873ab-33e0-40d2-aabd-b7ffb7380549&_gl=1*18h88or*_ga*MTM5NDI4NjcyMS4xNjgwMzg1ODQy*_ga_CW55HF8NVT*MTY4NjI0MjQ4MC4xMjUuMS4xNjg2MjQyNTQwLjAuMC4w';
  const imageUrl2 = 'https://firebasestorage.googleapis.com/v0/b/deeprowserverless.appspot.com/o/Projects_webproj_webcom_qrkQYMXHouZo5aN2B6dZ_m1VSlR0tjYYp3s8WfnFS_section0_stiched_images_00011%201.jpg?alt=media&token=1525c6de-8cfd-473c-90ed-b970defead4e&_gl=1*3y0pb3*_ga*MTM5NDI4NjcyMS4xNjgwMzg1ODQy*_ga_CW55HF8NVT*MTY4NTczMTEwOC43OC4xLjE2ODU3MzM0MzUuMC4wLjA'
  const bbox = [
    {
      x: 100,
      y: 100,
      width: 300,
      height: 300,
      fill: 'rgba(0, 255, 0, 0.3)'
      // id: 'rect1',
    }
  ];
  const handleBoxChange = (newValue) => {
    // //////console.log(newValue, "hello world");
  };

  useEffect(() => {
    setFieldValues({
      defectNameState: distressPinned?.defectName ?? -1,
      transversePositionState: distressPinned?.transversePosition ?? -1,
      gpsCoordinatesState: `${distressPinned?.geo[0]},${distressPinned?.geo[1]}` ?? -1,
      typeState: distressPinned?.type ?? -1,
      severityState: distressPinned?.severity ?? -1,
      frameNumberState: distressPinned?.frameNumber ?? -1,
      areaState: distressPinned?.area ?? -1,
      depthState: distressPinned?.depth ?? -1,
    });
  }, [distressPinned])
  const numHeightOfHeader = 8;
  const numHeightOfFooter = 8;
  const customPadding = 15;
  const fieldAreaMargin = 20;
  const cardStyle = {
    "border": "2px solid grey",
    "borderRadius": "8px",
    "backgroundColor": '#f5f1f1'
  }
  const cardLightStyle = {
    "border": "0px solid grey",
    "borderRadius": "8px",
    "padding": "5px",
    "backgroundColor": '#f5f1f1'
  }
  const fieldStyle = {
    "border": "2px solid grey",
    "borderRadius": "5px",
    "padding": '5px',
    "fontSize": '0.8rem',
    "textTransform": 'capitalize'
  }
  const Item = styled(Paper)(({ theme }) => ({
    boxShadow: '0px 0px transparent',
    width: '100%',
    backgroundColor: 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
  }));
  const handleChange = (prop) => (event) => {
    setFieldValues({ ...fieldValues, [prop]: event.target.value });
  };
  const { defectName, profile, severity, transversePosition, type, wheelPath } = dataInfo;
  // //////console.log(distressPinned?.defectName, "defectName=")
  const defectNamesMap = {
    "map": dataInfo['defectName'],
    "keys": Object.keys(dataInfo['defectName']),
    "values": Object.values(dataInfo['defectName']),
    "usefulKeys": ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
  }
  const defectNamesList = defectNamesMap.keys.filter(e => defectNamesMap.usefulKeys.includes(e)).map((e, i) => {
    return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
      {`${defectNamesMap.values[i]['name']}`}
    </MenuItem>
  })
  const transversePositionMap = {
    "map": dataInfo['transversePosition'],
    "keys": Object.keys(dataInfo['transversePosition']),
    "values": Object.values(dataInfo['transversePosition']),
    "usefulKeys": ['0', '1', '2', '3', '4', '5'],
  }
  const transversePositionList = transversePositionMap.keys.filter(e => transversePositionMap.usefulKeys.includes(e)).map((e, i) => {
    return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
      {`${transversePositionMap.values[i]['name']}`}
    </MenuItem>
  })
  const typeMap = {
    "map": dataInfo['type'],
    "keys": Object.keys(dataInfo['type']),
    "values": Object.values(dataInfo['type']),
    "usefulKeys": ['0', '1', '2', '3', '4', '5', '6', '998'],
  }
  const typeList = typeMap.keys.filter(e => typeMap.usefulKeys.includes(e)).map((e, i) => {
    return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
      {`${typeMap.values[i]['name']}`}
    </MenuItem>
  })
  const severityMap = {
    "map": dataInfo['severity'],
    "keys": Object.keys(dataInfo['severity']),
    "values": Object.values(dataInfo['severity']),
    "usefulKeys": ['0', '1', '2'],
  }
  const severityList = severityMap.keys.filter(e => severityMap.usefulKeys.includes(e)).map((e, i) => {
    return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
      {`${severityMap.values[i]['name']}`}
    </MenuItem>
  })

  const fieldTextStyle = {
    fontSize: '15px',
    fontWeight: 'bold',
  }
  const allowedTypeEdit = [1];
  const allowedSeverityEdit = [3];
  const allowedProfileEdit = [2];
  return (
    <div style={{ height: '100vh', width: '100vw', backgroundColor: '#FAFAFA' }}>

      <div style={{ height: `${(100) - (numHeightOfHeader + numHeightOfFooter)}vh`, width: '100vw', backgroundColor: '' }}>
        <Grid container spacing={0}>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: '', height: `${(116) - (numHeightOfHeader + numHeightOfFooter)}vh` }}>

            <Grid container spacing={0} style={{ padding: `${customPadding}px` }}>


              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: '', height: `${((27) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh` }}>

                <Card sx={{ border: "2px solid grey", borderRadius: "15px", backgroundColor: '#f5f1f1', height: '100%' }}>

                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{
                    display: 'flex', justifyContent: 'center', backgroundColor: ''
                  }} >
                    <Typography variant="h6" sx={{ fontWeight: '' }}>
                      Distress editor
                    </Typography>

                  </Grid>

                </Card>
              </Grid>


              {/* fields */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: '', marginTop: `${fieldAreaMargin}px`, height: `${((50) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh` }}>
                <Card sx={{ ...cardStyle, height: '100%' }}>
                  <div style={{ padding: '5px', overflow: 'auto', height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                    <>
                      <Grid container>
                        <Grid item xs={6} >
                          <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
                            {allowedTypeEdit.includes(distressPinned?.defectName) ?
                              <Item>
                                <Grid container>
                                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                  }} >
                                    <Typography variant="body1" sx={fieldTextStyle}>
                                      Defect name:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <TextField
                                      id="defect name"
                                      select
                                      value={fieldValues.defectNameState}
                                      onChange={handleChange("defectNameState")}
                                      size="small"
                                      sx={{ textTransform: 'capitalize', width: '100%' }}
                                    >
                                      {defectNamesList}
                                    </TextField>
                                  </Grid>
                                </Grid>
                              </Item> : <></>}
                            <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body1" sx={fieldTextStyle}>
                                    Area:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <TextField
                                    disabled
                                    id="outlined-size-small"
                                    value={fieldValues.areaState}
                                    onChange={handleChange("areaState")}
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            </Item>
                            <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }} >
                                  <Typography variant="body1" sx={fieldTextStyle}>
                                    Transverse position:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <TextField
                                    id="transverse position"
                                    select
                                    value={fieldValues.transversePositionState}
                                    onChange={handleChange("transversePositionState")}
                                    size="small"
                                    sx={{ textTransform: 'capitalize', width: '100%' }}
                                    disabled={true}
                                  >
                                    {transversePositionList}
                                  </TextField>
                                </Grid>
                              </Grid>
                            </Item>
                          </div>
                        </Grid>

                        {/* second section */}
                        <Grid item xs={6} >
                          <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
                            <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }} >
                                  <Typography variant="body1" sx={fieldTextStyle}>
                                    GPS location:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                  <TextField
                                    disabled
                                    id="outlined-size-small"
                                    value={fieldValues.gpsCoordinatesState}
                                    onChange={handleChange("gpsCoordinatesState")}
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            </Item>
                            <Item>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }} >
                                  <Typography variant="body1" sx={fieldTextStyle}>
                                    Depth:
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                  <TextField
                                    disabled
                                    id="outlined-size-small"
                                    value={fieldValues.depthState}
                                    onChange={handleChange("depthState")}
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            </Item>
                            {fieldValues.typeState === -1 && fieldValues.severityState === -1 ? <></> :
                              fieldValues.typeState === -1 ?
                                <Item>
                                  <Grid container>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }} >
                                      <Typography variant="body1" sx={fieldTextStyle}>
                                        Severity:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                      <TextField
                                        id="severity"
                                        select
                                        value={fieldValues.severityState}
                                        onChange={handleChange("severityState")}
                                        size="small"
                                        sx={{ textTransform: 'capitalize', width: '100%' }}
                                      >
                                        {severityList}
                                      </TextField>
                                    </Grid>
                                  </Grid>
                                </Item> : <Item>
                                  <Grid container>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }} >
                                      <Typography variant="body1" sx={fieldTextStyle}>
                                        Type:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                      <TextField
                                        id="type"
                                        select
                                        value={fieldValues.typeState}
                                        onChange={handleChange("typeState")}
                                        size="small"
                                        sx={{ textTransform: 'capitalize', width: '100%' }}
                                      >
                                        {typeList}
                                      </TextField>
                                    </Grid>
                                  </Grid>
                                </Item>
                            }
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  </div>
                </Card>
              </Grid>
              {/* map */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: `${fieldAreaMargin}px`, backgroundColor: '', height: `calc(${((165) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                <Card sx={{ ...cardStyle, height: '100%' }}>
                  <div style={{ backgroundColor: 'green', overflow: 'auto', height: `calc(${((164) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)` }}>
                    <DistressPinOnMap distressPinned={distressPinned} />
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: '', height: `calc(116vh - ${numHeightOfHeader}vh - ${numHeightOfFooter}vh)` }}>


            <Grid container spacing={0} style={{
              // padding: `${customPadding}px ${customPadding}px ${customPadding}px 0px ` 
              padding: `${customPadding}px`
            }}>
              <Grid container spacing={0} sx={{ height: '100%' }}>
                {/* Stitch image */}
                <Grid item xs={6}>
                  <div style={{ height: '627px', marginLeft: '100px' }}>
                    <ImageAnnotator imageUrl={imageUrl} drawMap={true} bbox={bbox} onValueChange={(value) => handleBoxChange(value)} />
                  </div>
                </Grid>
                {/* Depth image */}
                <Grid item xs={6}>
                  <div style={{ height: '627px', marginLeft: '100px' }}>
                    <ImageAnnotator imageUrl={imageUrl2} drawMap={false} />
                    {/* <DistressEditorDepthMapImage sectionBBox={distressPinned?.bbox} selectedSegment={currentSection} numHeightOfHeader={numHeightOfHeader} numHeightOfFooter={numHeightOfFooter} customPadding={customPadding * 2} /> */}
                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>


        </Grid>
      </div>

      {/* <div style={{ height: `${numHeightOfFooter}vh`, width: '100vw', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
         
          <IconButton onClick={previousDistress} disabled={distressIndex === 0 ? true : false}>
            <Tooltip title="Previous distress"><KeyboardArrowLeftIcon sx={{ fontSize: { xs: '16px', sm: '16px', md: '24px' }, color: distressIndex === 0 ? 'grey' : '#1E1E1E' }} /></Tooltip>
          </IconButton>
          <Typography variant="h6" component="span" sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '16px' }, padding: `0px ${15 + 10}px 0px ${15 + 10}px` }}>
           Distress {distressIndex + 1} / {dataList.length}</Typography>

          <IconButton onClick={nextDistress} disabled={distressIndex === dataList.length - 1 ? true : false}>
            <Tooltip title="Next distress"><KeyboardArrowRightIcon sx={{ fontSize: { xs: '16px', sm: '16px', md: '24px' }, color: distressIndex === dataList.length - 1 ? 'grey' : '#1E1E1E' }} /></Tooltip>
          </IconButton>

        </Box>
      </div> */}


    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    datastore: state.datastore,
    project: state.project,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // // setCurrentProjectId: (proid) => dispatch(setCurrentProjectId(proid)),
    // moveAssetToArchive: (proid, sectionId, detectionId) => dispatch(moveAssetToArchive(proid, sectionId, detectionId)),
    // resetAsset: (proid, sectionId, detectionId) => dispatch(resetAsset(proid, sectionId, detectionId)),
    // updateAsset: (proid, sectionId, detectionId, roadSide, assetId) => dispatch(updateAsset(proid, sectionId, detectionId, roadSide, assetId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DistressPredictionEditor);
