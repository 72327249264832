import React, { useState, useEffect } from 'react';
import { MapContainer, LayerGroup, LayersControl, Polyline, CircleMarker, useMap } from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import "leaflet/dist/leaflet.css";
import { Tooltip as LeafletTooltip } from 'react-leaflet';
const VisualizerMap = (props) => {
    const { selectedCategories, zoom = 21, maxZoom = 25, handleFrameClick, nearestValues, currentTime,
        filteredGpsData360, markerDetections, handleMapFrameChange } = props;
    const [circleCoordinates, setCircleCoordinates] = useState({ lat: '', lng: '' });
    const [markerKey, setMarkerKey] = useState(0);
    useEffect(() => {
        setMarkerKey(markerKey + 1);
    }, [markerDetections, selectedCategories])
    useEffect(() => {
        if (nearestValues) {
            setCircleCoordinates((prevCoordinates) => {
                if (!prevCoordinates || prevCoordinates.lat !== nearestValues.lat || prevCoordinates.lng !== nearestValues.lng) {
                    return { lat: nearestValues.lat, lng: nearestValues.lng };
                }
                return prevCoordinates;
            });
        }
    }, [nearestValues]);

    if (filteredGpsData360 === undefined || filteredGpsData360 === null) {
        return <></>
    }
    const videoIndexMap = {};
    filteredGpsData360.forEach(item => {
        const { videoIndex } = item;
        videoIndexMap[videoIndex] = videoIndexMap[videoIndex] || [];
        videoIndexMap[videoIndex].push([item.lat, item.lng]);
    });
    const roadCoordinates = filteredGpsData360.map(entry => [entry.lat, entry.lng]);
    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);

        return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };
    const centerCoor = roadCoordinates[0]
    const Recenter = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }
    // const color = ['red','green']
    return (
        <div style={{ height: "100%" }}>
            <MapContainer
                center={[centerCoor[0], centerCoor[1]]}
                attributionControl={false}
                zoom={zoom} maxZoom={maxZoom}>
                <Recenter lat={circleCoordinates.lat === '' ? centerCoor[0] : circleCoordinates.lat} lng={circleCoordinates.lng === '' ? centerCoor[1] : circleCoordinates.lng} />
                <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={25} />
                <LayersControl position="bottomright" >
                    <LayersControl.Overlay name="Road" checked={true} key={0}>
                        <LayerGroup>
                            {
                                Object.keys(videoIndexMap).map((videoIndex) => (
                                    <Polyline
                                        key={videoIndex}
                                        positions={videoIndexMap[videoIndex]}
                                        color="gray"
                                        weight={5}
                                        stroke={1000}
                                    />
                                ))
                            }
                            {/* <Polyline positions={roadCoordinates} color="gray" weight={5} stroke={1000} /> */}
                        </LayerGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay name="Section markers" checked={true} key={1}>
                        <LayerGroup key={markerKey}>
                            {markerDetections.map((marker, index) => {
                                const featureCategories = marker.features ?
                                    Object.values(marker.features).filter(feature => feature.archive === '0').map(feature => feature.featureCategory) : [];
                                const isHighlighted = featureCategories.some(category => selectedCategories.includes(category));
                                return (
                                    <CircleMarker
                                        key={index}
                                        center={[marker.lat, marker.lng]}
                                        radius={isHighlighted ? 9 : 7}
                                        fillColor={isHighlighted ? 'darkorange' : '#FFFFFF'}
                                        color={isHighlighted ? 'darkorange' : '#025196'}
                                        fillOpacity={1}
                                        opacity={isHighlighted ? 0.5 : 1}
                                        stroke={true}
                                        fill={true}
                                        eventHandlers={{
                                            click: () => {
                                                // handleFrameClick(marker.diff / 1000, marker.sectionId)
                                                handleMapFrameChange(marker, index)
                                            }
                                        }}>
                                    </CircleMarker>
                                )
                            })}
                        </LayerGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay name="Current location" checked={true} key={2}>
                        <LayerGroup >
                            <CircleMarker
                                center={[circleCoordinates.lat, circleCoordinates.lng]}
                                radius={10}
                                fillColor='#FFFFFF'
                                color='black'
                                fillOpacity={1}
                                stroke={true}
                                fill={true}
                                opacity={1}
                            >
                                <LeafletTooltip>{formatTime(currentTime)}</LeafletTooltip>
                            </CircleMarker>
                        </LayerGroup>
                    </LayersControl.Overlay>
                </LayersControl>
            </MapContainer>
        </div>
    );
};

export default VisualizerMap;
