import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import CustomProgress from '../../../../../cidgets/CustomProgress';
import SmartUtils from '../../../../../../helpers/SmartUtils';
import Topbar from './Topbar';
import { Card, Grid, Pagination } from '@mui/material';
import ProjectDefectListView from '../ProjectDefectListView';
import IndividualSuperSectionMap from './IndividualSuperSectionMap';
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';
import SuperSectionSettings from './SuperSectionSettings';
import { superSectionSetting } from '../../../../../../store/actions/networkActions';
const SuperSectionController = (props) => {

  const { drNetworkData, superSections, allNetworkDefects, handleSSViewChange, showSSView } = props;
  const [superSectionList, setSuperSectionList] = useState([]);
  const [selectedSuperSection, setSelectedSuperSection] = useState('');
  const [selectedSSDefects, setSelectedSSDefects] = useState([]);
  const [mapDivDimen, setMapDivDimen] = useState({ height: 1, width: 1 });
  const [ssDefectPinned, setSSDefectPinned] = useState(null);
  const [openSSSettings, setOpenSSSettings] = useState(false);
  const [conditionMethod, setConditionMethod] = useState(1);
  const [conditionsList, setConditionsList] = useState([]);
  const headerRef = useRef();
  const mapRef = useRef();
  const headerHeight = headerRef?.current?.clientHeight ?? 0;
  const su = new SmartUtils();
  const overallCustomBorderPadding = 16;
  const cardWhiteStyle = {
    borderRadius: '15px',
  };
  // pagination states & functions
  const [page, setPage] = useState(0); // Starting from page 0
  const [limit] = useState(500); // Limit per page
  const totalPages = Math.ceil((allNetworkDefects?.length || 0) / limit);
  const startIndex = page * limit;
  const endIndex = startIndex + limit;
  const handleChange = (event, value) => {
    setPage(value - 1); // Adjust page numbering to start from 0
  };

  const sortedSelectedSSDefects = useMemo(() => {
    if (!selectedSSDefects) return [];
    return selectedSSDefects.slice(startIndex, endIndex).sort((a, b) => a.frameNumber - b.frameNumber);
  }, [selectedSSDefects, startIndex, endIndex]);
  useEffect(() => {
    if (drNetworkData && superSections) {
      if (drNetworkData.associatedSuperSections) {
        const superSectionList = superSections.filter((ss) =>
          drNetworkData.associatedSuperSections.includes(ss.id)
        );
        setSuperSectionList(superSectionList);
        setSelectedSuperSection(superSectionList[0]);
      }
    }
  }, [drNetworkData, superSections]);
  useEffect(() => {
    const processConditions = (drNetworkData, selectedSuperSection) => {
      const superSectionId = selectedSuperSection.superSectionId;
      const { condition, defaultCondition, weightedCondition, projectType } = drNetworkData;

      const output = [];

      if (projectType === 4) {
        if (defaultCondition && defaultCondition[superSectionId] !== null) {
          output.push({ key: 1, value: defaultCondition[superSectionId], name: 'Pavement Condition Index' });
        }

        if (weightedCondition && weightedCondition[superSectionId] !== null) {
          output.push({ key: 2, value: weightedCondition[superSectionId], name: 'Custom Condition Index' });
        }
      }
      else {
        if (condition && condition[superSectionId] !== null) {
          output.push({ key: 0, value: condition[superSectionId], name: 'Road Condition Index' });
        }

        if (defaultCondition && defaultCondition[superSectionId] !== null) {
          output.push({ key: 1, value: defaultCondition[superSectionId], name: 'Pavement Condition Index' });
        }

        if (weightedCondition && weightedCondition[superSectionId] !== null) {
          output.push({ key: 2, value: weightedCondition[superSectionId], name: 'Custom Condition Index' });
        }
      }

      return output;
    };

    const result = processConditions(drNetworkData, selectedSuperSection);
    setConditionsList(result);
  }, [drNetworkData, selectedSuperSection]);
  useEffect(() => {
    if (selectedSuperSection) {
      const defectsList = allNetworkDefects.filter((eachDefect) => eachDefect.superSectionId === selectedSuperSection.superSectionId);
      setSelectedSSDefects(defectsList)
    }
  }, [selectedSuperSection, allNetworkDefects]);


  const handleSuperSectionChange = (event) => {
    setSelectedSuperSection(event.target.value);
  };

  const handleOpenSSSettings = () => {
    setOpenSSSettings(true);
  }
  const handleCloseSSSettings = () => {
    setOpenSSSettings(false);
  }
  const handleConditionMethodChange = (event) => {
    const method = event.target.value;
    setConditionMethod(method);
    // props.superSectionSetting(method, drNetworkData.dbInstanceId);
  };
  const getVidWindowSize = () => {
    const newHeight = mapRef?.current?.clientHeight;
    const newWidth = mapRef?.current?.clientWidth;
    setMapDivDimen({
      height: newHeight,
      width: newWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('load', () =>
      setMapDivDimen(mapRef?.current === null ? 0 : mapRef?.current?.clientHeight)
    );
    window.addEventListener('resize', getVidWindowSize, true);
  }, []);

  return (
    <>
      <SimpleDialogBox
        open={openSSSettings}
        onClose={handleCloseSSSettings}
        title={"Supersection setting"}
        cancelText="Close"
        bodyWidget={
          <SuperSectionSettings
            selectedSuperSection={selectedSuperSection}
            handleSuperSectionChange={handleSuperSectionChange}
            superSectionList={superSectionList}
            conditionMethod={conditionMethod}
            handleConditionMethodChange={handleConditionMethodChange}
            drNetworkData={drNetworkData}
            conditionsList={conditionsList}
          />
        }
        width={900}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={6} >
          <div ref={headerRef} style={{ backgroundColor: '' }}>
            <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
              <Topbar handleOpenSSSettings={handleOpenSSSettings} selectedSuperSection={selectedSuperSection}
                handleSSViewChange={handleSSViewChange}
                allNetworkDefects={sortedSelectedSSDefects}
                handleSuperSectionChange={handleSuperSectionChange}
                superSectionList={superSectionList}
              />
            </div>
          </div>
          {headerHeight === 0 ? (
            <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CustomProgress text={"Loading map"} />
            </div>
          ) : (
            <div style={{ height: `calc(100vh - ${headerHeight}px)`, backgroundColor: '' }}>
              <div
                style={{
                  height: `${100}%`,
                  backgroundColor: '',
                  padding: su._createPadding(overallCustomBorderPadding, 'trbl'),
                }}
              >
                <div ref={mapRef} style={{ height: '100%', backgroundColor: '' }}>
                  <Card sx={{ ...cardWhiteStyle, height: '100%' }}>
                    <IndividualSuperSectionMap
                      setDefectPinned={setSSDefectPinned}
                      allNetworkDefects={sortedSelectedSSDefects}
                      drNetworkData={drNetworkData}
                      superSections={[selectedSuperSection]}
                      defectPinned={ssDefectPinned}
                      selectedSuperSection={selectedSuperSection}
                      conditionMethod={conditionMethod}
                      conditionsList={conditionsList}
                    />
                  </Card>
                </div>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
          <div style={{ padding: SmartUtils.edgeInsets(overallCustomBorderPadding, 'tbr'), height: `calc(100vh - ${overallCustomBorderPadding * 2}px )` }}>
            <Card sx={{ borderRadius: SmartUtils.edgeInsets(15, 'tblr'), height: `calc(100vh - ${overallCustomBorderPadding * 2}px )` }}>
              <div style={{ height: allNetworkDefects && allNetworkDefects.length > limit ? '90%' : '100%', overflow: 'auto' }}>
                <ProjectDefectListView
                  page={page}
                  limit={limit}
                  defectPinned={ssDefectPinned}
                  setDefectPinned={setSSDefectPinned}
                  allNetworkDefects={sortedSelectedSSDefects}
                  drNetworkData={drNetworkData}
                />
              </div>
              {selectedSSDefects && selectedSSDefects.length > limit && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: selectedSSDefects && selectedSSDefects.length > limit ? '10%' : '0%' }}>
                  <Pagination variant="outlined" shape="rounded" count={totalPages} page={page + 1} onChange={handleChange} />
                </div>
              )}
            </Card>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // drNetworkData: state.firestore.ordered.drNetworkData,
    // superSections: state.firestore.ordered.SuperSections,
    // allNetworkDefects: state.firestore.ordered.NetworkDefects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    superSectionSetting: (conditionMethod, networkId) => dispatch(superSectionSetting(conditionMethod, networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperSectionController)
