const initState = {
    isDoneAndSent: false,
    ActiveprojectsData: [],
    HaltedprojectsData: [],
    CompletedprojectsData: [],
    QueuedprojectsData: [],
    CrashedProjectsData: [],
    responseMsg: "",
    error: null,
    crashedError: "",
    DownSamplingProjectsData: [],
    controlRoomData: [],
    isControlRoomDataDownload: false,
    isControlRoomDataDownloading: false,
    isLoading: true,
};

const controlroomReducer = (state = initState, action) => {
    if (action.type === 'FETCH_ACTIVE_PROJECTS_START') {
        return {
            ...state,
            isDoneAndSent: false,
            isLoading: true
        };
    } else if (action.type === 'FETCH_ACTIVE_PROJECTS_SUCCESS') {
        return {
            ...state,
            isDoneAndSent: action.isDoneAndSent,
            ActiveprojectsData: action.ActiveprojectsData,
            responseMsg: action.responseMsg,
            isLoading: false
        };
    } else if (action.type === 'FETCH_ACTIVE_PROJECTS_FAILURE') {
        return {
            ...state,
            isDoneAndSent: action.isDoneAndSent,
            responseMsg: action.responseMsg,
            isLoading: false,
            error: action.error
        };
    }
    else if (action.type === 'FETCH_HALTED_PROJECTS_START') {
        return {
            ...state,
            isDoneAndSent: false,
            isLoading: true
        };
    } else if (action.type === 'FETCH_HALTED_PROJECTS_SUCCESS') {
        return {
            ...state,
            isDoneAndSent: true,
            HaltedprojectsData: action.HaltedprojectsData,
            isLoading: false,
            responseMsg: action.responseMsg
        };
    } else if (action.type === 'FETCH_HALTED_PROJECTS_FAILURE') {
        return {
            ...state,
            isDoneAndSent: false,
            responseMsg: action.responseMsg,
            isLoading: false,
            error: action.error
        };
    }
    else if (action.type === 'FETCH_COMPLETED_PROJECTS_START') {
        return {
            ...state,
            isDoneAndSent: false,
            isLoading: true
        };
    } else if (action.type === 'FETCH_COMPLETED_PROJECTS_SUCCESS') {
        return {
            ...state,
            isDoneAndSent: true,
            CompletedprojectsData: action.CompletedprojectsData,
            isLoading: false,
            responseMsg: action.responseMsg
        };
    } else if (action.type === 'FETCH_COMPLETED_PROJECTS_FAILURE') {
        return {
            ...state,
            isDoneAndSent: false,
            responseMsg: action.responseMsg,
            isLoading: false,
            error: action.error
        };
    }
    else if (action.type === 'FETCH_QUEUED_PROJECTS_START') {
        return {
            ...state,
            isDoneAndSent: false,
            isLoading: true
        };
    } else if (action.type === 'FETCH_QUEUED_PROJECTS_SUCCESS') {
        return {
            ...state,
            isDoneAndSent: true,
            QueuedprojectsData: action.QueuedprojectsData,
            isLoading: false,
            responseMsg: action.responseMsg
        };
    } else if (action.type === 'FETCH_QUEUED_PROJECTS_FAILURE') {
        return {
            ...state,
            isDoneAndSent: false,
            responseMsg: action.responseMsg,
            isLoading: false,
            error: action.error
        };
    }
    else if (action.type === 'FETCH_CRASHED_PROJECTS_START') {
        return {
            ...state,
            isDoneAndSent: false,
            isLoading: true
        };
    } else if (action.type === 'FETCH_CRASHED_PROJECTS_SUCCESS') {
        return {
            ...state,
            isDoneAndSent: true,
            CrashedProjectsData: action.CrashedProjectsData,
            crashedError: action.CrashedProjectsData.message,
            isLoading: false,
            responseMsg: action.responseMsg
        };
    } else if (action.type === 'FETCH_CRASHED_PROJECTS_FAILURE') {
        return {
            ...state,
            isDoneAndSent: false,
            responseMsg: action.responseMsg,
            isLoading: false,
            error: action.error
        };
    }
    else if (action.type === "SET_CONTROL_ROOM_DATA") {
        return {
            ...state,
            controlRoomData: action.controlRoomData,
            isControlRoomDataDownload: true,
            isControlRoomDataDownloading: false,
        }
    }
    else if (action.type === "START_DOWNLOADING_CONTROL_ROOM_DATA") {
        return {
            ...state,
            isControlRoomDownloading: true,
        }
    }

    else if (action.type === 'FETCH_DOWNSAMPLING_PROJECTS_START') {
        return {
            ...state,
            isDoneAndSent: false,
            isLoading: true
        };
    } else if (action.type === 'FETCH_DOWNSAMPLING_PROJECTS_SUCCESS') {
        return {
            ...state,
            isDoneAndSent: true,
            DownSamplingProjectsData: action.DownSamplingProjectsData,
            isLoading: false,
            responseMsg: action.responseMsg
        };
    } else if (action.type === 'FETCH_DOWNSAMPLING_PROJECTS_FAILURE') {
        return {
            ...state,
            isDoneAndSent: false,
            responseMsg: action.responseMsg,
            isLoading: false,
            error: action.error
        };
    }
    return state;

}
export default controlroomReducer;
