import AddNewCardForm from "../subpages/addNewCardComponents/AddNewCardForm"
import {
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import React, { useEffect } from "react";
import {
    attachPaymentMethodToCustomer,
    openStripePaymentMethodModal,
} from "../../../../../store/actions/paymentActions"
import { connect } from 'react-redux';
import { Button, CircularProgress } from "@mui/material";
import CustomProgress from '../../../../cidgets/CustomProgress'
const AddNewCard = (props) => {
    const { companyData, payment, userData, attachPaymentMethodToCustomer, buttonText = "Add this card" } = props;
    // //////console.log("company data", companyData);
    const { coid } = companyData;
    const { stripeSetupIntent, paymentMessage, stripeSetupIntentLoader } = payment;
    useEffect(() => {
        props.openStripePaymentMethodModal(coid);
    }, []);
    let relevantComponentBody = <React.Fragment />;
    if (stripeSetupIntent === "") {
        relevantComponentBody = (
            <div >
                <CustomProgress text={"It might take a few seconds for the form to load below."} />
            </div>
        );
    } else if (stripeSetupIntent === "-1") {
        relevantComponentBody = <>
            <p>{paymentMessage}</p>
            {stripeSetupIntentLoader ?
                <>
                    <Button variant="contained" disabled sx={{
                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                        "&.Mui-disabled": {
                            backgroundColor: '#025196',
                            color: '#ffffff'
                        }
                    }}>
                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Initiating...</span>
                    </Button>
                </>
                :
                < Button variant="contained" sx={{
                    marginTop: '0px',
                    textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#025196',
                        color: '#ffffff'
                    }
                }}
                    onClick={() => { props.openStripePaymentMethodModal(coid) }}
                >Run again</Button>
            }

        </>;
    } else {
        relevantComponentBody = (
            <ElementsConsumer>
                {({ elements, stripe }) => (
                    <AddNewCardForm
                        elements={elements}
                        stripe={stripe}
                        attachPaymentMethodToCustomer={attachPaymentMethodToCustomer}
                        companyData={companyData}
                        userData={userData}
                        payment={payment}
                        buttonText={buttonText}
                    />
                )}
            </ElementsConsumer>
        );
    }

    return (
        <div>
            {relevantComponentBody}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userData: state.firebase.profile,
        companyData: state.firestore.data.Company,
        payment: state.payment,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        openStripePaymentMethodModal: (coid) =>
            dispatch(openStripePaymentMethodModal(coid)),
        attachPaymentMethodToCustomer: (coid, paymentMethodId) =>
            dispatch(attachPaymentMethodToCustomer(coid, paymentMethodId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCard);