import { act } from "react-dom/test-utils"

const initState = {
    /// README: IMPORTANT (do not remove without authority)
    // proid: undefined,

    videoProgress: null,
    videoProgressV2: {},
    isVideoUploading: false,

    uploadVideoStatus: {},
    uploadVideoStatusAction: {},

    videoUploadingErrorMessage: "",

    gpsProgress: null,
    isGpsUploading: false,
    gpsUploadingErrorMessage: "",

    uploadGpsStatus: {},

    uploadInferenceStatus: {},

    assetArchiveMessage: '',
    assetArchiveIsDoneAndSent: false,
    showAssetArchiveLoader: false,

    featureArchiveMessage: '',
    featureArchiveIsDoneAndSent: null,
    showFeatureArchiveLoader: false,

    basicFeatureArchiveMessage: "",
    showBasicFeatureArchiveLoader: false,
    basicFeatureArchiveIsDoneAndSent: null,

    basicFeatureUnArchiveMessage: "",
    showBasicFeatureUnArchiveLoader: false,
    basicFeatureUnArchiveIsDoneAndSent: null,

    basicConfirmValidityProjectMessage: "",
    basicConfirmValidityProjectIsDoneAndSent: null,
    showBasicConfirmValidityProjectLoader: false,

    basicValidConfirmMessage: "",
    basicValidConfirmIsDoneAndSent: null,
    showBasicValidConfirmLoader: false,

    confirmDeltaMessage: '',
    showConfirmDeltaLoader: false,
    confirmDeltaIsDoneAndSent: null,

    updateDefectMessage: "",
    showUpdateDefectLoader: false,
    updateDefectDoneAndSent: null,

    addDefectMessage: "",
    showAddDefectLoader: false,
    addDefectDoneAndSent: null,

    downSamplingMessage: '',
    showDownSamplingLoader: false,
    downSamplingIsDoneAndSent: null,
    downSamplingData: null,

    distressArchiveMessage: '',
    distressArchiveIsDoneAndSent: false,
    showDistressArchiveLoader: false,
    isArchiveModalClose: null,
    isModalClose: null,

    genReportMessage: "",
    showGenReportLoader: false,
    genReportIsDoneAndSent: false,

    genReportDistressMessage: '',
    showGenReportDistressLoader: false,
    genReportDistressIsDoneAndSent: false,

    resetAssetArchiveMessage: '',
    resetAssetArchiveIsDoneAndSent: false,
    showResetAssetArchiveLoader: false,

    updateAssetArchiveMessage: '',
    updateAssetArchiveIsDoneAndSent: false,
    showUpdateAssetArchiveLoader: false,

    updateDistressArchiveMessage: '',
    updateDistressArchiveIsDoneAndSent: false,
    showUpdateDistressArchiveLoader: false,
    isEditModalClose: null,

    addDistressArchiveMessage: '',
    showAddDistressArchiveLoader: false,
    addDistressArchiveIsDoneAndSent: false,
    isAddModalClose: null,


    addFeatureArchiveMessage: '',
    showAddFeatureArchiveLoader: false,
    addFeatureArchiveIsDoneAndSent: false,
    isAddFeatureModalClose: null,

    editFeatureArchiveMessage: '',
    showEditFeatureArchiveLoader: false,
    editFeatureArchiveIsDoneAndSent: false,
    isEditFeatureModalClose: null,

    archiveFeatureArchiveMessage: '',
    showArchiveFeatureArchiveLoader: false,
    archiveFeatureArchiveIsDoneAndSent: false,
    isArchiveFeatureModalClose: null,

    unarchiveFeatureArchiveMessage: '',
    showUnarchiveFeatureArchiveLoader: false,
    unarchiveFeatureArchiveIsDoneAndSent: false,
    isUnarchiveFeatureModalClose: null,

    addCategoryArchiveMessage: '',
    showAddCategoryArchiveLoader: false,
    addCategoryArchiveIsDoneAndSent: false,
    isAddCategoryModalClose: null,

    videoEstimationMessage: '',
    videoEstimationIsDoneAndSent: null,
    videoEstimationLoader: null,

    routeCalculationMessage: '',
    routeCalculationIsDoneAndSent: null,
    routeCalculationLoader: null,
    routeCalculationData: null,

    mirrorProjectMessage: null,
    mirrorProjectLoader: null,
    mirrorProjectIsDoneAndSent: null,

    saveRouteCalculationToDbMessage: '',
    saveRouteCalculationToDbIsDoneAndSent: null,
    saveRouteCalculationToDbLoader: null,

    convertedCoordinatesData: null,
    convertEncodedGeometryMessage: "",
    convertEncodedGeometryLoader: null,
    convertEncodedGeometryIsDoneAndSent: null,


    inferenceMessage: '',
    infernenceIsDoneAndSent: null,
    inferenceLoader: null,
    inferenceid: null,

    haltMessage: "",
    haltLoader: null,
    haltIsDoneAndSent: null,

    confirmMapMessage: '',
    confirmMapIsDoneAndSent: null,
    confirmMapLoader: null,

    cancelMapMessage: '',
    cancelMapIsDoneAndSent: null,
    cancelMapLoader: null,

    selectVideoMessage: '',
    selectVideoLoader: null,
    selectVideoIsDoneAndSent: null,

    wholeSelectVideoMessage: "",
    wholeSelectVideoIsDoneAndSent: null,
    wholeSelectVideoLoader: null,

    addNewAssetMessage: '',
    showAddNewAssetLoader: null,
    addNewAssetIsDoneAndSent: null,

    individualDetectionMessage: '',
    showindividualDetectionLoader: null,
    individualDetectionIsDoneAndSent: null,
    individualDetectionData: null,

    depthCrossSectionalProfileMessage: "",
    depthCrossSectionalProfileLoader: null,
    depthCrossSectionalProfileIsDoneAndSent: null,
    depthCrossSectionalData: null,

    saveDepthCrossSectionalPointsMessage: "",
    saveDepthCrossSectionalPointsLoader: null,
    saveDepthCrossSectionalPointsIsDoneAndSent: null,

    uploadEditGpsLoader: null,
    uploadEditGpsIsDoneAndSent: null,

    /// README: NEW PROJECT PAGE
    isProjectCreating: false,
    isProjectCreated: false,


    /// README: PROJECT DELETE
    isProjectDeleting: null,
    isProjectDeleted: false,

    /// README: PROJECT UPDATE
    isProjectUpdating: null,
    isProjectUpdated: null,


    // Add new project by using model
    isProjectModelOpen: false,

    // project pictures uploading mechanism
    isUploading: false,
    isUploaded: false,
    projectMessage: null,
    failedUploads: [],
    pictureIndexStatus: null,
    showInferenceButton: false,

    /// README: PROJECT API CALL
    papiMessage: "",
    papiIsDoneAndSent: false,
    showPapiLoader: false,
    papid: -1,
    showPapiModal: false,

    // SURFACE API CALL
    papiSurfaceMessage: "",
    papiSurfaceIsDoneAndSent: false,
    showPapiSurfaceLoader: false,
    papidSurface: -1,


    // GPS file
    isVideoProcessEnd: false,
    isModalOpen: true,
    isGpsModalOpen: true,
    // isGpsUploading: false,
    gpsMessage: "",
    isGpsMapShow: false,
    // currentImageGpsCoordinate: [],

    // infer
    isFirstInferenceRun: false,

    deleteRouteLoader: null,

    assignedFeatureMessage: "",
    assignedFeatureIsDoneAndSent: null,
    assignedFeatureLoader: null,

    approveRejectMessage: "",
    approveRejectIsDoneAndSent: null,
    approveRejectLoader: null,

    archiveFeatureMessage: "",
    archiveFeatureIsDoneAndSent: null,
    archiveFeatureLoader: null,

    pciCalcMessage: "",
    pciCalcIsDoneAndSent: null,
    pciCalcLoader: null,

}

const ProjectReducer = (state = initState, action) => {
    // //////console.log("Project_reducer", action)
    /// README: NEW PROJECT CREATION
    // if (action.type === "SET_CURRENT_PROJECT_ID") {
    //     return {
    //         ...state,
    //         proid: action.proid,
    //     }
    // }
    // //////console.log(action.type, "action")
    if (action.type === "OPEN_NEW_PROJECT_PAGE") {
        return {
            ...state,
            isProjectCreating: false,
            isProjectCreated: false,
        }
    }
    else if (action.type === "DELETE_ROUTE_START" || action.type === "DELETE_ROUTE_COMPLETE") {
        return {
            ...state,
            deleteRouteLoader: action.deleteRouteLoader,
        }
    }
    else if (action.type === "ADD_NEW_PROJECT") {
        return {
            ...state,
            isProjectCreating: true,
        }
    }
    else if (action.type === "ADD_NEW_PROJECT") {
        return {
            ...state,
            isProjectCreating: true,
        }
    }
    else if (action.type === "NEW_PROJECT_ADDED") {
        return {
            ...state,
            isProjectCreating: false,
            isProjectCreated: true,
            proid: action.proid

        }
    }
    else if (action.type === "OPEN_NEW_PROJECT_MODAL") {
        // //////console.log("action")
        return {
            ...state,
            isProjectCreating: false,
            isProjectCreated: false,
        }
    }

    // project pictures uploading mechanism
    else if (action.type === "RESET_PROJECT") {
        //////console.log(action)
        return {
            ...state,
            isUploading: false,
            isUploaded: false,
            projectMessage: null,
            failedUploads: [],
            pictureIndexStatus: null,
            showInferenceButton: false,

            papiMessage: "",
            papiIsDoneAndSent: false,
            showPapiLoader: false,
            papid: -1,
        }
    }
    else if (action.type === "PROJECT_GPS_UPLOADING") {
        //////console.log(action)
        return {
            ...state,
            projectMessage: "GPS uploading, do not leave your browser",
        }
    }
    else if (action.type === "PROJECT_GPS_UPLOADED") {
        //////console.log(action)
        return {
            ...state,
            projectMessage: "GPS uploaded! Initiating picture upload...",
        }
    }

    else if (action.type === "PROJECT_PICTURE_GPS_UPLOADING") {
        return {
            ...state,
            gpsMessage: "GPS uploading, do not leave your browser",
            isGpsUploading: true,
            isGpsModalOpen: false,
        }
    }
    else if (action.type === "PROJECT_PICTURE_GPS_UPLOADED") {
        return {
            ...state,
            gpsMessage: "GPS uploaded!",
            isGpsModalOpen: true,
            isGpsUploading: false,
        }
    }
    else if (action.type === "RESET_GPS_UPLOAD_PARAMS") {
        return {
            ...state,
            isGpsUploading: false,
            isGpsModalOpen: false,
            gpsMessage: "",
        }
    }
    // VIDEO
    else if (action.type === "PROJECT_PICTURE_UPLOADING") {
        //////console.log(action)
        return {
            ...state,
            isUploading: true,
            isUploaded: false,
            isModalOpen: false,
            projectMessage: "Picture upload process has started, do not leave your browser",
            failedUploads: [],
            pictureIndexStatus: 0,
            showInferenceButton: false,
        }
    }
    else if (action.type === "PROJECT_PICTURE_UPLOADING_STATUS") {
        //////console.log(action)
        return {
            ...state,
            pictureIndexStatus: action.pictureIndexStatus,
            projectMessage: "Pictures are uploading, do not refresh browser",
            failedUploads: [],
            showInferenceButton: false,
        }
    }
    else if (action.type === "PROJECT_PICTURE_UPLOADED") {
        //////console.log(action)
        return {
            ...state,
            isUploading: false,
            isUploaded: true,
            isModalOpen: true,
            projectMessage: "Pictures are uploaded",
            failedUploads: action.failedUploads,
            showInferenceButton: action.showInferenceButton,
            isVideoProcessEnd: true,
        }
    } else if (action.type === "RESET_VIDEO_UPLOAD_PARAMS") {
        return {
            ...state,
            isUploading: false,
            isUploaded: false,
            isModalOpen: false,
            projectMessage: "",
            failedUploads: [],
            showInferenceButton: false,
            isVideoProcessEnd: false,
        }
    }

    // else if (action.type === "SET_CURRENT_GPS_COORDINATE") {
    //     return {
    //         ...state,
    //         currentImageGpsCoordinate: action.currentImageGpsCoordinate,
    //     }
    // }

    /// README: PROJECT API CALLS
    else if (action.type === "PAPI_CALL_INIT") {
        //////console.log(action)
        return {
            ...state,
            papiMessage: action.papiMessage,
            showPapiLoader: action.showPapiLoader,
            papid: action.papid,
            papiIsDoneAndSent: action.papiIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "PAPI_CALL_COMPLETED" || action.type === "PAPI_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            papiMessage: action.papiMessage,
            showPapiLoader: action.showPapiLoader,
            papid: action.papid,
            papiIsDoneAndSent: action.papiIsDoneAndSent ?? false,
        }
    }

    // SURFACE ANALYSIS API CALLS
    else if (action.type === "PAPI_SURFACE_CALL_INIT") {
        //////console.log(action)
        return {
            ...state,
            papiSurfaceMessage: action.papiSurfaceMessage,
            showPapiSurfaceLoader: action.showPapiSurfaceLoader,
            papidSurface: action.papidSurface,
            papiSurfaceIsDoneAndSent: action.papiSurfaceIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "PAPI_SURFACE_CALL_COMPLETED" || action.type === "PAPI_SURFACE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            papiSurfaceMessage: action.papiSurfaceMessage,
            showPapiSurfaceLoader: action.showPapiSurfaceLoader,
            papidSurface: action.papidSurface,
            papiSurfaceIsDoneAndSent: action.papiSurfaceIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLOSE_PAPI_MESSAGE_MODAL") {
        //////console.log(action)
        return {
            ...state,
            showPapiModal: false
        }
    }
    else if (action.type === "ADD_NEW_PROJECT") {
        return {
            ...state,
            isProjectModelOpen: true
        }
    }
    else if (action.type === "START_DELETE_PROJECT") {
        return {
            ...state,
            isProjectDeleting: true
        }
    }
    else if (action.type === "PROJECT_DELETED") {
        return {
            ...state,
            isProjectDeleted: true,
            isProjectDeleting: false
        }
    }
    else if (action.type === "START_UPDATE_PROJECT") {
        return {
            ...state,
            isProjectUpdating: true,
            isProjectUpdated: false
        }
    }
    else if (action.type === "CLEAR_UPDATE_PROJECT_STATE") {
        return {
            ...state,
            isProjectUpdating: null,
            isProjectUpdated: null
        }
    }
    else if (action.type === "PROJECT_UPDATED") {
        return {
            ...state,
            isProjectUpdated: true,
            isProjectUpdating: false
        }
    }
    // else if (action.type === "PROJECT_VIDEO_UPLOADING") {
    //     return {
    //         ...state,
    //         // isVideoUploading: true
    //         videoProgressV2: {
    //             ...state.videoProgressV2,
    //             [`isVideoUploading_${action.proid}`]: true,

    //         }
    //     }
    // }
    else if (action.type === "PROJECT_VIDEO_UPLOAD_PROGRESS") {
        return {
            ...state,
            uploadVideoStatus: {
                ...state.uploadVideoStatus,
                [action.proid]: {
                    'estimatedTimeRemaining': action.estimatedTimeRemaining,
                    'progress': action.progress,
                    'isVideoUploading': action.progress > 0 && action.progress < 100,
                    'errorMessage': action.errorMessage
                }

            }
        }
    }
    else if (action.type === "PROJECT_360_VIDEO_UPLOAD_PROGRESS") {

        return {
            ...state,
            uploadVideoStatus: {
                ...state.uploadVideoStatus,
                [action.proid]: {
                    ...state.uploadVideoStatus[action.proid],
                    ['individual' + action.videoFileName]: action.videoFileName,
                    [action.videoFileName]: {
                        'estimatedTimeRemaining': action.estimatedTimeRemaining,
                        'progress': action.progress,
                        'isVideoUploading': action.progress > 0 && action.progress < 100,
                        'errorMessage': action.errorMessage
                    }
                }

            }
        }
    }
    else if (action.type === "CANCEL_VIDEO_UPLOAD") {
        //////console.log("action type", action.type, action)
        return {
            ...state,
            uploadVideoStatusAction: {
                ...state.uploadVideoStatusAction,
                [action.proid]: {
                    [action.videoFileName]: {
                        "isCancel": true,
                    }
                }

            }
        }
    }
    // else if (action.type === "PROJECT_VIDEO_UPLOADED_ERROR") {
    //     return {
    //         ...state,
    //         // isVideoUploading: true,
    //         // videoUploadingErrorMessage: action.error,
    //         videoProgressV2: {
    //             ...state.videoProgressV2,
    //             [`isVideoUploading_${action.proid}`]: true,
    //             [`videoUploadingErrorMessage${action.proid}`]: action.error,
    //         }

    //     }
    // }
    // else if (action.type === "PROJECT_VIDEO_UPLOADED") {
    //     return {
    //         ...state,
    //         // isVideoUploading: false
    //         videoProgressV2: {
    //             ...state.videoProgressV2,
    //             [`isVideoUploading_${action.proid}`]: false,
    //         }

    //     }
    // }
    else if (action.type === "CLEAR_UPLOAD_ERROR_MSG") {
        return {
            ...state,
            videoProgressV2: {
                ...state.videoProgressV2,
                [`isVideoUploading_${action.proid}`]: false,
            }
            // isVideoUploading: false,
            // videoProgress: ""
        }
    }
    // else if (action.type === "PROJECT_GPS_UPLOADING") {
    //     return {
    //         ...state,
    //         isGpsUploading: true
    //     }
    // }
    else if (action.type === "PROJECT_GPS_UPLOAD_PROGRESS") {
        return {
            ...state,
            uploadGpsStatus: {
                ...state.uploadGpsStatus,
                [action.proid]: {
                    'gpsProgress': action.progress,
                    'isGpsUploading': action.progress > 0 && action.progress < 100,
                    'gpsErrorMessage': action.errorMessage,
                    'gpsApiCalling': action.gpsApiCalling,
                    'gpsApiMessage': action.gpsApiMessage,
                    'isLoading': action.isLoading
                }

            }
        }
    }
    else if (action.type === "CLEAR_GPS_ERROR_MSG") {
        return {
            ...state,
            isGpsUploading: false,
            gpsProgress: ""
        }
    }

    else if (action.type === "ADD_NEW_ASSET" || action.type === "ADD_NEW_ASSET_CALL_COMPLETED" || action.type === "ADD_NEW_ASSET_CALL_ERROR") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            addNewAssetMessage: action.addNewAssetMessage,
            showAddNewAssetLoader: action.showAddNewAssetLoader,
            addNewAssetIsDoneAndSent: action.addNewAssetIsDoneAndSent ?? false,
        }
    }

    else if (action.type === "ADD_FEATURE" || action.type === "ADD_FEATURE_CALL_COMPLETED" || action.type === "ADD_FEATURE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            addFeatureArchiveMessage: action.addFeatureArchiveMessage,
            showAddFeatureArchiveLoader: action.showAddFeatureArchiveLoader,
            addFeatureArchiveIsDoneAndSent: action.addFeatureArchiveIsDoneAndSent ?? false,
            isAddFeatureModalClose: action.isAddFeatureModalClose
        }
    }

    else if (action.type === "EDIT_FEATURE" || action.type === "EDIT_FEATURE_CALL_COMPLETED" || action.type === "EDIT_FEATURE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            editFeatureArchiveMessage: action.editFeatureArchiveMessage,
            showEditFeatureArchiveLoader: action.showEditFeatureArchiveLoader,
            editFeatureArchiveIsDoneAndSent: action.editFeatureArchiveIsDoneAndSent ?? false,
            isEditFeatureModalClose: action.isEditFeatureModalClose
        }
    }

    else if (action.type === "ARCHIVE_FEATURE" || action.type === "ARCHIVE_FEATURE_CALL_COMPLETED" || action.type === "ARCHIVE_FEATURE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            archiveFeatureArchiveMessage: action.archiveFeatureArchiveMessage,
            showArchiveFeatureArchiveLoader: action.showArchiveFeatureArchiveLoader,
            archiveFeatureArchiveIsDoneAndSent: action.archiveFeatureArchiveIsDoneAndSent ?? false,
            isArchiveFeatureModalClose: action.isArchiveFeatureModalClose
        }
    }

    else if (action.type === "UNARCHIVE_FEATURE" || action.type === "UNARCHIVE_FEATURE_CALL_COMPLETED" || action.type === "UNARCHIVE_FEATURE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            unarchiveFeatureArchiveMessage: action.unarchiveFeatureArchiveMessage,
            showUnarchiveFeatureArchiveLoader: action.showUnarchiveFeatureArchiveLoader,
            unarchiveFeatureArchiveIsDoneAndSent: action.unarchiveFeatureArchiveIsDoneAndSent ?? false,
            isUnarchiveFeatureModalClose: action.isUnarchiveFeatureModalClose
        }
    }

    else if (action.type === "ADD_CATEGORY" || action.type === "ADD_CATEGORY_CALL_COMPLETED" || action.type === "ADD_CATEGORY_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            addCategoryArchiveMessage: action.addCategoryArchiveMessage,
            showAddCategoryArchiveLoader: action.showAddCategoryArchiveLoader,
            addCategoryArchiveIsDoneAndSent: action.addCategoryArchiveIsDoneAndSent ?? false,
            isAddCategoryModalClose: action.isAddCategoryModalClose
        }
    }

    else if (action.type === "INFERENCE_CALL_INIT" || action.type === "INFERENCE_CALL_COMPLETED" || action.type === "INFERENCE_CALL_ERROR") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            inferenceMessage: action.inferenceMessage,
            inferenceLoader: action.inferenceLoader,
            infernenceIsDoneAndSent: action.infernenceIsDoneAndSent ?? null,
            inferenceid: action.inferenceid
        }
    }
    else if (action.type === "HALT_PROCESSING_CALL_INIT" || action.type === "HALT_PROCESSING_CALL_COMPLETED" || action.type === "HALT_PROCESSING_CALL_ERROR") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            haltMessage: action.haltMessage,
            haltLoader: action.haltLoader,
            haltIsDoneAndSent: action.haltIsDoneAndSent ?? null
        }
    }

    else if (action.type === "VISUALIZER_INFERENCE_CALL_INIT" || action.type === "VISUALIZER_INFERENCE_CALL_COMPLETED" || action.type === "VISUALIZER_INFERENCE_CALL_ERROR") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            inferenceMessage: action.inferenceMessage,
            inferenceLoader: action.inferenceLoader,
            infernenceIsDoneAndSent: action.infernenceIsDoneAndSent ?? null,
            inferenceid: action.inferenceid
        }
    }

    else if (action.type === "CONFIRM_MAP_CALL_INIT" || action.type === "CONFIRM_MAP_CALL_COMPLETE") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            confirmMapMessage: action.confirmMapMessage,
            confirmMapLoader: action.confirmMapLoader,
            confirmMapIsDoneAndSent: action.confirmMapIsDoneAndSent ?? false,
        }
    }

    else if (action.type === "SELECT_VIDEO_CALL_INIT" || action.type === "SELECT_VIDEO_COMPLETE" || action.type === "SELECT_VIDEO_ERROR") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            selectVideoMessage: action.selectVideoMessage,
            selectVideoLoader: action.selectVideoLoader,
            selectVideoIsDoneAndSent: action.selectVideoIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "WHOLE_SELECT_VIDEO_CALL_INIT" || action.type === "WHOLE_SELECT_VIDEO_COMPLETE" || action.type === "WHOLE_SELECT_VIDEO_ERROR") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            wholeSelectVideoMessage: action.wholeSelectVideoMessage,
            wholeSelectVideoIsDoneAndSent: action.wholeSelectVideoIsDoneAndSent ?? false,
            wholeSelectVideoLoader: action.wholeSelectVideoLoader,

        }
    }
    else if (action.type === "UPLOAD_EDIT_GPS_START" || action.type === "UPLOAD_EDIT_GPS_COMPLETED" || action.type === "UPLOAD_EDIT_GPS_END") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            uploadEditGpsLoader: action.uploadEditGpsLoader,
            uploadEditGpsIsDoneAndSent: action.uploadEditGpsIsDoneAndSent,
        }
    }
    else if (action.type === "REMOVE_UPLOAD_EDIT_GPS_ERROR_MSG") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            uploadEditGpsLoader: null,
            uploadEditGpsIsDoneAndSent: null,
        }
    }
    else if (action.type === "CLEAR_VIDEO_SELECTION_ERROR_MSG") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            selectVideoMessage: null,
            selectVideoLoader: null,
            selectVideoIsDoneAndSent: null,
        }
    }
    else if (action.type === "CLEAR__WHOLE_VIDEO_SELECTION_ERROR_MSG") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            wholeSelectVideoMessage: null,
            wholeSelectVideoIsDoneAndSent: null,
            wholeSelectVideoLoader: null,
        }
    }

    else if (action.type === "CANCEL_MAP_CALL_INIT" || action.type === "CANCEL_MAP_CALL_COMPLETE") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            cancelMapMessage: action.cancelMapMessage,
            cancelMapLoader: action.cancelMapLoader,
            cancelMapIsDoneAndSent: action.cancelMapIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_VIDEO_CALL_INIT" || action.type === "CLEAR_VIDEO_CALL_COMPLETE") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            clearVideoMessage: action.clearVideoMessage,
            clearVideoLoader: action.clearVideoLoader,
            clearVideoIsDoneAndSent: action.clearVideoIsDoneAndSent,
        }
    }

    else if (action.type === "CLEAR_INFERENCE_ERROR_MSG") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            inferenceMessage: '',
            inferenceLoader: null,
            infernenceIsDoneAndSent: null,
            inferenceid: null
        }
    }
    else if (action.type === "CLEAR_HALT_ERROR_MSG") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            haltMessage: "",
            haltLoader: null,
            haltIsDoneAndSent: null
        }
    }
    else if (action.type === "INDIVIDUAL_DETECTION" || action.type === "INDIVIDUAL_DETECTION_CALL_COMPLETED" || action.type === "INDIVIDUAL_DETECTION_CALL_ERROR") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            individualDetectionMessage: action.individualDetectionMessage,
            showindividualDetectionLoader: action.showindividualDetectionLoader,
            individualDetectionIsDoneAndSent: action.individualDetectionIsDoneAndSent ?? false,
            individualDetectionData: action.individualDetectionData,
        }
    }
    else if (action.type === "CLEAR_INDIVIDUAL_DETECTION_ERROR_MSG") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            individualDetectionMessage: '',
            showindividualDetectionLoader: null,
            individualDetectionIsDoneAndSent: null,
            individualDetectionData: null
        }
    }
    else if (action.type === "CLEAR_ADD_NEW_ASSET_ERROR_MSG") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            addNewAssetMessage: '',
            showAddNewAssetLoader: null,
            addNewAssetIsDoneAndSent: null,
        }
    }

    else if (action.type === "GENERATING_REPORT" || action.type === "GENERATED_REPORT" || action.type === "GENERATED_REPORT_ERROR") {
        //////console.log(action)
        return {
            ...state,
            genReportMessage: action.genReportMessage,
            showGenReportLoader: action.showGenReportLoader,
            genReportIsDoneAndSent: action.genReportIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "VIDEO_ESTIMATION_COST_REQUEST" || action.type === "VIDEO_ESTIMATION_COST_COMPLETED" || action.type === "VIDEO_ESTIMATION_COST_ERROR") {
        //////console.log(action)
        return {
            ...state,
            videoEstimationMessage: action.videoEstimationMessage,
            videoEstimationLoader: action.videoEstimationLoader,
            videoEstimationIsDoneAndSent: action.videoEstimationIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_VIDEO_ESTIMATION_ERROR_MSG") {
        //////console.log(action)
        return {
            ...state,
            videoEstimationMessage: '',
            videoEstimationLoader: null,
            videoEstimationIsDoneAndSent: null,
        }
    }
    else if (action.type === "GENERATING_REPORT_DISTRESS" || action.type === "GENERATED_REPORT_DISTRESS" || action.type === "GENERATED_REPORT_DISTRESS_ERROR") {
        //////console.log(action)
        return {
            ...state,
            genReportDistressMessage: action.genReportDistressMessage,
            showGenReportDistressLoader: action.showGenReportDistressLoader,
            genReportDistressIsDoneAndSent: action.genReportDistressIsDoneAndSent ?? false,
            isModalClose: action.isModalClose
        }
    }

    else if (action.type === "ASSET_ARCHIVE" || action.type === "ASSET_ARCHIVE_CALL_COMPLETED" || action.type === "ASSET_ARCHIVE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            assetArchiveMessage: action.assetArchiveMessage,
            showAssetArchiveLoader: action.showAssetArchiveLoader,
            assetArchiveIsDoneAndSent: action.assetArchiveIsDoneAndSent ?? false,
        }
    }

    else if (action.type === "FEATURE_ARCHIVE" || action.type === "FEATURE_ARCHIVE_CALL_COMPLETED" || action.type === "FEATURE_ARCHIVE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            featureArchiveMessage: action.featureArchiveMessage,
            showFeatureArchiveLoader: action.showFeatureArchiveLoader,
            featureArchiveIsDoneAndSent: action.featureArchiveIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "BASIC_FEATURE_ARCHIVE" || action.type === "BASIC_FEATURE_ARCHIVE_CALL_COMPLETED"
        || action.type === "BASIC_FEATURE_ARCHIVE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            basicFeatureArchiveMessage: action.basicFeatureArchiveMessage,
            showBasicFeatureArchiveLoader: action.showBasicFeatureArchiveLoader,
            basicFeatureArchiveIsDoneAndSent: action.basicFeatureArchiveIsDoneAndSent ?? false,
        }
    }

    else if (action.type === "BASIC_FEATURE_UNARCHIVE" || action.type === "BASIC_FEATURE_UNARCHIVE_CALL_COMPLETED"
        || action.type === "BASIC_FEATURE_UNARCHIVE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            basicFeatureUnArchiveMessage: action.basicFeatureUnArchiveMessage,
            showBasicFeatureUnArchiveLoader: action.showBasicFeatureUnArchiveLoader,
            basicFeatureUnArchiveIsDoneAndSent: action.basicFeatureUnArchiveIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "BASIC_CONFIRM_VALIDITY_PROJECT" || action.type === "BASIC_CONFIRM_VALIDITY_PROJECT_COMPLETED"
        || action.type === "BASIC_CONFIRM_VALIDITY_PROJECT_ERROR") {
        //////console.log(action)
        return {
            ...state,
            basicConfirmValidityProjectMessage: action.basicConfirmValidityProjectMessage,
            showBasicConfirmValidityProjectLoader: action.showBasicConfirmValidityProjectLoader,
            basicConfirmValidityProjectIsDoneAndSent: action.basicConfirmValidityProjectIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "BASIC_VALID_CONFIRM" || action.type === "BASIC_VALID_CONFIRM_COMPLETED"
        || action.type === "BASIC_VALID_CONFIRM_ERROR") {
        //////console.log(action)
        return {
            ...state,
            basicValidConfirmMessage: action.basicValidConfirmMessage,
            showBasicValidConfirmLoader: action.showBasicValidConfirmLoader,
            basicValidConfirmIsDoneAndSent: action.basicValidConfirmIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "UPDATE_DEFECT" || action.type === "UPDATE_DEFECT_COMPLETED"
        || action.type === "UPDATE_DEFECT_ERROR") {
        //////console.log(action)
        return {
            ...state,
            updateDefectMessage: action.updateDefectMessage,
            showUpdateDefectLoader: action.showUpdateDefectLoader,
            updateDefectDoneAndSent: action.updateDefectDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_UPDATE_DEFECT") {
        //////console.log(action)
        return {
            ...state,
            updateDefectMessage: "",
            showUpdateDefectLoader: null,
            updateDefectDoneAndSent: null,
        }
    }
    else if (action.type === "CLEAR_ARCHIVE_ERROR_AND_MSG") {
        //////console.log(action)
        return {
            ...state,
            basicFeatureArchiveMessage: "",
            showBasicFeatureArchiveLoader: null,
            basicFeatureArchiveIsDoneAndSent: null,
        }
    }
    else if (action.type === "ADD_DEFECT" || action.type === "ADD_DEFECT_COMPLETED"
        || action.type === "ADD_DEFECT_ERROR") {
        //////console.log(action)
        return {
            ...state,
            addDefectMessage: action.addDefectMessage,
            showAddDefectLoader: action.showAddDefectLoader,
            addDefectDoneAndSent: action.addDefectDoneAndSent ?? false,
        }
    }
    else if (action.type === "CLEAR_ADD_DEFECT_ERROR_AND_MSG") {
        //////console.log(action)
        return {
            ...state,
            addDefectMessage: "",
            showAddDefectLoader: null,
            addDefectDoneAndSent: null,
        }
    }

    else if (action.type === "CONFIRM_DELTA" || action.type === "CONFIRM_DELTA_CALL_COMPLETED" || action.type === "CONFIRM_DELTA_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            confirmDeltaMessage: action.confirmDeltaMessage,
            showConfirmDeltaLoader: action.showConfirmDeltaLoader,
            confirmDeltaIsDoneAndSent: action.confirmDeltaIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "DOWNSAMPLING_CALL_START" || action.type === "DOWNSAMPLING_CALL_COMPLETED" || action.type === "DOWNSAMPLING_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            downSamplingMessage: action.downSamplingMessage,
            showDownSamplingLoader: action.showDownSamplingLoader,
            downSamplingIsDoneAndSent: action.downSamplingIsDoneAndSent ?? false,
            downSamplingData: action.downSamplingData
        }
    }
    else if (action.type === "RESET_ARCHIVE_FEATURE") {
        //////console.log(action)
        return {
            ...state,
            featureArchiveMessage: '',
            showFeatureArchiveLoader: null,
            featureArchiveIsDoneAndSent: null,
        }
    }

    else if (action.type === "DISTRESS_ARCHIVE" || action.type === "DISTRESS_ARCHIVE_CALL_COMPLETED" || action.type === "DISTRESS_ARCHIVE_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            distressArchiveMessage: action.distressArchiveMessage,
            showDistressArchiveLoader: action.showDistressArchiveLoader,
            distressArchiveIsDoneAndSent: action.distressArchiveIsDoneAndSent ?? false,
            isArchiveModalClose: action.isArchiveModalClose
        }
    }
    else if (action.type === "CLEAR_DISTRESS_ERROR_MSG") {
        //////console.log(action)
        return {
            ...state,
            distressArchiveMessage: null,
            showDistressArchiveLoader: null,
            distressArchiveIsDoneAndSent: null,
            isArchiveModalClose: null
        }
    }

    else if (action.type === "RESET_ASSET" || action.type === "RESET_ASSET_CALL_COMPLETED" || action.type === "RESET_ASSET_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            resetAssetArchiveMessage: action.resetAssetArchiveMessage,
            showResetAssetArchiveLoader: action.showResetAssetArchiveLoader,
            resetAssetArchiveIsDoneAndSent: action.resetAssetArchiveIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "UPDATE_ASSET" || action.type === "UPDATE_ASSET_CALL_COMPLETED" || action.type === "UPDATE_ASSET_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            updateAssetArchiveMessage: action.updateAssetArchiveMessage,
            showUpdateAssetArchiveLoader: action.showUpdateAssetArchiveLoader,
            updateAssetArchiveIsDoneAndSent: action.updateAssetArchiveIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "UPDATE_DISTRESS" || action.type === "UPDATE_DISTRESS_CALL_COMPLETED" || action.type === "UPDATE_DISTRESS_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            updateDistressArchiveMessage: action.updateDistressArchiveMessage,
            showUpdateDistressArchiveLoader: action.showUpdateDistressArchiveLoader,
            updateDistressArchiveIsDoneAndSent: action.updateDistressArchiveIsDoneAndSent ?? false,
            isEditModalClose: action.isEditModalClose
        }
    }
    else if (action.type === "ROUTE_CALCULATION_REQUEST_START" || action.type === "ROUTE_CALCULATION_REQUEST_COMPLETED" || action.type === "ROUTE_CALCULATION_REQUEST_ERROR") {
        //////console.log(action)
        return {
            ...state,
            routeCalculationData: action.routeCalculationData,
            routeCalculationMessage: action.routeCalculationMessage,
            routeCalculationLoader: action.routeCalculationLoader,
            routeCalculationIsDoneAndSent: action.routeCalculationIsDoneAndSent ?? false,
        }
    }

    else if (action.type === "MIRROR_PROJECT_REQUEST_START" || action.type === "MIRROR_PROJECT_REQUEST_COMPLETED" || action.type === "MIRROR_PROJECT_REQUEST_ERROR") {
        //////console.log(action)
        return {
            ...state,
            mirrorProjectMessage: action.mirrorProjectMessage,
            mirrorProjectLoader: action.mirrorProjectLoader,
            mirrorProjectIsDoneAndSent: action.mirrorProjectIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "RESET_MIRROR_PROJECT") {
        //////console.log(action)
        return {
            ...state,
            mirrorProjectMessage: null,
            mirrorProjectLoader: null,
            mirrorProjectIsDoneAndSent: null,
        }
    }
    else if (action.type === "SAVE_ROUTE_CALCULATION_TO_DB_REQUEST_START" || action.type === "SAVE_ROUTE_CALCULATION_TO_DB_REQUEST_COMPLETED" || action.type === "SAVE_ROUTE_CALCULATION_TO_DB_REQUEST_ERROR") {
        //////console.log(action)
        return {
            ...state,
            saveRouteCalculationToDbMessage: action.saveRouteCalculationToDbMessage,
            saveRouteCalculationToDbLoader: action.saveRouteCalculationToDbLoader,
            saveRouteCalculationToDbIsDoneAndSent: action.saveRouteCalculationToDbIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "CONVERT_ENCODED_GEOMETRY_REQUEST_START" || action.type === "CONVERT_ENCODED_GEOMETRY_REQUEST_COMPLETED" || action.type === "CONVERT_ENCODED_GEOMETRY_REQUEST_ERROR") {
        //////console.log(action)
        return {
            ...state,
            convertedCoordinatesData: action.convertedCoordinatesData,
            convertEncodedGeometryMessage: action.convertEncodedGeometryMessage,
            convertEncodedGeometryIsDoneAndSent: action.convertEncodedGeometryIsDoneAndSent,
            convertEncodedGeometryLoader: action.convertEncodedGeometryLoader,

        }
    }
    else if (action.type === "CLEAR_ROUTE_CALCULATION_ERROR_MSG") {
        //////console.log(action)
        return {
            ...state,
            routeCalculationData: null,
            routeCalculationMessage: '',
            routeCalculationLoader: null,
            routeCalculationIsDoneAndSent: null,
        }
    }
    else if (action.type === "ADD_DISTRESS" || action.type === "ADD_DISTRESS_CALL_COMPLETED" || action.type === "ADD_DISTRESS_CALL_ERROR") {
        //////console.log(action)
        return {
            ...state,
            addDistressArchiveMessage: action.addDistressArchiveMessage,
            showAddDistressArchiveLoader: action.showAddDistressArchiveLoader,
            addDistressArchiveIsDoneAndSent: action.addDistressArchiveIsDoneAndSent ?? false,
            isAddModalClose: action.isAddModalClose
        }
    }
    else if (action.type === "DEPTH_CROSS_SECTIONAL_PROFILE_START" || action.type === "DEPTH_CROSS_SECTIONAL_PROFILE_COMPLETED" || action.type === "DEPTH_CROSS_SECTIONAL_PROFILE_ERROR") {
        //////console.log(action)
        return {
            ...state,
            depthCrossSectionalProfileMessage: action.depthCrossSectionalProfileMessage,
            depthCrossSectionalProfileLoader: action.depthCrossSectionalProfileLoader,
            depthCrossSectionalProfileIsDoneAndSent: action.depthCrossSectionalProfileIsDoneAndSent ?? false,
            depthCrossSectionalData: action.depthCrossSectionalData
        }
    }
    else if (action.type === "SAVE_DEPTH_CROSS_SECTIONAL_POINTS_START" || action.type === "SAVE_DEPTH_CROSS_SECTIONAL_POINTS_COMPLETED" || action.type === "SAVE_DEPTH_CROSS_SECTIONAL_POINTS_ERROR") {
        //////console.log(action)
        return {
            ...state,
            saveDepthCrossSectionalPointsMessage: action.saveDepthCrossSectionalPointsMessage,
            saveDepthCrossSectionalPointsLoader: action.saveDepthCrossSectionalPointsLoader,
            saveDepthCrossSectionalPointsIsDoneAndSent: action.saveDepthCrossSectionalPointsIsDoneAndSent ?? false,
        }
    }

    else if (action.type === "RESET_ADD_DISTRESS") {
        // //////console.log(action, 'aus')
        return {
            ...state,
            isAddModalClose: action.isAddModalClose
        }
    }

    else if (action.type === "RESET_EDIT_DISTRESS") {
        return {
            ...state,
            isEditModalClose: action.isEditModalClose
        }
    }

    else if (action.type === "RESET_ARCHIVE_DISTRESS") {
        return {
            ...state,
            isArchiveModalClose: action.isArchiveModalClose
        }
    }

    else if (action.type === "RESET_GENERATE_DISTRESS") {
        return {
            ...state,
            isModalClose: action.isModalClose
        }
    }
    else if (action.type === "ASSIGNED_FEATURE_REQUEST_START" || action.type === "ASSIGNED_FEATURE_REQUEST_COMPLETED" || action.type === "ASSIGNED_FEATURE_REQUEST_ERROR") {
        //////console.log(action)
        return {
            ...state,
            assignedFeatureMessage: action.assignedFeatureMessage,
            assignedFeatureIsDoneAndSent: action.assignedFeatureIsDoneAndSent ?? false,
            assignedFeatureLoader: action.assignedFeatureLoader,

        }
    }
    else if (action.type === "CLEAR_ASSIGNED_FEATURE") {
        return {
            ...state,
            assignedFeatureMessage: "",
            assignedFeatureIsDoneAndSent: null,
            assignedFeatureLoader: null,

        }
    }
    else if (action.type === "APPROVE_REJECT_FEATURE_REQUEST_START" || action.type === "APPROVE_REJECT_REQUEST_COMPLETED"
        || action.type === "APPROVE_REJECT_REQUEST_ERROR") {
        //////console.log(action)
        return {
            ...state,
            approveRejectMessage: action.approveRejectMessage,
            approveRejectIsDoneAndSent: action.approveRejectIsDoneAndSent ?? false,
            approveRejectLoader: action.approveRejectLoader,

        }
    }
    else if (action.type === "ARCHIVE_FEATURE_REQUEST_START" || action.type === "ARCHIVE_FEATURE_REQUEST_COMPLETED"
        || action.type === "ARCHIVE_FEATURE_REQUEST_ERROR") {
        //////console.log(action)
        return {
            ...state,
            archiveFeatureMessage: action.archiveFeatureMessage,
            archiveFeatureIsDoneAndSent: action.archiveFeatureIsDoneAndSent ?? false,
            archiveFeatureLoader: action.archiveFeatureLoader,
        }
    }
    else if (action.type === "CLEAR_ARCHIVE_FEATURE") {
        return {
            ...state,
            archiveFeatureMessage: "",
            archiveFeatureIsDoneAndSent: null,
            archiveFeatureLoader: null,
        }
    }

    else if (action.type === "PCI_CALC_START" || action.type === "PCI_CALC_COMPLETED" || action.type === "PCI_CALC_ERROR") {
        return {
            ...state,
            pciCalcMessage: action.pciCalcMessage,
            pciCalcIsDoneAndSent: action.pciCalcIsDoneAndSent ?? false,
            pciCalcLoader: action.pciCalcLoader,
        }
    }
    else if (action.type === "CLEAR_APPROVE_REJECT_FEATURE") {
        return {
            ...state,
            approveRejectMessage: "",
            approveRejectIsDoneAndSent: null,
            approveRejectLoader: null,

        }
    }
    else if (action.type === "CLEAR_PCI_CALC") {
        return {
            ...state,
            pciCalcMessage: "",
            pciCalcIsDoneAndSent: null,
            pciCalcLoader: null,
        }
    }

    return state;
}
export default ProjectReducer