import React, { useEffect, useState, useRef } from "react"
import './table.css'
import { Card, Checkbox, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, Button, DialogContentText, Paper } from '@mui/material'
import CustomTooltip from '../../../../../cidgets/CustomTooltip';

const PotholeTable = ({ defectImageURLs, priorityMap, basicImagingData, openCompareDialog, handleCallCompareDataApi, isCompareOpen, setIsCompareOpen, handleClickOpenCompareDialog, playerRef, dataList = [], assetData = [], handlePinned, pinned, fps, disableHeader = false }) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [open, setOpen] = useState(false);
    const [videoPlayerState, setVideoPlayerState] = useState(null);
    const tableRef = useRef(null);
    const { defectNames } = basicImagingData;
    const style = {
        // "background": "#FAFAFA",
        // "color": "white",
        "paddingLeft": "15px",
        "paddingRight": "15px",
        "width": "100%",
        "height": "100%",
        "overflow": "auto",
        // "border": "1px solid #ccc",
    }

    useEffect(() => {
        const element = document.getElementById(pinned?.pred?.id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, [pinned])
    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });
    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const handleShareClick = (outputId) => {
        setOpen(true)
        navigator.clipboard.writeText(`${window.location.href}/${outputId}`)
    }
    const handleBind = (state) => {
        setVideoPlayerState(state);
    }
    const handleSeekTo = (timeSeek) => {
        playerRef.current.seek(timeSeek)
    }

    const handlePinning = (e) => {
        handlePinned(e)
        if (!openCompareDialog) {
            handleSeekTo(Number(e.pred.frameTime).toFixed(3) + (1 / fps))
        }
    }
    const handleComparePinning = (e, outputId) => {
        // handlePinned(null)
        handleCallCompareDataApi(outputId);
        handleClickOpenCompareDialog();
        setIsCompareOpen(e);
    }

    const calculateAccuracyRating = (score) => {
        if (score < 35) {
            return "Bad"
        }

        if (score >= 35 && score < 75) {
            return "Medium"
        }

        if (score >= 75 && score <= 100) {
            return "Good"
        }
    }

    if (assetData.length === 0) {
        return <>Loading...</>
    }
    return (
        <>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied the link to clipboard"
            />
            <div style={style}>
                {dataList.length > 0 ?
                    <table className="assetTable">
                        {disableHeader ? <></> : <thead style={{}}>
                            <tr>
                                <th className="" style={{ width: '10%', fontWeight: '700' }}>Number</th>
                                <th className="" style={{ width: '20%', fontWeight: '700' }}>Feature</th>
                                <th className="" style={{ width: '40%', fontWeight: '700' }}>Category</th>
                                <th className="" style={{ width: '20%', fontWeight: '700' }}>Priority</th>
                                <th className="" style={{ width: '20%', fontWeight: '700' }}>Confirm data</th>
                            </tr>
                        </thead>
                        }
                        <tbody ref={tableRef}>
                            {dataList.map((e, i) => {
                                // //////console.log(e, "e")
                                const { id, sectionId, defectName, deltaScore, priority, isValid } = e;
                                const obj = {
                                    pred: e,
                                    // index: i
                                }
                                const isConfirmed = e.isConfirmed ? e.isConfirmed : false;
                                const disabledRow = isValid === true && isConfirmed === true ? true : false;
                                const outputId = `${sectionId}-${id}`;
                                // const scoreValue = calculateAccuracyRating(deltaScore);
                                // const score = SmartUtils.safetyPerformanceColor(scoreValue);
                                const check = (obj.pred.id === pinned?.pred?.id ?? '')
                                const compareCheck = (obj.pred.id === isCompareOpen?.pred?.id ?? '')
                                const imBase64 = `data:image/png;base64, ${obj?.pred?.cropb64 ?? ''}`;
                                const metaBase64 = `data:image/png;base64, ${obj?.meta?.base64 ?? ''}`;
                                return <tr
                                    // className={disabledRow ? "disabled-row" : ""}
                                    key={id}
                                    id={id}
                                    style={{
                                        transition: 'all ease 0.0s', ...check ? { backgroundColor: '#B0FFA4', border: '2px solid #60EE00', cursor: 'pointer' } : { cursor: 'pointer' },
                                        ...compareCheck ? { backgroundColor: '#7997FB', border: '2px solid #1C1BFE', cursor: 'pointer' } : { cursor: 'pointer' },
                                        ...(check && compareCheck) ? { backgroundColor: '#7997FB', border: '2px solid #60EE00', cursor: 'pointer' } : { cursor: 'pointer' },
                                    }}
                                    role="button"
                                // onClick={() => { handlePinning(obj) }}
                                >
                                    <td style={{ width: '10%', fontWeight: '400' }}
                                        // onClick={!isCompareOpen ? () => handlePinning(obj) : null}
                                        onClick={() => handlePinning(obj)}
                                    >
                                        <div style={{
                                            width: '40px', height: '40px', borderRadius: '50%',
                                            // backgroundColor: score,
                                            display: 'flex', alignItems: 'center',
                                            justifyContent: 'center',
                                            color: "black",
                                            fontSize: '16px',
                                            fontWeight: '400'
                                        }}>{i + 1}</div>
                                    </td>
                                    <td style={{ width: '20%', fontWeight: '400' }}
                                        //  onClick={!isCompareOpen ? () => { handlePinning(obj) } : null}
                                        onClick={() => handlePinning(obj)}
                                    >
                                        <CustomTooltip
                                            placement="top"
                                            arrow
                                            title={
                                                <Card
                                                    //  style={{ width: isTraficSignClass ? '210px' : '100px' }} 
                                                    elevation={5}>
                                                    <img src={defectImageURLs[id]} width="100px" style={{ maxHeight: '200px' }} />
                                                    {/* {isTraficSignClass ? <img src={metaBase64} width="100px" style={{ margin: '2px' }} /> : <></>} */}
                                                </Card>
                                            }
                                            widget={
                                                <img src={defectImageURLs[id]} height={50} width={50} style={{ objectFit: 'cover' }} />
                                            }
                                        />
                                    </td>
                                    <td
                                        onClick={() => handlePinning(obj)}
                                        // onClick={!isCompareOpen ? () => { handlePinning(obj) } : null} 
                                        style={{ width: '40%', fontWeight: '400', color: '#000000' }} className="capitalize-text">
                                        {defectNames ? defectNames[defectName] : <span style={{ color: '#000000', fontWeight: '400' }}><i>{'unnamed'}</i></span>}
                                    </td>

                                    <td style={{ width: '20%', color: '#000000', fontWeight: '400' }} className="capitalize-text"
                                        // onClick={() => { handleShareClick(outputId) }}
                                        onClick={() => handlePinning(obj)}
                                    >
                                        {`${priorityMap?.map[priority].name}`}
                                        {/* <IconButton disableRipple={true} sx={{ '&:hover': { backgroundColor: '#F0F0F0' }, backgroundColor: '#F0F0F0', borderRadius: '9px', position: 'static' }}
                                            // onClick={() => { handleShareClick(outputId) }}
                                            onClick={() => { handleComparePinning(obj, outputId) }}
                                        >
                                            <LinkIcon sx={{ color: '#606060' }} />
                                        </IconButton> */}
                                    </td>
                                    <td
                                        onClick={() => handlePinning(obj)}
                                        // onClick={!isCompareOpen ? () => { handlePinning(obj) } : null} 
                                        style={{ width: '20%', fontWeight: '400' }}><Checkbox checked={isValid} disabled  {...label}
                                            sx={{
                                                '&.Mui-checked.Mui-disabled': {
                                                    color: '#f0f0f0',
                                                    '& .MuiSvgIcon-root': {
                                                        backgroundColor: '#606060',
                                                    },
                                                },
                                                '&.Mui-disabled': {
                                                    color: '#606060',
                                                    backgroundColor: 'transparent',
                                                    '& .MuiSvgIcon-root': {
                                                        backgroundColor: 'transparent',
                                                    },
                                                },
                                                position: 'static',
                                            }}
                                        /></td>
                                </tr>
                            })}
                        </tbody>
                    </table> : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No data found</div>
                }

            </div >
        </>
    );
}

export default PotholeTable;