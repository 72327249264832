import React, { useEffect, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import SmartUtils from '../../../../../../helpers/SmartUtils';
import { Autocomplete, Box, Button, Card, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';
import Overview from '../Overview';

const Topbar = (props) => {
    const topbarSection1Width = 30;
    const topbarSection2Width = 70;

    const { handleOpenSSSettings, selectedSuperSection, handleSSViewChange, handleSuperSectionChange, superSectionList, allNetworkDefects } = props;
    const [openOverviewDialog, setOpenOverviewDialog] = useState(false);
    let navigate = useNavigate();
    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };
    const keyMap = {
        OVERVIEW_MAC: "command+p",
        OVERVIEW_WIN: "ctrl+p",
    };
    const handleOverviewOpen = () => {
        // setOpenOverview(true);
    };

    const handlers = preventDefaultHandlers({
        OVERVIEW_MAC: handleOverviewOpen,
        OVERVIEW_WIN: handleOverviewOpen,
    });
    const su = new SmartUtils()
    const [os, setOs] = useState({})
    const { ctrl } = su._platformKeys(os?.platform);

    useEffect(() => {
        const fetchData = async () => {
            const data = await su._checkOS(navigator);
            setOs(data);
        }

        fetchData()
    }, []);

    if (!selectedSuperSection) {
        return <></>
    }

    const handleOpenOverViewDialog = () => {
        setOpenOverviewDialog(true);
    }
    const handleCloseOverviewDialog = () => {
        setOpenOverviewDialog(false);
    }
    return (
        <>
            <SimpleDialogBox
                open={openOverviewDialog}
                onClose={handleCloseOverviewDialog}
                title={"Overview"}
                cancelText="Close"
                bodyWidget={
                    <Overview allNetworkDefects={allNetworkDefects} />
                }
                width={800}
            />
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
                    <div style={{
                        borderRadius: '50%'
                    }}>
                        <Card sx={{
                            borderRadius: '50px', backgroundColor: '#fff', '&:hover': {
                                backgroundColor: '#fafafa',
                            },
                        }} elevation={5}>
                            <Tooltip arrow placement="bottom" title="Back to networks">
                                <IconButton aria-label="roundedBtn" style={{ backgroundColor: 'transparent', }} onClick={() => {
                                    handleSSViewChange({ target: { checked: false } })
                                }}>
                                    <KeyboardArrowLeft fontSize='large' sx={{ color: '#1E1E1E' }} />
                                </IconButton>
                            </Tooltip>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={10} sm={10} md={11} lg={11} xl={11} sx={{ backgroundColor: 'transparent', borderRadius: '15px' }}>
                    <Card elevation={4} sx={{ backgroundColor: '#FAFAFA', borderRadius: '15px', padding: '5px 10px 5px 0px' }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' }, width: { xs: '0%', md: `${topbarSection1Width}%` } }} style={{ height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={`${selectedSuperSection.superSectionTitle}}`} placement="bottom">
                                    <Typography
                                        variant="body1"
                                        color="initial"
                                        className='font-color'
                                        sx={{ paddingLeft: '20px', cursor: 'pointer', display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' }, alignItems: 'center' }}
                                    >
                                        {selectedSuperSection.superSectionTitle ? (
                                            selectedSuperSection.superSectionTitle.length > 15 ? (
                                                <span>{selectedSuperSection.superSectionTitle.slice(0, 15)}...</span>
                                            ) : (
                                                <span>{selectedSuperSection.superSectionTitle} </span>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </Typography>
                                </Tooltip>
                            </Box>

                            <Box sx={{ width: { xs: `${100}%`, md: `${topbarSection2Width}%` }, justifyContent: { xs: 'space-evenly', md: 'end' } }} style={{ marginRight: { xs: '20px', sm: '20px', md: '0px' }, height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center', }}>
                                {/* <FormControl
                                    sx={{
                                        alignItems: 'center',
                                        marginRight: '15px'
                                    }}
                                    size="small"
                                >
                                    <TextField
                                        id="supersection-dropdown"
                                        select
                                        value={selectedSuperSection}
                                        onChange={handleSuperSectionChange}
                                        size="small"
                                        sx={{
                                            border: `1px solid #041256`,
                                            textTransform: 'capitalize', width: '100%',
                                            outline: 'none',
                                            color: "#041256",
                                            borderRadius: '4px'
                                        }}
                                    >
                                        {superSectionList.map((option, index) => (
                                            <MenuItem key={option.superSectionId} value={option} sx={{ textTransform: 'capitalize' }}>
                                                {option.superSectionTitle}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl> */}


                                {/* <Box>
                                    <Autocomplete
                                        size='small'
                                        value={selectedSuperSection}
                                        onChange={handleSuperSectionChange}
                                        options={superSectionList}
                                        getOptionLabel={(option) => option.superSectionTitle}
                                        getOptionSelected={(option, value) => option.superSectionId === value.superSectionId}
                                        renderInput={(params) => <TextField {...params} label="Select a Super Section" />}
                                    />
                                </Box> */}

                                <Box sx={{ border: '1px solid #000000', marginLeft: '15px', borderRadius: '4px', float: 'left', display: { xs: "none", sm: "none", md: 'none', lg: "flex" }, }}>

                                    <Tooltip title={`Use ${ctrl.name}${ctrl.plus}P to view`} arrow placement='bottom'>
                                        <Button variant="text" size='small'
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                backgroundColor: 'transparent',
                                                color: '#000000', textTransform: 'initial', paddingInline: '8px',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    color: '#000000',
                                                },
                                            }}

                                            onClick={handleOpenOverViewDialog}
                                        >Results overview</Button>
                                    </Tooltip>
                                </Box>
                                <Tooltip title={'Supersection settings'} arrow placement='bottom'>
                                    <Button variant="contained"
                                        onClick={handleOpenSSSettings}
                                        size="small" sx={{
                                            marginLeft: '10px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            marginInline: '10px',
                                            textTransform: 'initial',
                                            backgroundColor: '#025196', ':hover': {
                                                backgroundColor: '#025196'
                                            },
                                        }}

                                    >
                                        Settings</Button>
                                </Tooltip>
                            </Box>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Topbar