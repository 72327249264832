import React, { useState, useEffect } from 'react';
import AfterAssetInferenceDashboard from "./AfterAssetInferenceDashboard";
import { get } from "lodash";
import { connect } from "react-redux";
import SetupProject from "./subpages/beforeInference/SetupProject";
import { compose } from "redux";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import AfterSurfaceEvaluationInferenceDashboardV3 from "./AfterSurfaceEvaluationInferenceDashboardV3";
import AfterSurfaceEvaluationInferenceGroupDashboard from "./AfterSurfaceEvaluationInferenceGroupDashboard";
import { useGenerateSignedUrl } from "../../../../chooks/useGenerateSignedUrl";
import CustomProgress from "../../../cidgets/CustomProgress";
import * as ROUTES from "../../../../constants/routes";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import VisualizerDashboardWrapper from "./VisualizerDashboardWrapper";
import SafetyInferenceDashboard from './subpages/safetyInspection/SafetyInferenceDashboard';
import PotholeInferenceDashboard from './subpages/potholeInspection/PotholeInferenceDashboard';

const ProjectDashboardController = (props) => {
    const { originalProjectGpsDataList, secId, projectPredictions, predictionCropb64List, predictionGpsDataList, proid, projNotifs, distressProjectPredictions, visualizerProjectPredictions, analysisRequests, filteredGpsData360 } = props;
    // //////console.log(originalProjectGpsDataList, "originalProjectGpsDataList")
    const { drProjectData, userData } = props;
    const showGroupDashboard = userData.showGroupDashboard ?? false;
    const inferenceCompleteStatus = 8 // TODO: this will change in the future
    const allowedStatus = [8, 9];
    const [loading, setLoading] = useState(true);
    const transcodeStatus = {
        FAILED: 0,
        PASS: 1,
        RUNNING: 2
    }

    useEffect(() => {
        const fetchData = async () => {
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        };
        // Check if data is initially null or undefined
        if (drProjectData === undefined) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [drProjectData]);
    if (drProjectData === undefined) {
        if (loading) {
            return (
                <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomProgress text={"Loading data"} />
                </div>
            )
        }
        else {
            return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    <h6>No data found!</h6>
                    <Link
                        style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
                        to={`/p${ROUTES.CONTACT}`}
                        state={{ proid: proid, isProject: true }}
                    >  Click here</Link>&nbsp;to contact customer support team.
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <Link
                            to={`/home/${ROUTES.HOME_DASHBOARD}`}
                        >
                            <Button variant='contained' sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                }
                            }}
                            >Go back to home</Button>
                        </Link>
                    </div>
                </div>
            </div>
        }
    }
    if (drProjectData && userData.isLoaded) {
        const isProjectAssetBased = 'projectType' in drProjectData ? drProjectData['projectType'] === 0 : false;
        const isProject360Based = 'projectType' in drProjectData ? drProjectData['projectType'] === 2 : false;
        const isProjectSafetyBased = 'projectType' in drProjectData ? drProjectData['projectType'] === 3 : false;
        const isProjectBasicsBased = 'projectType' in drProjectData ? drProjectData['projectType'] === 5 : false;
        const isProjectAdvancedDistress = 'projectType' in drProjectData ? drProjectData['projectType'] === 4 : false;

        if (isProjectAssetBased) {
            if (allowedStatus.includes(drProjectData.status)) {
                if (Object.values(projectPredictions).length === 0) {
                    return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h6>No data found!</h6>
                            <Link
                                style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
                                to={`/p${ROUTES.CONTACT}`}
                                state={{ proid: proid, isProject: true }}
                            >  Click here</Link>&nbsp;to contact customer support team.
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Link
                                    to={`/home/${ROUTES.HOME_DASHBOARD}`}
                                >
                                    <Button variant='contained' sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        }
                                    }}
                                    >Go back to home</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                else if (Object.values(projectPredictions).length > 0) {
                    return <AssetInferenceWrapper loadTranscodedVideo={drProjectData.hasTranscoded === transcodeStatus.PASS} drProjectData={drProjectData} projectPredictions={projectPredictions} predictionCropb64List={predictionCropb64List} proid={proid} />
                }
                else {
                    return <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomProgress text="Building your dashboard" />
                    </div>
                }

            } else {
                return <SetupProject proid={proid} analysisRequests={analysisRequests} originalProjectGpsDataList={originalProjectGpsDataList} />
            }
        }
        else if (isProjectSafetyBased) {
            if (allowedStatus.includes(drProjectData.status)) {
                if (Object.values(projectPredictions).length === 0) {
                    return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h6>No data found!</h6>
                            <Link
                                style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
                                to={`/p${ROUTES.CONTACT}`}
                                state={{ proid: proid, isProject: true }}
                            >  Click here</Link>&nbsp;to contact customer support team.
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Link
                                    to={`/home/${ROUTES.HOME_DASHBOARD}`}
                                >
                                    <Button variant='contained' sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        }
                                    }}
                                    >Go back to home</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                else if (Object.values(projectPredictions).length > 0) {
                    return <SafetyInferenceWrapper loadTranscodedVideo={drProjectData.hasTranscoded === transcodeStatus.PASS} drProjectData={drProjectData} projectPredictions={projectPredictions} predictionCropb64List={predictionCropb64List} proid={proid} />
                }
                else {
                    return <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomProgress text="Building your dashboard" />
                    </div>
                }

            } else {
                return <SetupProject proid={proid} analysisRequests={analysisRequests} />
            }
        }
        else if (isProjectBasicsBased) {
            if (allowedStatus.includes(drProjectData.status)) {
                if (Object.values(projectPredictions).length === 0) {
                    return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h6>No data found!</h6>
                            <Link
                                style={{ color: 'black', cursor: 'pointer', marginTop: '0px' }}
                                to={`/p${ROUTES.CONTACT}`}
                                state={{ proid: proid, isProject: true }}
                            >  Click here</Link>&nbsp;to contact customer support team.
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Link
                                    to={`/home/${ROUTES.HOME_DASHBOARD}`}
                                >
                                    <Button variant='contained' sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        }
                                    }}
                                    >Go back to home</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                else if (Object.values(projectPredictions).length > 0) {
                    return <PotholeInferenceWrapper loadTranscodedVideo={drProjectData.hasTranscoded === transcodeStatus.PASS} drProjectData={drProjectData} projectPredictions={projectPredictions} predictionCropb64List={predictionCropb64List} proid={proid} />
                }
                else {
                    return <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomProgress text="Building your dashboard" />
                    </div>
                }

            } else {
                return <SetupProject proid={proid} analysisRequests={analysisRequests} originalProjectGpsDataList={originalProjectGpsDataList} />
            }
        }
        else if (isProject360Based) {
            if (allowedStatus.includes(drProjectData.status)) {
                return <ImageInferenceWrapper loadTranscodedVideo={drProjectData.hasTranscoded === transcodeStatus.PASS} drProjectData={drProjectData} proid={proid} visualizerProjectPredictions={visualizerProjectPredictions} filteredGpsData360={filteredGpsData360} />
            }
            else {
                return <SetupProject proid={proid} analysisRequests={analysisRequests} filteredGpsData360={filteredGpsData360} />
            }
        }
        else if (isProjectAdvancedDistress) {
            if (allowedStatus.includes(drProjectData.status)) {
                if (Object.values(distressProjectPredictions).length === 0) {
                    return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h6>No data found!</h6>
                            <Link
                                style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
                                to={`/p${ROUTES.CONTACT}`}
                                state={{ proid: proid, isProject: true }}
                            >  Click here</Link>&nbsp;to contact customer support team.
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Link
                                    to={`/home/${ROUTES.HOME_DASHBOARD}`}
                                >
                                    <Button variant='contained' sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        }
                                    }}
                                    >Go back to home</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                else if (Object.values(distressProjectPredictions).length > 0) {
                    return <AdvancedDistressWrapper showGroupDashboard={showGroupDashboard} secId={secId} predictionGpsDataList={predictionGpsDataList} loadTranscodedVideo={drProjectData.hasTranscoded === transcodeStatus.PASS} proid={proid} drProjectData={drProjectData} distressProjectPredictions={distressProjectPredictions} />
                }
                else {
                    return <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomProgress text="Building your dashboard" />
                    </div>
                }

            } else {
                return <SetupProject proid={proid} analysisRequests={analysisRequests} originalProjectGpsDataList={originalProjectGpsDataList} />
            }
        }
        else {
            if (allowedStatus.includes(drProjectData.status)) {
                if (Object.values(distressProjectPredictions).length === 0) {
                    return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h6>No data found!</h6>
                            <Link
                                style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
                                to={`/p${ROUTES.CONTACT}`}
                                state={{ proid: proid, isProject: true }}
                            >  Click here</Link>&nbsp;to contact customer support team.
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Link
                                    to={`/home/${ROUTES.HOME_DASHBOARD}`}
                                >
                                    <Button variant='contained' sx={{
                                        textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#025196',
                                            color: '#ffffff'
                                        }
                                    }}
                                    >Go back to home</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                else if (Object.values(distressProjectPredictions).length > 0) {
                    return <SurfaceEvaluationInferenceWrapper showGroupDashboard={showGroupDashboard} secId={secId} predictionGpsDataList={predictionGpsDataList} loadTranscodedVideo={drProjectData.hasTranscoded === transcodeStatus.PASS} proid={proid} drProjectData={drProjectData} distressProjectPredictions={distressProjectPredictions} />
                }
                else {
                    return <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomProgress text="Building your dashboard" />
                    </div>
                }

            } else {
                return <SetupProject proid={proid} analysisRequests={analysisRequests} originalProjectGpsDataList={originalProjectGpsDataList} />
            }
        }
    }

    // else if (drProjectData === undefined) {
    //     return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //         <div style={{ textAlign: 'center' }}>
    //             <h6>No data found!</h6>
    //             <Link
    //                 style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
    //                 to={`/p${ROUTES.CONTACT}`}
    //                 state={{ proid: proid, isProject: true }}
    //             >  Click here</Link>&nbsp;to contact customer support team.
    //             <div style={{ textAlign: 'center', marginTop: '10px' }}>
    //                 <Link
    //                     to={`/home/${ROUTES.HOME_DASHBOARD}`}
    //                 >
    //                     <Button variant='contained' sx={{
    //                         textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
    //                         '&:hover': {
    //                             backgroundColor: '#025196',
    //                             color: '#ffffff'
    //                         }
    //                     }}
    //                     >Go back to home</Button>
    //                 </Link>
    //             </div>
    //         </div>
    //     </div>
    // }
    return <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CustomProgress text="Building your dashboard" />
    </div>

}
const AssetInferenceWrapper = ({ drProjectData, proid, projectPredictions, predictionCropb64List, loadTranscodedVideo }) => {
    const { status, error, data } = useGenerateSignedUrl(loadTranscodedVideo ? drProjectData?.transcodePath : drProjectData?.videoPath ?? "")
    const isFetched = status === 'fetched'

    return isFetched ? <AfterAssetInferenceDashboard projectPredictions={projectPredictions} predictionCropb64List={predictionCropb64List} proid={proid} link={data} />
        : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomProgress text="Building your dashboard" />
        </div>
}
const SafetyInferenceWrapper = ({ drProjectData, proid, projectPredictions, predictionCropb64List, loadTranscodedVideo }) => {
    const { status, error, data } = useGenerateSignedUrl(loadTranscodedVideo ? drProjectData?.transcodePath : drProjectData?.videoPath ?? "")
    const isFetched = status === 'fetched'

    return isFetched ?
        <SafetyInferenceDashboard projectPredictions={projectPredictions} predictionCropb64List={predictionCropb64List} proid={proid} link={data} />
        : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomProgress text="Building your dashboard" />
        </div>
}
const PotholeInferenceWrapper = ({ drProjectData, proid, projectPredictions, predictionCropb64List, loadTranscodedVideo }) => {
    const { status, error, data } = useGenerateSignedUrl(loadTranscodedVideo ? drProjectData?.transcodePath : drProjectData?.videoPath ?? "")
    const isFetched = status === 'fetched'

    return isFetched ?
        <PotholeInferenceDashboard projectPredictions={projectPredictions} predictionCropb64List={predictionCropb64List} proid={proid} link={data} />
        : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomProgress text="Building your dashboard" />
        </div>
}
const ImageInferenceWrapper = ({ drProjectData, proid, loadTranscodedVideo, visualizerProjectPredictions, filteredGpsData360 }) => {
    const { status, error, data } = useGenerateSignedUrl(drProjectData?.compiledVideoPath ?? "")
    const isFetched = status === 'fetched'

    return drProjectData?.compiledVideoPath ?
        isFetched ?
            <VisualizerDashboardWrapper link={data} drProjectData={drProjectData} proid={proid} visualizerProjectPredictions={visualizerProjectPredictions} filteredGpsData360={filteredGpsData360} />
            : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomProgress text="Building your dashboard" />
            </div> :
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ textAlign: 'center' }}>
                <h6>No data found!</h6>
                <Link
                    style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', marginTop: '0px' }}
                    to={`/p${ROUTES.CONTACT}`}
                    state={{ proid: proid, isProject: true }}
                >  Click here</Link>&nbsp;to contact customer support team.
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <Link
                        to={`/home/${ROUTES.HOME_DASHBOARD}`}
                    >
                        <Button variant='contained' sx={{
                            textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#025196',
                                color: '#ffffff'
                            }
                        }}
                        >Go back to home</Button>
                    </Link>
                </div>
            </div>
        </div>


}

const SurfaceEvaluationInferenceWrapper = ({ showGroupDashboard, secId, predictionGpsDataList, drProjectData, proid, distressProjectPredictions, loadTranscodedVideo }) => {
    const { status, error, data } = useGenerateSignedUrl(loadTranscodedVideo ? drProjectData?.transcodePath : drProjectData?.videoPath ?? "")
    const isFetched = status === 'fetched'
    // return <p>ioi</p>
    // //////console.log("SurfaceEvaluationInferenceWrapper")
    return (
        showGroupDashboard && drProjectData.groupVideosInfo && drProjectData.groupVideosInfo.length > 1 ?
            <AfterSurfaceEvaluationInferenceGroupDashboard
                drProjectData={drProjectData}
                distressProjectPredictions={distressProjectPredictions}
                secId={secId}
                proid={proid}
            /> : isFetched ? <AfterSurfaceEvaluationInferenceDashboardV3 secId={secId} proid={proid} drProjectData={drProjectData} predictionGpsDataList={predictionGpsDataList} distressProjectPredictions={distressProjectPredictions} link={data} />
                : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomProgress text="Building your dashboard" />
                </div>
    )
}
const AdvancedDistressWrapper = ({ showGroupDashboard, secId, predictionGpsDataList, drProjectData, proid, distressProjectPredictions, loadTranscodedVideo }) => {
    const { status, error, data } = useGenerateSignedUrl(loadTranscodedVideo ? drProjectData?.transcodePath : drProjectData?.videoPath ?? "")
    const isFetched = status === 'fetched'
    // return <p>ioi</p>
    // //////console.log("SurfaceEvaluationInferenceWrapper")
    return (
        showGroupDashboard && drProjectData.groupVideosInfo && drProjectData.groupVideosInfo.length > 1 ? <AfterSurfaceEvaluationInferenceGroupDashboard /> : isFetched ? <AfterSurfaceEvaluationInferenceDashboardV3 secId={secId} proid={proid} drProjectData={drProjectData} predictionGpsDataList={predictionGpsDataList} distressProjectPredictions={distressProjectPredictions} link={data} />
            : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomProgress text="Building your dashboard" />
            </div>
    )

}

const mapStateToProps = (state, ownProps) => {
    // //////console.log(state.firebase.data, "state.firebase.data")
    const { proid } = ownProps;
    const projetData = get(
        state.firestore.data,
        `DrProject.${proid}`
    )
    const forkedProid = projetData !== undefined ? (projetData.forkedFrom ? projetData.forkedFrom : undefined) : undefined;
    // const proid = useParams;
    return {
        projNotifs: state.firestore.ordered.ProjNotifs,
        userData: state.firebase.profile,
        drProjectData: get(
            state.firestore.data,
            `DrProject.${proid}`
        ),
        project: state.project,
        projectPredictions: state.firestore.ordered.ProjectPredictions,
        distressProjectPredictions: state.firestore.ordered.DistressProjectPredictions,
        visualizerProjectPredictions: state.firestore.ordered.VisualizerProjectPredictions,
        predictionCropb64List: get(state.firebase.data, `projectInferenceData.${projetData?.proid}.cropb64List`),
        predictionGpsDataList: get(state.firebase.data, `ProjectGpsData.${proid}.gps`),
        filteredGpsData360: get(state.firebase.data, `FilteredGpsData360.${projetData?.proid}.data`),
        analysisRequests: state.firestore.ordered.AnalysisRequests,
        originalProjectGpsDataList: get(state.firebase.data, `GpsData.${forkedProid !== undefined ? forkedProid : projetData?.proid}.gps`),
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        const project = props;
        const { userData } = props;
        const proid = project !== undefined ? (project.proid ? project.proid : "-1") : "-1";
        let ueid = `${userData.uid}${userData.employeeid}`;
        return [
            {
                collection: `Projects/${proid}/data`,
                // orderBy: [['sectionId', 'asc']],
                storeAs: "ProjectPredictions",
            },
            {
                collection: `AnalysisRequests`,
                where: [['proid', '==', proid]],
                orderBy: [['timeReqGen', 'desc']],
                storeAs: "AnalysisRequests",
                limit: 1
            },
            {
                collection: `Projects/${proid}/data`,
                orderBy: [['initIndex', 'asc']],
                storeAs: "DistressProjectPredictions",
            },
            {
                collection: `Projects/${proid}/coordinates`,
                // orderBy: [['sectionId', 'asc']],
                storeAs: "VisualizerProjectPredictions",
            },
            {
                collection: "Projects",
                where: [['proid', '==', proid], ['coid', '==', userData?.coid]],
                // where: [['proid', '==', proid], ['accessList', 'array-contains', ueid]],
                storeAs: "DrProject",
            },
            {
                collection: "Notifications",
                where: [['proid', '==', proid]],
                // where: [['seachables', 'in', [userData?.uid??'-1', proid]]],
                orderBy: [['dateCreated', 'desc']],
                storeAs: "ProjNotifs",
                limit: 10
            }
        ];
    }), firebaseConnect((props) => {
        const { project, drProjectData } = props;
        const proid =
            project !== undefined ? (project.proid ? project.proid : "-1") : "-1";
        const forkedProid = drProjectData !== undefined ? (drProjectData.forkedFrom ? drProjectData.forkedFrom : undefined) : undefined;
        return [
            `projectInferenceData/${drProjectData?.proid}/cropb64List`,
            `GpsData/${forkedProid !== undefined ? forkedProid : drProjectData?.proid}/gps`,
            `ProjectGpsData/${proid}/gps`,
            `FilteredGpsData360/${drProjectData?.proid}/data`
        ]
    }),
)(ProjectDashboardController);


