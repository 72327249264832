import React from 'react'
import { Box, Grid } from "@mui/material";
import CustomProgress from '../../../../../../cidgets/CustomProgress'

const PreprocessingStep = (props) => {
  return (
    <Grid container >
      <Grid item xs={12} style={{ margin: '40px' }}>
        <div style={{ backgroundColor: '', height: 'calc(100vh - 20vh - 80px)', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
          <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', backgroundColor: '' }}>
            <Box
              sx={{
                padding: '30px',
                minWidth: '500px',
                maxWidth: '1000px',
                width: '100vw',
                height: 'fit-content',
                backgroundColor: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // cursor: 'pointer',
                border: '2px solid grey',
                borderRadius: '20px',
                color: 'grey'
              }}
            // onClick={acceptedFiles.length > 0 ?null:open}
            >
              <div style={{ height: 'calc(100% - 4px)', width: '100%', backgroundColor: 'transparent' }}>
                <Grid container spacing={0}>
                  <Grid xs={12} sx={{ height: 'calc(100% - 4px)', backgroundColor: 'transparent', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CustomProgress text={"We are preprocessing the video"} />
                  </Grid>
                </Grid>
              </div>
            </Box>
          </div>
        </div>
      </Grid>

    </Grid>
  )
}

export default PreprocessingStep;
