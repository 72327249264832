import SimpleDialogBox from '../../../cidgets/SimpleDialogBox';
import { useState } from 'react';
import { Box, TextField, Typography, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Paper, CircularProgress, TablePagination, Divider } from '@mui/material';
const ControlRoomDetailsBox = ({ data, open, handleClose, isQueued, formatDate, projectType, status, priority }) => {



    const crashedError = data?.message;
    const [page, setPage] = useState(0);
    const { sectionInformation, projectType: currentProjectType } = data;
    const isSectionInformationValid = sectionInformation && Object.keys(sectionInformation).length > 0;
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    return (
        <SimpleDialogBox
            open={open}
            onClose={handleClose}
            title={data.projectTitle}
            width={750}
            showError={true}
            errorMessage={crashedError !== '' && crashedError}
            bodyWidget={
                <>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={{ width: "107px", textAlign: 'left', }}>Project ID: </Typography>
                        <TextField disabled value={data.proid} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            width: "230px",
                            marginLeft: "10px"
                        }} size='small' />
                        <Typography variant='body1'
                            sx={{ width: "70px", marginLeft: '15px', textAlign: 'left', padding: "2px" }}
                        >Priority:</Typography>
                        <TextField disabled value={priority[data.priority] || "not specified"} sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            width: "230px",
                            marginLeft: '10px',
                        }} size='small' />

                    </Box>
                    <Box display="flex" alignItems="center" sx={{ marginTop: '10px', textAlign: 'left', }}>
                        <Typography
                            sx={{ width: "107px" }}
                            variant="body1">Creator Name:</Typography>
                        <TextField disabled value={data.creatorName}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                                width: "230px",
                                marginLeft: '10px'
                            }}
                            size='small' />
                        {!isQueued && (
                            <>
                                <Typography variant='body1' sx={{ width: "70px", marginLeft: '15px', textAlign: 'left', padding: "2px" }}>
                                    Sections:
                                </Typography>
                                {sectionInformation && (
                                    <TextField
                                        disabled
                                        value={sectionInformation.sectionCount}
                                        sx={{
                                            marginLeft: '10px',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                            width: "230px",
                                        }}
                                        size='small'
                                    />
                                )}
                            </>
                        )}

                    </Box>
                    <Box display="flex" alignItems="center" sx={{ marginTop: '10px' }}>
                        <Typography variant="body1">Request Generated Time:</Typography>
                        <TextField disabled value={
                            formatDate(data.requestTime)
                        } sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            width: "230px",
                            marginLeft: '10px'
                        }} size='small' />
                    </Box>
                    {isQueued ? (
                        <>
                            <Box sx={{ marginTop: "10px" }}>
                                <Divider light sx={{ marginTop: "10px" }} />
                                <Typography variant="h6" sx={{ fontSize: "20px", marginTop: "10px" }}>Resource Details</Typography>
                                <Box display="flex" alignItems="center" sx={{ marginTop: "10px" }}>
                                    <Typography variant="body1" sx={{ width: "50px", textAlign: 'left' }}>CPU:</Typography>
                                    <TextField disabled value={data.CPU ? data.CPU : "Not Calculated"}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                            width: "230px",
                                            marginLeft: '10px'
                                        }}
                                        size='small' />
                                    <Typography variant='body1'
                                        sx={{ width: "70px", marginLeft: '15px', textAlign: 'left', }}
                                    >DISK: </Typography>
                                    <TextField disabled value={data.DISK ? data.DISK : "Not Calculated"} sx={{
                                        marginLeft: '10px',
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                        width: "230px",
                                    }} size='small' />
                                </Box>
                                <Box display="flex" alignItems="center" sx={{ marginTop: "10px" }}>
                                    <Typography variant="body1"
                                        sx={{ width: "50px", textAlign: 'left' }}
                                    >VM:</Typography>
                                    <TextField disabled value={data.VM ? data.VM : "Not Calculated"}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                            width: "230px",
                                            marginLeft: '10px'
                                        }}
                                        size='small' />
                                    <Typography variant='body1'
                                        sx={{ width: "70px", marginLeft: '15px', textAlign: 'left', }}
                                    >Memory: </Typography>
                                    <TextField disabled value={data.MEMORY ? data.MEMORY : "Not Calculated"} sx={{
                                        marginLeft: '10px',
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                        width: "230px",
                                    }} size='small' />
                                </Box>
                            </Box>

                        </>
                    ) : (
                        <div>
                            {isSectionInformationValid ? (
                                currentProjectType == 5 ? (
                                    // PROJECT TYPE BASIC
                                    <>
                                        <Box display="flex" alignItems="center" sx={{ marginTop: "10px" }}>
                                            <Divider light />
                                            <Typography variant="h6">Section Details</Typography>
                                        </Box>
                                        <Box sx={{ marginTop: "10px" }}>
                                            <TableContainer component={Paper} sx={{ height: "100%" }}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white", zIndex: 1000 }}>Section Index</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white", zIndex: 1000 }}>Masking</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white", zIndex: 1000 }}>Post Processing</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {sectionInformation.PPS.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sec, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {page * rowsPerPage + index}
                                                                </TableCell>
                                                                <TableCell>{status[sectionInformation.MES[index]]}   </TableCell>
                                                                <TableCell>{status[sec]}</TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={sectionInformation.PPS ? sectionInformation.PPS.length : 0}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Box>
                                    </>
                                ) : (
                                    // PROJECT TYPE OTHER THAN BASIC
                                    <>
                                        <Box display="flex" alignItems="center" sx={{ marginTop: "10px" }}>
                                            <Divider light />
                                            <Typography variant="h6">Section Details</Typography>
                                        </Box>
                                        <Box sx={{ marginTop: "10px" }}>
                                            <TableContainer component={Paper} sx={{ height: "100%" }}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white", zIndex: 1000 }}>Section Index</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white", zIndex: 1000 }}>Reconstruction</TableCell>
                                                            <TableCell sx={{ fontWeight: 'bold', fontSize: "16px", backgroundColor: "white", zIndex: 1000 }}>Post Processing</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {sectionInformation.IMS.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sec, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {page * rowsPerPage + index}
                                                                </TableCell>
                                                                <TableCell>{status[sectionInformation.SRS[index]]}   </TableCell>
                                                                <TableCell>{status[sec]}</TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={sectionInformation.IMS ? sectionInformation.IMS.length : 0}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Box>
                                    </>
                                )
                            ) : (
                                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "100%", marginTop: "30px", marginBottom: "30px" }}>
                                    <Typography variant="h6">No Section Information Available</Typography>
                                </Box>

                            )}
                        </div>
                    )}
                </>

            }


        />
    );
}

export default ControlRoomDetailsBox;