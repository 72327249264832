import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, IconButton, Toolbar, Typography, Divider, Card, Stack, MenuItem, styled, Paper, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageAnnotatorV2 from "../../../project/surfaceEvaluationComponents/ImageAnnotatorV2"
import DistressFieldsAdd from '../../../project/surfaceEvaluationComponents/DistressFieldsAdd';
import SmartUtils from '../../../../../../helpers/SmartUtils';
import DistressAddTopbar from '../../../project/surfaceEvaluationComponents/DistressAddTopbar';
import { connect } from 'react-redux';
import { addDistress } from '../../../../../../store/actions/projectActions';

const AddNewDistress = (props) => {
    const {drProjectData, onClose, handleCloseDialog, proid, distressPinned, currentSection, distressData, imageUrl1, imageUrl2, imageHeight, imageWidth, drawMap, editBox, dataInfo } = props;
    const numHeightOfHeader = 8;
    const numHeightOfFooter = 8;
    const customPadding = 15;
    const overallCustomBorderPadding = 16;

    const [topbarHeaderHeight, setTopbarHeaderHeight] = useState({ height: 1, width: 1 })
    const headerRef = useRef(null);
    const headerHeight = headerRef?.current?.clientHeight ?? 0;
    const su = new SmartUtils(); const fieldAreaMargin = 20;
    const editFormHeight = 50;
    const startTime = 0, fps = 24;
    // //////console.log(distressPinned?.transversePosition, 'hehehe')
    const assetClassesWhereNameWillNotShow = ['', '0', '2', '3', '4', '998', '999'];
    const cardStyle = {
        "border": "2px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#f5f1f1'
    }
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));

    const fieldTextStyle = {
        fontSize: '15px',
        fontWeight: 'bold',
    }
    /*
        const [fieldValues, setFieldValues] = useState({
            defectNameState: distressPinned?.defectName ?? -1,
            transversePositionState: distressPinned?.transversePosition ?? -1,
            gpsCoordinatesState: `${distressPinned?.geo[0]},${distressPinned?.geo[1]}` ?? -1,
            typeState: distressPinned?.type ?? -1,
            severityState: distressPinned?.severity ?? -1,
            frameNumberState: distressPinned?.frameNumber ?? -1,
            areaState: distressPinned?.area ?? -1,
            depthState: distressPinned?.depth ?? -1,
            transverseSpanState: distressPinned?.transverseSpan ?? -1,
            longitudinalSpanState: distressPinned?.longitudinalSpan ?? -1,
            profileState: distressPinned?.profile ?? -1,
            widthState: distressPinned?.width ?? -1,
            wheelPathState: distressPinned?.wheelPath ?? -1,
        });
    */
    const [fieldValues, setFieldValues] = useState({
        defectNameState: '',
        typeState: '',
        transversePositionState: '',
        wheelPathState: '',
    });

    const newBbox = [
        112,
        118,
        200,
        200,
        'rgba(0, 255, 0, 0.1)',
    ];

    // //////console.log(newBbox, 'job')

    const [Bbox, setBbox] = React.useState([{
        x: newBbox[0],
        y: newBbox[1],
        width: newBbox[2],
        height: newBbox[3],
        fill: 'rgba(0, 255, 0, 0.1)'
    }]);
    const [isBboxReset, setIsBboxReset] = useState(false);
    const handleResetBbox = () => {
        setIsBboxReset(true);
        setBbox([{
            x: newBbox[0],
            y: newBbox[1],
            width: newBbox[2],
            height: newBbox[3],
            fill: 'rgba(0, 255, 0, 0.1)'
        }]);
        // setFieldValues({
        //     defectNameState: distressPinned?.defectName ?? -1,
        //     typeState: distressPinned?.type ?? -1,
        // });
    }

    useEffect(() => {
        // //////console.log(headerRef.current.clientHeight, "headerRef.current.clientHeight)")
        window.addEventListener("load", setTopbarHeaderHeight(headerRef.current.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, []);
    const getVidWindowSize = () => {
        const newHeight = headerRef?.current?.clientHeight;
        const newWidth = headerRef?.current?.clientWidth;
        setTopbarHeaderHeight({
            height: newHeight,
            width: newWidth
        });
    };


    const handleAddDataSubmit = () => {

        let defectProperties = {};
        const sectionId = `${currentSection.sectionId}`;
        // const detectionId = `${distressPinned.defectId}`;
        const bbox = JSON.stringify({ x: Bbox[0].x, y: Bbox[0].y, w: Bbox[0].width, h: Bbox[0].height });
        const defectClass = fieldValues.defectNameState ? Number(fieldValues.defectNameState) : -1;
        const transversePosition = Number(fieldValues.transversePositionState);

        if (defectClass === 0) {
            // //////console.log(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties), "data123")
            props.addDistress(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties));
        }
        else if (defectClass === 1) {
            defectProperties = {
                type: Number(fieldValues.typeState)
            }
            // //////console.log(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties), "data456")
            props.addDistress(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties));
        }
        else if (defectClass === 2) {
            defectProperties = {
                profile: Number(fieldValues.profileState)
            }
            // //////console.log(proid, sectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.addDistress(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties));
        }
        else if (defectClass === 3) {
            defectProperties = {
                severity: Number(fieldValues.severityState)
            }
            // //////console.log(proid, sectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.addDistress(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties));
        }
        else if (defectClass === 4) {
            defectProperties = {
                wheelPath: Number(fieldValues.wheelPathState)
            }
            // //////console.log(proid, sectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.addDistress(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties));
        }
        else if (defectClass === 5) {
            // //////console.log(proid, sectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.addDistress(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties));
        }
        else if (defectClass === 10) {
            defectProperties = {
                severity: Number(fieldValues.severityState)
            }
            // //////console.log(proid, sectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.addDistress(proid, sectionId, bbox, defectClass, transversePosition, JSON.stringify(defectProperties));
        }
        else {
            alert('Select a distress!')
        }
    }

    const handleBoxChange = (newValue) => {
        // //////console.log(newValue[0][0], "bbox coordinates1");
        setBbox([{
            x: newValue[0][0],
            y: newValue[0][1],
            width: newValue[0][2],
            height: newValue[0][3],
            fill: 'rgba(0, 255, 0, 0.1)'
        }]);
    };

    const cardWhiteStyle = {
        "borderRadius": "15px",
    }

    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#E1E3E2' }}>

            <Grid container spacing={0} sx={{ height: `calc(100vh - ${0}px)` }}>
                <Grid item xs={6} sm={7} md={7} lg={6} xl={6} sx={{ backgroundColor: 'transparent' }}>

                    <div ref={headerRef}>
                        <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
                            <DistressAddTopbar proid={proid} handleResetBbox={handleResetBbox}
                                handleCloseDialogDistress={onClose} handleCloseDialog={handleCloseDialog}
                                handleAddDataSubmit={handleAddDataSubmit}
                            />
                        </div>
                    </div>

                    <div style={{ height: `calc(100vh - ${headerHeight}px)`, backgroundColor: 'transparent' }}>

                        <div style={{ height: `${editFormHeight}%`, backgroundColor: 'transparent', padding: su._createPadding(overallCustomBorderPadding, 'trbl') }}>
                            <div style={{ height: '100%', backgroundColor: '' }}>
                                <Card sx={{ ...cardWhiteStyle, height: '100%', overflow: 'auto', padding: su._createPadding(10, 'trbl') }}>
                                    <DistressFieldsAdd drProjectData={drProjectData} fieldValues={fieldValues} setFieldValues={setFieldValues} distressPinned={distressPinned} dataInfo={dataInfo} distressData={distressData} />
                                </Card>
                            </div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={6} sm={5} md={5} lg={6} xl={6} sx={{ backgroundColor: 'transparent' }}>
                    <div style={{ padding: SmartUtils.edgeInsets(overallCustomBorderPadding, 'tbr'), height: '100%', backgroundColor: 'transparent', }}>
                        <Card sx={{ borderRadius: SmartUtils.edgeInsets(15, 'tblr'), height: '100%' }}>
                            <Grid item xs={12} style={{ height: `calc(100vh - ${overallCustomBorderPadding * 2}px)`, backgroundColor: 'transparent', overflow: '' }}>
                                <Grid container spacing={0} sx={{ height: '100%' }}>
                                    <Grid item xs={6} sx={{ backgroundColor: 'transparent' }}>
                                        <ImageAnnotatorV2 imageUrl={imageUrl1} imageHeight={imageHeight} imageWidth={imageWidth} isEdit={true} isBboxReset={isBboxReset} setIsBboxReset={setIsBboxReset} editableBbox={Bbox} setBbox={setBbox} drawMap={true} editBox={true} onValueChange={(value) => handleBoxChange(value)} />

                                    </Grid>
                                    <Grid item xs={6}>
                                        <ImageAnnotatorV2 imageUrl={imageUrl2} imageHeight={imageHeight} imageWidth={imageWidth} drawMap={false} editBox={false} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div >
    );
}

const mapStateToProps = (state) => {
    // //////console.log(state.datastore, "datastore")
    return {
        datastore: state.datastore,
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        addDistress: (proid, sectionId, bbox, defectClass, transversePosition, defectProperties) => dispatch(addDistress(proid, sectionId, bbox, defectClass, transversePosition, defectProperties))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewDistress);