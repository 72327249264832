import Grid from "@mui/material/Grid";
import Error from "../../../../../cidgets/Error";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import { Typography, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import countryList from 'country-list-js';
// import { Select, MenuItem } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
let countries = Object.values(countryList.all).sort((a, b) => a.name.localeCompare(b.name));

const CardEditingForm = (props) => {
  const useStyles = makeStyles((theme) => ({
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    Heading: {
      fontWeight: "500",
    },
    paymentContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(3),
    },

  }));
  const { companyDefaultPaymentMethod, companyData, payment, userData } = props;
  const { name, address, } = companyDefaultPaymentMethod.billing_details;
  const { postal_code, line1, line2, city, country } = address;
  const countryObj = countries.find((e) => e.iso2 === country);
  const { exp_month, exp_year } = companyDefaultPaymentMethod.card;
  // //////console.log(companyDefaultPaymentMethod.card,"comapany")
  const [expMonth, setexpMonth] = useState(exp_month ?? "");
  const [expYear, setexpYear] = useState(exp_year ?? "");
  const [accountTitle, setaccountTitle] = useState(name ?? "");
  const [countryName, setCountry] = useState(countryObj ?? null);
  const [cityName, setCity] = useState(city ?? "");
  const [addressLine1, setaddressLine1] = useState(line1 ? line1 : "");
  const [addressLine2, setaddressLine2] = useState(line2 ? line2 : "");
  const [postal, setpostal] = useState(postal_code ?? "");
  const [pmid, setPmid] = useState(companyDefaultPaymentMethod.id ?? "");
  const [error, setError] = useState(companyDefaultPaymentMethod.id ?? "");
  const [billingDetails, setBillingDetails] = useState(companyDefaultPaymentMethod.billing_details ?? "");
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  // validation
  const [accountTitleError, setAccountTitleError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [expMonthError, setExpMonthError] = useState(false);
  const [expYearError, setExpYearError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorField, setErrorField] = useState(false);
  const [addressLine1Error, setAdressLine1Error] = useState(false);
  const [addressLine2Error, setAdressLine2Error] = useState(false);
  const [postalError, setPostalError] = useState(false);
  const handleSubmit = async (event) => {
    // //////console.log("CARD EDIT SUBMIT");
    // validation

    // //////console.log(exp_year);
    // //////console.log(accountTitle.length, expYear.length);
    if (accountTitle.length === 0 || accountTitle.length > 64) {
      setAccountTitleError(true);
      setErrorField(true);
      return;
    }
    // else if (country.length === 0) {
    //   setCountryError(true);
    //   setErrorField(true);
    //   return;
    // }
    else if (expMonth < currentMonth + 1 && expYear === currentYear) {
      setErrorField(true);
      setExpMonthError(true);
      return;
    }

    else if (addressLine1.length === 0 || addressLine1.length > 120) {
      setAdressLine1Error(true);
      setErrorField(true);
      return;
    }
    else if (addressLine2.length === 0 || addressLine2.length > 120) {
      setAdressLine2Error(true);
      setErrorField(true);
      return;
    }
    else if (postal.length === 0 || postal.length > 10) {
      setPostalError(true);
      setErrorField(true);
      return;
    }
    else {
      setErrorField(false);
      setAccountTitleError(false);
      setAdressLine1Error(false);
      setAdressLine2Error(false);
      setCountryError(false);
      setExpMonthError(false);
      setExpYearError(false);
      setPostalError(false);

    }
    // Block native form submission.
    event.preventDefault();
    const { coid } = companyData;
    var accountAddress = { ...billingDetails.address };
    accountAddress["city"] = city;
    accountAddress["line1"] = addressLine1;
    accountAddress["line2"] = addressLine2;
    accountAddress["postal_code"] = postal;
    accountAddress["country"] = countryName.iso2;
    var isExpiryGood = Number.parseInt(expMonth) > 0 && Number.parseInt(expMonth) < 13;
    if (isExpiryGood) {
      setError("");
      var cardDetailsObject = {
        stripePmid: pmid,
        expMonth: expMonth,
        expYear: expYear,
        accountTitle: accountTitle,
        accountAddress: accountAddress,
      };

      // //////console.log(cardDetailsObject, "card detail");
      props.changeCardDetailsOnStripe(cardDetailsObject, coid);
    } else {
      setError("Please enter correct expiry date");
    }
  };
  const { isPaymentMethodActed, isPaymentMethodActing, paymentMessage } =
    payment;
  const relevantComponent =
    // !isPaymentMethodActed ? (
    !isPaymentMethodActing ? (

      <Button
        variant="contained"
        onClick={handleSubmit}
        sx={{
          marginInline: '2px',
          textTransform: 'initial',
          backgroundColor: '#025196', ':hover': {
            backgroundColor: '#025196'
          }
        }}
      >
        <span>Update details</span>
      </Button>

    ) : (
      <Button
        variant="contained" disabled sx={{
          "&.Mui-disabled": {
            backgroundColor: '#025196',
            color: '#ffffff'
          },
          marginInline: '2px',
          textTransform: 'initial',
          color: '#025196',
          backgroundColor: '#025196', ':hover': {
            backgroundColor: '#025196'
          }
        }}
      >
        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Updating card...</span>
      </Button>
    )
  // ) : (
  //   <React.Fragment />
  // );

  const relevantMessage = isPaymentMethodActed ? paymentMessage : <Error errorMessage={paymentMessage} />
  // //////console.log(isPaymentMethodActed, "payment");
  return (
    <div>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" style={{ fontWeight: '550', fontSize: "15px" }}>
            Card holder's name
          </Typography>
          <TextField
            id="account title"
            helperText={accountTitleError ? "Maximum limit 64 character" : ""}
            type="text"
            variant="outlined"
            fullWidth
            error={accountTitleError}
            disabled
            value={accountTitle}
            onChange={(event) => { setaccountTitle(event.target.value); setAccountTitleError(false) }}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
            Country
          </Typography>
          <TextField
            select
            id="countrycode"
            variant="outlined"
            fullWidth
            value={countryName?.name ?? ''}
            onChange={(event) => setCountry(countries.find((e) => e.name === event.target.value))}
            // disabled={isPaymentMethodActing}
            disabled
            required
          >
            <MenuItem value="" disabled>
              Select a country
            </MenuItem>
            {countries.map((option, key) => (
              <MenuItem key={key} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>


        <Grid item sm={6} xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="h6" style={{ fontWeight: '550', fontSize: "15px" }}>
              Expiry month

            </Typography>
            <TextField
              select
              variant="outlined"
              id="expMonth"
              value={expMonth}
              onChange={(event) => {
                setexpMonth(event.target.value);
                setExpMonthError(false);
              }}
              disabled={isPaymentMethodActing}
              error={expMonthError}
              helperText={expMonthError ? "Please enter a valid expiry month" : ""}
            >
              {Array.from({ length: 12 }, (_, index) => index + 1).map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="h6" style={{ fontWeight: '550', fontSize: "15px" }}>
              Expiry year
            </Typography>
            <TextField
              select
              id="expYear"
              value={expYear}
              variant="outlined"
              onChange={(event) => { setexpYear(event.target.value); setExpYearError(false) }}
              disabled={isPaymentMethodActing}
              error={expYearError}
              helperText={expYearError ? 'Please enter a valid expiry year' : ''}
            >
              {Array.from({ length: 11 }, (_, index) => currentYear + index).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item sm={4} xs={6}>
          <Typography variant="h6" style={{ fontWeight: '550', fontSize: "15px" }}>
            Address line 1
          </Typography>
          <TextField
            id="address"
            type="text"
            variant="outlined"
            fullWidth
            disabled
            // disabled={isPaymentMethodActing}
            value={addressLine1}
            error={addressLine1Error}
            helperText={addressLine1Error ? 'Maximum limit 120 character' : ''}
            onChange={(event) => { setaddressLine1(event.target.value); setAdressLine1Error(false) }}
            required
          />
        </Grid>
        <Grid item sm={4} xs={6}>
          <Typography variant="h6" style={{ fontWeight: '550', fontSize: "15px" }}>
            Address line 2
          </Typography>
          <TextField
            id="address"
            type="text"
            variant="outlined"
            fullWidth
            error={addressLine2Error}
            value={addressLine2}
            helperText={addressLine2Error ? 'Maximum limit 120 character' : ''}
            disabled
            // disabled={isPaymentMethodActing}
            onChange={(event) => { setaddressLine2(event.target.value); setAdressLine2Error(false) }}
            required
          />
        </Grid>
        <Grid item sm={4} xs={6}>
          <Typography variant="h6" style={{ fontWeight: '550', fontSize: "15px" }}>
            Postal code
          </Typography>
          <TextField
            id="code"
            type="text"
            variant="outlined"
            fullWidth
            error={postalError}
            helperText={postalError ? 'Maximum limit 10 character' : ''}
            disabled
            // disabled={isPaymentMethodActing}
            value={postal}
            onChange={(event) => { setpostal(event.target.value); setPostalError(false) }}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '10px' }}>

        <Grid item xs={9}>
          {errorField ? <Error errorMessage={errorMessage} /> : <Typography variant="body2"> {relevantMessage} </Typography>}
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
          {relevantComponent}
        </Grid>
      </Grid>
    </div >
  );
}
export default CardEditingForm;