import React from "react";
import Grid from '@mui/material/Grid';
import { Typography, Card, Box, Divider } from "@mui/material";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import CustomProgress from '../../../../cidgets/CustomProgress'
import commonNames from '../../../../../constants/commonNames.json'
import releaseNotes from '../../../../../constants/releaseNotes.json'
import SmartUtils from "../../../../../helpers/SmartUtils";
const GeneralSettings = (props) => {
  const { userData, companyDefaultPaymentMethod, companyData } = props;
  const { organizationName, name, uid, countryName } = userData;


  if (companyDefaultPaymentMethod === undefined || companyDefaultPaymentMethod === null) {
    return <CustomProgress />
  }
  const { card, customer } = companyDefaultPaymentMethod;
  const { last4 } = card;
  const marginBottom = 20;
  const cardLightStyle = {
    "border": "0px solid grey",
    "borderRadius": "8px",
    "padding": "8px",
    "backgroundColor": '#f5f1f1'
    // "backgroundColor": '#f5f1f1'
  }
  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: '2px' }}>
        <Grid item xs={8}>

          <Grid item xs={12} sx={{ marginBottom: `${marginBottom}px` }}>
            <Typography style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 'bold' }}>Card holder's name:</span> {name ?? ""}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: `${marginBottom}px` }}>
            <Typography style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 'bold' }}>Organization name:</span> {organizationName ?? ""}
            </Typography>
          </Grid>

          {/* <Grid item xs={12} sx={{ marginBottom: `${marginBottom}px` }}>
            <Typography style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 'bold' }}>User ID:</span> {uid ?? ""}
            </Typography>
          </Grid> */}

          <Grid item xs={12} sx={{ marginBottom: `${marginBottom}px` }}>
            <Typography style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 'bold' }}>Card number:</span> ************{last4 ?? ""}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: `${marginBottom}px` }}>
            <Typography style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 'bold' }}>Country:</span> {countryName ?? ''}
            </Typography>
          </Grid>



          <Grid item xs={12} sx={{ marginBottom: `${marginBottom}px` }}>
            <Typography style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 'bold' }}>{commonNames.Credits} purchased:</span> {Number(companyData.totalCreditsPurchased).toFixed(2) ?? 0}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: `${marginBottom}px` }}>
            <Typography style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 'bold' }}>{commonNames.Credits} consumed:</span> {Number(companyData.totalCreditsConsumed).toFixed(2) ?? 0}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Card sx={{ ...cardLightStyle }} elevation={0}>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: SmartUtils.edgeInsets(10, 'tlr') }}>
              {/* <Typography variant="h7" sx={{ fontSize: '20px', ml: 2 }}> */}
              <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                ✅ Things to know
              </Typography>
            </Box>
            <Box sx={{ padding: SmartUtils.edgeInsets(10, 'tb') }}>
              <ul style={{ fontSize: '14px' }}>
                <li> You shoud buy {releaseNotes.name} credits in advance, which are also simply called credits</li>
                <li>Each credit costs &#163;1 (GBP)</li>
                <li>These credits are used to analyze your survey videos</li>
              </ul>
            </Box>
          </Card>
        </Grid>

      </Grid>
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    companyData: state.firestore.data.Company,
    themeColors: state.theme,
    userData: state.firebase.profile,
    userId: state.firebase.auth.uid,
    companyDefaultPaymentMethod: state.firestore.data.PaymentMethod,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // updateButton: (organizationName, name, countryName, uid) => dispatch(updateButton(organizationName, name, countryName, uid))

  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    // var { stripeDefaultPmid, coid } = props.companyData ?? {};
    var stripeDefaultPmid = props.companyData?.stripeDefaultPmid ?? "-1"
    const coid = props.companyData?.coid ?? "-1"
    stripeDefaultPmid = stripeDefaultPmid
      ? stripeDefaultPmid === ""
        ? "-1"
        : stripeDefaultPmid
      : "-1";
    // //////console.log(stripeDefaultPmid);
    return [
      {
        collection: `PaymentMethods/${coid}/StripePaymentMethods`,
        storeAs: "PaymentMethod",
        doc: stripeDefaultPmid,
      },
    ];
  })
);
export default enhance(GeneralSettings);
