import { useParams } from "react-router-dom";
import ProjectDashboardController from "./ProjectDashboardController";
const ProjectDashboardWrapper = () => {
    const { proid, sectionId } = useParams();
    return (
        <>
            <ProjectDashboardController proid={proid} secId={sectionId} />
        </>
    );


}

export default ProjectDashboardWrapper;