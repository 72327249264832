import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { sendVerificationEmail, signOut } from '../../../../store/actions/authActions';
import { Box, CircularProgress } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Card, Stack } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import newlogo from "../../../../assets/bg/roadGaugeLogo2.png";
// import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import { useLocation, Navigate } from 'react-router-dom';
import { db } from "../../../../store/LocalDatabase";

// Function to retrieve the access code
export const getAccessCode = async () => {
    try {
        const result = await db.accessCodes.toArray();
        return result.length > 0 ? result[0].accessCode : null;
    } catch (error) {
        console.error('Error getting access code:', error);
        return null;
    }
};

const VerifyEmailPage = (props) => {
    const [accessCode, setAccessCode] = useState(null)
    const { isSignedIn, userData, auth } = props;
    const { emailVerificationLoader } = auth;
    const { name } = userData
    const location = useLocation();

    useEffect(() => {
        const fetchAccessCode = async () => {
            const id = await getAccessCode();
            setAccessCode(id);
        };

        fetchAccessCode();
    }, [])

    const [snackbarState, setSnackbarState] = React.useState({
        openSnackbar: false,
        vertical: 'bottom',
        horizontal: 'right',
    });

    const { vertical, horizontal, openSnackbar } = snackbarState;

    const handleClickSnackbar = (newState) => {
        setSnackbarState({ openSnackbar: true, ...newState });
    };

    const handleCloseSnackbar = () => {
        setSnackbarState({ ...snackbarState, openSnackbar: false });
    };

    const resendEmail = () => {
        props.sendVerificationEmail();
        // handleClickSnackbar({
        //     vertical: 'bottom',
        //     horizontal: 'right',
        // })
        // //////console.log("h")
    }

    const styles = {
        verifyBtn: {
            backgroundColor: 'red',
            textTransform: 'none',
            cursror: 'pointer',
            padding: '5px 10px',
            borderRadius: '20px'
        },
        signinBtn: {
            backgroundColor: 'green',
            textTransform: 'none',
            padding: '4px 9px',
            borderRadius: '14px',
            marginLeft: '15px'
        },
        clickHere: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        reloadClick: {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
    if (!isSignedIn) {
        return <Navigate to="/a/login" state={{ from: location }} replace />;
    }
    else if (props.isSignedIn && props.isEmailVerified) {
        return <Navigate to="/home/dashboard" state={{ from: location }} replace />;
    }
    else if (props.isSignedIn && !props.isEmailVerified) {
        return (
            // <div style={{ height: `100vh`, backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            //     <Stack>
            //         <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
            //             <img
            //                 src={newlogo}
            //                 alt="logo"
            //                 width="130px"
            //                 height="auto"
            //             />
            //         </div>
            //         <div>
            //             <Card elevation={3} sx={{ display: 'block' }}>
            //                 <Box style={{ padding: '20px' }}>
            //                     <Box>
            //                         <Typography variant="h6" color="initial">Hi - {name};</Typography>
            //                         <Typography variant="body1" color="initial" style={{ marginTop: '12px' }}>
            //                            We have sent you a verification email. Check your inbox.
            //                         </Typography>
            //                         <div style={{ marginTop: '20px' }}>
            //                             <span style={{ marginTop: '8px', display: 'block' }}>
            //                                 Once you have completed the verification process, <span style={styles.reloadClick} onClick={() => { window.location.reload() }}>click on this link</span>
            //                             </span>
            //                             <span>
            //                                 In case you have not received any email yet, <span style={styles.clickHere} 
            //                                 onClick={resendEmail}>click on this link</span>
            //                             </span>

            //                             <span style={{ marginTop: '20px', display: 'block' }}>
            //                                 <span style={styles.reloadClick} onClick={() => { props.signOut() }}>Click on this link</span> to sign out
            //                             </span>
            //                         </div>

            //                     </Box>
            //                 </Box>
            //                 <div>
            //                     <Snackbar
            //                         anchorOrigin={{ vertical, horizontal }}
            //                         open={openSnackbar}
            //                         onClose={handleCloseSnackbar}
            //                         message='Link has been sent again.'
            //                         key={vertical + horizontal}
            //                         autoHideDuration={6000}

            //                     />
            //                 </div>
            //             </Card >
            //         </div>
            //     </Stack>




            // </div>
            <div style={{ height: `100vh`, backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Stack>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
                        <img
                            src={newlogo}
                            alt="logo"
                            width="130px"
                            height="auto"
                        />
                    </div>
                    <div style={{ textAlign: 'center', margin: '20px' }}>
                        <Typography variant="h5" style={{ marginBottom: '10px' }}>
                            Verification email sent
                        </Typography>
                        <Typography style={{ marginBottom: '20px' }}>
                            We have sent you an email to verify your account. Please check your inbox and follow the instructions in the
                            email to complete the verification process.
                        </Typography>
                        <Button variant="contained" sx={{
                            color: 'white', backgroundColor: '#025196', textTransform: 'initial',
                            '&:hover': {
                                color: 'white', backgroundColor: '#025196'
                            }
                        }} onClick={() => { window.location.reload() }} style={{ marginRight: '10px' }}>
                            Refresh Page
                        </Button>
                        {emailVerificationLoader ?
                            <>
                                <Button variant="contained" disabled sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                    "&.Mui-disabled": {
                                        backgroundColor: '#025196',
                                        color: '#ffffff'
                                    }
                                }}>
                                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Sending...</span>
                                </Button>
                            </>
                            :

                            <Button size="medium" variant="contained"
                                onClick={resendEmail}
                                sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#FFFFFF'
                                    }
                                }}>Resend Verification Email</Button>
                        }
                        {/* <Button variant="contained" sx={{
                            color: 'white', backgroundColor: '#041256', textTransform: 'initial',
                            '&:hover': {
                                color: 'white', backgroundColor: '#041256'
                            }
                        }} onClick={resendEmail} style={{ marginRight: '10px' }}>
                            Resend Verification Email
                        </Button> */}
                        <Button variant="contained" sx={{
                            marginLeft: '10px',
                            color: 'white', backgroundColor: '#025196', textTransform: 'initial',
                            '&:hover': {
                                color: 'white', backgroundColor: '#025196'
                            }
                        }} onClick={userData.linkedToUid ? () => { props.signOut(true, userData.uid, accessCode) } : () => { props.signOut() }}>
                            Sign Out
                        </Button>
                        <div>
                            <Typography variant="caption" style={{ marginTop: '20px' }}>
                                Didn't receive the email? Make sure to check your spam folder.
                            </Typography>
                        </div>
                    </div>
                </Stack>
                <div>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={openSnackbar}
                        onClose={handleCloseSnackbar}
                        message='Link has been sent again.'
                        key={vertical + horizontal}
                        autoHideDuration={6000}

                    />
                </div>
            </div>

        )
    } else {
        // return <Redirect to={ROUTES.LANDING} />;
    }
}

const mapStateToProps = (state) => {
    // //////console.log(state)
    return {
        isSignedIn: !state.firebase.auth.isEmpty,
        isEmailVerified: state.firebase.auth.emailVerified,
        userAuth: state.firebase.auth,
        theme: state.themeRed,
        userData: state.firebase.profile,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendVerificationEmail: () => dispatch(sendVerificationEmail()),
        signOut: (uidRequired, uid, accessCode) => dispatch(signOut(uidRequired, uid, accessCode)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPage);