import { Grid, Card } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SurfaceEvaluationTopbar from "./surfaceEvaluationComponents/SurfaceEvaluationTopbar";
import SurfaceEvaluationVideoPlayer from './subpages/afterInference/SurfaceEvaluationVideoPlayer';
import SmartUtils from '../../../../helpers/SmartUtils';
import CustomProgress from '../../../cidgets/CustomProgress';
import SegmentShifterV2 from './surfaceEvaluationComponents/SegmentShifterV2';

import DistressMap from './surfaceEvaluationComponents/DistressMap';
import { analysisSetting, depthCrossSectionalProfile, getDownSamplingData, saveDepthCrossSectionalPoints } from "../../../../store/actions/projectActions";
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const RATE = 0.1
const AfterSurfaceEvaluationInferenceDashboardV3 = (props) => {
    const { secId } = useParams();
    let navigate = useNavigate();
    const { proid, drProjectData, distressProjectPredictions, link } = props;

    // const { predictionGpsDataList, datastore, project } = props;
    const { dimensions } = drProjectData;
    const { height, width } = dimensions;
    const [sections, setSections] = React.useState(0);
    const [isWholeDistressVisualization, setIsWholeDistressVisualization] = useState(false);
    const handleDataVisualizationChange = (event) => {
        setIsWholeDistressVisualization(event.target.checked);
    };

    const [crossSectionalPoints, setCrossSectionalPoints] = useState([width * 0.5, height * 0.5, width * 0.8, height * 0.8]);
    const [defaultCrossSectionalPoints, setDefaultCrossSectionalPoints] = useState([width * 0.5, height * 0.5, width * 0.8, height * 0.8]);

    const [isDepthCrossSectional, setIsDepthCrossSectional] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [segmentIndex, setSegmentIndex] = useState(0)
    const [pinned, setPinned] = useState(null);

    const [sectionLength, setSectionLength] = useState(10);
    const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 });
    const su = new SmartUtils();
    const overallCustomBorderPadding = 16;
    const cardWhiteStyle = {
        "borderRadius": "15px",
    }
    const [distressPinned, setDistressPinned] = useState(null);
    const realFps = 'fps' in drProjectData ? drProjectData.fps : 59.94005994005994
    const FPS = Number(realFps).toFixed(0);
    const originalVideoHeight = drProjectData?.dimensions?.height ?? 0
    const originalVideoWidth = drProjectData?.dimensions?.width ?? 0
    const headerRef = useRef();
    const headerHeight = headerRef?.current?.clientHeight ?? 0
    const videoSectionHeight = 50;
    const mapSectionHeight = 50;
    const videoRef = useRef();
    const playerRef = useRef(null);
    const getVidWindowSize = () => {
        const newHeight = videoRef?.current?.clientHeight;
        const newWidth = videoRef?.current?.clientWidth;
        setVidDivDimen({
            height: newHeight,
            width: newWidth
        });
    };
    useEffect(() => {
        window.addEventListener("load", setVidDivDimen(videoRef?.current === null ? 0 : videoRef?.current?.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, []);
    useEffect(() => {
        props.getDownSamplingData(proid);
    }, [])
    const handleDepthCrossSectional = () => {
        setIsDepthCrossSectional(true);
    }
    const handleCloseDepthCrossSectional = () => {
        setIsDepthCrossSectional(false);
    }
    const onCurrentTimeChange = (currentTime) => {
        setCurrentTime(Number(currentTime - 1 / FPS).toFixed(5))
    }
    const handlePrevFrame = () => {
        playerRef?.current?.replay(1 / FPS)
    }

    const handleNextFrame = () => {
        playerRef?.current?.forward(1 / FPS)
    }

    const handleSeekTo = (timeSeek) => {
        // console.log(timeSeek, 'check34')
        playerRef?.current?.seek(timeSeek)

    }

    const handlePinned = (data) => {
        //////console.log(data, 'check12')
        const check = data === null ? false : pinned === null ? false : data.sectionId === pinned.sectionId
        playerRef.current?.pause()
        if (data) {
            handleSeekTo((data.initIndex) / FPS)
        }
        // console.log(check, 'check121')
        // setPinned(check ? null : data)
    }

    // let gpsData = []
    let gpsDataCombined = [];
    let detectionsList = [];
    let predictions = Object.values(distressProjectPredictions);
    const defectNames = SmartUtils.getDefectNamesList(drProjectData.projectType);
    const [selectedDefects, setSelectedDefects] = useState(drProjectData.selectedDefects ? drProjectData.selectedDefects : []);
    const [conditionMethod, setConditionMethod] = useState(drProjectData.conditionMethod ? drProjectData.conditionMethod : drProjectData.projectType === 4 ? -1 : 0);

    useEffect(() => {
        props.analysisSetting(selectedDefects, conditionMethod, proid);
    }, [selectedDefects, conditionMethod]);
    // const [filteredGpsData, setFilteredGpsData] = useState(predictions);
    const [filteredGpsData, setFilteredGpsData] = useState(Object.values(distressProjectPredictions));
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    useEffect(() => {
        const predictionsArray = Object.values(distressProjectPredictions);
        setFilteredGpsData(predictionsArray);
        if (selectedDefects.length > 0) {
            handleGpsDataFilterChange({ target: { value: selectedDefects } });
        }
    }, [distressProjectPredictions]);


    const handleGpsDataFilterChange = (event) => {
        const selectedDefectIds = event.target.value;
        setSelectedDefects(selectedDefectIds);

        if (selectedDefectIds.length > 0) {
            const finalFilteredData = Object.values(distressProjectPredictions).map((section) => {
                const filteredDetections = Object.fromEntries(
                    Object.entries(section.detections).filter(
                        ([key, value]) => selectedDefectIds.includes(value?.versioning?.defectName ?? value.defectName)
                    )
                );

                return {
                    ...section,
                    detections: filteredDetections,
                };
            });

            setFilteredGpsData(finalFilteredData);
        }

        else {
            setFilteredGpsData(Object.values(distressProjectPredictions));
        }
        setIsFilterOpen(true);

    }



    const getSelectedDefectNames = () => {
        return defectNames
            .filter((defect) => selectedDefects.includes(defect.defectId))
            .map((defect) => defect.name);
    };


    filteredGpsData.sort((a, b) => {
        return a.endIndex - b.endIndex
    });
    // //////console.log(predictions, "section0x")
    Object.values(filteredGpsData).forEach((section) => {
        // //////console.log(section, 'sec123')
        detectionsList = [...detectionsList, Object.values(section.detections)];

        Object.values(section.detections).forEach((eachData) => {
            // //////console.log(eachData, 'eachdata12')
            gpsDataCombined = [...gpsDataCombined, { sectionId: section.sectionId, lat: eachData.lat, lng: eachData.lng, time: Date.now(), defectName: eachData.defectName, geoTime: (eachData.frameNumber / FPS).toFixed(5) }]
        })

    });

    // //////console.log(gpsDataCombined, 'gpsDataCombined')

    const doesGpsDataCombinedExists = gpsDataCombined?.length > 0
    const startEndLocation = {
        startLat: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lat : 0,
        startLng: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lng : 0,
        endLat: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lat : 0,
        endLng: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lng : 0,
    }


    const onSegmentChange = (segmentObj) => {
        playerRef?.current?.pause()
        const startTime = (segmentObj.initIndex) / FPS;
        playerRef?.current?.seek(startTime + 1 / FPS)
    }

    const handleSectionChange = (event) => {
        const sectionIndex = event.target.value;
        navigate(`/home/pr/${proid}/${sectionIndex}`);
        // //////console.log(sectionIndex, 'sectionIndex12')
        const currentSection = Object.values(filteredGpsData)[sectionIndex];
        const crossSectionalParameters = currentSection.crossSectionalParameters ?? false;
        const sectionId = currentSection['sectionId'];

        if (crossSectionalParameters) {
            const points = [Number(crossSectionalParameters['x1']), Number(crossSectionalParameters['y1']), Number(crossSectionalParameters['x2']), Number(crossSectionalParameters['y2'])]
            setCrossSectionalPoints(points);
            props.depthCrossSectionalProfile({
                x1: Number(crossSectionalParameters['x1']), y1: Number(crossSectionalParameters['y1']), x2: Number(crossSectionalParameters['x2']), y2: Number(crossSectionalParameters['y2'])
            }, proid, sectionId);
        }
        else {
            const defaultPoints = {
                x1: Number(defaultCrossSectionalPoints[0]), y1: Number(defaultCrossSectionalPoints[1]),
                x2: Number(defaultCrossSectionalPoints[2]), y2: Number(defaultCrossSectionalPoints[3])
            }
            setCrossSectionalPoints(defaultCrossSectionalPoints);
            props.depthCrossSectionalProfile(defaultPoints, proid, sectionId);
        }

        setSections(sectionIndex);
        setSegmentIndex(sectionIndex);
        handlePinned(Object.values(filteredGpsData)[sectionIndex]);
        onSegmentChange(Object.values(filteredGpsData)[sectionIndex]);
    };
    const handleSectionChangeMap = (sectionIndex) => {
        navigate(`/home/pr/${proid}/${sectionIndex}`);
        const currentSection = Object.values(filteredGpsData)[sectionIndex];
        const crossSectionalParameters = currentSection.crossSectionalParameters ?? false;
        const sectionId = currentSection['sectionId'];

        if (crossSectionalParameters) {
            const points = [
                Number(crossSectionalParameters['x1']),
                Number(crossSectionalParameters['y1']),
                Number(crossSectionalParameters['x2']),
                Number(crossSectionalParameters['y2'])
            ];
            setCrossSectionalPoints(points);
            props.depthCrossSectionalProfile(
                {
                    x1: Number(crossSectionalParameters['x1']),
                    y1: Number(crossSectionalParameters['y1']),
                    x2: Number(crossSectionalParameters['x2']),
                    y2: Number(crossSectionalParameters['y2'])
                },
                proid,
                sectionId
            );
        } else {
            const defaultPoints = {
                x1: Number(defaultCrossSectionalPoints[0]),
                y1: Number(defaultCrossSectionalPoints[1]),
                x2: Number(defaultCrossSectionalPoints[2]),
                y2: Number(defaultCrossSectionalPoints[3])
            };
            setCrossSectionalPoints(defaultCrossSectionalPoints);
            props.depthCrossSectionalProfile(defaultPoints, proid, sectionId);
        }

        setSections(sectionIndex);
        setSegmentIndex(sectionIndex);
        handlePinned(Object.values(filteredGpsData)[sectionIndex]);
        onSegmentChange(Object.values(filteredGpsData)[sectionIndex]);
    };


    return (
        <div style={{
            height: '100vh', width: '100vw',
            backgroundColor: '#E1E3E2',
        }}>
            <Grid container spacing={0} sx={{ height: `calc(100vh - ${0}px)` }}>
                <Grid item xs={4} sm={4} md={5} lg={6} xl={6} sx={{ backgroundColor: '' }}>
                    {/* heaader */}
                    <div ref={headerRef} style={{ backgroundColor: '' }}>
                        <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
                            <SurfaceEvaluationTopbar filteredGpsData={filteredGpsData} isFilterOpen={isFilterOpen}
                                selectedDefects={selectedDefects} handleGpsDataFilterChange={handleGpsDataFilterChange}
                                getSelectedDefectNames={getSelectedDefectNames} defectNames={defectNames}
                                startEndLocation={startEndLocation} proid={proid} drProjectData={drProjectData}
                                distressProjectPredictions={filteredGpsData} conditionMethod={conditionMethod}
                                setConditionMethod={setConditionMethod} sectionLength={sectionLength}
                                setSectionLength={setSectionLength} />
                        </div>
                    </div>
                    {/* video player & map */}
                    {headerHeight === 0 ? <CustomProgress /> :
                        <div style={{ height: `calc(100vh - ${headerHeight}px)`, backgroundColor: '' }}>
                            <div style={{ height: `${videoSectionHeight}%`, backgroundColor: '', padding: su._createPadding(overallCustomBorderPadding, 'trbl') }}>

                                <div ref={videoRef} style={{ height: '100%', backgroundColor: '' }}>
                                    <Card sx={{ ...cardWhiteStyle }}>
                                        <SurfaceEvaluationVideoPlayer
                                            onCurrentTimeChange={onCurrentTimeChange}
                                            playerRef={playerRef} defaultRate={RATE}
                                            startTime={filteredGpsData.length > 0 ? filteredGpsData[Number(secId ?? 0)] ? filteredGpsData[Number(secId ?? 0)]['initIndex'] / FPS :
                                                filteredGpsData[0]['initIndex'] / FPS : 0}
                                            fps={FPS} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} link={link}
                                            height={videoRef.current === null ? 0 : videoRef.current?.clientHeight}
                                            control={false} />


                                    </Card>
                                </div>

                            </div>
                            <div style={{ height: `${mapSectionHeight}%`, backgroundColor: '', padding: su._createPadding(overallCustomBorderPadding, 'rbl') }}>
                                <div style={{ height: '100%', backgroundColor: '' }}>
                                    <Card sx={{ ...cardWhiteStyle, height: '100%' }}>
                                        <DistressMap isWholeDistressVisualization={isWholeDistressVisualization} distressPinned={distressPinned} setDistressPinned={setDistressPinned}
                                            drProjectData={drProjectData} predictions={predictions}
                                            segmentIndex={segmentIndex} FPS={FPS}
                                            distressProjectPredictions={filteredGpsData}
                                            zoom={18}
                                            //   polyPoints={gpsData}
                                            handleSectionChangeMap={handleSectionChangeMap}
                                            pinned={pinned} handleSeekTo={handleSeekTo}

                                            handlePinned={handlePinned}
                                            conditionMethod={conditionMethod} />
                                    </Card>
                                </div>
                            </div>
                        </div>
                    }
                </Grid>
                <Grid item xs={8} sm={8} md={7} lg={6} xl={6} sx={{ backgroundColor: '' }}>
                    <div style={{ padding: SmartUtils.edgeInsets(overallCustomBorderPadding, 'tbr'), height: '100%' }}>
                        <Card sx={{ borderRadius: SmartUtils.edgeInsets(15, 'tblr'), height: '100%' }}>
                            <SegmentShifterV2
                                handleSeekTo={handleSeekTo}
                                isWholeDistressVisualization={isWholeDistressVisualization}
                                handleDataVisualizationChange={handleDataVisualizationChange}
                                distressPinned={distressPinned}
                                setDistressPinned={setDistressPinned}
                                setPinned={setPinned}
                                isDepthCrossSectional={isDepthCrossSectional}
                                handleDepthCrossSectional={handleDepthCrossSectional}
                                handleCloseDepthCrossSectional={handleCloseDepthCrossSectional}
                                segmentIndex={segmentIndex}
                                setSegmentIndex={setSegmentIndex}
                                predictions={filteredGpsData}
                                pinned={pinned}
                                originalVideoHeight={originalVideoHeight}
                                originalVideoWidth={originalVideoWidth}
                                link={link}
                                FPS={FPS}
                                playerRef={playerRef}
                                proid={proid}
                                drProjectData={drProjectData}
                                handleSectionChange={handleSectionChange}
                                sections={sections}
                                setSections={setSections}
                                crossSectionalPoints={crossSectionalPoints}
                                setCrossSectionalPoints={setCrossSectionalPoints}
                                defaultCrossSectionalPoints={defaultCrossSectionalPoints}
                                currentTime={currentTime}
                                overallCustomBorderPadding={overallCustomBorderPadding}
                                conditionMethod={conditionMethod} />
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        datastore: state.datastore,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        depthCrossSectionalProfile: (points, proid, sectionId) => dispatch(depthCrossSectionalProfile(points, proid, sectionId)),
        saveDepthCrossSectionalPoints: (points, proid, sectionId) => dispatch(saveDepthCrossSectionalPoints(points, proid, sectionId)),
        getDownSamplingData: (proid) => dispatch(getDownSamplingData(proid)),
        analysisSetting: (selectedDefects, conditionMethod, proid) => dispatch(analysisSetting(selectedDefects, conditionMethod, proid)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AfterSurfaceEvaluationInferenceDashboardV3);
















