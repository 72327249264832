import { Button, Card, CardMedia, CircularProgress, IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AnalysisTypeIndicator from './AnalysisTypeIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DateParser from '../../helpers/DateParser';
import NetworkStatusIndicator from './NetworkStatusIndicator';
import SimpleDialogBox from './SimpleDialogBox';
import EditNetworkDetails from '../home/components/network/EditNetworkDetails';
import { connect } from 'react-redux';
import { updateNetwork, clearUpdateNetworkAndMsg, archiveNetwork, clearArchiveNetworkAndMsg } from '../../store/actions/networkActions'
const CustomCard = (props) => {
    const { cardRef, data, thumbnail = null, title, date, path, isDetailRequired = true, isNetwork = false } = props;
    const { network } = props;
    const { updateNetworkMessage, updateNetworkLoader, updateNetworkIsDoneAndSent } = network;
    const { archiveNetworkMessage, archiveNetworkLoader, archiveNetworkIsDoneAndSent } = network;
    const { projectType, } = data;
    const formattedDate = date ? DateParser(date, 0) : DateParser(date, 2);
    const storedNetworkId = localStorage.getItem('netId');


    useEffect(() => {
        if (updateNetworkIsDoneAndSent === true) {
            handleCloseDetailDialog();
        }
    }, [updateNetworkIsDoneAndSent]);

    useEffect(() => {
        if (archiveNetworkIsDoneAndSent === true) {
            handleCloseArchiveDialog();
        }
    }, [archiveNetworkIsDoneAndSent]);

    // dialog state and function
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDetailDialog, setOpenDetailDialog] = useState(false);
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
    const open = Boolean(anchorEl);
    const handleOpenDetailDialog = () => {
        props.clearUpdateNetworkAndMsg();
        setOpenDetailDialog(true);
        setShowTitleAlert(false);
        setNetworkTitle(data.title);
        setAnchorEl(null);
    }
    const handleCloseDetailDialog = () => {
        setOpenDetailDialog(false);
        props.clearUpdateNetworkAndMsg();
    }
    const handleOpenArchiveDialog = () => {
        props.clearArchiveNetworkAndMsg();
        setOpenArchiveDialog(true);
        setAnchorEl(null);
    }
    const handleCloseArchiveDialog = () => {
        setOpenArchiveDialog(false);
        props.clearArchiveNetworkAndMsg();
    }

    const menuItemList = [
        {
            title: 'Edit network',
            onClick: handleOpenDetailDialog
        },
        {
            title: 'Archive network',
            onClick: handleOpenArchiveDialog
        }
    ]
    // Edit detail state & function
    const [networkTitle, setNetworkTitle] = useState(data.title);
    const [showTitleAlert, setShowTitleAlert] = useState(false);

    const handleNetworkTitleChange = (e) => {
        setShowTitleAlert(false);
        setNetworkTitle(e.target.value);
    }
    const updateNetwork = () => {
        if (networkTitle.trim() === '') {
            setShowTitleAlert(true);
        }
        else {
            props.updateNetwork(networkTitle, data.id)
        }
    }
    // Archive state & function
    const archiveNetwork = () => {
        props.archiveNetwork(data.id);
    }
    return (
        <>
            <SimpleDialogBox
                open={openDetailDialog}
                onClose={handleCloseDetailDialog}
                title={"Update details"}
                cancelText="Close"
                bodyWidget={<EditNetworkDetails networkTitle={networkTitle} showTitleAlert={showTitleAlert} onChange={handleNetworkTitleChange} />}
                width={600}
                showError={updateNetworkIsDoneAndSent == true ? false : true}
                errorMessage={updateNetworkIsDoneAndSent === true ? "" : updateNetworkMessage}
                showPositive
                positiveButtonWidget={
                    updateNetworkLoader === true ? (
                        <>
                            <Button
                                variant="contained"
                                disabled
                                sx={{
                                    "&.Mui-disabled": {
                                        backgroundColor: "#025196",
                                        color: "#ffffff",
                                    },
                                    marginInline: "2px",
                                    textTransform: "initial",
                                    color: "#025196",
                                    backgroundColor: "#025196",
                                    ":hover": {
                                        backgroundColor: "#025196",
                                    },
                                }}
                            >
                                <CircularProgress
                                    variant="indeterminate"
                                    size={15}
                                    sx={{ color: "white" }}
                                />
                                <span style={{ paddingLeft: "15px" }}>
                                    Updating...
                                </span>
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                marginInline: "2px",
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                ":hover": {
                                    backgroundColor: "#025196",
                                },
                            }}
                            onClick={updateNetwork}
                        >
                            Update
                        </Button>
                    )
                }
            />
            <SimpleDialogBox
                open={openArchiveDialog}
                onClose={handleCloseArchiveDialog}
                title={"Archive network"}
                cancelText="Close"
                bodyWidget={<>Are you sure you want to archive?</>}
                width={600}
                showError={archiveNetworkIsDoneAndSent == true ? false : true}
                errorMessage={archiveNetworkIsDoneAndSent === true ? "" : archiveNetworkMessage}
                showPositive
                positiveButtonWidget={
                    archiveNetworkLoader === true ? (
                        <>
                            <Button
                                variant="contained"
                                disabled
                                sx={{
                                    "&.Mui-disabled": {
                                        backgroundColor: "#025196",
                                        color: "#ffffff",
                                    },
                                    marginInline: "2px",
                                    textTransform: "initial",
                                    color: "#025196",
                                    backgroundColor: "#025196",
                                    ":hover": {
                                        backgroundColor: "#025196",
                                    },
                                }}
                            >
                                <CircularProgress
                                    variant="indeterminate"
                                    size={15}
                                    sx={{ color: "white" }}
                                />
                                <span style={{ paddingLeft: "15px" }}>
                                    Archiving...
                                </span>
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                marginInline: "2px",
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                ":hover": {
                                    backgroundColor: "#025196",
                                },
                            }}
                            onClick={archiveNetwork}
                        >
                            Archive
                        </Button>
                    )
                }
            />

            <Menu anchorEl={anchorEl}
                open={open}
                onClose={() => { setAnchorEl(null) }}>
                {menuItemList.map((eachItem, index) => (
                    <MenuList key={index}>
                        <MenuItem onClick={eachItem.onClick}>
                            <ListItemText>  {eachItem.title}</ListItemText>
                        </MenuItem>
                        {/* {index !== data.length - 1 && < Divider />} */}
                    </MenuList>
                ))}
            </Menu>
            <Card
                ref={cardRef}
                sx={{
                    borderRadius: '20px', ':hover': {
                        boxShadow: 20,
                    },
                    outline: storedNetworkId === data.dbInstanceId ? '3px solid #025196' : 'none'
                }}
                elevation={3} >
                {/* {isDetailRequired && <CustomMenuList open={open} anchorEl={anchorEl} onClose={() => { setAnchorEl(null); }} />} */}
                <Link
                    className='on-cardhover'
                    style={{ color: 'black', cursor: 'pointer' }}
                    to={path}
                    onClick={() => {
                        localStorage.setItem('netId', data.dbInstanceId);
                    }}
                >
                    <div style={{
                        aspectRatio: '1.7', minWidth: '300px', width: '350px',
                        position: 'relative',
                    }}>
                        <CardMedia
                            sx={{
                                aspectRatio: '1.7', minWidth: '300px', width: '350px',
                                position: 'absolute', top: 0, left: '0%', backgroundColor: ''
                            }}
                            src='project'
                            image={thumbnail ?? ''}
                            title={title}
                        />
                        <div style={{
                            position: 'absolute', top: 10, left: '3%',
                            height: '100%', backgroundColor: 'transparent',
                        }}>
                            {isNetwork ? <NetworkStatusIndicator status={0} /> : <></>}
                            {/* <ProjectStatusIndicator status={status} projectCard={data} /> */}
                        </div>
                    </div>
                </Link>
                {/* <CardContent> */}
                <ListItem
                    dense
                    sx={{ paddingLeft: '17px', paddingTop: '13px', paddingBottom: '13px' }}
                    secondaryAction={
                        isDetailRequired &&
                        <IconButton
                            title='View options'
                            variant="contained"
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    }
                >
                    <ListItemIcon sx={{ backgroundColor: '' }}>
                        <AnalysisTypeIndicator type={projectType} size={8} hasBorder={false} weight={'bold'} />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ style: { whiteSpace: "normal", marginLeft: '5px', marginRight: '15px' } }}
                        secondaryTypographyProps={{ style: { whiteSpace: "normal", marginLeft: '5px' } }}
                        primary={
                            <>
                                <span style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: '0px' }}>
                                    <Link
                                        to={path}
                                        className='on-cardhover'
                                        style={{ cursor: 'pointer', backgroundColor: 'white', color: '#1e1e1e' }}
                                        onClick={() => {
                                            localStorage.setItem('netId', data.dbInstanceId);
                                        }}
                                    >
                                        <Tooltip title={title} placement='top'>
                                            <Typography
                                                variant="p"
                                                maxHeight={5}
                                                sx={{ fontWeight: 'bold' }}
                                            >{`${title}`.length > 18 ? `${title}`.slice(0, 18).concat("...") : title}</Typography>
                                        </Tooltip>
                                    </Link>

                                </span>
                            </>
                        }
                        secondary={
                            <>

                                <span style={{ fontSize: '14px', marginLeft: '0px', marginBottom: '0px' }}>{formattedDate}</span>
                                {/* {isForked ? <Tooltip title="A mirrored project is your private copy of an existing project" arrow><span style={{ color: 'grey', fontWeight: 'lighter', fontStyle: 'italic', cursor: 'pointer' }}> — Mirrored</span></Tooltip> : <></>}
                            {
                                identificationNo !== '' && (
                                    <>
                                        <br />

                                        <span style={{ marginBottom: '10px' }}>
                                            {`${identificationNo}`.length > 18 ? `${identificationNo}`.slice(0, 18).concat("...") : identificationNo}
                                        </span>
                                    </>
                                )
                            } */}
                            </>
                        }
                    />
                </ListItem>
            </Card>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        network: state.network,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateNetwork: (networkTitle, networkId) => dispatch(updateNetwork(networkTitle, networkId)),
        clearUpdateNetworkAndMsg: () => dispatch(clearUpdateNetworkAndMsg()),
        archiveNetwork: (networkId) => dispatch(archiveNetwork(networkId)),
        clearArchiveNetworkAndMsg: () => dispatch(clearArchiveNetworkAndMsg()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCard);