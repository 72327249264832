import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CustomProgress from '../../../cidgets/CustomProgress'
import { connect } from "react-redux";
import { getIndividualDetection, removeGetIndividualDetectionErrorAndMessage } from "../../../../store/actions/projectActions";
import { Button, Toolbar } from "@mui/material";
import IndividualAssetViewer from "./subpages/afterInference/IndividualAssetViewer";
import IndividualDistressViewer from "./surfaceEvaluationComponents/IndividualDistressViewer";
import * as ROUTES from "../../../../constants/routes";
import { useGenerateSignedUrl } from "../../../../chooks/useGenerateSignedUrl";
const ProjectDashboardWrapperSection = (props) => {
    const { individualDetectionData, individualDetectionMessage, individualDetectionIsDoneAndSent, showindividualDetectionLoader } = props.project;
    const { proid, outputId } = useParams();
    // //////console.log(proid, outputId, "ProjectDashboardWrapperSection");
    const transcodeStatus = {
        FAILED: 0,
        PASS: 1,
        RUNNING: 2
    }
    useEffect(() => {
        props.removeGetIndividualDetectionErrorAndMessage();
        props.getIndividualDetection(proid, outputId);
    }, []);

    return (
        <>
            {individualDetectionData !== null ?
                individualDetectionIsDoneAndSent === true ?
                    individualDetectionData.projectType === 0 || individualDetectionData.projectType === 3 ?
                        <AssetInferenceWrapper loadTranscodedVideo={individualDetectionData.hasTranscoded === transcodeStatus.PASS} individualDetectionData={individualDetectionData} proid={proid} /> :
                        <SurfaceEvaluationInferenceWrapper loadTranscodedVideo={individualDetectionData.hasTranscoded === transcodeStatus.PASS} individualDetectionData={individualDetectionData} proid={proid} /> :
                    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h6> {individualDetectionMessage}!</h6>
                            <Link to={`/home/${ROUTES.HOME_DASHBOARD}`}>
                                <Button variant='contained' sx={{
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#025196',
                                        color: '#ffffff'
                                    }
                                }}
                                >Go back to home</Button>
                            </Link>
                        </div>
                    </div>
                : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CustomProgress text={individualDetectionMessage} /></div>}
        </>
    );
}
const AssetInferenceWrapper = ({ loadTranscodedVideo, individualDetectionData, proid }) => {
    const { status, error, data } = useGenerateSignedUrl(loadTranscodedVideo ? individualDetectionData?.transcodePath : individualDetectionData?.videoPath ?? "")
    // //////console.log(data, status, error, 'useGenerateSignedUrl')
    const isFetched = status === 'fetched'

    return isFetched ? <IndividualAssetViewer individualDetectionData={individualDetectionData} proid={proid} link={data} />
        : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomProgress text="Building your dashboard" />
        </div>
}

const SurfaceEvaluationInferenceWrapper = ({ loadTranscodedVideo, individualDetectionData, proid }) => {
    const { status, error, data } = useGenerateSignedUrl(loadTranscodedVideo ? individualDetectionData?.transcodePath : individualDetectionData?.videoPath ?? "")
    const isFetched = status === 'fetched'
    return isFetched ? <IndividualDistressViewer individualDetectionData={individualDetectionData} proid={proid} link={data} />
        : <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomProgress text="Building your dashboard" />
        </div>
}
const mapStateToProps = (state) => {
    return {
        project: state.project,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getIndividualDetection: (proid, outputId) => dispatch(getIndividualDetection(proid, outputId)),
        removeGetIndividualDetectionErrorAndMessage: () => dispatch(removeGetIndividualDetectionErrorAndMessage()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDashboardWrapperSection);