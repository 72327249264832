import React, { useEffect, useState } from 'react';
import './Topbar.scss'
import { generateReport } from '../../../../../../store/actions/projectActions';
import { GlobalHotKeys } from "react-hotkeys";
import { Box, Button, Menu, MenuItem, Typography, IconButton, Stack, Card, Tooltip, Grid, CircularProgress, FormControl, Select } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import InsightsIcon from '@mui/icons-material/Insights';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';
import ProfileInfo from '../../../../../parents/sidebars/components/signedIn/laptopDrawer/ProfileInfo';
import CustomProgress from '../../../../../cidgets/CustomProgress';
import ProjectNotification from '../../../../components/project/components/ProjectNotification';
import UserProfile from '../../../../components/user/UserProfile';
import SmartUtils from '../../../../../../helpers/SmartUtils';
import StyledOutlineButton from '../../../../../cidgets/StyledOutlineButton';
import BasicImagingOverviewChart from './BasicImagingOverviewChart';

function BasicImagingTopbar(props) {
    const { userData, selectedValue, handleChange, filteredData } = props;
    const { uniqueProjects, selectedProject, handleProjectChange, detections, basicImagingDataInfo } = props;
    const { nameInitial } = userData;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    // DIALOG CONTROLS
    ///////////////////////////////////////////////////////////
    const [openOverview, setOpenOverview] = useState(false);

    const handleOverviewOpen = () => {
        setOpenOverview(true);
    };

    const handleOverviewClose = () => {
        setOpenOverview(false);
    };
    ////////////////////////////////////////////////////////////

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <ProjectNotification />
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <UserProfile />
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/home`;
        navigate(path);
    };

    const handleGenReport = () => {
        if (props.project.showGenReportLoader === false) {
            // props.generateReport(drProjectData.proid)
        }
    }

    const topbarSection1Width = 30;
    const topbarSection2Width = 70;
    const topbarSection3Width = 20;

    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };

    const keyMap = {
        OVERVIEW_MAC: "command+p",
        OVERVIEW_WIN: "ctrl+p",
        REPORT_MAC: "command+g",
        REPORT_WIN: "ctrl+g",
    };

    const handlers = preventDefaultHandlers({
        OVERVIEW_MAC: handleOverviewOpen,
        OVERVIEW_WIN: handleOverviewOpen,
        REPORT_MAC: handleGenReport,
        REPORT_WIN: handleGenReport,
    });

    const su = new SmartUtils()
    const [os, setOs] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const data = await su._checkOS(navigator);
            setOs(data);
        }

        fetchData()
    }, [])

    const { ctrl } = su._platformKeys(os?.platform)

    // //////console.log(props.project)
    return (
        <>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <SimpleDialogBox
                open={openOverview}
                onClose={handleOverviewClose}
                title="Features overview"
                width={800}
                bodyWidget={
                    <BasicImagingOverviewChart basicImagingDataInfo={basicImagingDataInfo} detections={detections} />
                }
            />

            <Grid container spacing={2}>
                <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
                    <div style={{
                        borderRadius: '50%'
                    }}>
                        <Card sx={{
                            borderRadius: '50px', backgroundColor: '#fff', '&:hover': {
                                backgroundColor: '#fafafa',
                            },
                        }} elevation={5}>
                            <IconButton aria-label="roundedBtn" style={{ backgroundColor: 'transparent', }} onClick={routeChange}>
                                <KeyboardArrowLeft fontSize='large' sx={{ color: '#1E1E1E' }} />
                            </IconButton>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={10} sm={10} md={11} lg={11} xl={11} sx={{ backgroundColor: 'transparent', borderRadius: '15px' }}>
                    <Card elevation={4} sx={{ backgroundColor: '#FAFAFA', borderRadius: '15px' }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' }, width: { xs: '0%', md: `${topbarSection1Width}%` } }} style={{ height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={"Defects"} placement="bottom">
                                    <Typography
                                        variant="body1"
                                        color="initial"
                                        className='font-color'
                                        sx={{ paddingLeft: '20px', cursor: 'pointer', display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' } }}
                                    >
                                        <span>{"Defects"}</span>
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box sx={{ width: { xs: `${100}%`, md: `${topbarSection2Width}%` }, justifyContent: { xs: 'space-evenly', md: 'end' } }} style={{ marginRight: { xs: '20px', sm: '20px', md: '0px' }, height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center', }}>
                                <Box sx={{ marginRight: '10px' }}> <StyledOutlineButton padding={6} paddingInline={18} onClick={handleOverviewOpen} text="Features overview" tooltipText={`Use ${ctrl.name}${ctrl.plus}P to view`} /></Box>
                                <FormControl>
                                    <Select
                                        value={selectedProject}
                                        onChange={handleProjectChange}
                                        displayEmpty
                                        size='small'
                                        sx={{ marginRight: '10px', }}
                                    >
                                        <MenuItem value="">All Project</MenuItem>
                                        {uniqueProjects.map((eachProject, i) => {
                                            const [proid, projectTitle] = eachProject.split('-');
                                            return (
                                                <MenuItem key={i} value={proid}>{projectTitle}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <Select
                                        value={selectedValue}
                                        onChange={handleChange}
                                        displayEmpty
                                        size='small'
                                    // sx={{ marginRight: '10px', }}
                                    >
                                        <MenuItem value="Un-Assigned">Un-Assigned</MenuItem>
                                        <MenuItem value="Assigned">Assigned</MenuItem>
                                        <MenuItem value="Compeleted">Compeleted</MenuItem>
                                        <MenuItem value="Approved">Approved</MenuItem>
                                        <MenuItem value="Rejected">Rejected</MenuItem>
                                    </Select>
                                </FormControl>


                                {/* <Box sx={{ float: 'left', marginLeft: '15px', display: { xs: "none", sm: "none", md: 'none', lg: "flex", } }}>
                                    {
                                        props.project.showGenReportLoader === true ?
                                            <>
                                                <Button variant="contained" size="small" disabled sx={{
                                                    whiteSpace: 'nowrap', display: 'flex', alignItems: 'center',
                                                    "&.Mui-disabled": {
                                                        backgroundColor: '#025196',
                                                        color: '#ffffff'
                                                    },
                                                    marginInline: '10px',
                                                    textTransform: 'initial',
                                                    color: '#025196',
                                                    backgroundColor: '#025196', ':hover': {
                                                        backgroundColor: '#025196'
                                                    }
                                                }} >
                                                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Generating...</span>
                                                </Button>
                                            </> :
                                            <Tooltip title={`Use ${ctrl.name}${ctrl.plus}G to generate report`} arrow placement='bottom'>
                                                <Button variant="contained" size="small" sx={{
                                                    whiteSpace: 'nowrap', display: 'flex', alignItems: 'center',
                                                    marginInline: '10px',
                                                    textTransform: 'initial',
                                                    backgroundColor: '#025196', ':hover': {
                                                        backgroundColor: '#025196'
                                                    },
                                                }} onClick={handleGenReport}>Generate report</Button>
                                            </Tooltip>
                                    }
                                </Box> */}

                                <Stack direction="row" spacing={0}>
                                    <IconButton sx={{
                                        backgroundColor: 'tranparent', color: 'black',
                                        display: { xs: "flex", sm: "flex", md: 'flex', lg: "none" }, marginRight: '10px',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }} onClick={handleOverviewOpen}>
                                        <InsightsIcon />
                                    </IconButton>

                                    {props.project.showGenReportLoader === true ? <Box sx={{ display: { xs: "flex", sm: "flex", md: 'flex', lg: 'none', alignItems: 'center' } }}><CustomProgress size={20} /></Box> : <IconButton onClick={handleGenReport} sx={{
                                        backgroundColor: 'tranparent', color: 'black', ':hover': {
                                            backgroundColor: 'transparent'
                                        },
                                        display: { xs: "flex", sm: "flex", md: 'flex', lg: 'none' }
                                    }}>
                                        <SummarizeIcon />
                                    </IconButton>}

                                    <div style={{ display: "flex" }} >
                                        <ProjectNotification />
                                        <ProfileInfo isProjectDashboard={true} />
                                        {renderMobileMenu}
                                        {renderMenu}
                                    </div>
                                </Stack>
                            </Box>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
const mapStateToProps = (state, ownProps) => {
    // const { proid } = state.project;
    const { proid } = ownProps
    return {
        project: state.project,
        projNotifs: state.firestore.ordered.ProjNotifs,
        userData: state.firebase.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        generateReport: (proid) => dispatch(generateReport(proid)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicImagingTopbar);