import React, { useState, useRef, useEffect } from 'react'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Button, Card, Checkbox, CircularProgress, Dialog, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useKeyPress } from '../../../../../../chooks/useKeyPress';
import LinkIcon from '@mui/icons-material/Link';
import DetailedSafetyEdit from './DetailedSafetyEdit';
import CustomProgress from '../../../../../cidgets/CustomProgress';
import SafetyEditFields from './SafetyEditFields';

const PinnedSafety = (props) => {
    const {
        showConfirmDeltaLoader,
        resetArchiveFeature,
        safetyConfirmDelta,
        safetyDataInfo,
        handleCallCompareDataApi,
        setIsCompareOpen, handleClickOpenCompareDialog,
        previousAsset, assetIndex, nextAsset, timeList, dataInfo,
        handleClickOpenDialog, openDialog, handleCloseDialog, pinned, handlePinned, classesMap, isTraficSignClass, roadSideMap, imageBase64, cropBase64,
        handleQuality, qualities, proid, fps, realFps, link, gpsData, gpsDataCombined, playerRef, originalVideoHeight, originalVideoWidth } = props;
    const label = { inputProps: { 'aria-label': 'Checkbox feature' } };

    const [fieldValues, setFieldValues] = useState(undefined);
    const [compareDeltaCheck, setCompareDeltaCheck] = useState(undefined);
    const [isQuickEdit, setIsQuickEdit] = useState(false);

    const handleCompareDeltaChange = (event) => {
        setCompareDeltaCheck(event.target.checked);
    };
    const handleChange = (prop) => (event) => {
        setFieldValues({ ...fieldValues, [prop]: event.target.value });
    };
    const NEXT = useKeyPress('ArrowRight')
    const PREV = useKeyPress('ArrowLeft')

    useEffect(() => {
        if (NEXT) {
            if (!(assetIndex === timeList.length - 1)) {
                nextAsset()
            }
        } else if (PREV) {
            if (!(assetIndex === 0)) {
                previousAsset()
            }
        }
    }, [NEXT, PREV])

    const cardLightStyle = {
        "border": "0px solid grey",
        "borderRadius": "8px",
        "padding": "5px",
        "backgroundColor": '#f5f1f1'
    }
    const ref = useRef(null)
    const imageHeight = ref?.current?.clientHeight ?? 0
    const outputId = `${pinned.pred.sectionId}-${pinned.pred.id}`;

    useEffect(() => {
        setCompareDeltaCheck(pinned.pred.isDelta || pinned.pred.isDeltaConfirm ? true : false);
        setFieldValues({
            safetyClassState: pinned.pred.defectName,
            sideState: pinned.pred.side ?? '',
            typeState: pinned.pred.type ?? '',
            lateralPositionState: pinned.pred.lateralPosition ?? '',
        });
    }, [pinned])

    const handleDiscardChanges = () => {
        setIsQuickEdit(false);
        setCompareDeltaCheck(pinned.pred.isDelta || pinned.pred.isDeltaConfirm ? true : false);
        setFieldValues({
            safetyClassState: pinned.pred.defectName,
            sideState: pinned.pred.side ?? '',
            typeState: pinned.pred.type ?? '',
            lateralPositionState: pinned.pred.lateralPosition ?? '',
        });
    }

    const handleSaveChanges = () => {
        safetyConfirmDelta(proid, pinned.pred.sectionId, pinned.pred.id)
    }

    const handleComparePinning = (outputId) => {
        // handlePinned(null)
        handleCallCompareDataApi(outputId);
        handleClickOpenCompareDialog();
        setIsCompareOpen(pinned)
    }
    if (fieldValues === undefined) {
        return <CustomProgress text={"Loading data"} />
    }
    const handleDetailEdit = () => {
        handleClickOpenDialog();
        resetArchiveFeature();
    }
    return (
        <>
            <div ref={ref} id='table-pinned' style={{ height: `100%`, backgroundColor: 'transparent' }}>
                <Box sx={{ paddingInline: '15px', height: { xs: '40%', md: '20%' }, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Grid container spacing={0} sx={{}}>
                        <Grid item xs={4} sm={4} md={6} lg={7} xl={7} sx={{
                            display: 'flex', justifyContent: 'start', alignItems: 'end',
                        }} >
                            <Grid container spacing={0} sx={{
                                display: 'flex', justifyContent: 'start', alignItems: 'end',
                            }} >
                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <Box >
                                        <IconButton onClick={previousAsset} disabled={assetIndex === 0 ? true : false}>
                                            <Tooltip title="Previous feature"><KeyboardArrowLeftIcon sx={{ color: assetIndex === 0 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                                        </IconButton>
                                        <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '16px' }, padding: `0px ${15 + 10}px 0px ${15 + 10}px` }}>Feature {assetIndex + 1} / {timeList.length}</Typography>
                                        <IconButton onClick={nextAsset} disabled={assetIndex === timeList.length - 1 ? true : false}>
                                            <Tooltip title="Next feature"><KeyboardArrowRightIcon sx={{ color: assetIndex === timeList.length - 1 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: isQuickEdit ? '1px solid #929292' : '', backgroundColor: isQuickEdit ? '' : '#F5F1F1', borderRadius: '7px' }}>
                                        <Typography variant='body1' sx={{ marginLeft: '10px', color: isQuickEdit ? '#606060' : '#B3B3B3', fontWeight: '700' }}>Confirm delta</Typography>
                                        <Checkbox {...label} size='small' onChange={handleCompareDeltaChange} checked={compareDeltaCheck} disabled={isQuickEdit ? false : true} disableRipple={true} sx={{
                                            color: '#606060',
                                            '&.Mui-checked.Mui-disabled': {
                                                color: '#f0f0f0',
                                                '& .MuiSvgIcon-root': {
                                                    backgroundColor: '#606060',
                                                },
                                            },
                                            '&.Mui-checked': {
                                                color: '#606060',
                                                '& .MuiSvgIcon-root': {
                                                    backgroundColor: '#f0fof0',
                                                },
                                            },
                                        }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', }}>
                                    <IconButton sx={{ backgroundColor: '#F0F0F0', borderRadius: '9px' }}>
                                        {/* <Tooltip title='Copy the link of this asset'> */}
                                        <LinkIcon
                                            sx={{
                                                color: "#1E1E1E",
                                                // marginInline: "25px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => { handleComparePinning(outputId) }}
                                        />
                                        {/* </Tooltip> */}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} sm={8} md={6} lg={5} xl={5} sx={{
                            display: 'flex', justifyContent: 'end', alignItems: 'center'
                        }}>
                            {isQuickEdit ? <></> : <Button variant="contained" size="small" sx={{
                                marginInline: '10px',
                                textTransform: 'initial',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                },
                            }} onClick={() => { setIsQuickEdit(true) }}>Quick edit</Button>}

                            {isQuickEdit ? <></> : <Button variant="contained" size="small" sx={{
                                marginInline: '10px',
                                textTransform: 'initial',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                },
                            }} onClick={handleDetailEdit}>Detailed edit</Button>}

                            {isQuickEdit ? <Button variant="contained" size="small" sx={{
                                marginInline: '10px',
                                textTransform: 'initial',
                                backgroundColor: '#6C6C6C', ':hover': {
                                    backgroundColor: '#6C6C6C'
                                },
                            }} onClick={() => { handleDiscardChanges() }}>Discard changes</Button> : <></>}
                            {isQuickEdit ? showConfirmDeltaLoader ? <Button variant="contained" disabled sx={{
                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                }
                            }}>
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Saving...</span>
                            </Button> :
                                <Button variant="contained" size="small" sx={{
                                    marginInline: '10px',
                                    textTransform: 'initial',
                                    backgroundColor: '#025196', ':hover': {
                                        backgroundColor: '#025196'
                                    },
                                }} onClick={handleSaveChanges}>Save changes</Button>
                                : <></>}

                            <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
                                <DetailedSafetyEdit
                                    safetyDataInfo={safetyDataInfo}
                                    originalVideoHeight={originalVideoHeight}
                                    originalVideoWidth={originalVideoWidth}
                                    setQualities={handleQuality}
                                    qualities={qualities}
                                    proid={proid}
                                    handlePinned={handlePinned}
                                    fps={fps} realFps={realFps}
                                    link={link} pinned={pinned} handleCloseDialog={handleCloseDialog}
                                    dataList={timeList} gpsData={gpsData} gpsDataCombined={gpsDataCombined} />
                            </Dialog>
                            {/* <Tooltip title='Copy the link of this asset'>
                                <LinkIcon
                                    sx={{
                                        color: "#1E1E1E",
                                        marginInline: "25px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => { handleShareClick(outputId) }}
                                />
                            </Tooltip> */}
                            <Tooltip title='Unpin this feature'>
                                <ClearIcon
                                    sx={{
                                        color: "#1E1E1E",
                                        // marginLeft: "20px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => { handlePinned(pinned) }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>

                <div style={{ marginLeft: '15px', marginRight: '15px', height: '5%', backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Box sx={{ width: "100%" }}>
                                <Divider light />
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Box sx={{ paddingInline: '15px', height: { xs: '50%', md: '70%' }, width: '100%', backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'start' }}>

                    <div style={{ backgroundColor: 'transparent', float: 'left', width: '60%', height: '100%' }}>
                        <Card sx={{ ...cardLightStyle, height: '87%', width: '100%', overflow: 'auto' }} elevation={0}>
                            <SafetyEditFields isQuickEdit={isQuickEdit} handleChange={handleChange} safetyDataInfo={safetyDataInfo}
                                fieldValues={fieldValues} pinned={pinned} />
                        </Card>
                    </div>

                    <div style={{ overflow: 'auto', backgroundColor: 'transparent', float: 'left', height: '100%', width: '40%', display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                        <div style={{ width: '100%', display: 'table', paddingLeft: '10px' }}>
                            <div style={{ display: 'table-row', height: `calc(${imageHeight * 0.4}px)` }}>
                                <Tooltip title="Original image" arrow>
                                    <div style={{ width: '50%', display: 'table-cell', background: 'transparent', paddingRight: '5px' }}>
                                        <img style={{ width: '100%', height: `calc(${imageHeight * 0.4}px)`, objectFit: 'contain' }} src={cropBase64} />
                                    </div>
                                </Tooltip>
                                {imageBase64 ?
                                    <Tooltip title="Recognition" arrow>
                                        <div style={{ display: 'table-cell', background: 'transparent' }}>
                                            <img style={{ width: '100%', height: `calc(${imageHeight * 0.4}px)`, paddingLeft: '5px', objectFit: 'contain' }} src={'data:image/png;base64,' + imageBase64 ?? ''} />
                                        </div>
                                    </Tooltip>
                                    :
                                    <React.Fragment />}
                            </div>
                        </div>

                    </div>
                </Box >
            </div >
        </>
    )
}
export default PinnedSafety
