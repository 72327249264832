import { Card, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { Chart, ArcElement } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';
import CircleIcon from '@mui/icons-material/Circle';
import colors from '../../../../../../constants/colors.json'
import { useApi } from '../../../../../../chooks/useApi';
Chart.register(ArcElement);

const PotholeOverviewChart = ({ basicImagingDataInfo, height = 10, detections = null, assetData = null, dataInfo = null, startEndLocation, estimatedDistanceKm = 0 }) => {
    const { status, error, data } = useApi(`https://api.openrouteservice.org/v2/directions/driving-car?api_key=5b3ce3597851110001cf624834d47bd5d59747d8bb2e4f065df3d60d&start=${startEndLocation.startLng},${startEndLocation.startLat}&end=${startEndLocation.endLng},${startEndLocation.endLat}`)

    const extractDistanceMetric = (data) => {
        const distance = !(data?.features) ? 0 : data?.features[0]?.properties?.summary?.distance ?? 0
        return distance
    }
    // //////console.log(data, "asset chart data")
    if (detections === null || assetData === null || dataInfo === null) {
        return <></>
    }

    const getAssetMeta = (assetId, debug = true) => {
        const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
        if (debug) {
            // //////console.log(assetMeta, "getAssetMeta")
        }
        return assetMeta
    }

    let countMap = { '0': 0};
    // //////console.log(detections, "det")
    const augmentedPredictions = [...detections].map((eachDetection, i) => {
        // const assetMeta = getAssetMeta(eachDetection.assetId)
        const obj = {
            pred: eachDetection,
            // meta: assetMeta
        }
        countMap[eachDetection.defectName] = countMap[eachDetection.defectName] + 1
        return obj
    })
    // //////console.log(augmentedPredictions, "augmentedPredictions")
    const totalAssets = augmentedPredictions.length;
    // const totalAssetLeft = augmentedPredictions.filter((e) => e.pred.roadSide === '0').length
    // const totalAssetRight = augmentedPredictions.filter((e) => e.pred.roadSide === '1').length
    Object.entries(countMap).map(([key, value]) => {
        countMap[key] = {
            class: key,
            count: value,
            percentage: (value / totalAssets) * 100,
            percentageToShow: Number((value / totalAssets) * 100).toFixed(1),
            outOfOne: (value / totalAssets),
            pieChartAngle: (value / totalAssets) * 360,
            pieChartRadians: ((value / totalAssets) * 360 * Math.PI) / 180,
            name: basicImagingDataInfo.defectName[key]['name'],
            nameToBeShown: `${basicImagingDataInfo.defectName[key]['name']}`.toUpperCase(),
            color: colors.colors[key]
        }
    });
    // //////console.log(countMap, "assetCountMap")
    const sortedCountArray = Object.values(countMap).sort((a, b) => a.class - b.class)
    const chartData = {
        labels: sortedCountArray.map((e) => (`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')),
        datasets: [{
            label: 'Summary',
            data: sortedCountArray.map((e) => e.count),
            backgroundColor: sortedCountArray.map((e) => e.color),
            hoverOffset: 4
        }]
    }

    const chartOptions = {
        legend: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
                labels: {
                    font: {
                        size: 16
                    }
                }
            }
        },
        layout: {
            padding: 0
        },
    }
    const cardLightStyle = {
        "border": "0px solid grey",
        "borderRadius": "8px",
        "padding": "8px",
        "backgroundColor": '#f5f1f1'
        // "backgroundColor": '#f5f1f1'
    }

    const wnpStyle = { fontSize: '15px' }

    const actualRoadLength = extractDistanceMetric(data);
    const isFetched = status === 'fetched'
    return <>
        <Grid container>

            <Grid xs={5} item>
                <List>
                    {sortedCountArray.map((e) => {
                        return <ListItem key={e.class} disablePadding>
                            <ListItemIcon>
                                <CircleIcon sx={{ color: e.color }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={(`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')}
                                secondary={`${e.percentageToShow}% (${e.count})`}
                            />
                        </ListItem>
                    })}
                </List>
            </Grid>
            <Grid xs={7} item>
                <Doughnut data={chartData} options={chartOptions} />
            </Grid>
        </Grid>
        <Grid container>
            <Grid xs={6} item>
                <Card sx={{ ...cardLightStyle, marginTop: '30px' }} elevation={0}>
                    <Typography variant="p" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                        ⚡️ Quick bits
                    </Typography>
                    <div style={{ margin: '14px' }}></div>
                    <div style={wnpStyle}>Total {totalAssets} features</div>
                    {/* <div style={wnpStyle}><CircleIcon sx={{ color: 'blue', fontSize: '10px', marginRight: '10px' }} />{totalAssetLeft} assets on the left side of the road</div>
                    <div style={wnpStyle}><CircleIcon sx={{ color: 'red', fontSize: '10px', marginRight: '10px' }} />{totalAssetRight} assets on the right side of the road</div> */}
                </Card>

            </Grid>
            <Grid xs={1} item></Grid>
            <Grid xs={5} item>
                <Card sx={{ marginTop: '30px', textAlign: 'end', padding: '15px', backgroundColor: 'transparent' }} elevation={0}>
                    <div style={{ fontSize: '45px', fontWeight: 'normal', color: 'rgba(0,0,0,1)' }}>{!isFetched ? <CircularProgress sx={{ color: '#1F0851' }} size={30} /> : `${estimatedDistanceKm === 0 ? Number(actualRoadLength).toFixed(2) : (estimatedDistanceKm * 1000).toFixed(2)} m`}</div>
                    <Tooltip title="Accurate to 0.01 meters" arrow>
                        <Typography variant="p" sx={{ fontWeight: 'normal', fontSize: '16px', cursor: 'pointer' }}>
                            {!isFetched ? 'Calculating road length...' : 'Actual road length'}
                        </Typography>
                    </Tooltip>
                </Card>
            </Grid>
        </Grid>
        {/* <div style={{width: '70%'}}><Doughnut data={chartData} options={chartOptions} /></div>
        <div>
            <List>
                {sortedCountArray.map((e) => {
                    return <ListItem key={e.class} disablePadding>
                        <ListItemIcon>
                            <CircleIcon sx={{ color: e.color }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={(`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')}
                            secondary={`${e.percentageToShow}% (${e.count})`}
                        />
                    </ListItem>
                })}
            </List>
        </div> */}
    </>
}

export default PotholeOverviewChart