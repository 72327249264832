import { Button, Card, ListItem, ListItemButton, ListItemText, Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import SimpleDialogBox from "../../../../../cidgets/SimpleDialogBox";
import { useState, useEffect } from "react";
import { unarchiveDistress } from "../../../../../../store/actions/projectActions";
import { connect } from "react-redux";
import CustomTooltip from "../../../../../cidgets/CustomTooltip";
import { useKeyPress } from "../../../../../../chooks/useKeyPress";
import ControlChip from "../../../../../cidgets/ControlChip";
import { HotKeys, GlobalHotKeys } from "react-hotkeys";
import SmartUtils from "../../../../../../helpers/SmartUtils";
import DeleteIcon from '@mui/icons-material/Delete';

const ArchivedDistressesViewer = ({ selectedSegment, distressData = [], detections = [], proid, unarchiveDistress }) => {
    // //////console.log(distressData, "distressData")
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };
    const keyShortcut = 'b'
    const keyMap = {
        BIN_MAC: "command+" + keyShortcut,
        BIN_WIN: "ctrl+" + keyShortcut,
    };

    const handlers = preventDefaultHandlers({
        BIN_MAC: handleOpen,
        BIN_WIN: handleOpen,
    });

    let archivedDetections = detections.filter((eachDetection) => {
        return eachDetection.archived ? eachDetection.archived === "1" : false
    })
    const su = new SmartUtils()
    const [os, setOs] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const data = await su._checkOS(navigator);
            setOs(data);
        }

        fetchData()
    }, [])

    const { ctrl } = su._platformKeys(os?.platform)

    return (
        <>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <SimpleDialogBox
                open={openDialog}
                onClose={handleClose}
                title="Archived distresses"
                cancelText="Close"
                desc={archivedDetections.length > 0 ? "Unarchive an distresses by clicking on it" : "There is nothing here!"}
                bodyWidget={
                    <ArchivedDistresses selectedSegment={selectedSegment} archivedDetections={archivedDetections} distressData={Object.values(detections)} proid={proid} unarchiveDistress={unarchiveDistress} />
                }
                width='600'
            />
            <Tooltip title={`Or use ${ctrl.name}${ctrl.plus}B to view bin`} placement="left" arrow><Button onClick={handleOpen} sx={{ marginRight: '10px', display: { xs: "none", sm: "none", md: 'flex' }, textTransform: 'none' }}>Distress bin</Button></Tooltip>

            <Tooltip title={`Or use ${ctrl.name}${ctrl.plus}B to view bin`} placement="left" arrow>
                <DeleteIcon
                    sx={{
                        fontSize: { xs: '16px', sm: '16px', md: '24px' },
                        display: { xs: "flex", sm: "flex", md: 'none' },
                        color: "#1E1E1E",
                        marginRight: "25px",
                        cursor: "pointer",
                    }}
                    onClick={handleOpen}
                />
                </Tooltip>

        </>
    );
}

const ArchivedDistresses = (props) => {
    const { selectedSegment, archivedDetections = [], distressData = [], proid, unarchiveDistress } = props;
    const cardStyle = {
        "border": "0.5px solid grey",
        "borderRadius": "8px",
        "backgroundColor": '#fff',
        "marginBlock": '10px',
        "marginTop": '20px',
    }

    const handleClick = (proid, e) => {
        // //////console.log(proid, e, "katt")
        unarchiveDistress(proid, selectedSegment.sectionId, e.defectId);
    }

    const getDistressMeta = (distressId, debug = true) => {
        const distressMeta = distressData.filter((e) => `${e.defectId}` === `${distressId}`)[0]
        if (debug) {
            // //////console.log(distressMeta, "killer")
        }
        return distressMeta
    }

    const positionNames = [
        'Left side',
        'Right side',
        'Nearside wheel path',
        'Offside wheel path',
        'Lane center',
        'Across the lane',
    ];
    const defectNames = [
        "Pothole",
        "Crack",
        "Subsidence",
        "Ravelling",
        "Rutting",
        "Patch",
        "Manhole",
        "Bleeding",
        "Depression",
        "Corrugation/Shoving",
        "Polished aggregate",
        "Water bleeding and pumping",
    ]
    return (
        <div>
            {archivedDetections.map((e, i) => {
                const obj = {
                    pred: e,
                    meta: getDistressMeta(e.defectId)
                }
                const defectName = defectNames[Number(obj.meta.defectName)];
                // const defectName = obj.meta.defectName === 0 ? 'Pothole' : 'Crack';
                const transversePositionName = defectName === 'Crack' ? positionNames[obj.meta.transversePosition] : null;

                // const imBase64 = `data:image/png;base64, ${obj?.pred?.cropb64 ?? ''}`;
                return <Card key={obj.pred.id} sx={cardStyle} elevation={0}>
                    <ListItem
                        dense
                        disablePadding
                        sx={{ paddingBlock: '5px' }}

                    >
                        <ListItemButton
                            title={`Unarchive ${defectName}`}
                            onClick={() => { handleClick(proid, e) }}
                        >
                            <ListItemText
                                primary={
                                    <>
                                        <Typography
                                            variant="p"
                                            sx={{ fontWeight: 'bold' }}
                                        >{i + 1}. {defectName} {transversePositionName}</Typography>
                                    </>
                                }
                                secondary={
                                    <span>
                                        GPS: ({obj.pred.geo[0]}, {obj.pred.geo[1]})
                                    </span>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </Card>
            })}
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // setCurrentProjectId: (proid) => dispatch(setCurrentProjectId(proid)),
        unarchiveDistress: (proid, sectionId, detectionId) => dispatch(unarchiveDistress(proid, sectionId, detectionId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedDistressesViewer);