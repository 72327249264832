import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import AccessControlAccountError from '../../cidgets/AccessControlAccountError';

const AccessController = (props) => {
    const isSignedIn = props.isSignedIn;
    const isEmailVerified = props.isEmailVerified;
    let location = useLocation();
    if (!isSignedIn) {
        return <Navigate to="/a" state={{ from: location }} replace />;
    }

    if (!isEmailVerified) {
        return <Navigate to="/a/verify" state={{ from: location }} replace />;
    }
    return (
        <AccessControlAccountError access={props.role} />
    )
}

export default AccessController