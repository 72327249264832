import { LayerGroup, LayersControl, MapContainer, Polyline, TileLayer, Tooltip, Circle, useMap } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import marker from '../../../../../assets/pin_sm.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import SmartUtils from '../../../../../helpers/SmartUtils';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip as MuiTooltip } from '@mui/material'
import { useTooltipContext } from '../../../../../contexts/TooltipContext';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { object } from 'prop-types';
const DistressMap = (props) => {
    const { isGroupData = false, isWholeDistressVisualization, setDistressPinned, drProjectData, predictions, segmentIndex, distressPinned = null, datastore, FPS, distressProjectPredictions = [], zoom = 10, pinned, handleSeekTo, handlePinned, conditionMethod, handleSectionChangeMap } = props;
    const iconSize = [26 / 1, 38 / 1];
    const [polylineKey, setPolylineKey] = useState(0);
    const DrIcon = new L.Icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
        iconAnchor: [iconSize[0] / 2, iconSize[1]],
        popupAnchor: [-0, -0],
        iconSize: iconSize,
    });
    L.Marker.prototype.options.icon = DrIcon;
    const { projectType, typeOfRoad } = drProjectData;
    const cciInverted = drProjectData.cciInverted ? drProjectData.cciInverted : false;
    const { distressData } = datastore;
    const mapRef = useRef(null);
    const allDetections = useMemo(() => {
        let detections = [];
        distressProjectPredictions.forEach(prediction => {
            Object.values(prediction.detections).forEach(detection => {
                const combinedData = {
                    ...detection,
                    ...prediction
                };
                detections = [...detections, combinedData];
            });
        });
        return detections;
    }, [distressProjectPredictions]);

    useEffect(() => {
        setPolylineKey(polylineKey + 1);
    }, [conditionMethod, distressProjectPredictions, cciInverted]);
    const currentSection = pinned === null ? distressProjectPredictions[0] : Object.values(distressProjectPredictions).filter(obj => obj.id === pinned.id)[0];
    const mapCenter = [currentSection?.info?.startingPoint?.lat, currentSection?.info?.startingPoint?.lng];
    const currentSectionColor =
        SmartUtils.supportCCIProject(projectType) && conditionMethod === 2 ?
            currentSection?.info?.weightedCondition ?
                SmartUtils.cciColor(currentSection?.info?.weightedCondition, cciInverted)
                :
                SmartUtils.cciColor(currentSection?.info?.condition, cciInverted) :

            SmartUtils.supportPCIProject(projectType) && conditionMethod === 1 ?
                currentSection?.info?.defaultCondition ?
                    SmartUtils.pciColor(currentSection?.info?.defaultCondition)
                    :
                    SmartUtils.pciColor(currentSection?.info?.condition)
                :
                SmartUtils.rciColor(currentSection?.info?.condition, typeOfRoad);

    const allSectionStartEndPoints = useMemo(() => {
        return Object.values(distressProjectPredictions).map((section) => [
            [section['info']['startingPoint']['lat'], section['info']['startingPoint']['lng']],
            [section['info']['endingPoint']['lat'], section['info']['endingPoint']['lng']]
        ]);
    }, [conditionMethod, distressProjectPredictions, cciInverted]);

    const allSectionRci = useMemo(() => {
        return Object.values(distressProjectPredictions).map((section) =>
            section['info']['condition']
        );
    }, [conditionMethod, distressProjectPredictions]);

    const allSectionCci = useMemo(() => {
        return Object.values(distressProjectPredictions).map((section) =>
            section['info']['weightedCondition'] ?? section['info']['condition']
        );
    }, [conditionMethod, distressProjectPredictions, cciInverted]);
    const allSectionPci = useMemo(() => {
        return Object.values(distressProjectPredictions).map((section) =>
            section['info']['defaultCondition'] ?? section['info']['condition']
        );
    }, [conditionMethod, distressProjectPredictions]);
    if (distressProjectPredictions.length === 0) {
        return <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'green' }} />
        </div>
    }
    if (distressData === undefined || distressData === null) {
        return <></>
    }
    const { defectNames } = distressData;
    if (defectNames === null || defectNames === undefined || defectNames.length === 0) {
        return <></>
    };

    // const startingPointOfPolyline = distressProjectPredictions.length > 0 && distressProjectPredictions[0]['info']['startingPoint'];
    // const endingPointOfPolyline = distressProjectPredictions.length > 0 && distressProjectPredictions[distressProjectPredictions.length - 1]['info']['endingPoint'];
    // const polylinePath = [[startingPointOfPolyline.lat, startingPointOfPolyline.lng], [endingPointOfPolyline.lat, endingPointOfPolyline.lng]];
    // const pathPolyPoints = polyPoints.length === 0 ? distressProjectPredictions.map((e) => Object.values(e.detections).map((eachDetection) => eachDetection[geoKey])) : polyPoints
    // const handleFrameClick = (sectionId) => {
    //     const underscoreIndex = sectionId.indexOf("_");
    //     const numberAfterUnderscore = sectionId.slice(underscoreIndex + 1);
    //     handleSectionChange(numberAfterUnderscore);
    // }

    // console.log("distressProjectPredictions", distressProjectPredictions);
    const LegendsCard = () => {
        const { showTooltip, handleTooltipToggle } = useTooltipContext();
        const tooltipContent = (
            <>
                {conditionMethod === 0 ? (
                    typeOfRoad === 'cycleTracks' ? (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                Section {segmentIndex + 1} Adjusted RCI (except texture & rutting) : {Math.round(Object.values(predictions)[segmentIndex]['info']['condition'])}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'green',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Excellent: 0-18 (Adjusted)</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 18-44 (Adjusted)</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Above poor: 44-above (Adjusted)</div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                Section {segmentIndex + 1} Adjusted RCI (except texture) : {Math.round(Object.values(predictions)[segmentIndex]['info']['condition'])}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'green',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Excellent: 0-31 (Adjusted)</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 31-76 (Adjusted)</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Above poor: 76-above (Adjusted)</div>
                            </div>
                        </>
                    )
                ) : conditionMethod === 2 ? (
                    cciInverted ?
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                Custom condition index - Section {segmentIndex + 1} : {Math.round(Object.values(predictions)[segmentIndex]['info']['weightedCondition'] ?? Object.values(predictions)[segmentIndex]['info']['condition'])}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkgreen',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 0-20</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: '#57C018',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 21-40</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'yellow',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor : 61-80</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious : 81-100</div>
                            </div>
                        </>
                        :
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                Custom condition index - Section {segmentIndex + 1} : {Math.round(Object.values(predictions)[segmentIndex]['info']['weightedCondition'] ?? Object.values(predictions)[segmentIndex]['info']['condition'])}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious: 0-20</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor: 21-40</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'yellow',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: '#57C018',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 61-80</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkgreen',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 81-100</div>
                            </div>
                        </>
                ) :
                    conditionMethod === 1 ? (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '12px', fontWeight: 600 }}>
                                Pavement condition index - Section {segmentIndex + 1} : {Math.round(Object.values(predictions)[segmentIndex]['info']['defaultCondition'] ?? Object.values(predictions)[segmentIndex]['info']['condition'])}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Serious: 0-20</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkorange',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Poor: 21-40</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'yellow',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Fair: 41-60</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: '#57C018',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Satisfactory: 61-80</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: 'darkgreen',
                                    }}
                                ></div>
                                <div style={{ fontSize: '12px', fontWeight: 500 }}>Good: 81-100</div>
                            </div>
                        </>
                    )
                        :
                        (
                            <></>
                        )}
            </>
        );
        return (
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    zIndex: 1000,
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}
            >
                <MuiTooltip
                    // open={showTooltip}
                    title={tooltipContent}
                    arrow
                >
                    <InfoIcon fontSize="medium" onClick={handleTooltipToggle} sx={{ cursor: 'pointer' }} />
                </MuiTooltip>
            </div>
        );
    };

    const Recenter = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }
    if (currentSection === undefined) {
        return <></>
    }
    return (
        <div style={{ height: '100%' }}>
            <MapContainer ref={mapRef} attributionControl={false} center={mapCenter} zoom={zoom} scrollWheelZoom={true} maxZoom={20}>
                <TileLayer
                    attribution=''
                    maxZoom={20}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Recenter lat={mapCenter[0]} lng={mapCenter[1]} />
                <ReactLeafletGoogleLayer apiKey='AIzaSyA1Zq5IDPlPl_i4MnG9wCBg8s6XQrfB9Ig' type={'roadmap'} maxZoom={25} maxNativeZoom={22} />
                {conditionMethod !== -1 ? <LegendsCard /> : <></>}
                <LayersControl position="topright">
                    <LayersControl.Overlay name="All sections road" checked={true}>
                        <LayerGroup key={polylineKey}>
                            {allSectionStartEndPoints.map((coords, index) => (
                                // Cycle through colors 
                                SmartUtils.supportCCIProject(projectType) && conditionMethod === 2 ?
                                    <Polyline key={index} positions={coords} color={SmartUtils.cciColor(allSectionCci[index], cciInverted)} weight={10}
                                        eventHandlers={{
                                            click: () => {
                                                handleSectionChangeMap(index);
                                            }
                                        }}
                                    />
                                    :
                                    SmartUtils.supportPCIProject(projectType) && conditionMethod === 1 ?
                                        <Polyline key={index} positions={coords} color={SmartUtils.pciColor(allSectionPci[index])} weight={10}
                                            eventHandlers={{
                                                click: () => {
                                                    handleSectionChangeMap(index);
                                                }
                                            }}
                                        />
                                        :
                                        conditionMethod === -1 ?
                                            <Polyline key={index} positions={coords}
                                                color="#1F0851"
                                                weight={10}
                                                eventHandlers={{
                                                    click: () => {
                                                        handleSectionChangeMap(index);
                                                    }
                                                }}
                                            />
                                            :
                                            <Polyline key={index} positions={coords} color={SmartUtils.rciColor(allSectionRci[index], typeOfRoad)} weight={10}
                                                eventHandlers={{
                                                    click: () => {
                                                        handleSectionChangeMap(index);
                                                    }
                                                }}
                                            />
                            ))}
                        </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Current location" checked={true}>
                        <LayerGroup>
                            <Circle
                                center={mapCenter}
                                pathOptions={{ fillColor: 'white', color: 'black', fillOpacity: 1, stroke: true, fill: true }}
                                radius={3}>
                                {/* <Tooltip>{currentSection?.sectionId}</Tooltip> */}
                            </Circle>
                        </LayerGroup>
                    </LayersControl.Overlay>
                    {/* {isWholeDistressVisualization ? <></> : <LayersControl.Overlay name="Each section distress" checked={true}>
                        <LayerGroup>
                            {Object.values(currentSection.detections).map((e, i) => {
                                return (
                                    <Circle
                                        key={i}
                                        eventHandlers={{
                                            click: () => {
                                                handleSeekTo(Number((e.frameNumber / FPS)).toFixed(5));
                                                setDistressPinned(e);
                                            }
                                        }}
                                        center={[e.lat, e.lng]}
                                        pathOptions={{ fillColor: conditionMethod === -1 ? '#025196' : currentSectionColor, color: (distressPinned?.defectId ?? "-1") === e.defectId ? `rgba(57, 1, 120,0.9)` : conditionMethod === -1 ? '#025196' : currentSectionColor, fillOpacity: 1, stroke: true, fill: true }}
                                        radius={1}
                                    >
                                        <Tooltip>{e.defectName === -1 ? <></> : defectNames[e.defectName]}</Tooltip>
                                    </Circle>
                                );
                            })}

                        </LayerGroup>
                    </LayersControl.Overlay>} */}
                    {isWholeDistressVisualization ? <></> : (
                        <LayersControl.Overlay name="Each section distress" checked={true}>
                            <LayerGroup>
                                {Object.values(currentSection.detections).filter((eachDetection) => {
                                    return eachDetection.archived ? eachDetection.archived === "0" : eachDetection
                                }).map((e, i) => {
                                    if (!e) return <></>;
                                    return (
                                        <Circle
                                            key={e.defectId || i}
                                            eventHandlers={{
                                                click: () => {
                                                    handleSeekTo(Number((isGroupData ? e.groupFrameNumber / FPS : e.frameNumber / FPS)).toFixed(5));
                                                    setDistressPinned(e);
                                                }
                                            }}
                                            center={[e.lat, e.lng]}
                                            pathOptions={{
                                                fillColor: conditionMethod === -1 ? '#025196' : currentSectionColor,
                                                color: (distressPinned?.defectId ?? "-1") === e.defectId ? `rgba(57, 1, 120,0.9)` : conditionMethod === -1 ? '#025196' : currentSectionColor,
                                                fillOpacity: 1,
                                                stroke: true,
                                                fill: true
                                            }}
                                            radius={1}
                                        >
                                            <Tooltip>{e.defectName === -1 ? <></> : defectNames[e.defectName]}</Tooltip>
                                        </Circle>
                                    );
                                })}
                            </LayerGroup>
                        </LayersControl.Overlay>
                    )}

                    {isWholeDistressVisualization ? <LayersControl.Overlay name="All section distress" checked={isWholeDistressVisualization}>
                        <LayerGroup>
                            {allDetections.filter((eachDetection) => {
                                return eachDetection.archived ? eachDetection.archived === "0" : eachDetection
                            }).map((e, i) => {
                                return (
                                    <Circle
                                        key={i}
                                        eventHandlers={{
                                            click: () => {
                                                handleSeekTo(Number((isGroupData ? e.groupFrameNumber / FPS : e.frameNumber / FPS)).toFixed(5));
                                                setDistressPinned(e);
                                            }
                                        }}
                                        center={[e.lat, e.lng]}
                                        pathOptions={{
                                            fillColor: conditionMethod === -1 ? '#025196' : SmartUtils.supportPCIProject(projectType) && conditionMethod === 1 ? SmartUtils.pciColor(e.info.defaultCondition ?? e.info.condition) : SmartUtils.supportCCIProject(projectType) && conditionMethod === 2 ? SmartUtils.cciColor(e.info.weightedCondition ?? e.info.condition, cciInverted) : SmartUtils.rciColor(e.info.condition, typeOfRoad),
                                            color: (distressPinned?.defectId ?? "-1") === e.defectId ? `rgba(57, 1, 120,0.9)` : conditionMethod === -1 ? '#025196' :
                                                SmartUtils.supportPCIProject(projectType) && conditionMethod === 1 ? SmartUtils.pciColor(e.info.defaultCondition ?? e.info.condition, cciInverted) :
                                                    SmartUtils.supportCCIProject(projectType) && conditionMethod === 2 ? SmartUtils.cciColor(e.info.weightedCondition ?? e.info.condition, cciInverted)
                                                        : SmartUtils.rciColor(e.info.condition, typeOfRoad), fillOpacity: 1, stroke: true, fill: true
                                        }}
                                        radius={1}
                                    >
                                        <Tooltip>{e.defectName === -1 ? <></> : defectNames[e.defectName]}</Tooltip>
                                    </Circle>
                                );
                            })}

                        </LayerGroup>
                    </LayersControl.Overlay> : <></>}
                </LayersControl>
            </MapContainer >
        </div >
    )

}
const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
    };
};
export default connect(mapStateToProps)(DistressMap);
