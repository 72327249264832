import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import CustomConditionIndex from './CustomConditionIndex';

const SuperSectionSettings = (props) => {
    const { conditionsList, selectedSuperSection, handleSuperSectionChange,
        superSectionList, conditionMethod, handleConditionMethodChange, drNetworkData } = props;

    return (
        <>
            <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel htmlFor="supersection-select">Select supersection</InputLabel>
                <Select
                    size="small"
                    sx={{
                        width: '100%',
                        backgroundColor: "transparent",
                        color: "#000000",
                        textTransform: "initial",
                        paddingInline: "8px",
                        overflow: "auto",
                        "&:hover": {
                            backgroundColor: "transparent",
                            color: "#000000",
                        },
                    }}
                    value={selectedSuperSection}
                    onChange={handleSuperSectionChange}
                    label="Select supersection"
                    inputProps={{
                        name: 'supersection',
                        id: 'supersection-select',
                    }}
                >
                    {superSectionList.map((option, index) => (
                        <MenuItem key={option.superSectionId} value={option} sx={{ textTransform: 'capitalize' }}>
                            {option.superSectionTitle}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: "100%", marginTop: 3 }}>
                <InputLabel id="condition-method-label">Condition evaluation method</InputLabel>
                <Select
                    labelId="condition-method-label"
                    id="condition-method"
                    value={conditionsList.length === 0 ? -1 : conditionMethod}
                    label="Condition evaluation method"
                    onChange={handleConditionMethodChange}
                >
                    {conditionsList.map((condition) => (
                        <MenuItem key={condition.key} value={condition.key}>
                            {condition.name}
                        </MenuItem>
                    ))}
                    <MenuItem value={-1}>None</MenuItem>
                </Select>
            </FormControl>
            {conditionMethod === 2 ? <CustomConditionIndex height="100%" drNetworkData={drNetworkData} /> : <></>}
        </>
    )
}

export default SuperSectionSettings