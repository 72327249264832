import React, { useEffect, useState } from 'react';
import { Container, Button, TextField, CircularProgress, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { connect } from 'react-redux';
import { clearLinkedAccountMsgAndError, registerLinkedAccount, removeLinkedAccountVerificationMessage } from '../../../../../../store/actions/authActions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Error from '../../../../../cidgets/Error';

const LinkedAccountRegisterForm = (props) => {
    const { auth, userData } = props;
    const { uid } = userData;
    const { registerLinkedAccountLoader, registerLinkedAccountIsDoneAndSent, registerLinkedAccountMessage, checkLinkedAccountVerificationAccountIsDoneAndSent, checkLinkedAccountVerificationAccountMessage } = auth;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    useEffect(() => {
        props.clearLinkedAccountMsgAndError();
        // props.removeLinkedAccountVerificationMessage();
    }, [])
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleEmailChange = (event) => {
        setEmailError(false);
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPasswordError(false);
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setEmailError(false);
        setPasswordError(false);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        if (!isValid) {
            setEmailError(true);
        }
        if (password.length < 8) {
            setPasswordError(true);
        }
        else {
            props.registerLinkedAccount(uid, email, password);
            props.removeLinkedAccountVerificationMessage();
        }
    };

    return (
        <Container fixed>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    error={emailError}
                    helperText={emailError ? "Incorrect email" : "Please provide a new email and not the one you used for account registration."}
                    onChange={handleEmailChange}
                    disabled={registerLinkedAccountLoader}
                />
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        error={passwordError}

                        // helperText={passwordError ? "Password length should be greater than 7 characters" : "Password should be at least 8 characters"}
                        onChange={handlePasswordChange}
                        disabled={registerLinkedAccountLoader}
                        value={password}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                    <FormHelperText sx={{ color: passwordError ? '#d32f2f' : '' }}>{passwordError ? "Password length should be 8 characters or greater than 8 characters." : "Password should contain 8 character at least."}</FormHelperText>
                </FormControl>
                {
                    registerLinkedAccountLoader ?
                        <Button variant="contained" disabled sx={{
                            marginTop: '20px',
                            textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                            "&.Mui-disabled": {
                                backgroundColor: '#025196',
                                color: '#ffffff'
                            }
                        }}>
                            <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Registering...</span>
                        </Button> :
                        <Button type="submit" size="medium" variant="contained"
                            sx={{
                                marginTop: '20px',
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>Register</Button>
                }
                <div style={{ marginTop: '5px' }}>
                    {/* {checkLinkedAccountVerificationAccountIsDoneAndSent === false ? <Error errorMessage={checkLinkedAccountVerificationAccountMessage} /> : <></>} */}
                    {registerLinkedAccountIsDoneAndSent === false ? <Error errorMessage={registerLinkedAccountMessage} /> : <></>}
                </div>
            </form>
        </Container>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userData: state.firebase.profile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        registerLinkedAccount: (uid, email, password) => dispatch(registerLinkedAccount(uid, email, password)),
        clearLinkedAccountMsgAndError: () => dispatch(clearLinkedAccountMsgAndError()),
        removeLinkedAccountVerificationMessage: () => dispatch(removeLinkedAccountVerificationMessage())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedAccountRegisterForm);