import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { checkLinkedAccountVerification, deleteLinkedAccount, removeLinkedAccountVerificationMessage } from '../../../../../../store/actions/authActions';
import Error from '../../../../../cidgets/Error';

const LinkedAccountVerify = (props,) => {
    const { userData, auth, setActiveStep, linkedAccountUserData } = props;
    const { uid } = userData;
    const { deleteLinkedAccountLoader, checkLinkedAccountVerificationAccountLoader, checkLinkedAccountVerificationAccountIsDoneAndSent, checkLinkedAccountVerificationAccountMessage } = auth;
    const [timer, setTimer] = useState(2 * 24 * 60 * 60); // 2 days in seconds
    const maxTimer = 2 * 24 * 60 * 60; // 2 days in seconds


    useEffect(() => {
        if (linkedAccountUserData) {
            const firebaseTimestamp = linkedAccountUserData?.dateCreated;
            const endTime = firebaseTimestamp.toDate().getTime() + maxTimer * 1000;

            const calculateRemainingTime = () => {
                const currentTime = new Date().getTime();
                const remainingTime = endTime - currentTime;

                if (remainingTime > 0) {
                    setTimer(Math.floor(remainingTime / 1000)); // Convert milliseconds to seconds
                } else {
                    setTimer(0);
                }
            };

            calculateRemainingTime(); // Initial calculation

            const interval = setInterval(calculateRemainingTime, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [maxTimer, linkedAccountUserData]);
    // useEffect(() => {
    //     if (checkLinkedAccountVerificationAccountIsDoneAndSent === false) {
    //         setActiveStep(0);
    //         // props.removeLinkedAccountVerificationMessage();
    //     }
    // }, [checkLinkedAccountVerificationAccountIsDoneAndSent])
    useEffect(() => {
        if (timer === 0) {
            props.checkLinkedAccountVerification(uid);
        }
    }, [timer])
    if (linkedAccountUserData === null) {
        return <></>
    }
    const handleVerificationStatusCheck = () => {
        props.checkLinkedAccountVerification(uid);
    };
    const handleDelete = () => {
        props.deleteLinkedAccount(uid);
    };

    const formatTime = (time) => {
        const days = Math.floor(time / (24 * 60 * 60));
        const hours = Math.floor((time % (24 * 60 * 60)) / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };
    return (
        <div>
            <Typography variant="body1" paragraph>
                We have sent you a link on the linked account email address.
                Please verify and press "Activate account" to continue
                within 2 days.
            </Typography>
            {timer > 0 && (
                <Typography variant="body1" paragraph>
                    Timer: {formatTime(timer)} left
                </Typography>
            )}
            <Box sx={{ marginTop: '20px', }}>
                {/* <Typography variant="body2" paragraph>
                    Note: Please do the verification on your email address and then click activate button else it will not activate your account.
                </Typography> */}
                {
                    checkLinkedAccountVerificationAccountLoader ?
                        <Button variant="contained" disabled sx={{
                            // marginTop: '10px',
                            textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                            "&.Mui-disabled": {
                                backgroundColor: '#025196',
                                color: '#ffffff'
                            }
                        }}>
                            <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Verifying...</span>
                        </Button> :
                        <Button disabled={timer <= 0} onClick={handleVerificationStatusCheck} size="medium" variant="contained"
                            sx={{
                                // marginTop: '10px',
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>Activate account</Button>
                }
                {<>
                    { deleteLinkedAccountLoader?
                        <>
                            <Button variant="contained" disabled sx={{
                                marginLeft: '10px',
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Deleting...</span>
                            </Button>
                        </>
                        :

                        <Button size="medium" variant="contained"
                            onClick={handleDelete}
                            sx={{
                                marginLeft: '10px',
                                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#FFFFFF'
                                }
                            }}>Delete account</Button>
                    }
                </>}
            </Box>
            <div style={{ marginTop: '5px' }}>
                {checkLinkedAccountVerificationAccountIsDoneAndSent === false ? <Error errorMessage={checkLinkedAccountVerificationAccountMessage} /> : <></>}
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userData: state.firebase.profile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        checkLinkedAccountVerification: (uid) => dispatch(checkLinkedAccountVerification(uid)),
        removeLinkedAccountVerificationMessage: () => dispatch(removeLinkedAccountVerificationMessage()),
        deleteLinkedAccount: (uid) => dispatch(deleteLinkedAccount(uid))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedAccountVerify);