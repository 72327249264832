import React, { createContext, useState, useContext } from 'react';

const networkContext = createContext();
export const useNetworkContext = () => {
    return useContext(networkContext);
}; 

const NetworkProvider = ({ children }) => {
    const [isNetworkView, setIsNetworkView] = useState(false);
    const handleChangeView = (event) => {
        setIsNetworkView(event.target.checked);
    };
    return (
        <networkContext.Provider value={{ isNetworkView, handleChangeView }}>
            {children}
        </networkContext.Provider>
    );
};
export default NetworkProvider;