import React from 'react'
import Error from "../../../../../cidgets/Error"
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import { Typography, Button, Grid, MenuItem } from "@mui/material";
import countryList from 'country-list-js';
import { useState } from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import StripeInput from "./StripeInput";
import CircularProgress from "@mui/material/CircularProgress";

let countries = Object.values(countryList.all).sort((a, b) => a.name.localeCompare(b.name));
// //////console.log(countries);
const AddNewCardForm = (props) => {
    const [emailForm, setEmailForm] = useState("");
    const [phoneForm, setPhoneForm] = useState("");
    const [accountTitle, setaccountTitle] = useState("");
    const [country, setCountry] = useState(null);
    const [city, setCity] = useState("");
    const [addressLine1, setaddressLine1] = useState("");
    const [addressLine2, setaddressLine2] = useState("");
    const [postal, setpostal] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    // errors
    const [accountTitleError, setAccountTitleError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const [addressLine1Error, setAdressLine1Error] = useState(false);
    const [addressLine2Error, setAdressLine2Error] = useState(false);
    const [postalError, setPostalError] = useState(false);
    // const [cvcError, setCvcError] = useState(false);
    // const [cardNumberError, setCardNumberError] = useState(false);
    // const [expError, setExpError] = useState(false);
    const { userData, companyData, payment, stripe, elements, buttonText="Add this card" } = props;
    const handleSubmit = async (event) => {
        // //////console.log("CARD ATTACH SUBMIT");
        const { uid } = userData;
        const { coid } = companyData;
        const { stripeSetupIntent, paymentMessage } = payment;
        // Block native form submission.
        event.preventDefault();


        // validation 
        if (accountTitle.length === 0 || accountTitle.length > 64) {
            setAccountTitleError(true);
            setErrorField(true);
            return;
        }
        else if (country === null) {
            setCountryError(true);
            setErrorField(true);
            return;
        }
        // else if (CardCvcElement.length === 0) {
        //     setCvcError(true);
        //     setErrorField(true);
        //     return;
        // }
        // else if (CardExpiryElement.length === 0) {
        //     setExpError(true);
        //     setErrorField(true);
        //     return;
        // }
        // else if (CardNumberElement.length === 0) {
        //     setCardNumberError(true);
        //     setErrorField(true);
        //     return;
        // }
        else if (city.length === 0) {
            setCityError(true);
            setErrorField(true);
            return;
        }
        else if (emailForm.length === 0 || emailForm.length > 64 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailForm)) {
            setEmailError(true);
            setErrorField(true);
            return;
        }
        else if (addressLine1.length === 0 || addressLine1.length > 120) {
            setAdressLine1Error(true);
            setErrorField(true);
            return;
        }
        else if (addressLine2.length > 120) {
            setAdressLine2Error(true);
            setErrorField(true);
            return;
        }
        else if (phoneForm.length === 0 || phoneForm.length === 15 || !/^[0-9+#()\-.\s]{1,}$/.test(phoneForm)) {
            setPhoneError(true);
            setErrorField(true);
            return;
        }
        else if (postal.length === 0 || postal.length > 10) {
            setPostalError(true);
            setErrorField(true);
            return;
        }
        else {
            setErrorField(false);
            setAccountTitleError(false);
            setAdressLine1Error(false);
            setAdressLine2Error(false);
            setPhoneError(false);
            // setCvcError(false);
            // setExpError(false);
            // setCardNumberError(false);
            setPostalError(false);
            setCountryError(false);
            setCityError(false);
            setEmailError(false);
        }




        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            // //////console.log("not loaded");
            return;
        }
        // //////console.log(" loaded");

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const { error, setupIntent } = await stripe.confirmCardSetup(
            `${stripeSetupIntent}`,
            {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        address: {
                            city: city,
                            country: country.iso2,
                            line1: addressLine1,
                            line2: addressLine2,
                            postal_code: postal,
                            state: null,
                        },
                        email: emailForm,
                        name: accountTitle,
                        phone: phoneForm,
                    },
                },
            }
        );

        if (error) {
            // //////console.log("[error]", error);
            setErrorField(true);
            setErrorMessage(error.message);
        } else {
            const paymentMethodId = setupIntent.payment_method;
            props.attachPaymentMethodToCustomer(coid, paymentMethodId);
            // //////console.log("sucess");
        }
    };

    const { isPaymentMethodActed, isPaymentMethodActing, paymentMessage } =
        payment;
    const relevantComponent = !isPaymentMethodActed ? (
        !isPaymentMethodActing ? (
            <Button

                onClick={handleSubmit}
                disabled={!stripe}
                style={{ color: "white" }}
                sx={{
                    paddingInline: '15px',
                    paddingBlock: '11px',
                    textTransform: 'initial',
                    backgroundColor: '#025196', ':hover': {
                        backgroundColor: '#025196'
                    }
                }}
            >
                <span>{buttonText}</span>
            </Button>
        ) : (
            <Button
                variant="contained" disabled
                sx={{
                    "&.Mui-disabled": {
                        backgroundColor: '#025196',
                        color: '#ffffff'
                    },
                    marginInline: '2px',
                    textTransform: 'initial',
                    color: '#025196',
                    backgroundColor: '#025196', ':hover': {
                        backgroundColor: '#025196'
                    }
                }}
            >
                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>
                    Attaching card</span>
            </Button>
        )
    ) : (
        <React.Fragment />
    );


    const relevantMessage = (
        <p style={{ color: `${isPaymentMethodActed ? "black" : "red"}` }}>
            {paymentMessage}
        </p>
    );
    return (
        <div>

            <Grid container spacing={2}>

                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Card holder's name
                    </Typography>
                    <TextField
                        id="account title"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={accountTitleError}
                        helperText={accountTitleError ? "Maximum limit 64 character" : ""}
                        value={accountTitle}
                        onChange={(event) => setaccountTitle(event.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Email
                    </Typography>
                    <TextField
                        id="Email"
                        type="email"
                        variant="outlined"
                        error={emailError}
                        helperText={emailError ? 'Invalid email format. Please enter a valid email address' : ''}
                        fullWidth
                        value={emailForm}
                        onChange={(event) => setEmailForm(event.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Phone
                    </Typography>
                    <TextField
                        type="phone"
                        variant="outlined"
                        fullWidth
                        error={phoneError}
                        helperText={phoneError ? 'Invalid phone number format. Please enter a valid phone number' : ''}
                        value={phoneForm}
                        onChange={(event) => setPhoneForm(event.target.value)}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Country
                    </Typography>
                    <TextField
                        select
                        id="country"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={countryError}
                        helperText={countryError ? 'Please select a country.' : ''}
                        value={country?.name ?? ""}
                        onChange={(event) => setCountry(countries.find((e) => e.name === event.target.value))}
                        required
                    >
                        <MenuItem value="" disabled>
                            Select a country
                        </MenuItem>
                        {countries.map((option, key) => (
                            <MenuItem key={key} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        City
                    </Typography>
                    <TextField
                        id="city"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={cityError}
                        helperText={cityError ? 'Maximum limit 50 characters ' : ''}
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Address line 1
                    </Typography>
                    <TextField
                        id="address"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={addressLine1}
                        error={addressLine1Error}
                        helperText={addressLine1Error ? 'Maximum limit 120 character' : ''}
                        onChange={(event) => setaddressLine1(event.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Address line 2 (optional)
                    </Typography>
                    <TextField
                        id="address"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={addressLine2Error}
                        helperText={addressLine2Error ? 'Maximum limit 120 character' : ''}
                        value={addressLine2}
                        onChange={(event) => setaddressLine2(event.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Postal code
                    </Typography>
                    <TextField
                        id="code"
                        type="text"
                        variant="outlined"
                        fullWidth
                        error={postalError}
                        helperText={postalError ? 'Maximum limit 10 character' : ''}
                        value={postal}
                        onChange={(event) => setpostal(event.target.value)}
                        required
                    />
                </Grid>
                {/* <Grid item xs={12}>
                        <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                            Card details
                        </Typography>
                    </Grid> */}
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Credit card number
                    </Typography>
                    <TextField
                        name="ccnumber"
                        variant="outlined"
                        required
                        fullWidth
                        // helperText={cardNumberError ? 'Please enter a valid expiry number' : ''}
                        // error={cardNumberError}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardNumberElement,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        Expiry date
                    </Typography>
                    <TextField
                        name="ccexp"
                        variant="outlined"
                        required
                        fullWidth
                        // helperText={expError ? 'Please enter a valid expiry' : ''}
                        // error={expError}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardExpiryElement,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography style={{ fontWeight: '550', fontSize: "15px" }}>
                        CVC
                    </Typography>
                    <TextField
                        name="cvc"
                        variant="outlined"
                        required
                        fullWidth
                        // helperText={cvcError ? 'Maximum limit 4 characters' : ''}
                        // error={cvcError}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardCvcElement,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                {errorField ? <Error errorMessage={errorMessage} /> : <Typography variant="body2"> {relevantMessage} </Typography>}
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                    {relevantComponent}
                </Grid>
            </Grid>
        </div >
    );
}

export default AddNewCardForm;