import React, { createRef, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Button, CircularProgress } from '@mui/material'
import { Divider } from "@mui/material";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { removeUploadGpsErrorAndMessage, uploadGps } from '../../../../../../../store/actions/projectActions';
import { get } from "lodash";
import SmartUtils from '../../../../../../../helpers/SmartUtils';
import CustomProgress from '../../../../../../cidgets/CustomProgress';
import Error from '../../../../../../cidgets/Error';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} color="success" sx={{ backgroundColor: '#E1E3E2' }} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const GpsExtractionStep = (props) => {
    const [preview, setPreview] = useState([]);
    // //////console.log(preview,'jack')
    const { project, proid, drProjectData } = props;
    const { status, gpsApprovalMessage, gpsMessage, isGpsUploadingOnDb } = drProjectData;
    const { uploadGpsStatus } = project;
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        accept: {
            'application/gpx+xml': ['.gpx'],
            "application/vnd.google-earth.kml+xml": ['.kml'],
        },
        minSize: 10,
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => {
            setPreview(prevFiles => [...prevFiles, ...acceptedFiles]);

        }
    });
    const removeFile = file => () => {
        setPreview(prevFiles => prevFiles.filter(prevFile => prevFile !== file));
    }

    const removeAll = () => {
        setPreview([])
    }

    useEffect(() => {
        props.removeUploadGpsErrorAndMessage();
    }, [])
    const uploadGps = (proid) => {
        // //////console.log(proid, preview, 'dank')
        // props.uploadGps(proid, preview);
        setPreview([]);
    }

    useEffect(() => {
        if (status === -5) {
            setPreview([])
        }

    }, [status])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(preview);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setPreview(items);
    };

    const gpsProgress = proid in uploadGpsStatus ? uploadGpsStatus[proid]['gpsProgress'] : null
    const isGpsUploading = proid in uploadGpsStatus ? uploadGpsStatus[proid]['isGpsUploading'] : null
    const gpsErrorMessage = proid in uploadGpsStatus ? uploadGpsStatus[proid]['gpsErrorMessage'] : null
    const gpsApiCalling = proid in uploadGpsStatus ? uploadGpsStatus[proid]['gpsApiCalling'] : false;
    const gpsApiMessage = proid in uploadGpsStatus ? uploadGpsStatus[proid]['gpsApiMessage'] : '';
    const isLoading = proid in uploadGpsStatus ? uploadGpsStatus[proid]['isLoading'] : false;
    // //////console.log(isLoading, "call")
    const su = new SmartUtils();
    
    const files = <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable">
        {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
                {preview.map((file, index) => (
                    <Draggable key={file.name} draggableId={file.name} index={index}>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Stack spacing={1} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
                                    <span style={{ width: 'fit-content' }}><b>Name:</b> {file.name}</span>
                                    {!isLoading? (
                                        <>
                                            <Divider ligth sx={{ width: 'fit-content' }} />
                                            <span style={{ width: 'fit-content' }}>
                                                <a style={{ color: 'inherit' }} href="#!" onClick={removeFile(file)}>
                                                    <b>Remove file</b>
                                                </a>
                                            </span>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Stack>
                            </div>
                        )}
                    </Draggable>
                ))}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
</DragDropContext>

    const inputVideoAllowedStatus = [-3, -4, -5];
    const boxHeight = '25vh'
    const inputDiv = (<div onClick={open} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
        <input {...getInputProps()} />
        <Stack>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* <CloudUploadIcon sx={{ fontSize: '75px' }} /> */}
            </div>

            <p>Drag and drop a GPS file here, or click to select a file</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <em>(Only *.KML and *.GPX files will be accepted.)</em>
            </div>
            <p style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>{status === -5 ? <Error errorMessage={gpsApprovalMessage} /> : <></>}</p>
        </Stack>
    </div>)
    
    const afterInputDiv = (<div style={{ height: `calc(100% - 4px)`, width: '100%', backgroundColor: 'transparent', padding: '30px' }}>
        <Grid container spacing={0}>
            <Grid item xs={12} sx={{ height: `calc(100% - 4px)`, backgroundColor: '', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}>
                {isGpsUploadingOnDb === true ? <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', alignItems: 'center' }}><CustomProgress text={gpsMessage} /></div> : files}
            </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            {status === 11 || isGpsUploading ?
                <Box sx={{ width: '90%', marginTop: '40px' }}>
                    {gpsProgress === null ?
                        <LinearProgress />
                        : <LinearProgressWithLabel value={gpsProgress} />
                    }
                </Box>
                :
                // isLoading || isGpsUploadingOnDb ?
                //   <>
                //     <CustomProgress />
                //   </>

                //   :
                <>
                    {isGpsUploadingOnDb === true ? <></> :
                        <>
                            <Button size="large" variant="contained" onClick={() => { uploadGps(proid) }} sx={{
                                marginTop: '20px',
                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                }
                            }}>
                                {/* Upload the gps */}
                                Upload
                            </Button>

                            <Button size="large" variant="contained" onClick={open} sx={{
                                marginTop: '20px',
                                marginLeft: '20px',
                                textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                }
                            }}>Attach more gps</Button>
                        </>
                    }
                </>}

        </div>
    </div>)
    return (
        <Grid container>
            <Grid item xs={12} style={{ margin: '40px' }}>
                <div style={{ backgroundColor: '', height: 'calc(100vh - 20vh - 80px)', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                    <div style={{ height: 'calc(100vh - 20vh -80px)' }} {...getRootProps({ className: 'dropzone' })}>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start', backgroundColor: '' }}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    minWidth: '500px',
                                    maxWidth: '1000px',
                                    width: '100vw',
                                    height: 'fit-content',
                                    backgroundColor: 'transparent',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: acceptedFiles.length > 0 ? '' : 'pointer',
                                    border: '2px dashed grey',
                                    borderRadius: '20px',
                                    color: 'grey'
                                }}
                            >
                                <div style={{ height: `calc(100% - 4px)`, width: '100%', backgroundColor: '' }}>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} sx={{ height: `calc(100% - 4px)`, backgroundColor: '', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                                            {status === 11 ?
                                                <Stack sx={{ padding: '30px' }}>
                                                    <CustomProgress text={"Gps extracting"} />
                                                </Stack>
                                                : <Stack sx={{ width: '100%' }}>
                                                    {
                                                        inputVideoAllowedStatus.includes(status) ?
                                                            preview.length > 0 || isGpsUploadingOnDb ?
                                                                afterInputDiv :
                                                                inputDiv
                                                            :
                                                            preview.length > 0 || gpsProgress || isGpsUploading ?
                                                                afterInputDiv
                                                                :
                                                                inputDiv

                                                    }
                                                </Stack>
                                            }

                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                        </div>
                    </div>

                </div>
            </Grid>

        </Grid>
    )
}
const mapStateToProps = (state, ownProps) => {
    const { proid } = ownProps
    return {
        drProjectData: get(
            state.firestore.data,
            `DrProject.${proid}`
        ),
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        uploadGps: (proid, gpsFile) => dispatch(uploadGps(proid, gpsFile)),
        removeUploadGpsErrorAndMessage: () => dispatch(removeUploadGpsErrorAndMessage())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GpsExtractionStep);
