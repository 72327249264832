import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import paymentReducer from "./paymentReducer";
import authReducer from "./authReducer";
import themeReducer from "./themeReducer";
import projectReducer from "./projectReducer";
import datastoreReducer from "./datastoreReducer";
import appControlReducer from "./appControlReducer";
import controlroomReducer from "./controlroomReducer";
import networkReducer from './networkReducer';
import companyReducer from "./companyReducer";
import customFirestoreReducer from './firestoreReducer';
const persistConfig = {
  key: "drroot",
  storage,
  //DOCS: Conflicting datastore so uncomment when release build.
  whitelist: [],
};

const mainReducer = combineReducers({

  
  // APP CONTROL AN AUTH
  auth: authReducer,
  project: projectReducer,

  firestoreQuery: customFirestoreReducer,

  // FIREBASE
  firebase: firebaseReducer,
  firestore: firestoreReducer,

  // THEME
  themeRed: themeReducer,

  // APP CONTROL
  appControl: appControlReducer,

  company: companyReducer,

  payment: paymentReducer,
  // control Room reducer
  controlRoom: controlroomReducer,
  ////////////////////////////////////////////////
  // DATASTORE TO CACHE DATA (SPECIFICALLY DESIGNED FOR REPORTS)
  datastore: datastoreReducer,

  network: networkReducer,
});

export default persistReducer(persistConfig, mainReducer);
