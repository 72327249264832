import { IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SimpleDialogBox from '../../../../cidgets/SimpleDialogBox';

const NetworkNotification = (props) => {
    const { ctrl } = props;
    const [openNotifDialog, setOpenNotifDialog] = useState(false);
    const handleOpenNotifDialog = () => {
        setOpenNotifDialog(true);
    }
    const handleCloseNotifDialog = () => {
        setOpenNotifDialog(false);
    }



    return (
        <>
            <SimpleDialogBox
                open={openNotifDialog}
                onClose={handleCloseNotifDialog}
                title={"Notifications"}
                cancelText="Close"
                bodyWidget={
                    <>No notifications found</>
                }
                width={600}
            />
            <Tooltip title={`Network notifications`} arrow placement='bottom'>
                <IconButton
                    size="small"
                    aria-label="show new notifications"
                    sx={{
                        backgroundColor: 'tranparent', color: 'black', padding: '10px', marginInline: '8px', ':hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                    onClick={handleOpenNotifDialog}
                >
                    <NotificationsNoneOutlinedIcon sx={{ color: 'black' }} />
                    {/* <Badge
                    badgeContent={calculateRead()}
                    // variant="dot"
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    max={9}
                >
                    <NotificationsNoneOutlinedIcon sx={{ color: 'black' }} />
                </Badge> */}

                </IconButton>
            </Tooltip>

        </>
    )
}

export default NetworkNotification