class SmartUtils {
    constructor() { }

    _try(func, fallbackValue) {
        try {
            var value = func();
            return (value === null || value === undefined) ? fallbackValue : value;
        } catch (e) {
            return fallbackValue;
        }
    }

    _tryKey(func, key, fallbackValue) {
        try {
            var value = func();
            return (value === null || value === undefined || key === null || key === undefined) ? fallbackValue : key in value ? value[key] : fallbackValue;
        } catch (e) {
            return fallbackValue;
        }
    }

    _capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || "";

    _formatBytes = (bytes, decimals = 2) => {
        // //////console.log(bytes, 'sizebyte')
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    _checkOS = async (n) => {
        if (n.userAgentData) {
            const hints = ["architecture", "model", "platform", "platformVersion", "uaFullVersion"];
            const data = await n.userAgentData.getHighEntropyValues(hints)
            // //////console.log(data)
            return data
        } else {
            const isSafari = n.vendor && n.vendor.indexOf('Apple') > -1 &&
                n.userAgent &&
                n.userAgent.indexOf('CriOS') == -1 &&
                n.userAgent.indexOf('FxiOS') == -1;
            if (isSafari) {
                return {
                    platform: 'macOS'
                }
            }

            // //////console.log(n.userAgent);
            return "n.userAgentData is not supported!";
        }
    }

    _platformKeys = (platform) => {
        const isMac = platform === "macOS"
        const CTRL_COMMAND = isMac ? "⌘" : "Ctrl+"
        return {
            ctrl: {
                name: CTRL_COMMAND,
                plus: isMac ? '' : '+'
            }
        }
    }

    static formatTime = (seconds = 0) => {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);

        var timeStr = '';

        if (hours > 0) {
            timeStr += hours + ' hour ';
        }

        if (minutes > 0) {
            timeStr += minutes + ' min ';
        }

        if (hours === 0 && minutes === 0) {
            timeStr += 'less than a min';
        }

        return timeStr;
    }

    static shortenNumber = (number) => {
        if (number >= 1e9) {
            return (number / 1e9).toFixed(1) + 'b';
        } else if (number >= 1e6) {
            return (number / 1e6).toFixed(1) + 'm';
        } else if (number >= 1e3) {
            return (number / 1e3).toFixed(1) + 'k';
        } else {
            return number.toString();
        }
    };



    static safetyPerformanceColor = (score = 'Good') => {
        let color = '#8BFF6D';
        if (score === 'Medium') {
            color = '#FCAE66';
        } else if (score === 'Bad') {
            color = '#F85D5D';
        }
        return color
    }

    /**
    * Produces a CSS string with edge. Use it to create padding, margin, border etc
    *
    * @param   inset  Must be a number. Considered as px
    * @param   key  Example: trbl, tbl, bl, l (where t: top-left, r: top-right, b: bottom-left, l: bottom-right)
    * @returns string.
    */
    static edgeInsets = (inset, key = "") => {
        return new SmartUtils()._createPadding(inset, key)
    }

    _createPadding = (padding, key = "") => {
        const keys = ['t', 'r', 'b', 'l']
        const keysReq = key.split('')

        if (keysReq.length === 0) {
            return [`${padding}px`, `${padding}px`, `${padding}px`, `${padding}px`].join(' ');
        }

        let paddings = [`${0}px`, `${0}px`, `${0}px`, `${0}px`];
        // //////console.log(keysReq)
        for (let i = 0; i < keysReq.length; i++) {
            const index = keys.indexOf(keysReq[i]);
            paddings[index] = `${padding}px`
        }

        return paddings.join(' ')
    }

    static checkInRange(lower, upper, value) {
        // //////console.log(Number(lower) <= Number(value) && Number(upper) > Number(value), "check", lower, value, upper)
        return Number(lower) <= Number(value) && Number(upper) > Number(value)
    }

    static rciColor = (score = 0, typeOfRoad) => {
        let color = 'green';
        if (typeOfRoad === 'cycleTracks') {
            if (score >= 17.8 && score <= 44.4) {
                color = 'darkorange';
            } else if (score > 44.4) {
                color = 'red';
            }
            else if (score < 0) {
                color = '#1F0851';
            }
        }
        else {
            if (score >= 30.5 && score <= 76.2) {
                color = 'darkorange';
            } else if (score > 76.2) {
                color = 'red';
            }
            else if (score < 0) {
                color = '#1F0851';
            }
        }
        return color
    }

    static getDefectNamesList = (projectType) => {
        let defectNames;
        if (projectType === 1 || projectType === 5) {
            defectNames = [
                { defectId: 0, name: 'Pothole' },
                { defectId: 1, name: 'Crack' },
                { defectId: 2, name: 'Subsidence' },
            ];
        }
        else {
            defectNames = [
                { defectId: 0, name: 'Pothole' },
                { defectId: 1, name: 'Crack' }
            ];
        }
        return defectNames;
    }

    static pciColor = (score = 0) => {
        let color = '#025196';
        if (Math.round(score) >= 0 && Math.round(score) <= 20) {
            color = 'red';
        } else if (Math.round(score) >= 21 && Math.round(score) <= 40) {
            color = 'darkorange';
        }
        else if (Math.round(score) >= 41 && Math.round(score) <= 60) {
            color = 'yellow';
        }
        else if (Math.round(score) >= 61 && Math.round(score) <= 80) {
            color = '#57C018';
        }
        else if (Math.round(score) >= 81 && Math.round(score) <= 100) {
            color = 'darkgreen';
        }
        return color
    }
    static cciColor = (score = 0, cciInverted = false) => {
        let color = '#025196';
        if (cciInverted) {
            if (Math.round(score) >= 0 && Math.round(score) <= 20) {
                color = 'darkgreen';
            } else if (Math.round(score) >= 21 && Math.round(score) <= 40) {
                color = '#57C018';
            }
            else if (Math.round(score) >= 41 && Math.round(score) <= 60) {
                color = 'yellow';
            }
            else if (Math.round(score) >= 61 && Math.round(score) <= 80) {
                color = 'darkorange';
            }
            else if (Math.round(score) >= 81 && Math.round(score) <= 100) {
                color = 'red';
            }
        }
        else {
            if (Math.round(score) >= 0 && Math.round(score) <= 20) {
                color = 'red';
            } else if (Math.round(score) >= 21 && Math.round(score) <= 40) {
                color = 'darkorange';
            }
            else if (Math.round(score) >= 41 && Math.round(score) <= 60) {
                color = 'yellow';
            }
            else if (Math.round(score) >= 61 && Math.round(score) <= 80) {
                color = '#57C018';
            }
            else if (Math.round(score) >= 81 && Math.round(score) <= 100) {
                color = 'darkgreen';
            }
        }

        return color
    }

    static mapScoreColor = (score = 0) => {
        let color = '';
        if (score !== -1) {
            if (Math.round(score) >= 0 && Math.round(score) <= 50) {
                color = 'red';
            } else if (Math.round(score) >= 21 && Math.round(score) <= 40) {
                color = '#57C018';
            }
            else if (Math.round(score) >= 51 && Math.round(score) <= 75) {
                color = 'darkorange';
            }
            else if (Math.round(score) >= 76 && Math.round(score) <= 100) {
                color = 'green';
            }
        }
        return color
    }
    static supportCCIProject(projectType) {
        const type = [1, 4];
        return type.includes(projectType);
    }
    static supportPCIProject(projectType) {
        const type = [1, 4];
        return type.includes(projectType);
    }
    static scoreText(score = 0, typeOfRoad, captitalize = false, debug = false,) {
        const scores = typeOfRoad === 'cycleTracks' ? [[0, 17.8], [17.8, 44.4], [44.4, 500]] : [[0, 30.5], [30.5, 76.2], [76.2, 500]]
        const scoresMap = { 0: "Excellent", 1: "Good", 2: 'Poor' }
        // const scores = [[0, 15], [15, 30], [30, 55], [55, 75], [75, 100]]
        // const scoresMap = { 0: "worse", 1: "bad", 2: 'fair', 3: 'good', 4: 'excellent' }

        const selectedScore = scores.filter((eachScores) => (eachScores[0] <= score && eachScores[1] >= score))[0]
        if (debug) {
            //  //////console.log(selectedScore, score, scoresMap[scores.indexOf(selectedScore)]) 
        }
        return captitalize ? new SmartUtils()._capitalize(scoresMap[scores.indexOf(selectedScore)]) : scoresMap[scores.indexOf(selectedScore)]
    }
    static scoreTextCCI(score = 0, cciInverted = false, captitalize = false, debug = false) {
        const scores = [[0, 20], [21, 40], [41, 60], [61, 80], [81, 100]]
        const scoresMap = cciInverted ? { 0: "Good", 1: "Satisfactory", 2: 'Fair', 3: "Poor", 4: "Serious" }
            : { 0: "Serious", 1: "Poor", 2: 'Fair', 3: "Satisfactory", 4: "Good" }

        const selectedScore = scores.filter((eachScores) => (eachScores[0] <= score && eachScores[1] >= score))[0]
        if (debug) {
            //  //////console.log(selectedScore, score, scoresMap[scores.indexOf(selectedScore)]) 
        }
        return captitalize ? new SmartUtils()._capitalize(scoresMap[scores.indexOf(selectedScore)]) : scoresMap[scores.indexOf(selectedScore)]
    }
    static scoreTextPCI(score = 0, captitalize = false, debug = false) {
        const scores = [[0, 20], [21, 40], [41, 60], [61, 80], [81, 100]]
        const scoresMap = { 0: "Serious", 1: "Poor", 2: 'Fair', 3: "Satisfactory", 4: "Good" }
        const selectedScore = scores.filter((eachScores) => (eachScores[0] <= score && eachScores[1] >= score))[0]
        console.log(selectedScore, "selectedScore")
        if (debug) {
            //  //////console.log(selectedScore, score, scoresMap[scores.indexOf(selectedScore)]) 
        }
        return captitalize ? new SmartUtils()._capitalize(scoresMap[scores.indexOf(selectedScore)]) : scoresMap[scores.indexOf(selectedScore)]
    }
    static individualVideoMapColor(index) {
        const sectionIdToColor = {
            0: 'red',
            1: 'green',
            2: 'yellow',
            3: 'blue',
            4: 'orange',
            5: 'purple',
            6: 'brown',
            7: 'indigo',
            8: 'teal',

            9: 'red',
            10: 'green',
            11: 'yellow',
            12: 'blue',
            13: 'orange',
            14: 'purple',
            15: 'brown',
            16: 'indigo',
            17: 'teal',

            18: 'red',
            19: 'green',
            20: 'yellow',
            21: 'blue',
            22: 'orange',
            23: 'purple',
            24: 'brown',
            25: 'indigo',
            26: 'teal'
        };
        return sectionIdToColor[index] ?? sectionIdToColor[0];
    }
    static statusIndicatorColor(status) {
        const color = {
            "0": 'red',
            "1": 'darkorange',
            "2": 'green',
            "3": '#025196',
            "-3": "red",
        };
        return color[status];
    }

    static detectionColor(status) {
        const color = {
            "0": "#004cff",
            "1": "#60EE00",
            "3": "#00ee83",
            "4": "#00eeda",
            "10": "#ee1000",
            "-1": "#5f00ee"
        };
        return color[status] ?? color["-1"]
        // || "#5f00ee";
    }
}

export default SmartUtils;