import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { connect } from 'react-redux';
import CustomProgress from '../../../../cidgets/CustomProgress';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);




function LineChart(props) {
    const { project } = props;
    const { depthCrossSectionalProfileIsDoneAndSent, depthCrossSectionalData } = project;

    // const listOfIndicesContainingNan = ([...depthCrossSectionalData?.depthVector??[]]).map((e, i) => {return e==="nan" ? i : undefined}).filter((e) => e !== undefined)
    // const filteredDistanceVector = [...depthCrossSectionalData?.distVector??[]].filter((e, i) => !listOfIndicesContainingNan.includes(i))
    // const filteredDepthVector = [...depthCrossSectionalData?.depthVector??[]].filter((e, i) => !listOfIndicesContainingNan.includes(i))

    // const lastPointOfDistanceData = depthCrossSectionalData ? Number(filteredDistanceVector[filteredDistanceVector.length - 1]) : 0
    const lastPointOfDistanceData = depthCrossSectionalData ? Number(depthCrossSectionalData.distVector[depthCrossSectionalData.distVector.length - 1]) : 0
    const options = {
        plugins: {
            legend: {
                display: false,
            }
        },
        // Ref:  https://www.chartjs.org/docs/2.9.4/general/responsive.html
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: -100,
                        max: 100,
                    },
                },
            ],
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Distance (m)',
                },
            },
            y: {
                type: 'linear',
                position: 'left',
                title: {
                    display: true,
                    text: 'Depth (mm)',
                },
            },
        },
    };

    const data = {
        // labels: depthCrossSectionalProfileIsDoneAndSent ? Object.values(filteredDistanceVector).map((eachdist) => Number(eachdist)) : [],
        labels: depthCrossSectionalProfileIsDoneAndSent ? Object.values(depthCrossSectionalData.distVector).map((eachdist) => Number(eachdist)) : [],
        datasets: [
            {
                fill: false,
                lineTension: 0.1,
                borderColor: 'black',
                borderWidth: 1,
                pointBackgroundColor: ['green', 'red'],
                pointRadius: 5, // Set the size of the points
                data: [
                    { x: 0, y: 0 },
                    { x: lastPointOfDistanceData, y: 0 },
                ],
            },
            {
                // data: depthCrossSectionalProfileIsDoneAndSent ? filteredDepthVector : [],
                data: depthCrossSectionalProfileIsDoneAndSent ? depthCrossSectionalData.depthVector : [],
                backgroundColor: '#041256',
                borderColor: '#041256',
                // backgroundColor: 'rgba(75, 192, 192, 0.2)',
                // borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
                pointRadius: 0,
                borderWidth: 1,

            },
        ],
    };
    return <>
        {
            depthCrossSectionalProfileIsDoneAndSent ?
                <Line options={options} data={data} width={`100%`} />
                :
                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomProgress text={"Loading data, please wait"} />
                </div>
        }

    </>;
}
const mapStateToProps = (state) => {
    return {
        project: state.project,
    };
};
export default connect(mapStateToProps)(LineChart);

