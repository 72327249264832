import React, { createContext, useState, useContext } from 'react';

const tooltipContext = createContext();
export const useTooltipContext = () => {
    return useContext(tooltipContext);
};

const TooltipProvider = ({ children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleTooltipToggle = () => {
        setShowTooltip((prev) => !prev);
    };
    const handleCloseTooltipToggle = () => {
        setShowTooltip(false);
    };
    return (
        <tooltipContext.Provider value={{ showTooltip, handleTooltipToggle, handleCloseTooltipToggle }}>
            {children}
        </tooltipContext.Provider>
    );
};
export default TooltipProvider;