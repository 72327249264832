import React, { useEffect, useState } from 'react';
import { Stage, Layer, Rect, Transformer, Image, Text } from "react-konva";
import { Typography } from "@mui/material";
import { Portal, Html } from 'react-konva-utils'
import { useGenerateSignedUrl } from '../../../../../chooks/useGenerateSignedUrl';

const Rectangle = ({ shapeProps, isSelected, editBox, onSelect, onChange, aspectRatio, maxBoxSize = 5, stroke, strokeWidth }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    React.useEffect(() => {
        if (isSelected && editBox) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();

        }
    }, [isSelected, editBox]);
    ////////console.log((aspectRatio)
    ////////console.log((shapeProps)

    function getCorner(pivotX, pivotY, diffX, diffY, angle) {
        const distance = Math.sqrt(diffX * diffX + diffY * diffY);

        /// find angle from pivot to corner
        angle += Math.atan2(diffY, diffX);

        /// get new x and y and round it off to integer
        const x = pivotX + distance * Math.cos(angle);
        const y = pivotY + distance * Math.sin(angle);

        return { x: x, y: y };
    }

    function getClientRect(rotatedBox) {
        const { x, y, width, height } = rotatedBox;
        const rad = rotatedBox.rotation;

        const p1 = getCorner(x, y, 0, 0, rad);
        const p2 = getCorner(x, y, width, 0, rad);
        const p3 = getCorner(x, y, width, height, rad);
        const p4 = getCorner(x, y, 0, height, rad);

        const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
        const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
        const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
        const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    }

    function getTotalBox(boxes) {
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;

        boxes.forEach((box) => {
            minX = Math.min(minX, box.x);
            minY = Math.min(minY, box.y);
            maxX = Math.max(maxX, box.x + box.width);
            maxY = Math.max(maxY, box.y + box.height);
        });
        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    }

    if (editBox) {
        return (
            <React.Fragment>
                <Rect
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                    onClick={onSelect}
                    onTap={onSelect}
                    ref={shapeRef}
                    {...shapeProps}
                    draggable
                    // onDragStart={(e) => {
                    //     onChange({
                    //         ...shapeProps,
                    //         x: e.target.x(),
                    //         y: e.target.y()
                    //     });
                    // }}
                    onDragEnd={(e) => {
                        // //////console.log(e.target)
                        onChange({
                            ...shapeProps,
                            x: e.target.x(),
                            y: e.target.y()
                        });
                    }}
                    onTransformEnd={(e) => {
                        // transformer is changing scale of the node
                        // and NOT its width or height
                        // but in the store we have only width and height
                        // to match the data better we will reset scale on transform end
                        const node = shapeRef.current;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        // we will reset it back
                        node.scaleX(1);
                        node.scaleY(1);
                        onChange({
                            ...shapeProps,
                            x: node.x(),
                            y: node.y(),
                            // set minimal value
                            width: Math.max(maxBoxSize, node.width() * scaleX),
                            height: Math.max(node.height() * scaleY)
                        });
                    }}
                />
                {isSelected && (
                    <Transformer
                        anchorSize={5}
                        // anchorStroke= {'red'}
                        // anchorFill= {'yellow'}
                        borderStroke={'transparent'}
                        // borderDash= {[3, 3]}
                        rotateEnabled={false}
                        ref={trRef}
                        // dragBoundFunc={(oldBox, newBox) => {
                        //     ////////console.log((newBox,'box1')
                        //     if (newBox.x < 0 || newBox.y < 0) {
                        //         return oldBox;
                        //     }
                        //     return newBox;
                        // }}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < maxBoxSize || newBox.height < maxBoxSize) {
                                return oldBox;
                            }

                            const stageWidth = aspectRatio.width;
                            const stageHeight = aspectRatio.height;
                            const box = getClientRect(newBox);
                            const isOut =
                                box.x < 0 ||
                                box.y < 0 ||
                                box.x + box.width > stageWidth ||
                                box.y + box.height > stageHeight;

                            if (isOut) {
                                console.table('oldbox', oldBox)
                                return oldBox;
                            }
                            console.table('newbox', newBox)

                            return newBox;
                        }}

                        onDragMove={() => {
                            const boxes = trRef.current.nodes().map((node) => node.getClientRect());
                            const box = getTotalBox(boxes);
                            trRef.current.nodes().forEach((shape) => {
                                const absPos = shape.getAbsolutePosition();
                                // Where are shapes inside the bounding box of all shapes?
                                const offsetX = box.x - absPos.x;
                                const offsetY = box.y - absPos.y;

                                // If the total box goes outside of the viewport, we need to move the absolute position of the shape
                                const newAbsPos = { ...absPos };
                                if (box.x < 0) {
                                    newAbsPos.x = -offsetX;
                                }
                                if (box.y < 0) {
                                    newAbsPos.y = -offsetY;
                                }
                                if (box.x + box.width > aspectRatio.width) {
                                    newAbsPos.x = aspectRatio.width - box.width - offsetX;
                                }
                                if (box.y + box.height > aspectRatio.height) {
                                    newAbsPos.y = aspectRatio.height - box.height - offsetY;
                                }
                                //////console.log(("newAbsPos: ", newAbsPos)
                                shape.setAbsolutePosition(newAbsPos);
                            });
                        }}
                    />
                )}
            </React.Fragment>
        );
    }
    else {
        return (
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                stroke={stroke}
                strokeWidth={strokeWidth}
                {...shapeProps}


            />
        );
    }
};

const initialRectangles = [
    {
        x: 112,
        y: 118,
        width: 200,
        height: 200,
        fill: 'transparent',
        // id: 'rect1',
    }
];

const resetButton = [
    {
        x: 112,
        y: 200,
        width: 50,
        height: 50,
        fill: 'rgba(255, 0, 0, 0.3)',
        // id: 'rect1',
    }
];

const ImageWithAspectRatio = ({ isEdit = false, setIsBboxReset, isBboxReset, imageUrl, setBbox, bbox, drawMap, editBox, onValueChange = (value = []) => { } }) => {
    const [image, setImage] = useState(null);
    const [originalWidth, setOriginalWidth] = useState(0);
    const [originalHeight, setOriginalHeight] = useState(0);
    const [svgDimensions, setSvgDimensions] = useState({ height: 1, width: 1, scale: 1, offsetTop: 0, offsetLeft: 0 });
    const [defectNameList, setDefectNameList] = useState([]);
    const [rectangles, setRectangles] = React.useState(bbox || initialRectangles);

    const { status, error, data } = useGenerateSignedUrl(imageUrl)
    const isFetched = status === 'fetched'

    useEffect(() => {
        if (!isEdit)
            if (bbox) {
                // setRectangles(bbox);
                updateScaledRectangles();
            }
    }, [bbox])
    useEffect(() => {
        const img = new window.Image();
        img.src = data;
        img.onload = () => {
            setImage(img);
            setOriginalWidth(img.width);
            setOriginalHeight(img.height);
        };
    }, [data]);

    useEffect(() => {
        // window.addEventListener("load", calculateAspectRatio())
        window.addEventListener("resize", getVidWindowSize);
    }, [])

    const getVidWindowSize = () => {
        const img = new window.Image();
        img.src = data;
        img.onload = () => {
            setImage(img);
            setOriginalWidth(img.width);
            setOriginalHeight(img.height);
        };
    };

    const calculateAspectRatio = () => {
        if (image) {
            const stage = document.getElementById('stage');
            const divHeight = stage.clientHeight;
            const divWidth = stage.clientWidth;
            const imageHeight = originalHeight
            const imageWidth = originalWidth

            const asDiv = (divHeight / divWidth)
            const asImg = (imageHeight / imageWidth)
            const ratio = asDiv / asImg

            let svgWidth = 0
            let svgHeight = 0
            if (ratio >= 1) {
                svgWidth = divWidth
                svgHeight = asImg * divWidth
            } else {
                svgHeight = divHeight
                svgWidth = (1 / asImg) * divHeight
            }

            return { width: svgWidth, height: svgHeight };
        }
        return { width: 0, height: 0 };
    };

    const aspectRatio = calculateAspectRatio();
    const scaleFactor = originalWidth / aspectRatio.width;
    ////////console.log((scaleFactor)

    useEffect(() => {
        updateScaledRectangles();
    }, [scaleFactor])

    // ////////console.log((initialRectangles)
    //////console.log((scaleFactor)

    useEffect(() => {
        // //////console.log(isBboxReset, "bbboxReset")
        if (isBboxReset) {
            updateScaledRectangles();
            setIsBboxReset(false);
        }

    }, [bbox])

    const updateScaledRectangles = () => {
        if (!isNaN(scaleFactor)) {
            if (bbox) {
                const scaledRectangles = bbox.map((eachRect, i) => {
                    return {
                        x: eachRect.x / scaleFactor,
                        y: eachRect.y / scaleFactor,
                        width: eachRect.width / scaleFactor,
                        height: eachRect.height / scaleFactor,
                        fill: eachRect.fill,
                        id: eachRect.id
                    }
                })
                // setBoundingBoxCoord()
                ////////console.log((scaledRectangles)
                setRectangles(scaledRectangles)
            }
            else {
                const scaledRectangles = initialRectangles.map((eachRect, i) => {
                    return {
                        x: eachRect.x / scaleFactor,
                        y: eachRect.y / scaleFactor,
                        width: eachRect.width / scaleFactor,
                        height: eachRect.height / scaleFactor,
                        fill: eachRect.fill,
                        id: eachRect.id
                    }
                })
                // setBoundingBoxCoord()
                ////////console.log((scaledRectangles)
                setRectangles(scaledRectangles)
            }
        }
    }

    const handleResetBoundingBoxes = () => {
        updateScaledRectangles();
    };

    onValueChange(rectangles?.map(rectangle => [
        rectangle.x,
        rectangle.y,
        rectangle.width,
        rectangle.height
    ]))

    console.table(rectangles,"please")

    return (
        <>
            {/* <h2>Original Width: {originalWidth}px</h2>
            <h2>Original Height: {originalHeight}px</h2>
            <h1> Width: {aspectRatio.width}px</h1>
            <h1> Height: {aspectRatio.height}px</h1> */}
            <div id='stage' style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', cursor: isEdit ? 'pointer' : '' }}>
                <Stage
                    // width='100%'
                    // height='100%'
                    width={aspectRatio.width} height={aspectRatio.height}
                >
                    {isFetched ? (
                        <Layer>
                            <Image image={image}
                                //  width='100%'
                                //  height='100%'
                                width={aspectRatio.width} height={aspectRatio.height}
                            />
                        </Layer>
                    ) : null}


                    <Layer>
                        {/* {rectangles.map((rect, i) => {
                        return (
                            <Rect
                                x={rect.x / scaleFactor}
                                y={rect.y / scaleFactor}
                                width={rect.width / scaleFactor}
                                height={rect.height / scaleFactor}
                                fill={rect.fill}
                                id={rect.id}
                            />
                        );
                    })} */}


                        {rectangles.map((rect, i) => {
                            return (
                                        <Rectangle
                                            key={i}
                                            shapeProps={rect}
                                            isSelected={true}
                                            editBox={editBox}
                                            // isSelected={rect.id === selectedId}
                                            // onSelect={() => {
                                            //     selectShape(rect.id);
                                            // }}
                                            onChange={(newAttrs) => {
                                                const rects = rectangles.slice();
                                                rects[i] = newAttrs;
                                                setRectangles(rects);
                                                setBbox(rects);
                                            }}
                                            aspectRatio={aspectRatio}
                                            stroke={'#60EE00'}
                                            strokeWidth={3}
                                        />
                                       
                            );
                        })}
                    </Layer>

                    {/* <Layer>
                        {editBox && (
                            <Text
                                x={40}
                                y={615}
                                // ref={textRef}
                                text="Reset Button"
                                fill="white"
                                fontFamily="sans-serif"
                                fontSize={16}
                                onClick={handleResetBoundingBoxes}
                                width={aspectRatio.width}
                                height={aspectRatio.height}
                            />
                        )}
                    </Layer> */}
                </Stage>


            </div>
            {/* <button style={{ position: 'absolute', bottom: '30px' }}
                onClick={handleResetBoundingBoxes}>Reset Bounding Boxes</button> */}

            {/* {drawMap ? (
                <Typography
                    variant="body1"
                    onClick={handleResetBoundingBoxes}
                    sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        position: 'absolute',
                        // bottom: '100px',
                        textAlign: 'center'
                    }}
                >
                    Reset Box
                </Typography>
            )
                :
                (
                    <></>
                )} */}
        </>
    );
};

export default ImageWithAspectRatio;
