import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { generateReport } from '../../../../../store/actions/projectActions';
import { Grid, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import SmartUtils from '../../../../../helpers/SmartUtils';
import CustomSeparator from '../../../../cidgets/CustomSeparator';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const DistressTopbar = (props) => {
    const { drProjectData, userData, detections, assetData, dataInfo, startEndLocation, proid, handleCloseDialog, segmentIndex } = props;
    const { nameInitial } = userData;
    // const { projectTitle } = drProjectData;

    const topbarSection1Width = 100;
    // const topbarSection2Width = 70;
    const su = new SmartUtils()
    const [os, setOs] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const data = await su._checkOS(navigator);
            setOs(data);
        }

        fetchData()
    }, [])
    const { ctrl } = su._platformKeys(os?.platform)
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/home`;
        navigate(path);
    };
    // DIALOG CONTROLS
    ///////////////////////////////////////////////////////////
    const [openOverview, setOpenOverview] = useState(false);

    const handleOverviewOpen = () => {
        setOpenOverview(true);
    };

    const handleOverviewClose = () => {
        setOpenOverview(false);
    };
    ////////////////////////////////////////////////////////////
    const keyMap = {
        OVERVIEW_MAC: "command+p",
        OVERVIEW_WIN: "ctrl+p",
        REPORT_MAC: "command+g",
        REPORT_WIN: "ctrl+g",
    };
    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };
    const handleGenReport = () => {
        if (props.project.showGenReportLoader === false) {
            props.generateReport(drProjectData.proid)
        }
    }
    const handlers = preventDefaultHandlers({
        OVERVIEW_MAC: handleOverviewOpen,
        OVERVIEW_WIN: handleOverviewOpen,
        REPORT_MAC: handleGenReport,
        REPORT_WIN: handleGenReport,
    });
    const handleClick = (event, key) => {
        if (event.target.getAttribute('href') === '') {
            event.preventDefault();
            if (key === 1) {
                handleCloseDialog();
            }
        }
    };
    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12} sx={{ backgroundColor: '', borderRadius: '15px' }}>
                    {/* <Card elevation={4} sx={{ backgroundColor: '#FAFAFA', borderRadius: '15px', padding: '0px' }}> */}
                    <div style={{ height: '100%', width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                        {/* <Box sx={{ backgroundColor: '', display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' }, width: { xs: '0%', md: `${topbarSection1Width}%` } }} style={{ height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center' }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="inherit" href="../">
                                    <Typography variant="h6"  component="span" style={{cursor: 'pointer', color: 'black'}}>
                                        Main Dashboard
                                    </Typography>
                                </Link>
                                              
                                    <Typography  onClick={handleCloseDialog} variant="h6" component="span" style={{cursor: 'pointer', color: 'black'}}>
                                        Project Dashboard
                                    </Typography>
                                
                            </Breadcrumbs>
                        </Box> */}
                        <CustomSeparator
                            icon={<NavigateBeforeIcon fontSize="small" />}
                            widget={[
                                <Link underline="hover"
                                    href=""
                                    key="1"
                                    color="text.primary"
                                    onClick={((e) => { handleClick(e, 1) })}
                                    // onClick={handleCloseDialog}
                                    sx={{
                                        cursor: 'pointer', color: 'black',
                                        '&:hover': {
                                            color: 'black',

                                        }
                                    }}
                                >
                                    Dashboard
                                </Link>,
                                <Typography key="3" color="text.primary"
                                    sx={{ textDecoration: 'underline' }}
                                >
                                    Section details
                                </Typography>,]} />
                    </div>
                    {/* </Card> */}
                </Grid>
            </Grid>
        </>
    )
}
const mapStateToProps = (state, ownProps) => {
    // const { proid } = state.project;
    const { proid } = ownProps
    return {
        project: state.project,
        projNotifs: state.firestore.ordered.ProjNotifs,
        userData: state.firebase.profile,
        drProjectData: get(
            state.firestore.data,
            `DrProject.${proid}`
        ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        generateReport: (proid) => dispatch(generateReport(proid)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistressTopbar);