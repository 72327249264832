import { Autocomplete, Checkbox, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { clearSetupNetworkAndMsg } from '../../../../../../store/actions/networkActions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const NetworkSetting = (props) => {
    const { drNetworkData, superSections, allProjects } = props;
    const { showProjectAlert, setShowProjectAlert, selectedProjectList, setSelectedProjectList, projectList, setProjectList } = props;
    const { showSuperSectionAlert, setShowSuperSectionAlert, superSectionList, setSuperSectionList } = props;


    // useEffect(() => {
    //     if (drNetworkData && superSections) {
    //         if (drNetworkData.associatedSuperSections) {
    //             const superSectionList = superSections.filter(ss => drNetworkData.associatedSuperSections.includes(ss.id));
    //             setSuperSectionList(superSectionList);
    //         }
    //     }
    // }, [drNetworkData, superSections])
    useEffect(() => {
        if (drNetworkData && allProjects) {
            if (drNetworkData.typeOfRoad) {
                const filteredProjectList = allProjects.filter(eachProj => drNetworkData.projectType === eachProj.projectType && drNetworkData.typeOfRoad === eachProj.typeOfRoad);
                setProjectList(filteredProjectList);
            }
            else {
                const filteredProjectList = allProjects.filter(eachProj => drNetworkData.projectType === eachProj.projectType);
                setProjectList(filteredProjectList);
            }

            if (drNetworkData.associatedProjects) {
                const selectedProjectList = allProjects.filter(eachProj => drNetworkData.associatedProjects.includes(eachProj.proid));
                setSelectedProjectList(selectedProjectList);
            }
        }
    }, [drNetworkData, allProjects])
    useEffect(() => {
        props.clearSetupNetworkAndMsg();
    }, []);
    return (
        <>
            <Autocomplete
                multiple
                limitTags={4}
                id="projectList"
                options={projectList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.projectTitle}
                getOptionSelected={(option, value) => option.proid === value.proid}
                sx={{ marginTop: '16px' }}
                value={selectedProjectList}
                onChange={(event, newValue) => {
                    setShowProjectAlert(false);
                    setSelectedProjectList(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }} checked={selected} />
                        {option.projectTitle}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select projects"
                        error={showProjectAlert}
                        helperText={showProjectAlert ? "Please make sure to select at least one project" : ""}
                    />
                )}
            />
            {superSections ? <Autocomplete
                multiple
                limitTags={4}
                id="ssList"
                options={superSections}
                disableCloseOnSelect
                getOptionLabel={(option) => option.superSectionTitle}
                getOptionSelected={(option, value) => option.superSectionId === value.superSectionId}
                sx={{ marginTop: '16px' }}
                value={superSectionList}
                onChange={(event, newValue) => {
                    setShowSuperSectionAlert(false);
                    setSuperSectionList(newValue);
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }} checked={selected} />
                        {option.superSectionTitle}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select super section"
                        error={showSuperSectionAlert}
                        helperText={showSuperSectionAlert ? "Please make sure to select at least one super section" : ""}
                    />
                )}
            /> : <></>}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        superSections: state.firestore.ordered.SuperSections,
        allProjects: state.firestore.ordered.Projects,
        userData: state.firebase.profile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        clearSetupNetworkAndMsg: () => dispatch(clearSetupNetworkAndMsg()),
    };
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        // var ueid = props.userData.uid + props.userData.employeeid;
        var coid = props.userData.coid;
        return [
            {
                collection: 'Projects',
                where: [['coid', '==', `${coid}`], ['archived', '==', false]],
                orderBy: [['dateCreated', 'desc']],
            }
        ]
    })
)(NetworkSetting);
