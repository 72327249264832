import React from 'react';
import colors from '../../../../../constants/colors.json'
import { Card, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import CircleIcon from '@mui/icons-material/Circle';
import { useApi } from '../../../../../chooks/useApi';
import CustomProgress from '../../../../cidgets/CustomProgress'
import { connect } from 'react-redux';

ChartJS.register(ArcElement, Legend);

const Overview = (props) => {
    const { datastore, allNetworkDefects } = props;
    const { distressData } = datastore;
    let countMap = {
        '0': 0,
        '1': 0,
        // '2': 0,
        // '3': 0,
        // '4': 0,
        '5': 0,
        '6': 0,
        '7': 0,
        // '8': 0,
        // '9': 0,
        // '10': 0,
        '11': 0
    };
    if (distressData === null || distressData === undefined) {
        return <CustomProgress centerInLayout={true} text={"Loading results overview"} />
    }

    const { defectNames } = distressData;
    if (defectNames === null || defectNames === undefined) {
        return <CustomProgress text={"Loading results overview"} />
    }

    const augmentedPredictions = allNetworkDefects.map((eachDetection, i) => {
        countMap['versioning' in eachDetection ? 'defectName' in eachDetection['versioning'] ? Number(eachDetection['versioning']['defectName']) : Number(eachDetection['defectName']) : Number(eachDetection['defectName'])] = countMap['versioning' in eachDetection ? 'defectName' in eachDetection['versioning'] ? Number(eachDetection['versioning']['defectName']) : Number(eachDetection['defectName']) : Number(eachDetection['defectName'])] + 1
        return eachDetection;
    });
    const totalDistress = augmentedPredictions.length;

    Object.entries(countMap).map(([key, value]) => {
        const eachValueOverTotalDistress = totalDistress !== 0 ? value / totalDistress : 0;
        countMap[key] = {
            class: key,
            count: value,
            percentage: (eachValueOverTotalDistress) * 100,
            percentageToShow: Number((eachValueOverTotalDistress) * 100).toFixed(1),
            outOfOne: (eachValueOverTotalDistress),
            pieChartAngle: (eachValueOverTotalDistress) * 360,
            pieChartRadians: ((eachValueOverTotalDistress) * 360 * Math.PI) / 180,
            name: defectNames[key],
            nameToBeShown: defectNames[key],
            color: colors.colors[key]
        }
    });

    const sortedCountArray = Object.values(countMap).sort((a, b) => a.class - b.class);
    const chartData = {
        labels: sortedCountArray.map((e) => (`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')),
        datasets: [{
            label: 'Summary',
            data: sortedCountArray.map((e) => e.count),
            backgroundColor: sortedCountArray.map((e) => e.color),
            hoverOffset: 4
        }]
    }
    const chartOptions = {
        legend: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
                labels: {
                    font: {
                        size: 16
                    }
                }
            }
        },
        layout: {
            padding: 0
        },
    }

    const cardLightStyle = {
        "border": "0px solid grey",
        "borderRadius": "8px",
        "padding": "8px",
        "backgroundColor": '#f5f1f1'
        // "backgroundColor": '#f5f1f1'
    }

    const wnpStyle = { fontSize: '15px' };
    return (
        <>
            <Grid container>
                <Grid xs={12} item>
                    <Card sx={{ ...cardLightStyle, marginTop: '30px' }} elevation={0}>
                        <Typography variant="p" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                            ⚡️ Quick bits
                        </Typography>
                        <div style={{ margin: '14px' }}></div>
                        <div style={wnpStyle}>Total {totalDistress} distress</div>
                    </Card>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={5} item>
                    <List>
                        {sortedCountArray.map((e) => {
                            return <ListItem key={e.class} disablePadding>
                                <ListItemIcon>
                                    <CircleIcon sx={{ color: e.color }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={(`${e.name}`.charAt(0).toUpperCase() + `${e.name}`.slice(1)).replace('_', ' ')}
                                    secondary={`${e.percentageToShow}% (${e.count})`}
                                />
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid xs={7} item sx={{ padding: '25px' }}>
                    <Doughnut data={chartData} options={chartOptions} />
                </Grid>
            </Grid>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        datastore: state.datastore,
    };
};

export default connect(mapStateToProps, null)(Overview);