import { Card, Grid, Paper, Tooltip, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { styled } from '@mui/material/styles';
const CompareFields = ({ safetyDataInfo, individualDetectionData, dataInfo, assetData, height }) => {
    const allowedVegetationProblemsType = ['0'];
    const allowedCarriagewayDefectsType = ['1'];
    const allowedDepthProperty = ['1'];
    const ref = useRef(null)
    const imageHeight = ref?.current?.clientHeight ?? 0;

    if (!individualDetectionData) {
        return <></>
    }

    const sideMap = {
        "map": (() => {
            const usefulKeys = ['0', '1'];
            return usefulKeys.reduce((result, key) => {
                result[key] = safetyDataInfo['side'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(safetyDataInfo['side']),
        "values": Object.values(safetyDataInfo['side']).map(value => value.name),
        "usefulKeys": ['0', '1'],
    }
    const safetyClassesMap = {
        "map": (() => {
            const usefulKeys = ['0', '1'];
            return usefulKeys.reduce((result, key) => {
                result[key] = safetyDataInfo['defectName'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(safetyDataInfo['defectName']),
        "values": Object.values(safetyDataInfo['defectName']).map(value => value.name),
        "usefulKeys": ['0', '1'],
        // 'safetyClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
        // "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
        "trafficSign": ['2'],
    }

    const lateralPositionMap = {
        "map": (() => {
            const usefulKeys = ['0', '1', '2', '3', '4', '5'];
            return usefulKeys.reduce((result, key) => {
                result[key] = safetyDataInfo['lateralPosition'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(safetyDataInfo['lateralPosition']),
        "values": Object.values(safetyDataInfo['lateralPosition']).map(value => value.name),
        "usefulKeys": ['0', '1', '2', '3', '4', '5'],
    }
    const typeMap = {
        "map": (() => {
            const usefulKeys = ['0', '1'];
            return usefulKeys.reduce((result, key) => {
                result[key] = safetyDataInfo['type'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(safetyDataInfo['type']),
        "values": Object.values(safetyDataInfo['type']).map(value => value.name),
        "usefulKeys": ['0', '1'],
    }
    const fieldStyle = {
        "border": "2px solid grey",
        "borderRadius": "5px",
        "padding": '5px',
        "fontSize": '0.8rem',
        "textTransform": 'capitalize',
        "overflow": 'auto'
    }
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));
    return (
        <Grid container spacing={0} sx={{ height: `${height}` }}>
            <Grid xs={7} sx={{ paddingRight: '10px', height: `${height}`, overflow: 'auto' }}>
                <Card elevation={0} sx={{ padding: '10px', height: `calc(${height} - 0px)`, backgroundColor: '#f5f1f1', borderRadius: '8px', overflow: 'auto', border: '2px solid grey' }}>
                    <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                            }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    Feature category:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {`${safetyClassesMap.map[individualDetectionData.defectName]}`.replace('_', ' ')}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item>

                    {allowedVegetationProblemsType.includes(`${individualDetectionData.defectName}`) ?
                        <Item>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "start",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Side:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                    <Card sx={fieldStyle}>
                                        <Typography variant="inherit">
                                            {`${sideMap.map[individualDetectionData.side]}`.replace('_', ' ')}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Item> : <></>}
                    {allowedVegetationProblemsType.includes(`${individualDetectionData.defectName}`) ?
                        <Item>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }} >
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Clearance height (m):
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Card sx={fieldStyle}>
                                        <Typography variant="inherit">
                                            {individualDetectionData.clearanceHeight ?? "To be calculated"}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Item>
                        : <></>}
                    {allowedVegetationProblemsType.includes(`${individualDetectionData.defectName}`) ?
                        <Item>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }} >
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Distance from the lane edge (m):
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Card sx={fieldStyle}>
                                        <Typography variant="inherit">
                                            {individualDetectionData.distanceFromTheLaneEdge ?? "To be calculated"}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Item>
                        : <></>}
                    {allowedCarriagewayDefectsType.includes(`${individualDetectionData.defectName}`) ?
                        <Item>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }} >
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Type:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Card sx={fieldStyle}>
                                        <Typography variant="inherit">
                                            {`${typeMap.map[individualDetectionData.type]}`.replace('_', ' ')}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Item>
                        : <></>}
                    {allowedCarriagewayDefectsType.includes(`${individualDetectionData.defectName}`) ?
                        <Item>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }} >
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Lateral position:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Card sx={fieldStyle}>
                                        <Typography variant="inherit">
                                            {`${lateralPositionMap.map[individualDetectionData.lateralPosition]}`.replace('_', ' ')}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Item>
                        : <></>}

                    {allowedCarriagewayDefectsType.includes(`${individualDetectionData.defectName}`) ?
                        allowedDepthProperty.includes(`${individualDetectionData.type}`) ?
                            <Item>
                                <Grid container>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                        display: "flex",
                                        justifyContent: "start",
                                        alignItems: "center",
                                    }} >
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            Depth (m):
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Card sx={fieldStyle}>
                                            <Typography variant="inherit">
                                                {individualDetectionData.depth ?? "To be calculated"}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Item>
                            : <></>
                        : <></>}
                    {allowedCarriagewayDefectsType.includes(`${individualDetectionData.defectName}`) ?
                        <Item>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }} >
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Length (m):
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Card sx={fieldStyle}>
                                        <Typography variant="inherit">
                                            {individualDetectionData.length ?? "To be calculated"}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Item>
                        : <></>}
                    {allowedCarriagewayDefectsType.includes(`${individualDetectionData.defectName}`) ?
                        <Item>
                            <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }} >
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        Width (m):
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Card sx={fieldStyle}>
                                        <Typography variant="inherit">
                                            {individualDetectionData.width ?? "To be calculated"}
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Item>
                        : <></>}
                    <Item>
                        <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                                sx={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }} >
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    GPS location:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                                < Card sx={fieldStyle}>
                                    <Typography variant="inherit">
                                        {individualDetectionData.geo[0]},{individualDetectionData.geo[1]}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Item>
                </Card>
            </Grid>
            <Grid ref={ref} xs={5} sx={{ background: '', height: `${height}` }}>
                <Card elevation={0} sx={{ padding: '0px', height: `calc(${height} - 0px)`, backgroundColor: '#f5f1f1', borderRadius: '8px', border: '2px solid grey' }}>
                    <Grid container spacing={0} sx={{ height: `${height}` }}>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', height: `${height}`, alignItems: 'center' }}>
                            <Tooltip title="Original image" arrow>
                                <div style={{ width: '50%', display: 'table-cell', background: 'transparent', paddingRight: '5px' }}>
                                    <img style={{
                                        width: '100%',
                                        height: `calc(${imageHeight * 0.4}px)`,
                                        //  height: `calc(${((100) - (numHeightOfHeader + numHeightOfFooter)) / 2}vh - ${customPadding * 2}px - ${fieldAreaMargin}px)`,
                                        objectFit: 'contain'
                                    }} src={'data:image/png;base64,' + individualDetectionData.cropb64} />
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', height: `${height}` }}>
                            {individualDetectionData.imageBase64 ?
                                <Tooltip title="Recognition" arrow>
                                    <div style={{ display: 'table-cell', background: 'transparent' }}>
                                        <img style={{
                                            width: '100%',
                                            height: `calc(${imageHeight * 0.4}px)`,
                                            paddingLeft: '5px', objectFit: 'contain'
                                        }} src={'data:image/png;base64,' + individualDetectionData.imageBase64 ?? ''} />
                                    </div>
                                </Tooltip>
                                :
                                <React.Fragment />}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid >
    )
}

export default CompareFields