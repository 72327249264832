const initState = {
    // isSignedIn - used in App.js, Landing.js, Navbar.js - state.firebase.auth.isEmpty (t => nl, f => l)
    isSignedIn: !true, // use only for testing and adjust on above files
    isLoginModalOpen: false,
    showLoader: false,
    /// README: AUTH ERROR
    error: null,

    /// README: MESSAGES
    message: null,

    /// README: USER MODEL
    userModel: {
        firstName: "",
        lastName: "",
        email: "",
    },

    /// README: UPDATE PROFILE MODAL
    isUserUpdating: false,
    isPictureAdded: false,
    isUserUpdated: false,
    hasShop: false,

    isUpdating: false,

    customerSupportMessage: '',
    customerSupportIsDoneAndSent: null,
    customerSupportLoader: false,

    registerLinkedAccountMessage: "",
    registerLinkedAccountLoader: false,
    registerLinkedAccountIsDoneAndSent: null,
    registerLinkedAccountData: null,

    checkLinkedAccountVerificationAccountMessage: "",
    checkLinkedAccountVerificationAccountLoader: false,
    checkLinkedAccountVerificationAccountIsDoneAndSent: null,

    deleteLinkedAccountMessage: "",
    deleteLinkedAccountIsDoneAndSent: null,
    deleteLinkedAccountLoader: false,

    addAccessCodeMessage: "",
    addAccessCodeIsDoneAndSent: null,
    addAccessCodeLoader: false,

    updateAccessCodeMessage: "",
    updateAccessCodeIsDoneAndSent: null,
    updateAccessCodeLoader: false,

    removeAccessCodeMessage: "",
    removeAccessCodeIsDoneAndSent: null,
    removeAccessCodeLoader: false,

    verifyAccessCodeMessage: "",
    verifyAccessCodeIsDoneAndSent: null,
    verifyAccessCodeLoader: false,

    emailVerificationLoader: false,

    isPasswordUpdating: false,
    isPasswordUpdated: false,
    passwordChangeError: null,
    passwordChangeMessage: "",

}

const authReducer = (state = initState, action) => {
    if (action.type === 'INIT_USER_ACTION') {
        //////console.log(action)
        return {
            ...state,
            showLoader: true,
            error: null,
            message: null,
        }
    }
    else if (action.type === 'CLEAR_REGISTER_LINKED_ACCOUNT_MSG_ERROR') {
        return {
            ...state,
            registerLinkedAccountMessage: "",
            registerLinkedAccountLoader: null,
            registerLinkedAccountIsDoneAndSent: null,
            registerLinkedAccountData: null,
        }
    }
    else if (action.type === 'SEND_VERIFICATION_EMAIL_START' || action.type === 'SEND_VERIFICATION_EMAIL_COMPLETE' || action.type === 'SEND_VERIFICATION_EMAIL_ERROR') {
        return {
            ...state,
            emailVerificationLoader: action.emailVerificationLoader,
        }
    }
    else if (action.type === 'CLEAR_ERROR_MSG') {
        return {
            ...state,
            showLoader: false,
            error: null,
            message: null
        }
    }
    else if (action.type === 'SIGN_IN') {
        //////console.log(action)
        return {
            ...state,
            showLoader: false,
            isLoginModalOpen: true,
            isSignedIn: true,
            error: null,
            message: null,
        }
    }
    else if (action.type === 'SIGN_IN_ERROR') {
        //////console.log(action)
        return {
            ...state,
            showLoader: false,
            isLoginModalOpen: false,
            isSignedIn: false,
            error: action.error,
            message: null,
        }
    }
    else if (action.type === 'SIGN_OUT') {
        //////console.log(action)
        return {
            ...state,
            isLoginModalOpen: false,
            isSignedIn: false,
            message: null,
        }
    }

    else if (action.type === 'UPDATE_DETAILS') {
        //////console.log(action)
        return {
            ...state,
            isUpdating: true
        }
    }

    else if (action.type === 'UPDATED_DETAILS') {
        //////console.log(action)
        return {
            ...state,
            isUpdating: false
        }
    }

    else if (action.type === 'SIGN_UP') {
        //////console.log(action)
        return {
            ...state,
            showLoader: false,
            isSignedIn: true,
            error: null,
            message: null,
        }
    }
    else if (action.type === 'SIGN_UP_ERROR') {
        //////console.log(action)
        return {
            ...state,
            showLoader: false,
            isSignedIn: false,
            error: action.error,
            message: null,
        }
    }
    else if (action.type === 'VERIFY') {
        //////console.log(action)
        return {
            ...state,
            error: null,
            message: null,
        }
    }
    else if (action.type === 'PASSWORD_RESET') {
        //////console.log(action)
        return {
            ...state,
            message: action.message,
            showLoader: false
        }
    }
    else if (action.type === 'INIT_FORGOT_PASSWORD_ACTION') {
        return {
            ...state,
            showLoader: true,
            error: null,
            message: null,
        }
    }
    else if (action.type === 'PASSWORD_FORGOT_EXCEPTION') {
        return {
            ...state,
            showLoader: false,
            error: action.message
        }
    }

    /// README: PROFILE UPDATING
    else if (action.type === "OPEN_USER_UPDATE_MODAL") {
        return {
            ...state,
            isUserUpdating: false,
            isPictureAdded: false,
            isUserUpdated: false,
        }
    }
    else if (action.type === "UPDATE_USER") {
        return {
            ...state,
            isUserUpdating: true,
        }
    }
    else if (action.type === "USER_DP_UPDATED") {
        return {
            ...state,
            isPictureAdded: true,
        }
    }
    else if (action.type === "USER_UPDATED") {
        return {
            ...state,
            isUserUpdating: false,
            isPictureAdded: false,
            isUserUpdated: true,
        }
    }
    else if (action.type === "CLEAR_CUSTOMER_SUPPORT_ERROR_MSG") {
        // //////console.log("action dispatch..")
        return {
            ...state,
            customerSupportMessage: '',
            customerSupportLoader: false,
            customerSupportIsDoneAndSent: null,
        }
    }

    else if (action.type === "GENERATING_CUSTOMER_SUPPORT_REQUEST" || action.type === "GENERATING_CUSTOMER_SUPPORT_COMPLETE" || action.type === "GENERATING_CUSTOMER_SUPPORT_ERROR") {
        //////console.log(action)
        return {
            ...state,
            customerSupportMessage: action.customerSupportMessage,
            customerSupportLoader: action.customerSupportLoader,
            customerSupportIsDoneAndSent: action.customerSupportIsDoneAndSent ?? false,
        }
    }

    else if (action.type === "GENERATING_REGISTER_LINKED_ACCOUNT_REQUEST" || action.type === "GENERATING_REGISTER_LINKED_ACCOUNT_COMPLETE" || action.type === "GENERATING_REGISTER_LINKED_ACCOUNT_ERROR") {
        //////console.log(action)
        return {
            ...state,
            registerLinkedAccountMessage: action.registerLinkedAccountMessage,
            registerLinkedAccountLoader: action.registerLinkedAccountLoader,
            registerLinkedAccountIsDoneAndSent: action.registerLinkedAccountIsDoneAndSent ?? false,
            registerLinkedAccountData: action.registerLinkedAccountData
        }
    }
    else if (action.type === "GENERATING_CHECKED_LINKED_ACCOUNT_VERIFICATION_REQUEST" || action.type === "GENERATING_CHECKED_LINKED_ACCOUNT_VERIFICATION_COMPLETE" || action.type === "GENERATING_CHECKED_LINKED_ACCOUNT_VERIFICATION_ERROR") {
        //////console.log(action)
        return {
            ...state,
            checkLinkedAccountVerificationAccountMessage: action.checkLinkedAccountVerificationAccountMessage,
            checkLinkedAccountVerificationAccountLoader: action.checkLinkedAccountVerificationAccountLoader,
            checkLinkedAccountVerificationAccountIsDoneAndSent: action.checkLinkedAccountVerificationAccountIsDoneAndSent,
        }
    }
    else if (action.type === "REMOVE_LINKED_ACCOUNT_VERIFICATION_MESSAGE") {
        //////console.log(action)
        return {
            ...state,
            checkLinkedAccountVerificationAccountMessage: null,
            checkLinkedAccountVerificationAccountLoader: null,
            checkLinkedAccountVerificationAccountIsDoneAndSent: null
        }
    }
    else if (action.type === "GENERATING_DELETE_LINKED_ACCOUNT_REQUEST" || action.type === "GENERATING_DELETE_LINKED_ACCOUNT_COMPLETE" || action.type === "GENERATING_DELETE_LINKED_ACCOUNT_ERROR") {
        //////console.log(action)
        return {
            ...state,
            deleteLinkedAccountMessage: action.deleteLinkedAccountMessage,
            deleteLinkedAccountLoader: action.deleteLinkedAccountLoader,
            deleteLinkedAccountIsDoneAndSent: action.deleteLinkedAccountIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "GENERATING_ADD_ACCESS_CODE_REQUEST" || action.type === "GENERATING_ADD_ACCESS_CODE_COMPLETE" || action.type === "GENERATING_ADD_ACCESS_CODE_ERROR") {
        //////console.log(action)
        return {
            ...state,
            addAccessCodeMessage: action.addAccessCodeMessage,
            addAccessCodeLoader: action.addAccessCodeLoader,
            addAccessCodeIsDoneAndSent: action.addAccessCodeIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "GENERATING_UPDATE_ACCESS_CODE_REQUEST" || action.type === "GENERATING_UPDATE_ACCESS_CODE_COMPLETE" || action.type === "GENERATING_UPDATE_ACCESS_CODE_ERROR") {
        //////console.log(action)
        return {
            ...state,
            updateAccessCodeMessage: action.updateAccessCodeMessage,
            updateAccessCodeLoader: action.updateAccessCodeLoader,
            updateAccessCodeIsDoneAndSent: action.updateAccessCodeIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "GENERATING_REMOVE_ACCESS_CODE_REQUEST" || action.type === "GENERATING_REMOVE_ACCESS_CODE_COMPLETE" || action.type === "GENERATING_REMOVE_ACCESS_CODE_ERROR") {
        //////console.log(action)
        return {
            ...state,
            removeAccessCodeMessage: action.removeAccessCodeMessage,
            removeAccessCodeLoader: action.removeAccessCodeLoader,
            removeAccessCodeIsDoneAndSent: action.removeAccessCodeIsDoneAndSent ?? false,
        }
    }
    else if (action.type === "GENERATING_VERIFY_ACCESS_CODE_REQUEST" || action.type === "GENERATING_VERIFY_ACCESS_CODE_COMPLETE" || action.type === "GENERATING_VERIFY_ACCESS_CODE_ERROR") {
        //////console.log(action)
        return {
            ...state,
            verifyAccessCodeMessage: action.verifyAccessCodeMessage,
            verifyAccessCodeLoader: action.verifyAccessCodeLoader,
            verifyAccessCodeIsDoneAndSent: action.verifyAccessCodeIsDoneAndSent ?? null,
        }
    }
    else if (action.type === "REMOVE_VERIFY_ACCESS_CODE_MESSAGE") {
        //////console.log(action)
        return {
            ...state,
            verifyAccessCodeMessage: "",
            verifyAccessCodeLoader: null,
            verifyAccessCodeIsDoneAndSent: null,
        }
    }
    else if (action.type === "REMOVE_DELETE_LINKED_ACCOUNT_MESSAGE") {
        //////console.log(action)
        return {
            ...state,
            deleteLinkedAccountMessage: "",
            deleteLinkedAccountLoader: null,
            deleteLinkedAccountIsDoneAndSent: null,
        }
    }
    else if (action.type === "REMOVE_CHANGE_PASSWORD_MESSAGE") {
        return {
            ...state,
            passwordChangeMessage: "",
            // passwordChangeError: null,
        }
    }
    else if (action.type === "START_CHANGE_PASSWORD") {
        return {
            ...state,
            isPasswordUpdating: true,
            passwordChangeError: null,
            isPasswordUpdated: false,
             passwordChangeMessage: ""
        }
    }
    else if (action.type === "PASSWORD_CHANGED") {
        return {
            ...state,
            isPasswordUpdated: true,
            isPasswordUpdating: false,
            passwordChangeError: null,
            passwordChangeMessage: ""
        }
    }
    else if (action.type === "PASSWORD_CHANGE_ERROR") {
        return {
            ...state,
            // isPasswordUpdated: true,
            isPasswordUpdating: false,
            passwordChangeMessage: action.payload,
            passwordChangeError: action.payload
        }
    }
    return state;
}

export default authReducer;