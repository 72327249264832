import { Card, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import DistressPinOnMap from './DistressPinOnMap';
import SmartUtils from '../../../../../helpers/SmartUtils';
import ImageAnnotator from "./ImageAnnotator";
import ImageAnnotatorV2 from "./ImageAnnotatorV2";
import DistressEditorTopbar from './DistressEditorTopbar';
import DistressFieldsEdit from './DistressFieldsEdit';
import { updateDistress } from '../../../../../store/actions/projectActions';

const DistressPredicitionEditorV2 = (props) => {
    const { isGroupData = false, drProjectData, handleCloseDialogDistress, dataInfo, distressData, setDistressPinned, currentSection, predictions, segmentIndex, handleSegmentChange, previousDistress, distressIndex, nextDistress, dataList,
        handleEditorClickOpenDialog, openDialog, handleEditorCloseDialog, handleQuality,
        qualities, proid, handleDistressPinned, fps, realFps, link, distressPinned, playerRef,
        originalVideoHeight, originalVideoWidth, handleCloseDialog } = props;

    const [fieldValues, setFieldValues] = useState({
        defectNameState: distressPinned?.defectName ?? -1,
        transversePositionState: distressPinned?.transversePosition ?? -1,
        gpsCoordinatesState: `${distressPinned?.geo[0]},${distressPinned?.geo[1]}` ?? -1,
        typeState: distressPinned?.type ?? -1,
        severityState: distressPinned?.severity ?? -1,
        frameNumberState: isGroupData ? distressPinned?.groupFrameNumber : distressPinned?.frameNumber ?? -1,
        areaState: distressPinned?.area ?? -1,
        depthState: distressPinned?.depth ?? -1,
        transverseSpanState: distressPinned?.transverseSpan ?? -1,
        longitudinalSpanState: distressPinned?.longitudinalSpan ?? -1,
        profileState: distressPinned?.profile ?? -1,
        widthState: distressPinned?.width ?? -1,
        wheelPathState: distressPinned?.wheelPath ?? -1,
    });
    // //////console.log(distressPinned?.bbox, 'never')
    const [Bbox, setBbox] = React.useState(
        distressPinned.bbox ?
            [{
                x: distressPinned?.bbox[0],
                y: distressPinned?.bbox[1],
                width: distressPinned?.bbox[2],
                height: distressPinned?.bbox[3],
                fill: 'rgba(0, 255, 0, 0.1)'
            }] : [{}]
    );
    // //////console.log(distressPinned, "bbox321")
    const [isBboxReset, setIsBboxReset] = useState(false);
    const handleResetBbox = () => {
        setIsBboxReset(true);
        setBbox([{
            x: distressPinned?.bbox[0],
            y: distressPinned?.bbox[1],
            width: distressPinned?.bbox[2],
            height: distressPinned?.bbox[3],
            fill: 'rgba(0, 255, 0, 0.1)'
        }]);
        setFieldValues({
            defectNameState: distressPinned?.defectName ?? -1,
            transversePositionState: distressPinned?.transversePosition ?? -1,
            gpsCoordinatesState: `${distressPinned?.geo[0]},${distressPinned?.geo[1]}` ?? -1,
            typeState: distressPinned?.type ?? -1,
            severityState: distressPinned?.severity ?? -1,
            frameNumberState: isGroupData ? distressPinned?.groupFrameNumber : distressPinned?.frameNumber ?? -1,
            areaState: distressPinned?.area ?? -1,
            depthState: distressPinned?.depth ?? -1,
            transverseSpanState: distressPinned?.transverseSpan ?? -1,
            longitudinalSpanState: distressPinned?.longitudinalSpan ?? -1,
            profileState: distressPinned?.profile ?? -1,
            widthState: distressPinned?.width ?? -1,
            wheelPathState: distressPinned?.wheelPath ?? -1,
        });
    }
    const handleEditDataSubmit = () => {

        let defectProperties = {};
        const sectionId = `${currentSection.sectionId}`;
        const detectionId = `${distressPinned.defectId}`;
        const bbox = JSON.stringify({ x: Bbox[0].x, y: Bbox[0].y, w: Bbox[0].width, h: Bbox[0].height });
        // //////console.log(bbox, 'cant')
        const defectClass = Number(fieldValues.defectNameState);

        if (defectClass === 0) {
            // //////console.log(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.updateDistress(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties));
        }
        else if (defectClass === 1) {
            defectProperties = {
                type: Number(fieldValues.typeState)
            }
            // //////console.log(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.updateDistress(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties));
        }
        else if (defectClass === 2) {
            defectProperties = {
                profile: Number(fieldValues.profileState)
            }
            // //////console.log(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.updateDistress(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties));
        }
        else if (defectClass === 3) {
            defectProperties = {
                severity: Number(fieldValues.severityState)
            }
            // //////console.log(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.updateDistress(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties));
        }
        else if (defectClass === 4) {
            // //////console.log(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.updateDistress(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties));
        }
        else if (defectClass === 5) {
            // //////console.log(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.updateDistress(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties));
        }
        else if (defectClass === 10) {
            // //////console.log(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties), "data")
            props.updateDistress(proid, sectionId, detectionId, bbox, defectClass, JSON.stringify(defectProperties));
        }
    }


    const handleBoxChange = (newValue) => {
        // //////console.log(newValue[0][0], "bbox coordinates1");
        setBbox([{
            x: newValue[0][0],
            y: newValue[0][1],
            width: newValue[0][2],
            height: newValue[0][3],
            fill: 'rgba(0, 255, 0, 0.1)'
        }]);
    };
    const [topbarHeaderHeight, setTopbarHeaderHeight] = useState({ height: 1, width: 1 })
    const headerRef = useRef(null);
    const headerHeight = headerRef?.current?.clientHeight ?? 0;
    const su = new SmartUtils();
    const editFormHeight = 50;
    const mapHeight = 50;
    const overallCustomBorderPadding = 16;

    useEffect(() => {
        // //////console.log(headerRef.current.clientHeight, "headerRef.current.clientHeight)")
        window.addEventListener("load", setTopbarHeaderHeight(headerRef.current.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, []);
    const getVidWindowSize = () => {
        const newHeight = headerRef?.current?.clientHeight;
        const newWidth = headerRef?.current?.clientWidth;
        setTopbarHeaderHeight({
            height: newHeight,
            width: newWidth
        });
    };

    const cardWhiteStyle = {
        "borderRadius": "15px",
    }
    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#E1E3E2' }}>

            <Grid container spacing={0} sx={{ height: `calc(100vh - ${0}px)` }}>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={6} sx={{ backgroundColor: 'transparent' }}>

                    <div ref={headerRef}>
                        <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
                            {/* <SurfaceEvaluationTopbar proid={proid} drProjectData={drProjectData} /> */}
                            <DistressEditorTopbar proid={proid} handleResetBbox={handleResetBbox} handleCloseDialogDistress={handleCloseDialogDistress} handleCloseDialog={handleCloseDialog} handleEditDataSubmit={handleEditDataSubmit} />
                        </div>
                    </div>

                    <div style={{ height: `calc(100vh - ${headerHeight}px)`, backgroundColor: 'transparent' }}>

                        <div style={{ height: `${editFormHeight}%`, backgroundColor: 'transparent', padding: su._createPadding(overallCustomBorderPadding, 'trbl') }}>
                            <div style={{ height: '100%', backgroundColor: '' }}>
                                <Card sx={{ ...cardWhiteStyle, height: '100%', overflow: 'auto', padding: su._createPadding(10, 'trbl') }}>
                                    <DistressFieldsEdit drProjectData={drProjectData} fieldValues={fieldValues} setFieldValues={setFieldValues} distressPinned={distressPinned} dataInfo={dataInfo} distressData={distressData} />
                                </Card>
                            </div>
                        </div>
                        <div style={{ height: `${mapHeight}%`, backgroundColor: 'transparent', padding: su._createPadding(overallCustomBorderPadding, 'rbl') }}>
                            <div style={{ height: '100%', backgroundColor: '' }}>
                                <Card sx={{ ...cardWhiteStyle, height: '100%' }}>
                                    <DistressPinOnMap distressPinned={distressPinned} />
                                </Card>
                            </div>
                        </div>

                    </div>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={6} sx={{ backgroundColor: 'transparent' }}>
                    <div style={{ padding: SmartUtils.edgeInsets(overallCustomBorderPadding, 'tbr'), height: '100%', backgroundColor: 'transparent', }}>
                        <Card sx={{ borderRadius: SmartUtils.edgeInsets(15, 'tblr'), height: '100%' }}>
                            <Grid item xs={12} style={{ height: `calc(100vh - ${overallCustomBorderPadding * 2}px)`, backgroundColor: 'transparent', overflow: '' }}>
                                <Grid container spacing={0} sx={{ height: '100%' }}>
                                    <Grid item xs={6} sx={{ backgroundColor: 'transparent' }}>
                                        <ImageAnnotatorV2 imageUrl={currentSection.stitchPath} imageHeight={currentSection.HRHeight} imageWidth={currentSection.HRWidth} isEdit={true} isBboxReset={isBboxReset} setIsBboxReset={setIsBboxReset} editableBbox={Bbox} setBbox={setBbox} drawMap={true} editBox={true} onValueChange={(value) => handleBoxChange(value)} />

                                    </Grid>
                                    <Grid item xs={6}>
                                        <ImageAnnotatorV2 imageUrl={currentSection.depthMapPath} imageHeight={currentSection.HRHeight} imageWidth={currentSection.HRWidth} drawMap={false} editBox={false} />

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}
const mapStateToProps = (state) => {
    // //////console.log(state.datastore, "datastore")
    return {
        datastore: state.datastore,
        project: state.project,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateDistress: (proid, sectionId, detectionId, bbox, defectClass, defectProperties) => dispatch(updateDistress(proid, sectionId, detectionId, bbox, defectClass, defectProperties)),
        // // setCurrentProjectId: (proid) => dispatch(setCurrentProjectId(proid)),
        // moveAssetToArchive: (proid, sectionId, detectionId) => dispatch(moveAssetToArchive(proid, sectionId, detectionId)),
        // resetAsset: (proid, sectionId, detectionId) => dispatch(resetAsset(proid, sectionId, detectionId)),
        // updateAsset: (proid, sectionId, detectionId, roadSide, assetId) => dispatch(updateAsset(proid, sectionId, detectionId, roadSide, assetId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DistressPredicitionEditorV2);