import React, { createRef, useEffect, useState } from "react"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import './table.css'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Card, Tooltip } from "@mui/material";
import CustomTooltip from "../../../../../cidgets/CustomTooltip";
import { Button, Snackbar } from '@mui/material'
import { useRef } from "react";
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import { useParams } from "react-router-dom";

const AssetTable = ({ playerRef, dataList = [], assetData = [], handlePinned, pinned, fps, dataInfo, disableHeader = false }) => {
    const [open, setOpen] = useState(false)
    const handleShareClick = (outputId) => {
        setOpen(true)
        navigator.clipboard.writeText(`${window.location.href}/${outputId}`)
    }

    const tableRef = useRef(null)
    useEffect(() => {
        const element = document.getElementById(pinned?.pred?.id);
        // //////console.log(element)
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        // //////console.log(tableRef?.current?.lastElementChild)
        // tableRef?.current?.lastElementChild.scrollIntoView()
    }, [pinned])


    const [videoPlayerState, setVideoPlayerState] = useState(null)

    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });

    const handleBind = (state) => {
        setVideoPlayerState(state);
    }

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const refs = dataList.reduce((acc, value) => {
        acc[value.id] = createRef();
        return acc;
    }, {});
    // menu states start here
    const [anchorElRoadSide, setAnchorElRoadSide] = React.useState(null);
    const openRoadSide = Boolean(anchorElRoadSide);
    const handleClickOpenRoadSide = (event) => {
        setAnchorElRoadSide(event.currentTarget);
    };
    const handleCloseRoadSide = () => {
        setAnchorElRoadSide(null);
    };

    const [anchorElQuality, setAnchorElQuality] = React.useState(null);
    const openQuality = Boolean(anchorElQuality);
    const handleClickOpenQuality = (event) => {
        setAnchorElQuality(event.currentTarget);
    };
    const handleCloseQuality = () => {
        setAnchorElQuality(null);
    };

    // menu states end here

    const handleClick = (id) => {
        refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    //     useEffect(()=>{
    // //////console.log(dataList,"d-")
    //     },[dataList])
    const [qualities, setQualities] = useState([])
    const [sides, setSides] = useState([])

    const handleGotoTime = (frameTime) => {
        handleSeekTo(frameTime + 1 / fps)
    }

    const handleSeekTo = (timeSeek) => {
        // //////console.log(playerRef.current,"Player Ref")
        playerRef.current.seek(timeSeek)
    }

    const handleQuality = (index) => {
        if (qualities.includes(index)) {
            setQualities([...qualities].filter((e) => e !== index))
        } else {
            setQualities([...qualities, index])
        }

    }

    const handleSide = (index) => {
        if (sides.includes(index)) {
            setSides([...sides].filter((e) => e !== index))
        } else {
            setSides([...sides, index])
        }

    }

    const handlePinning = (e) => {
        // //////console.log("handlePinning",e)
        handlePinned(e)
        handleSeekTo(e.pred.frameTime + (1 / fps))
    }

    const calculateQuality = (score) => {
        if (score < 35) {
            return 2
        }

        if (score >= 35 && score < 75) {
            return 1
        }

        if (score >= 75 && score <= 100) {
            return 0
        }
    }

    const calculateAccuracyRating = (score) => {
        if (score < 35) {
            return "Bad"
        }

        if (score >= 35 && score < 75) {
            return "Medium"
        }

        if (score >= 75 && score <= 100) {
            return "Good"
        }
    }

    // dataList = qualities.length === 0 ? dataList : dataList.filter((e) => qualities.includes(calculateQuality(isAssetTrafficSign(getAssetMetaDebug(e.assetId, true).class) ? 0 : e.detectionScore)))
    // dataList = sides.length === 0 ? dataList : dataList.filter((e) => sides.includes(e.roadSide))

    // const filterButtonBarQuality = <div style={{}}>
    //     <MenuItem sx={{ border: "none" }} onClick={() => handleQuality(0)}>
    //         {qualities.includes(0) ? "Good X" : "Good"}
    //     </MenuItem>
    //     <MenuItem sx={{ border: "none" }} onClick={() => handleQuality(1)}>
    //         {qualities.includes(1) ? "Medium X" : "Medium"}
    //     </MenuItem>
    //     <MenuItem sx={{ border: "none" }} onClick={() => handleQuality(2)}>
    //         {qualities.includes(2) ? "Bad X" : "Bad"}
    //     </MenuItem>
    //     <span style={{ marginInline: '5px' }}></span>
    // </div>

    // const filterButtonBarRoadSide = <div style={{}}>
    //     <MenuItem sx={{ border: "none" }} onClick={() => handleSide('0')}>
    //         {sides.includes('0') ? `Left (${dataList.filter((e) => e.roadSide === '0').length})` : "Left"}
    //     </MenuItem>
    //     <MenuItem sx={{ border: "none" }} onClick={() => handleSide('1')}>
    //         {sides.includes('1') ? `Right (${dataList.filter((e) => e.roadSide === '1').length})` : "Right"}
    //     </MenuItem>
    // </div>

    const style = {
        // "background": "#FAFAFA",
        // "color": "white",
        "paddingLeft": "15px",
        "paddingRight": "15px",
        "width": "100%",
        "height": "100%",
        "overflow": "auto",
        // "border": "1px solid #ccc",
    }
    if (assetData.length === 0) {
        return <>Loading...</>
    }
    const roadSideMap = {
        "map": dataInfo['roadSides'],
        "keys": Object.keys(dataInfo['roadSides']),
        "values": Object.values(dataInfo['roadSides']),
        "usefulKeys": ['0', '1'],
    }

    const classesMap = {
        "map": dataInfo['classes'],
        "keys": Object.keys(dataInfo['classes']),
        "values": Object.values(dataInfo['classes']),
        "usefulKeys": ['0', '1', '2', '3', '4', '998'],
        'assetClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
        "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
        "trafficSign": ['1'],
    }

    const getAssetMeta = (assetId, debug = false) => {
        const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
        if (debug) {
            // //////console.log(assetMeta, "getAssetMeta")
        }
        return assetMeta
    }

    const getAssetMetaDebug = (assetId) => {
        const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
        // //////console.log(assetMeta, "getAssetMeta")
        return assetMeta
    }

    const isAssetTrafficSign = (assetClass) => {
        return !classesMap.assetClassesWhereNameWillNotShow.includes(assetClass);
    }
    return (
        <>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied the link to clipboard"
            />
            <div style={style}>
                {dataList.length > 0 ?
                    <table className="assetTable">
                        {disableHeader ? <></> : <thead style={{}}>
                            <tr>
                                <th className="" style={{ width: '10%' }}>Number</th>
                                <th className="" style={{ width: '10%' }}>Asset</th>
                                <th className="" style={{ width: '40%' }}>Category</th>
                                <th className="" style={{ width: '10%' }}>Recognition score</th>
                                <th className="" style={{ width: '10%' }}>Side of road</th>
                                <th className="" style={{ width: '10%' }}>Time</th>
                                {/* <th className="" style={{ width: '10%' }} >Share</th> */}
                            </tr>
                        </thead>
                        }
                        <tbody ref={tableRef}>
                            {dataList.map((e, i) => {
                                const { id, assetId, frameTime, frameNumber, detectionScore, recognitionScore, sectionId } = e;
                                // //////console.log(parseFloat(recognitionScore),"recognitionScore")
                                // //////console.log(assetId, assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0]))
                                const assetMeta = getAssetMeta(assetId)
                                const obj = {
                                    pred: e,
                                    meta: assetMeta

                                }
                                const outputId = `${sectionId}-${id}`;
                                // //////console.log('obj==========>',obj.meta)
                                const isTraficSignClass = isAssetTrafficSign(assetMeta?.class)
                                const score = calculateAccuracyRating(isTraficSignClass ? recognitionScore === '10000.00' ? '100' : recognitionScore : detectionScore);
                                const check = (obj.pred.id === pinned?.pred?.id ?? '')
                                const imBase64 = `data:image/png;base64, ${obj?.pred?.cropb64 ?? ''}`;
                                const metaBase64 = `data:image/png;base64, ${obj?.meta?.base64 ?? ''}`;
                                return <tr
                                    key={id}
                                    id={id}
                                    style={{ transition: 'all ease 0.0s', ...check ? { backgroundColor: '#B0FFA4', border: '2px solid #60EE00', cursor: 'pointer' } : { cursor: 'pointer' } }}
                                    role="button"
                                    onClick={() => { handlePinning(obj) }}
                                >
                                    <td style={{ width: '10%' }}>{i + 1}</td>
                                    <td style={{ width: '10%' }}>
                                        <img src={imBase64} height={50} width={50} style={{ objectFit: 'cover' }} />
                                        {/* <CustomTooltip
                                        placement="top"
                                        arrow
                                        title={
                                            <Card style={{ width: isTraficSignClass ? '210px' : '100px' }} elevation={5}>
                                                <img src={imBase64} width="100px" style={{ maxHeight: '200px' }} />
                                                {isTraficSignClass ? <img src={metaBase64} width="100px" style={{ margin: '2px' }} /> : <></>}
                                            </Card>
                                        }
                                        widget={
                                            <img src={imBase64} height={50} width={50} style={{ objectFit: 'cover' }} />
                                        }
                                    /> */}
                                    </td>
                                    <td style={{ width: '40%' }} className="capitalize-text">{assetMeta?.name.length === 0 ? <span style={{ color: 'gray' }}><i>{'unnamed'}</i></span> : assetMeta?.name}</td>
                                    <td style={{ width: '10%' }} className="">{score}</td>
                                    {/* <td>{frameNumber}</td> */}
                                    <td style={{ width: '10%' }} className="capitalize-text">{roadSideMap.map[obj.pred.roadSide]}</td>
                                    <td style={{ width: '10%' }} className=""><a href="#" onClick={() => { handlePinning(obj) }}>{new Date(frameTime * 1000).toISOString().slice(11, 19)}</a></td>
                                    {/* <td style={{ width: '10%', cursor: 'pointer' }} className="" onClick={() => { handlePinning(obj) }}>{check ? <NavigateBeforeIcon sx={{ color: '#626262' }} /> : <KeyboardArrowRightIcon sx={{ color: '#626262' }} />}</td> */}
                                    {/* <td style={{ width: '10%', cursor: 'pointer' }} className="" onClick={() => { handleShareClick(outputId) }}>{<LinkIcon sx={{ color: '#626262' }} />}</td> */}
                                </tr>
                            })}
                        </tbody>
                    </table> : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No filter data found</div>
                }

            </div>
        </>
    );
}

export default AssetTable;