import React from 'react'
import { Grid, Card, IconButton, Tooltip, Divider } from '@mui/material'
import 'leaflet/dist/leaflet.css';
import SubRouteList from './SubRouteList';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet'
import CustomProgress from '../../../cidgets/CustomProgress'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UserRoutePlanningSchedule from './UserRoutePlanningSchedule';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import DateParser from '../../../../helpers/DateParser';
import UserIndividualRoutePlanningSchedule from './UserIndividualRoutePlanningSchedule';
import Error from '../../../cidgets/Error';
const RoutePlannerSubRoutes = ({ userLocation, selectedRouteData, routesData, showSubRoute,
    mapSubRouteData, convertedCoordinatesData, showSubRouteMap, savedRoutes,
    convertEncodedGeometryLoader, showFullMap, openScheduler, showScheduler, IndvidualSubRouteData }) => {
    const indvidualSubRouteDate = IndvidualSubRouteData && IndvidualSubRouteData.length > 0 ? DateParser(IndvidualSubRouteData[0].date, 6) : ''
    const cardStyle = {
        height: "calc(100vh - 0px)",
    };
    const blurredMapStyle = {
        height: '100vh',
        pointerEvents: 'none',
        opacity: '0.3'
    };
    const mapStyle = {
        height: '100vh',
    };
    const centeredContainerStyle = {
        position: 'absolute',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '70vw'
    };
    return (
        <>
            <Grid container >
                <Grid item xs={4}>
                    <Card sx={cardStyle}>
                        <Grid container spacing={0} sx={{ height: '8%', background: '' }}>
                            <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'end', paddingLeft: '10px' }}>

                                <Tooltip title={selectedRouteData.title ?? 'Unknown'} placement='top'>
                                    {selectedRouteData.title ?
                                        <h6 style={{ fontWeight: 'bold' }}>Optimal time to survey ({`${selectedRouteData.title}`.length > 20 ? `${selectedRouteData.title}`.slice(0, 20).concat("...") : selectedRouteData.title})</h6>
                                        :
                                        <h6 style={{ fontWeight: 'bold' }}>Unknown</h6>
                                    }
                                </Tooltip>
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                <Tooltip title="Back to saved routes" arrow placement='top'>
                                    <IconButton aria-label="saved" onClick={savedRoutes}>
                                        <HomeIcon />
                                    </IconButton>
                                </Tooltip>
                                {openScheduler ? <Tooltip title="View route map" arrow placement='top' onClick={showFullMap}>
                                    <IconButton aria-label="route">
                                        <MapIcon />
                                    </IconButton>
                                </Tooltip> :
                                    <Tooltip title="View route schedule" arrow placement='top'>
                                        <IconButton aria-label="calender" onClick={showScheduler}>
                                            <CalendarMonthIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                            <Divider light sx={{ width: '100%' }} />
                        </Grid>
                        {!selectedRouteData.result ?
                            <div style={{ height: '92%', background: '', marginTop: '20px' }}>
                                <CustomProgress text={"Hold on, we are calculating the best time slot for your survey"} />
                            </div> :
                            selectedRouteData.result.length <= 0 ?
                                selectedRouteData.weatherReason ?
                                    <div style={{ padding: '10px', height: '92%', background: '', marginTop: '10px', display: 'flex', justifyContent: 'start' }}>
                                        <Error errorMessage={selectedRouteData.weatherReason} />
                                    </div>
                                    : <></>
                                :
                                <div style={{ height: '92%', background: '' }}>
                                    <SubRouteList routesData={routesData} showSubRoute={showSubRoute} />
                                </div>
                        }
                    </Card>
                </Grid >
                {
                    openScheduler ?
                        <Grid item xs={8}>
                            {
                                showSubRouteMap ? (
                                    < UserIndividualRoutePlanningSchedule routesData={IndvidualSubRouteData} availableViews={['day']} defaultView={"day"}
                                        initialDate={indvidualSubRouteDate}
                                    />
                                ) : (
                                    < UserRoutePlanningSchedule routesData={routesData} availableViews={['month', 'week', 'day', 'agenda']} defaultView={"month"} />)
                            }
                        </Grid > :
                        <Grid item xs={8}>
                            {showSubRouteMap ? (
                                <MapContainer
                                    center={mapSubRouteData[0]}
                                    style={mapStyle}
                                    zoom={18}
                                >
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    <Polyline pathOptions={{ color: 'green' }} positions={mapSubRouteData} />
                                </MapContainer>
                            ) : (
                                convertedCoordinatesData !== null ? (
                                    <MapContainer
                                        center={convertedCoordinatesData?.coordinatesArray[0]}
                                        style={mapStyle}
                                        zoom={18}
                                    >
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                        {convertedCoordinatesData === null ? <></> : <Polyline pathOptions={{ color: 'green' }} positions={convertedCoordinatesData.coordinatesArray} />}
                                    </MapContainer>
                                ) : (
                                    <div>

                                        {convertEncodedGeometryLoader === true ? (
                                            <div style={centeredContainerStyle}>
                                                <CustomProgress text={"Loading route please wait"} />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <MapContainer
                                            center={[userLocation.lat, userLocation.lng]}
                                            style={blurredMapStyle}
                                            zoom={18}
                                            zoomControl={false}
                                        >
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                        </MapContainer>
                                    </div>
                                )
                            )}
                        </Grid>
                }
            </Grid >
        </>
    )
}

export default RoutePlannerSubRoutes