import { storage, cloudFunction } from "../../config/firebaseConfig";
export const fetchActiveProjects = () => {
    return async (dispatch) => {
        dispatch({ type: 'FETCH_ACTIVE_PROJECTS_START' })
        cloudFunction.httpsCallable("controlRoom-activeProjects")({
        })
            .then(res => {

                //console.log(res.data, "active")


                dispatch({
                    type: 'FETCH_ACTIVE_PROJECTS_SUCCESS',
                    ActiveprojectsData: res.data.projectsData,
                    responseMsg: res.data.responseMsg,

                });
            })
            .catch(error => {
                //console.log(error, "error");
                dispatch({
                    type: 'FETCH_ACTIVE_PROJECTS_FAILURE',
                    responseMsg: 'Failed to fetch active projects',
                    error: error
                });
            });
    };
};
export const fetchHaltedProjects = () => {
    return async (dispatch) => {
        dispatch({ type: 'FETCH_HALTED_PROJECTS_START' })

        cloudFunction.httpsCallable("controlRoom-haltedProjects")({})
            .then(res => {

                //console.log(res.data.projectsData, "res")

                dispatch({
                    type: 'FETCH_HALTED_PROJECTS_SUCCESS',
                    HaltedprojectsData: res.data.projectsData,
                    responseMsg: res.data.responseMsg,
                });
            })
            .catch(error => {
                dispatch({
                    type: 'FETCH_HALTED_PROJECTS_FAILURE',
                    responseMsg: 'Failed to fetch halted projects',
                    error: error
                });
            });
    };
};
export const fetchCompletedProjects = () => {
    return async (dispatch) => {
        dispatch({ type: 'FETCH_COMPLETED_PROJECTS_START' })
        cloudFunction.httpsCallable("controlRoom-returnCompletedProjects")({})
            .then(res => {

                //console.log(res.data.projectsData, "completedprojects")
                dispatch({
                    type: 'FETCH_COMPLETED_PROJECTS_SUCCESS',
                    CompletedprojectsData: res.data.projectsData,
                    responseMsg: res.data.responseMsg,
                });
            })
            .catch(error => {
                //console.log(error, "error");
                dispatch({
                    type: 'FETCH_COMPLETED_PROJECTS_FAILURE',
                    responseMsg: 'Failed to fetch completed projects',
                    error: error
                });
            });
    };
};
export const fetchQueuedProjects = () => {
    return async (dispatch) => {
        dispatch({ type: 'FETCH_QUEUED_PROJECTS_START' })
        cloudFunction.httpsCallable("controlRoom-returnQueuedProjects")({})
            .then(res => {

                //console.log(res.data.projectsData, "queuedprojects")
                dispatch({
                    type: 'FETCH_QUEUED_PROJECTS_SUCCESS',
                    QueuedprojectsData: res.data.projectsData,
                    responseMsg: res.data.responseMsg,
                });
            })
            .catch(error => {
                //console.log(error, "error");
                dispatch({
                    type: 'FETCH_QUEUED_PROJECTS_FAILURE',
                    responseMsg: 'Failed to fetch completed projects',
                    error: error
                });
            });
    };
};
export const fetchCrashedProjects = () => {
    return async (dispatch) => {
        dispatch({ type: 'FETCH_CRASHED_PROJECTS_START' })
        cloudFunction.httpsCallable("controlRoom-returnCrashedProjects")({})
            .then(res => {


                dispatch({
                    type: 'FETCH_CRASHED_PROJECTS_SUCCESS',
                    CrashedProjectsData: res.data.projectsData,
                    responseMsg: res.data.responseMsg,
                });
            })
            .catch(error => {
                //console.log(error, "error");
                dispatch({
                    type: 'FETCH_CRASHED_PROJECTS_FAILURE',
                    responseMsg: 'Failed to fetch completed projects',
                    error: error
                });
            });
    };
};

export const fetchDownSamplingProjects = () => {
    return async (dispatch) => {
        dispatch({ type: 'FETCH_DOWNSAMPLING_PROJECTS_START' })
        cloudFunction.httpsCallable("controlRoom-downsamplingProjects")({})
            .then(res => {

                //console.log("downsampling", res.data.projectsData);
                dispatch({
                    type: 'FETCH_DOWNSAMPLING_PROJECTS_SUCCESS',
                    DownSamplingProjectsData: res.data.projectsData,
                    responseMsg: res.data.responseMsg,
                });
            })
            .catch(error => {
                //console.log(error, "error");
                dispatch({
                    type: 'FETCH_DOWNSAMPLING_PROJECTS_FAILURE',
                    responseMsg: 'Failed to fetch completed projects',
                    error: error
                });
            });
    };
};


export const getControlRoomData = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'START_DOWNLOADING_CONTROL_ROOM_DATA'
        })
        fetch('https://us-central1-deeprowserverless.cloudfunctions.net/controlRoom-getBasicControlRoomLegend')
            .then((response) => response.json())
            .then(async (response) => {

                const controlRoomData = response.responseMsg;
                //console.log(controlRoomData, "controlRoomData");
                return dispatch({ type: 'SET_CONTROL_ROOM_DATA', controlRoomData });
            })
            .catch((error) => {
                const controlRoomData = [];
                return dispatch({ type: 'SET_CONTROL_ROOM_DATA', controlRoomData });
            });

    };
}