import React, { useState, useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { connect } from "react-redux";
import AssetVideoPlayer from './components/AssetVideoPlayer';
// import sampleImg from '../../../../assets/bg/poster.png'
import Card from '@mui/material/Card';
import AssetMap from './components/AssetMap';
import Topbar from '../../../parents/topbar/Topbar';
import AssetTable from './subpages/afterInference/AssetTable';
import AssetFrameList from './subpages/afterInference/AssetFrameList';
import { get } from 'lodash';
import PinnedAsset from './subpages/afterInference/PinnedAsset';
import AssetTableFilter from './subpages/afterInference/AssetTableFilter';
import { Button, Divider } from '@mui/material';
import ArchivedAssetsViewer from './subpages/afterInference/ArchivedAssetsViewer';
import DrawBBox from './components/DrawBbox';
import { removeAddNewAssetErrorAndMessage } from '../../../../store/actions/projectActions';
import { useAccessControlContext } from '../../../../contexts/AccessControlProvider';
// import AddNewAssetViewer from './subpages/afterInference/AddNewAssetViewer';

// const link = 'https://firebasestorage.googleapis.com/v0/b/deeprowserverless.appspot.com/o/Survey-Videos%2FGX010132.MP4?alt=media&token=abc53c8e-111f-40e2-b028-d657649554ef';
// const link = "https://firebasestorage.googleapis.com/v0/b/deeprowserverless.appspot.com/o/Survey-Videos%2FGX010227_out.mp4?alt=media&token=08b88f39-078a-4110-bebd-5b3c663c3e76"
// const loadImage = (setImageDimensions, imageUrl) => {
//     const img = new Image();
//     img.src = imageUrl;

//     img.onload = () => {
//         setImageDimensions({
//             height: img.height,
//             width: img.width
//         });
//     };
//     img.onerror = (err) => {
//         //////console.log("img error");
//         console.error(err);
//     };
// };

const AfterAssetInferenceDashboard = (props) => {
    const { permissions } = useAccessControlContext();
    const { canAddDefectAsset, canUnarchiveDefectAsset } = permissions;
    const { datastore, projectPredictions, predictionCropb64List, drProjectData, proid, link, project } = props;
    const { showAddNewAssetLoader, addNewAssetMessage } = project;
    const { assetData, dataInfo } = datastore;
    // //////console.log(dataInfo, 'info')
    const [pinned, setPinned] = useState(null);
    const [toggleImage, setToggleImage] = useState(false)
    const vidDivRef = useRef(null);
    const playerRef = useRef(null);
    const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 })
    const [svgDimensions, setSvgDimensions] = useState({ height: 1, width: 1, scale: 1, offsetTop: 0, offsetLeft: 0 });
    const [imageDimensions, setImageDimensions] = useState({ height: 1, width: 1 });
    const [openDialog, setOpenDialog] = React.useState(false);
    const [qualities, setQualities] = useState([])
    const videoHeight = 50;
    const mapHeight = 50;
    let assetVideoPlayerPadding;
    const overallCustomBorderPadding = assetVideoPlayerPadding = 16;
    const customMargin = 10;
    const realFps = 'fps' in drProjectData ? drProjectData.fps : 23.976023976023978
    const fps = Number(realFps).toFixed(0)
    const originalVideoHeight = drProjectData?.dimensions?.height ?? 0
    const originalVideoWidth = drProjectData?.dimensions?.width ?? 0

    const [openAddNewAssetDialog, setOpenAddNewAssetDialog] = useState(false);

    const handleOpenAddNewAssetDialog = () => {
        props.removeAddNewAssetErrorAndMessage();
        setOpenAddNewAssetDialog(true);
    };

    const handleCloseAddNewAssetDialog = () => {
        setOpenAddNewAssetDialog(false);
    };

    // const link = drProjectData.videoLink

    const roadSideMap = {
        "map": dataInfo['roadSides'],
        "keys": Object.keys(dataInfo['roadSides']),
        "values": Object.values(dataInfo['roadSides']),
        "usefulKeys": ['0', '1'],
    }
    const classesMap = {
        "map": dataInfo['classes'],
        "keys": Object.keys(dataInfo['classes']),
        "values": Object.values(dataInfo['classes']),
        "usefulKeys": ['0', '1', '2', '3', '4', '998'],
        'assetClassesWhereNameWillNotShow': ['0', '2', '3', '4', '998', '999'],
        "notTrafficSign": ['0', '2', '3', '4', '998', '999'],
        "trafficSign": ['1'],
    }



    const predictions = projectPredictions;

    let gpsData = []
    let gpsDataCombined = []
    let rawDetections = []
    let originalDetections = []
    // injecting addtional properties
    predictions.forEach((e) => {
        gpsData = [...gpsData, e['GPS']]
        gpsDataCombined = [...gpsDataCombined, ...e['GPS']]
        rawDetections = [...rawDetections, ...Object.values(e['detections'])]
        const localDetections = Object.values(e['detections']).map((eachDetection) => {
            return {
                ...eachDetection,
                cropb64: eachDetection['id'] in (predictionCropb64List ?? []) ? predictionCropb64List[eachDetection['id']] : '',
                sectionId: e['id'],
                assetId: 'versioning' in eachDetection ? eachDetection['versioning']['assetId'] : eachDetection['assetId'],
                roadSide: 'versioning' in eachDetection ? eachDetection['versioning']['roadSide'] : eachDetection['roadSide'],
                recognitionScore: (Number(eachDetection['recognitionScore']) * 100).toFixed(2),
                detectionScore: (Number(eachDetection['detectionScore'])).toFixed(2),
            }
        })
        originalDetections = [...originalDetections, ...localDetections]
    });
    let detections = originalDetections.filter((eachDetection) => {
        return eachDetection.archived === "0"
    })
    detections.sort((a, b) => {
        return a.frameNumber - b.frameNumber
    })

    const startEndLocation = {
        startLat: gpsDataCombined.at(0).lat,
        startLng: gpsDataCombined.at(0).lng,
        endLat: gpsDataCombined.at(-1).lat,
        endLng: gpsDataCombined.at(-1).lng,
    }

    const handleQuality = (index) => {
        setPinned(null);
        if (qualities.includes(index)) {
            setQualities([...qualities].filter((e) => e !== index))
        } else {
            setQualities([...qualities, index])
        }

    }
    const calculateQuality = (score) => {
        if (score < 35) {
            return 2
        }

        if (score >= 35 && score < 75) {
            return 1
        }

        if (score >= 75 && score <= 100) {
            return 0
        }
    }

    const getAssetMeta = (assetId, debug = false) => {
        const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
        if (debug) {
            // //////console.log(assetMeta, "getAssetMeta")
        }
        return assetMeta
    }

    const isAssetTrafficSign = (assetClass) => {
        return !classesMap.assetClassesWhereNameWillNotShow.includes(assetClass);
    }

    const dataset = assetData;
    const imageBase64 = dataset.filter((e) => `${e.id}`.split('_')[0] === `${pinned?.meta?.id ?? ''}`.split('_')[0])[0]?.base64;
    const cropBase64 = `data:image/png;base64, ${pinned?.pred?.cropb64 ?? ''}`;
    // let timeList = qualities.length === 0 ? detections : detections.filter((e) => qualities.includes(calculateQuality(e.detectionScore)))
    const timeList = qualities.length === 0 ? detections : detections.filter((e) => qualities.includes(calculateQuality(isAssetTrafficSign(getAssetMeta(e.assetId).class) ? e.recognitionScore : e.detectionScore)))

    const index = (element) => element.id === pinned?.pred?.id;
    var assetIndex = timeList.findIndex(index);
    const nextAsset = () => {
        if (assetIndex < timeList.length) {
            assetIndex = assetIndex + 1;
            const nextPred = timeList[assetIndex];
            const nextMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${nextPred.assetId}`.split('_')[0])[0]
            const obj = {
                pred: nextPred,
                meta: nextMeta
            }
            handlePinned(obj);
        }
    }
    const previousAsset = () => {
        if (assetIndex >= 0) {
            assetIndex = assetIndex - 1;
            const nextPred = timeList[assetIndex];
            const nextMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${nextPred.assetId}`.split('_')[0])[0]
            const obj = {
                pred: nextPred,
                meta: nextMeta
            }
            handlePinned(obj);
        }
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleNextFrame = () => {
        const secondPerFrame = 1 / fps
        playerRef.current.forward(secondPerFrame)
    }

    const handlePrevFrame = () => {
        const secondPerFrame = 1 / fps
        playerRef.current.replay(secondPerFrame)
    }

    const handlePinned = (data) => {
        const check = data === null ? false : pinned === null ? false : data.pred.id === pinned.pred.id
        playerRef.current?.pause()

        if (data) {
            handleSeekTo(data.pred.frameTime)
        }
        setPinned(check ? null : data)
    }

    // const handlePinned = (data) => {
    //     setPinned(data);
    // }
    const getVidWindowSize = () => {
        const newHeight = vidDivRef?.current?.clientHeight;
        const newWidth = vidDivRef?.current?.clientWidth;
        setVidDivDimen({
            height: newHeight,
            width: newWidth
        });
    };
    const handleSeekTo = (timeSeek) => {
        playerRef.current.seek(timeSeek + (1 / fps))
    }
    useEffect(() => {
        if (showAddNewAssetLoader === false) {
            const data = addNewAssetMessage;
            var obj = null;
            if (data) {
                const assetMeta = getAssetMeta(data.assetId)
                obj = {
                    pred: data,
                    meta: assetMeta

                }
            }
            handlePinned(obj)
            setOpenAddNewAssetDialog(false);
        }
    }, [showAddNewAssetLoader])
    useEffect(() => {
        window.addEventListener("load", setVidDivDimen(vidDivRef.current.clientHeight))
        window.addEventListener("resize", getVidWindowSize, true);
    }, [])
    useEffect(() => {
        const divHeight = vidDivRef?.current?.clientHeight
        const divWidth = vidDivRef?.current?.clientWidth - 0;
        const imageHeight = originalVideoHeight//playerRef?.current?.videoHeight//imageDimensions.height;
        const imageWidth = originalVideoWidth//playerRef?.current?.videoWidth//imageDimensions.width;

        // //////console.log(imageHeight, imageWidth)

        const asDiv = (divHeight / divWidth)
        const asImg = (imageHeight / imageWidth)
        const ratio = asDiv / asImg

        let svgWidth = 0
        let svgHeight = 0
        if (ratio >= 1) {
            svgWidth = divWidth
            svgHeight = asImg * divWidth
        } else {
            svgHeight = divHeight
            svgWidth = (1 / asImg) * divHeight
        }
        // //////console.log("Width:", divWidth, imageWidth, svgWidth)
        // //////console.log("Height:", divHeight, imageHeight, svgHeight)
        // //////console.log("Top:", (divHeight - svgHeight) / 2, "left:", (divWidth - svgWidth) / 2)
        setSvgDimensions({
            width: svgWidth,
            height: svgHeight,
            scale: imageHeight / svgHeight,
            offsetTop: (divHeight - svgHeight) / 2,
            offsetLeft: (divWidth - svgWidth) / 2,
        })
    }, [imageDimensions])
    useEffect(() => {
        // loadImage(setImageDimensions, sampleImg);
        setImageDimensions({
            height: originalVideoHeight,//playerRef?.current?.videoHeight,
            width: originalVideoWidth,//playerRef?.current?.videoWidth,
        })
    }, [pinned]);
    useEffect(() => {
        // loadImage(setImageDimensions, sampleImg);
        setImageDimensions({
            height: originalVideoHeight,//playerRef?.current?.videoHeight,
            width: originalVideoWidth,//playerRef?.current?.videoWidth,
        })
    }, [vidDivDimen])

    useEffect(() => {
        if (pinned) {
            // //////console.log(projectPredictions,Object.values(projectPredictions).filter((e) => e.id === pinned.pred.sectionId),"checkxxx",pinned.pred)
            let newPred = Object.values(projectPredictions).filter((e) => e.id === pinned.pred.sectionId)[0]['detections'][pinned.pred.id];
            newPred['cropb64'] = predictionCropb64List ? predictionCropb64List[newPred['id']] ? predictionCropb64List[newPred['id']] : '' : '';
            newPred['sectionId'] = pinned.pred.sectionId;
            newPred['roadSide'] = 'versioning' in newPred ? newPred['versioning']['roadSide'] : newPred['roadSide'];

            const assetId = 'versioning' in newPred ? newPred['versioning']['assetId'] : newPred['assetId'];
            const assetMeta = assetData.filter((e) => `${e.id}`.split('_')[0] === `${assetId}`.split('_')[0])[0]
            const obj = {
                pred: newPred,
                meta: assetMeta

            }
            setPinned(obj);


            //    //////console.log(obj,"obj")
        }

        // setPinned(null);
    }, [projectPredictions])


    const getRectWh = (xy = [], wh = [], shouldScale = true, pred = false) => {
        if (xy.length !== 2 || wh.length !== 2) {
            return <></>
        }
        const x1 = xy[0] / (shouldScale ? svgDimensions.scale : 1)
        const y1 = xy[1] / (shouldScale ? svgDimensions.scale : 1)
        const x2 = (wh[0] + xy[0]) / (shouldScale ? svgDimensions.scale : 1)
        const y2 = (wh[1] + xy[1]) / (shouldScale ? svgDimensions.scale : 1)
        return <path d={`M ${x1}, ${y1} ${x1}, ${y2} ${x2}, ${y2} ${x2}, ${y1} z`} className='bbox-path' onClick={() => { }} />

    }
    const cardWhiteStyle = {
        "borderRadius": "15px",
    }

    // const [videoPlayerState, setVideoPlayerState] = useState(null)

    // useEffect(() => {
    //     if (playerRef) {
    //         playerRef.current.subscribeToStateChange(handleBind)
    //     }
    // });

    // useEffect(() => {
    //     return () => {
    //         if (playerRef.current) {
    //             playerRef.current = null;
    //         }
    //     };
    // }, [playerRef]);

    // const handleBind = (state) => {
    //     setVideoPlayerState(state);
    // }
    const isTraficSignClass = isAssetTrafficSign(pinned?.meta?.class);
    // const currentPlayedFrame = Math.floor(fps * videoPlayerState?.currentTime ?? 0)
    const isCurrentFramePinned = true//pinned !== null ? currentPlayedFrame === pinned?.pred?.frameNumber : false

    const isVideoActive = false//videoPlayerState?.seeking || videoPlayerState?.waiting

    const headerRef = useRef(null)
    const headerHeight = headerRef?.current?.clientHeight ?? 0
    const pinnedUiHeight = pinned === null ? 0 : 45;
    const tableHeight = 90 - pinnedUiHeight;
    // //////console.log(playerRef, "playerRefdash")
    // //////console.log(pinned, "pinned");
    return (
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#E1E3E2' }}>

            <Grid container spacing={0}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)`, padding: '' }}>
                    {/* Topbar */}
                    {/* TRBL Sequence of padding */}
                    <div ref={headerRef} style={{ padding: `${overallCustomBorderPadding}px 0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`, backgroundColor: '' }}>
                        <Topbar drProjectData={drProjectData} startEndLocation={startEndLocation} proid={proid} detections={detections} assetData={assetData} dataInfo={dataInfo} />
                    </div>
                    <div style={{ height: `calc(100vh - ${headerHeight}px)`, backgroundColor: '' }}>
                        <div style={{ height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)`, paddingLeft: `${overallCustomBorderPadding}px`, paddingBottom: `${overallCustomBorderPadding}px`, backgroundColor: '' }}>
                            <Card elevation={4} sx={{ ...cardWhiteStyle, height: `calc(100vh  - ${headerHeight}px - ${overallCustomBorderPadding}px)` }}>


                                <div style={{ height: `${pinnedUiHeight}%`, backgroundColor: '', }}>
                                    {
                                        pinned === null ? <></> :
                                            <PinnedAsset originalVideoHeight={originalVideoHeight}
                                                originalVideoWidth={originalVideoWidth} playerRef={playerRef} proid={proid} qualities={qualities} handleQuality={handleQuality} timeList={timeList} handleClickOpenDialog={handleClickOpenDialog} openDialog={openDialog} handleCloseDialog={handleCloseDialog}
                                                previousAsset={previousAsset} assetIndex={assetIndex} nextAsset={nextAsset}
                                                handlePinned={handlePinned} fps={fps} realFps={realFps} link={link} pinned={pinned} gpsData={gpsData} gpsDataCombined={gpsDataCombined}
                                                classesMap={classesMap} isTraficSignClass={isTraficSignClass} roadSideMap={roadSideMap} imageBase64={imageBase64} cropBase64={cropBase64}
                                            />
                                    }
                                </div>
                                <div style={{ height: `${tableHeight}%`, backgroundColor: '', }}>
                                    <AssetTable disableHeader={false} fps={fps} playerRef={playerRef} pinned={pinned} dataList={timeList} handlePinned={handlePinned} assetData={dataset} dataInfo={dataInfo} />
                                </div>
                                <div style={{ height: `10%`, backgroundColor: '', }}>
                                    <Divider light />
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <AssetTableFilter qualities={qualities} handleQuality={handleQuality} />
                                        </Grid>
                                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                            <Button disabled={!canAddDefectAsset} onClick={handleOpenAddNewAssetDialog} sx={{ marginRight: '10px', textTransform: 'none' }}>+ Add new asset</Button>

                                            {canUnarchiveDefectAsset ? <ArchivedAssetsViewer assetData={assetData} detections={originalDetections} proid={proid} /> : <></>}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Card>
                        </div>
                    </div>

                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ backgroundColor: 'transparent', height: `calc(100vh - ${0}px)` }}>
                    <div id='asset-player' style={{ padding: `${assetVideoPlayerPadding}px`, height: `calc(${videoHeight}vh - ${0 * 2}px)`, backgroundColor: 'transparent' }}>
                        <Card elevation={4} sx={cardWhiteStyle}>
                            {/* AssetFrameList */}
                            <div style={{ height: `calc(${videoHeight}vh - ${assetVideoPlayerPadding * 2}px)`, backgroundColor: 'transparent', width: '12%', float: 'left', padding: '10px' }}>
                                <AssetFrameList handlePinned={handlePinned} playerRef={playerRef} detections={detections} pinned={pinned} fps={fps} />
                            </div>
                            {/* AssetVideoPlayer */}
                            <div ref={vidDivRef} style={{ height: `calc(${videoHeight}vh - ${assetVideoPlayerPadding * 2}px)`, backgroundColor: 'transparent', width: '88%', float: 'right' }}>
                                <div className="container" style={{ padding: '0px' }}>
                                    <div className="inner" style={{ zIndex: 997 }}>
                                        <AssetVideoPlayer fps={fps} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} playerRef={playerRef}
                                            originalVideoHeight={originalVideoHeight}
                                            originalVideoWidth={originalVideoWidth}
                                            allPolyPoints={gpsDataCombined}
                                            proid={proid}
                                            openAddNewAssetDialog={openAddNewAssetDialog} setOpenAddNewAssetDialog={setOpenAddNewAssetDialog} handleCloseAddNewAssetDialog={handleCloseAddNewAssetDialog}
                                            link={link} pinned={pinned} height={vidDivRef.current === null ? 0 : vidDivRef.current?.clientHeight} startTime={1 / fps} control={toggleImage || pinned} disableControlsFully={toggleImage || pinned} />
                                    </div>
                                    {pinned ?
                                        <div className="inner" style={{ zIndex: 998, width: vidDivRef.current === null ? 0 : vidDivRef.current?.clientWidth }}>
                                            <div style={{
                                                height: '100%', width: '100%', backgroundColor: '', textAlign: 'center', display: 'flex',
                                                justifyContent: 'center', alignItems: 'center', marginTop: '-0px'
                                            }}>
                                                <svg height={svgDimensions.height} width={svgDimensions.width}

                                                    style={{
                                                        backgroundColor: 'rgba(34,55,245, 0.0)',

                                                    }}
                                                >
                                                    {/* {isVideoActive ? <></> : pinned !== null ? isCurrentFramePinned ? getRectWh([...pinned?.pred?.bbox].splice(0, 2), [...pinned?.pred?.bbox].splice(2, 4), true, true) : <></> : <></>} */}
                                                    {isVideoActive ? <></> : pinned !== null ? isCurrentFramePinned ? <DrawBBox xy={[...pinned?.pred?.bbox].splice(0, 2)} wh={[...pinned?.pred?.bbox].splice(2, 4)} shouldScale scale={svgDimensions.scale} /> : <></> : <></>}
                                                </svg>
                                            </div>
                                        </div> : <></>}
                                </div>
                            </div>
                        </Card>
                    </div>
                    {/* Asset map */}
                    <div id='asset-map' style={{ padding: `0px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px ${overallCustomBorderPadding}px`, height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 1}px)`, backgroundColor: 'transparent' }}>
                        <Card elevation={4} sx={cardWhiteStyle}>
                            <div style={{ height: `calc(${mapHeight}vh - ${overallCustomBorderPadding * 1}px)` }}>
                                {gpsDataCombined.length > 0 ? <AssetMap fps={fps} playerRef={playerRef} zoom={15} dataList={timeList} assetData={dataset} polyPoints={gpsData} allPolyPoints={gpsDataCombined} center={gpsDataCombined[0]} pinned={pinned} handleSeekTo={handleSeekTo} handlePinned={handlePinned} /> : <></>}
                            </div>
                        </Card>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { proid } = ownProps
    return {
        project: state.project,
        datastore: state.datastore,
        drProjectData: get(
            state.firestore.data,
            `DrProject.${proid}`
        ),

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        removeAddNewAssetErrorAndMessage: () => dispatch(removeAddNewAssetErrorAndMessage()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AfterAssetInferenceDashboard);
