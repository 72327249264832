import React, { useCallback, useEffect, useState } from 'react'
import { useMapEvents } from 'react-leaflet';
import RoutePlannerMap from './RoutePlannerMap';
import RoutePlannerCard from './RoutePlannerCard';
import { removeRouteCalculationErrorAndMessage, routeCalculation, saveRouteCalculationToDb, convertEncodedGeometry, deleteRoute } from '../../../../store/actions/projectActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid } from '@mui/material'
import { firestoreConnect } from 'react-redux-firebase';
import RoutePlannerSavedRoutes from './RoutePlannerSavedRoutes';
import RoutePlannerSubRoutes from './RoutePlannerSubRoutes';
import CustomAlert from '../../../cidgets/CustomAlert';

const RoutePlannerController = (props) => {
    const [markerID, setMarkerID] = useState(0);
    const [markerPositions, setMarkerPositions] = useState([]);
    const [fields, setFields] = useState([{ id: 1, value: { lat: '', lng: '' } }, { id: 2, value: { lat: '', lng: '' } }]);
    const [showFeildError, setShowFeildError] = useState(false);
    const emptyIds = [];
    const [userLocation, setUserLocation] = useState({ lat: 51.505, lng: -0.09 });
    const [userLocationErrorMessage, setUserLocationErrorMessage] = useState(false);
    const [openUserLocationError, setOpenUserLocationError] = useState(false);
    const [isUserLocation, setIsUserLocation] = useState(true);
    const [draggable, setDraggable] = useState(false);
    const [isCreatingRoute, setIsCreatingRoute] = useState(false);
    const [isRoutePlannerDataEmpty, setIsRoutePlannerDataEmpty] = useState(false);
    const [openCalculation, setOpenCalculation] = useState(false);
    const [openScheduler, setOpenScheduler] = useState(false);
    const [showSubRoutes, setShowSubRoutes] = useState(false);
    const [subRouteDataArray, setSubRouteDataArray] = useState([]);
    const [mapSubRouteData, setMapSubRouteData] = useState([]);
    const [showSubRouteMap, setShowSubRouteMap] = useState(false);
    const [indvidualSubRouteData, setIndvidualSubRouteData] = useState([]);
    const [selectedRouteData, setSelectedRouteData] = useState([]);
    const [routeTitle, setRouteTitle] = useState('');
    const [routeTitleError, setRouteTitleError] = useState(false);
    
    useEffect(() => {
        setOpenUserLocationError(false);
        if (navigator.geolocation) {
            navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    setOpenUserLocationError(true);
                    setUserLocationErrorMessage("Please give your location access to roadgauge");
                }
                else {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setIsUserLocation(true);
                            setUserLocation({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            });
                        },
                        (error) => {
                            setOpenUserLocationError(true);
                            setUserLocationErrorMessage("Please give your location access to roadgauge");
                        }
                    );
                }
            });
        } else {
            setOpenUserLocationError(true);
            setUserLocationErrorMessage("Geolocation is not supported by this browser.");

        }
    }, []);

    const { project, userData, RoutePlannerData } = props;
    const { deleteRouteLoader, routeCalculationMessage, routeCalculationIsDoneAndSent, routeCalculationLoader, routeCalculationData, saveRouteCalculationToDbLoader, saveRouteCalculationToDbMessage, saveRouteCalculationToDbIsDoneAndSent, convertedCoordinatesData, convertEncodedGeometryIsDoneAndSent, convertEncodedGeometryLoader } = project;
    useEffect(() => {
        if (RoutePlannerData) {
            if (selectedRouteData) {
                const data = RoutePlannerData.find(obj => obj.rpid === selectedRouteData.rpid)
                // //////console.log(data,"RoutePlannerData",RoutePlannerData)
                if (data) {
                    setSelectedRouteData(data);
                    setSubRouteDataArray(data.result);

                }
            }
        }
    }, [RoutePlannerData])
    useEffect(() => {
        for (const obj of fields) {
            if (obj.value.lat === '' || obj.value.lng === '') {
                emptyIds.push(obj.id);
            }
        }

    }, [fields]);
    useEffect(() => {
        if (Array.isArray(RoutePlannerData) && RoutePlannerData.length === 0) {
            setIsRoutePlannerDataEmpty(true);
        }
        else {
            setIsRoutePlannerDataEmpty(false);
            setIsCreatingRoute(true);
        }
    }, [RoutePlannerData]);
    useEffect(() => {
        if (saveRouteCalculationToDbIsDoneAndSent === true) {
            savedRouteScreen();
            // showRoute(Object.values(RoutePlannerData)[0].encodedGeometry);
            // setSubRouteDataArray(Object.values(RoutePlannerData)[0].result);
        }
    }, [saveRouteCalculationToDbIsDoneAndSent, RoutePlannerData])
    if (userData === undefined) {
        return <></>
    }
    const { coid } = userData;
    const getToCenterOfUserLocation = (e) => {
        setOpenUserLocationError(false);
        if (navigator.geolocation) {
            navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    setOpenUserLocationError(true);
                    setUserLocationErrorMessage("Please give your location access to roadgauge");
                }
                else {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setIsUserLocation(true);
                            setUserLocation({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            });
                        },
                        (error) => {
                            setOpenUserLocationError(true);
                            setUserLocationErrorMessage(error.message);
                            console.error('Error getting user location:', error);
                            // alert('Please allow location access.');
                            // window.location.href = "app-settings:location";
                        }
                    );
                }
            });
        } else {
            setOpenUserLocationError(true);
            setUserLocationErrorMessage("Geolocation is not supported by this browser.");

        }
    }
    const getToSpecificRouteLocation = (lat, lng) => {
        setIsUserLocation(false);
        setUserLocation({
            lat: lat,
            lng: lng,
        });
    }
    const handleChange = (id, event) => {
        setShowFeildError(false);
        const data = event.target.value;
        const isDataCorrect = `${data}`.includes(',');
        if (isDataCorrect) {
            const dataList = `${data}`.split(',');
            const dataObject = {
                lat: dataList[0],
                lng: dataList[1],
            }
            const newFields = fields.map(field => {
                if (field.id === id) {
                    return { ...field, value: dataObject };
                }
                return field;
            });
            setFields(newFields);
        }
        else {
            setShowFeildError(true);
        }


    };

    const handleChangeWithFieldData = (id, event) => {
        const newFields = fields.map(field => {
            if (field.id === id) {
                return { ...field, value: event.target.value };
            }
            return field;
        });
        setFields(newFields);
    }

    const handleAddField = () => {
        const newField = { id: markerID + 1, value: { lat: '', lng: '' } };
        setFields([...fields, newField]);
    };

    const handleAddFieldWithData = (lat, lng) => {
        if (markerPositions.length === 25 && fields.length === 25) {
            alert("up to 25 longitude,latitude waypoints.")
        }
        else {
            const newField = { id: markerID + 1, value: { lat: lat, lng: lng } };
            const newMarkerPositions = [...markerPositions, { id: markerID + 1, lat: lat, lng: lng }];
            setFields([...fields, newField]);
            setMarkerPositions(newMarkerPositions);
            setMarkerID(markerID + 1)
        }
    };

    const handleClearFields = () => {
        setMarkerID(0);
        setMarkerPositions([]);
        setFields([{ id: 1, value: { lat: '', lng: '' } }, { id: 2, value: { lat: '', lng: '' } }])
        props.removeRouteCalculationErrorAndMessage();
    }

    const handleMapClick = (e) => {
        if (markerPositions.length === 25 && fields.length === 25) {
            alert("up to 25 longitude,latitude waypoints.")
        }

        else {
            if (markerPositions.length === fields.length) {
                handleAddFieldWithData(e.latlng.lat, e.latlng.lng)
            }
            else {
                const newMarkerPositions = [...markerPositions, { id: markerID + 1, lat: e.latlng.lat, lng: e.latlng.lng }];
                setMarkerPositions(newMarkerPositions);
                handleChangeWithFieldData(markerID + 1, { target: { value: { lat: e.latlng.lat, lng: e.latlng.lng } } })
                setMarkerID(markerID + 1)
            }

        }
    };
    const saveRouteCalculation = () => {
        setRouteTitleError(false);
        if (routeTitle.length <= 0) {
            setRouteTitleError(true);
        }
        else {
            props.saveRouteCalculationToDb(JSON.stringify(routeCalculationData), coid, routeTitle)
        }
    }
    const handleMarkerPositionChange = (id, newPosition) => {
        const updatedMarkers = markerPositions.map((marker) => (marker.id === id ? { id: id, lat: newPosition.lat, lng: newPosition.lng } : marker));
        const updateFields = fields.map(field => {
            if (field.id === id) {
                return { ...field, value: { lat: newPosition.lat, lng: newPosition.lng } };
            }
            return field;
        });
        setMarkerPositions(updatedMarkers);
        setFields(updateFields);
    };
    const handleMarkerRemove = (id) => {
        const updatedMarkers = markerPositions.filter((marker) => marker.id !== id);
        setMarkerPositions(updatedMarkers);
        const updateFields = fields.filter((feild) => feild.id !== id);
        setFields(updateFields);
        props.removeRouteCalculationErrorAndMessage();
    };
    const MapClickHandler = () => {
        useMapEvents({
            click: draggable || !isCreatingRoute ? <></> : handleMapClick,
        });

        return null;
    };
    function sortByID(a, b) {
        return a.id - b.id;
    }
   
    const handleCreatingNewRoute = () => {
        handleClearFields();
        setIsCreatingRoute(true);
        setOpenCalculation(true);
    }
    const calculateRoute = async () => {
        const sortedLngLatArray = [];
        markerPositions.sort(sortByID);
        for (const item of markerPositions) {
            sortedLngLatArray.push([item.lng, item.lat]);
        }
        props.routeCalculation(JSON.stringify(sortedLngLatArray));
    }
    const showRoute = (encodedGeometry) => {
        props.convertEncodedGeometry(encodedGeometry);
        setShowSubRoutes(true);
    };
    const savedRouteScreen = () => {
        setOpenCalculation(false);
    };
    const showScheduler = () => {
        setOpenScheduler(true);
        setShowSubRouteMap(false);
    }
    const showRouteScreen = () => {
        setOpenScheduler(false);
    }
    const savedRoutes = () => {
        setShowSubRoutes(false);
        setShowSubRouteMap(false);
        setOpenScheduler(false);
    }
    const showSubRoute = (result) => {
        const coordinates = convertedCoordinatesData.coordinatesArray.slice(result.startIndex, result.endIndex + 1);
        setMapSubRouteData(coordinates);
        setIndvidualSubRouteData([result]);
        setShowSubRouteMap(true);
        setOpenScheduler(true);
    };
    const showFullMap = () => {
        setIndvidualSubRouteData([]);
        setShowSubRouteMap(false);
        setOpenScheduler(false);
    };
    return (
        <>
            <CustomAlert autoHideDuration={5000} text={userLocationErrorMessage} open={openUserLocationError} onClose={() => setOpenUserLocationError(false)} severity="error" />
            <div style={{ background: openScheduler ? '' : showSubRoutes ? '#FAFAFA' : '#E1E3E2' }}>
                {!openCalculation ? (
                    showSubRoutes ? (
                        <RoutePlannerSubRoutes selectedRouteData={selectedRouteData} userLocation={userLocation} routesData={subRouteDataArray} showSubRoute={showSubRoute} mapSubRouteData={mapSubRouteData} showSubRouteMap={showSubRouteMap}
                            convertedCoordinatesData={convertedCoordinatesData}
                            convertEncodedGeometryLoader={convertEncodedGeometryLoader}
                            savedRoutes={savedRoutes} showFullMap={showFullMap}
                            openScheduler={openScheduler}
                            showScheduler={showScheduler}
                            IndvidualSubRouteData={indvidualSubRouteData}
                        />
                    ) : (
                        <RoutePlannerSavedRoutes
                            setSelectedRouteData={setSelectedRouteData}
                            RoutePlannerData={RoutePlannerData}
                            showRoute={showRoute}
                            userLocation={userLocation}
                            handleCreatingNewRoute={handleCreatingNewRoute}
                            convertEncodedGeometryIsDoneAndSent={convertEncodedGeometryIsDoneAndSent}
                            openScheduler={openScheduler}
                            showRouteScreen={showRouteScreen}
                            showScheduler={showScheduler}
                            setSubRouteDataArray={setSubRouteDataArray}
                            deleteRoute={props.deleteRoute}
                            coid={coid}
                            deleteRouteLoader={deleteRouteLoader}
                            setIndvidualSubRouteData={setIndvidualSubRouteData}
                        />

                    )
                ) : (
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            <RoutePlannerCard
                                getToSpecificRouteLocation={getToSpecificRouteLocation}
                                routeTitleError={routeTitleError}
                                setRouteTitleError={setRouteTitleError}
                                saveRouteCalculationToDbIsDoneAndSent={saveRouteCalculationToDbIsDoneAndSent}
                                saveRouteCalculationToDbMessage={saveRouteCalculationToDbMessage}
                                routeTitle={routeTitle}
                                setRouteTitle={setRouteTitle}
                                saveRouteCalculation={saveRouteCalculation}
                                routeCalculationData={routeCalculationData}
                                draggable={draggable}
                                setDraggable={setDraggable}
                                savedRouteScreen={savedRouteScreen}
                                showFeildError={showFeildError}
                                getToCenterOfUserLocation={getToCenterOfUserLocation}
                                handleClearFields={handleClearFields}
                                handleAddField={handleAddField}
                                handleMarkerRemove={handleMarkerRemove}
                                fields={fields}
                                handleChange={handleChange}
                                markerPositions={markerPositions}
                                calculateRoute={calculateRoute}
                                routeCalculationLoader={routeCalculationLoader}
                                saveRouteCalculationToDbLoader={saveRouteCalculationToDbLoader}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <RoutePlannerMap
                                isUserLocation={isUserLocation}
                                routeCalculationData={routeCalculationData}
                                isRoutePlannerDataEmpty={isRoutePlannerDataEmpty}
                                draggable={draggable}
                                userLocation={userLocation}
                                handleMarkerPositionChange={handleMarkerPositionChange}
                                markerPositions={markerPositions}
                                MapClickHandler={MapClickHandler}
                            />
                        </Grid>
                    </Grid>

                )}
            </div >
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        project: state.project,
        userData: state.firebase.profile,
        RoutePlannerData: state.firestore.ordered.RoutePlannerData,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        routeCalculation: (coordinatesList) => dispatch(routeCalculation(coordinatesList)),
        saveRouteCalculationToDb: (routePlannerData, coid, title) => dispatch(saveRouteCalculationToDb(routePlannerData, coid, title)),
        removeRouteCalculationErrorAndMessage: () => dispatch(removeRouteCalculationErrorAndMessage()),
        convertEncodedGeometry: (encodedGeometry) => dispatch(convertEncodedGeometry(encodedGeometry)),
        deleteRoute: (coid, id) => dispatch(deleteRoute(coid, id)),
    };
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        const coid = props.userData.coid ?? '-1';
        return [
            {
                collection: `Companies/${coid}/RoutePlanner`,
                orderBy: [['dateCreated', 'desc']],
                storeAs: "RoutePlannerData",
            }
        ];
    })
)(RoutePlannerController);
