import { Box, Button, Card, CircularProgress, Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import DateParser from '../../../../../helpers/DateParser';
import { GlobalHotKeys } from 'react-hotkeys';
import SmartUtils from '../../../../../helpers/SmartUtils';
import NetworkNotification from './NetworkNotification';
import SimpleDialogBox from '../../../../cidgets/SimpleDialogBox';
import NetworkSetting from './defectsListView/NetworkSetting';
import { connect } from 'react-redux';
import { clearSetupNetworkAndMsg, setupNetwork } from '../../../../../store/actions/networkActions';
import Overview from './Overview';
const Topbar = (props) => {
    const topbarSection1Width = 30;
    const topbarSection2Width = 70;

    const { drNetworkData, netid, network, allNetworkDefects, superSections, handleSSViewChange, showSSView } = props;
    const { setupNetworkLoader, setupNetworkMessage, setupNetworkIsDoneAndSent } = network;
    const [openDialog, setOpenDialog] = useState(false);
    const [openOverviewDialog, setOpenOverviewDialog] = useState(false);
    const [showProjectAlert, setShowProjectAlert] = useState(false);
    const [selectedProjectList, setSelectedProjectList] = useState([]);
    const [projectList, setProjectList] = useState([]);

    const [showSuperSectionAlert, setShowSuperSectionAlert] = useState(false);
    const [superSectionList, setSuperSectionList] = useState([]);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        setShowProjectAlert(false);
        setSelectedProjectList([]);
        setProjectList([]);
        setShowSuperSectionAlert(false);
        // setSuperSectionList([]);
        setOpenDialog(false);
    }
    const handleOpenOverViewDialog = () => {
        setOpenOverviewDialog(true);
    }
    const handleCloseOverviewDialog = () => {
        setOpenOverviewDialog(false);
    }

    const { title, creationTime } = drNetworkData;
    const date = creationTime
        ? DateParser(creationTime, 0)
        : DateParser(creationTime, 2);
    let navigate = useNavigate();

    const handleOverviewOpen = () => {
        // setOpenOverview(true);
    };
    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };
    const keyMap = {
        OVERVIEW_MAC: "command+p",
        OVERVIEW_WIN: "ctrl+p",
    };

    const handlers = preventDefaultHandlers({
        OVERVIEW_MAC: handleOverviewOpen,
        OVERVIEW_WIN: handleOverviewOpen,
    });
    const su = new SmartUtils()
    const [os, setOs] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const data = await su._checkOS(navigator);
            setOs(data);
        }

        fetchData()
    }, []);

    useEffect(() => {
        if (drNetworkData && superSections) {
            if (drNetworkData.associatedSuperSections) {
                const superSectionList = superSections.filter(ss => drNetworkData.associatedSuperSections.includes(ss.id));
                setSuperSectionList(superSectionList);
            }
        }
    }, [drNetworkData, superSections])
    useEffect(() => {
        if (setupNetworkIsDoneAndSent === true) {
            handleCloseDialog();
        }
    }, [setupNetworkIsDoneAndSent])

    const { ctrl } = su._platformKeys(os?.platform);

    const handleNetwork = () => {
        setShowProjectAlert(false);
        setShowSuperSectionAlert(false);
        props.clearSetupNetworkAndMsg();

        if (superSectionList.length <= 0 && selectedProjectList.length <= 0) {
            setShowProjectAlert(true);
            setShowSuperSectionAlert(true);
        }
        else if (superSectionList.length <= 0) {
            setShowSuperSectionAlert(true);
        }
        else if (selectedProjectList.length <= 0) {
            setShowProjectAlert(true);
        }
        else {
            const ssList = superSectionList.map((each) => each.superSectionId);
            const proidList = selectedProjectList.map((each) => each.proid);
            props.setupNetwork(netid, ssList, proidList);
        }
    }
    const handleNavigate = () => {
        navigate('/home/super-section');
        handleCloseDialog();
    }
    return (
        <>
            <SimpleDialogBox
                open={openDialog}
                onClose={handleCloseDialog}
                title={"Network setting"}
                cancelText="Close"
                bodyWidget={
                    <NetworkSetting drNetworkData={drNetworkData}
                        showProjectAlert={showProjectAlert} setShowProjectAlert={setShowProjectAlert}
                        selectedProjectList={selectedProjectList} setSelectedProjectList={setSelectedProjectList}
                        projectList={projectList} setProjectList={setProjectList}
                        showSuperSectionAlert={showSuperSectionAlert} setShowSuperSectionAlert={setShowSuperSectionAlert}
                        superSectionList={superSectionList} setSuperSectionList={setSuperSectionList}
                    />
                }
                width={900}
                showError={setupNetworkIsDoneAndSent === true ? false : true}
                errorMessage={setupNetworkIsDoneAndSent === true ? "" : setupNetworkMessage}
                showPositive
                positiveButtonWidget={
                    <>
                        {setupNetworkLoader === true ? (
                            <>
                                <Button
                                    variant="contained"
                                    disabled
                                    sx={{
                                        "&.Mui-disabled": {
                                            backgroundColor: "#025196",
                                            color: "#ffffff",
                                        },
                                        marginInline: "2px",
                                        textTransform: "initial",
                                        color: "#025196",
                                        backgroundColor: "#025196",
                                        ":hover": {
                                            backgroundColor: "#025196",
                                        },
                                    }}
                                >
                                    <CircularProgress
                                        variant="indeterminate"
                                        size={15}
                                        sx={{ color: "white" }}
                                    />
                                    <span style={{ paddingLeft: "15px" }}>
                                        Processing...
                                    </span>
                                </Button>
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{
                                    marginInline: "2px",
                                    textTransform: "initial",
                                    backgroundColor: "#025196",
                                    ":hover": {
                                        backgroundColor: "#025196",
                                    },
                                }}
                                onClick={handleNetwork}
                            >
                                Process
                            </Button>
                        )}
                        {superSections ? <></> : < Button
                            variant="contained"
                            sx={{

                                whiteSpace: 'nowrap',
                                marginInline: "10px",
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                ":hover": {
                                    backgroundColor: "#025196",
                                },
                            }}
                            onClick={handleNavigate}
                        >
                            Super sections
                        </Button>}
                    </>
                }
            />


            <SimpleDialogBox
                open={openOverviewDialog}
                onClose={handleCloseOverviewDialog}
                title={"Overview"}
                cancelText="Close"
                bodyWidget={
                    <Overview allNetworkDefects={allNetworkDefects} />
                }
                width={800}
            />
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
                    <div style={{
                        borderRadius: '50%'
                    }}>
                        <Card sx={{
                            borderRadius: '50px', backgroundColor: '#fff', '&:hover': {
                                backgroundColor: '#fafafa',
                            },
                        }} elevation={5}>
                            <IconButton aria-label="roundedBtn" style={{ backgroundColor: 'transparent', }} onClick={() => { navigate('/home/dashboard') }}>
                                <KeyboardArrowLeft fontSize='large' sx={{ color: '#1E1E1E' }} />
                            </IconButton>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={10} sm={10} md={11} lg={11} xl={11} sx={{ backgroundColor: 'transparent', borderRadius: '15px' }}>
                    <Card elevation={4} sx={{ backgroundColor: '#FAFAFA', borderRadius: '15px', paddingBlock: '0px' }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' }, width: { xs: '0%', md: `${topbarSection1Width}%` } }} style={{ height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={`${title} - ${date}`} placement="bottom">
                                    <Typography
                                        variant="body1"
                                        color="initial"
                                        className='font-color'
                                        sx={{ paddingLeft: '20px', cursor: 'pointer', display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' }, alignItems: 'center' }}
                                    >
                                        {title ? (
                                            title.length > 15 ? (
                                                <span>{title.slice(0, 15)}... — {date}</span>
                                            ) : (
                                                <span>{title} — {date} </span>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </Typography>
                                </Tooltip>
                            </Box>

                            <Box sx={{ width: { xs: `${100}%`, md: `${topbarSection2Width}%` }, justifyContent: { xs: 'space-evenly', md: 'end' } }} style={{ marginRight: { xs: '20px', sm: '20px', md: '0px' }, height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center', }}>
                                {superSectionList.length > 0 ? <Tooltip arrow placement="bottom" title="Show super sections">
                                    <Switch
                                        checked={showSSView}
                                        onChange={handleSSViewChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        sx={{
                                            '& .MuiSwitch-thumb': {
                                                backgroundColor: '#025196',
                                            },
                                            '& .Mui-checked': {
                                                color: '#025196'
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                backgroundColor: '#025196',
                                            }
                                        }}
                                    />
                                </Tooltip> : <></>}
                                <Tooltip title={'Network settings'} arrow placement='bottom'>
                                    <Button variant="contained"
                                        onClick={handleOpenDialog}
                                        size="small" sx={{
                                            marginLeft: '10px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            marginInline: '10px',
                                            textTransform: 'initial',
                                            backgroundColor: '#025196', ':hover': {
                                                backgroundColor: '#025196'
                                            },
                                        }}

                                    >
                                        Network settings</Button>
                                </Tooltip>
                                <Box sx={{ border: '1px solid #000000', marginLeft: '15px', borderRadius: '4px', float: 'left', display: { xs: "none", sm: "none", md: 'none', lg: "flex" }, }}>
                                    <Tooltip title={`Use ${ctrl.name}${ctrl.plus}P to view`} arrow placement='bottom'>
                                        <Button variant="text" size='small'
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                backgroundColor: 'transparent',
                                                color: '#000000', textTransform: 'initial', paddingInline: '8px',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    color: '#000000',
                                                },
                                            }}

                                            onClick={handleOpenOverViewDialog}>Results overview</Button>
                                    </Tooltip>
                                </Box>

                                <NetworkNotification ctrl={ctrl} />
                            </Box>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        network: state.network,
        superSections: state.firestore.ordered.SuperSections,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setupNetwork: (networkId, ssList, proidList) => dispatch(setupNetwork(networkId, ssList, proidList)),
        clearSetupNetworkAndMsg: () => dispatch(clearSetupNetworkAndMsg()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);