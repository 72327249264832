import { useState } from "react"
import SimpleDialogBox from "../../../../cidgets/SimpleDialogBox";

const DrawBBox = ({xy = [], wh = [], shouldScale = true, pred = false, scale = 1}) => {
    const [openDialog, setOpenDialog] = useState(false)

    const handleOpenDialog = () => {
        // //////console.log("ghjhgj")
        // setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (xy.length !== 2 || wh.length !== 2) {
        // //////console.log("lkjkjkjk")
        return <></>
    }

    const x1 = xy[0] / (shouldScale ? scale : 1)
    const y1 = xy[1] / (shouldScale ? scale : 1)
    const x2 = (wh[0] + xy[0]) / (shouldScale ? scale : 1)
    const y2 = (wh[1] + xy[1]) / (shouldScale ? scale : 1)
    return <>
        <SimpleDialogBox
            open={openDialog}
            onClose={handleCloseDialog}
            title="What if you can move it?"
            cancelText="Close"
            desc="Something is cooking... And it is almost ready for you!"
            bodyWidget={
                <>

                </>
            }
            width='600'
        />
        <path d={`M ${x1}, ${y1} ${x1}, ${y2} ${x2}, ${y2} ${x2}, ${y1} z`} className='bbox-path' onClick={handleOpenDialog} />
    </>
}

export default DrawBBox;