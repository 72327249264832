import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import commonNames from '../../constants/commonNames.json';
import DateParser from '../../helpers/DateParser';
import SmartUtils from '../../helpers/SmartUtils';

const InvoiceComponent = ({ credits = 0, priceInCurrency = 0, currency = '', organizationName = '' }) => {
    const cardStyle = {
        minWidth: 300,
        margin: '0px',
        padding: '0px',
        backgroundColor: 'transparent',
        borderRadius: '4px',
    };

    const messageStyle = {
        marginBottom: '8px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '1.4',
    };

    const detailsContainerStyle = {
        marginBottom: '8px',
    };

    const detailsStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    const borderStyle = {
        borderTop: '1px solid #ccc',
        margin: SmartUtils.edgeInsets(20, 'tb'),
    };
    return (
        <Card style={cardStyle} elevation={0}>
            <CardContent>
                <Typography variant="body2" style={{ fontWeight: 'bold', margin: SmartUtils.edgeInsets(10, 'b') }}>
                    Important information
                </Typography>
                <Typography variant="body2" style={messageStyle}>
                    {credits} {commonNames.credits} will be equal to {priceInCurrency} {currency.toUpperCase()}.
                </Typography>
                <Typography variant="body2" style={messageStyle}>
                    Please note that your attached bank card will be used for payment after confirmation.
                </Typography>
                <div style={borderStyle}></div>
                <div style={detailsContainerStyle}>
                    <div style={detailsStyle}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">Date:</Typography>
                        <Typography variant="body2">{DateParser(null, 2)}</Typography>
                    </div>
                    <div style={detailsStyle}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">Organization name:</Typography>
                        <Typography variant="body2">{organizationName}</Typography>
                    </div>
                    <div style={detailsStyle}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">Total amount:</Typography>
                        <Typography variant="body2">{currency.toUpperCase()} {priceInCurrency}</Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default InvoiceComponent;
