import { Escalator, KeyboardArrowLeft } from '@mui/icons-material'
import { Box, Button, Card, CardActionArea, CircularProgress, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Link from '@mui/material/Link';
import CustomSeparator from '../../../../cidgets/CustomSeparator';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { connect } from 'react-redux';
const DistressEditorTopbar = (props) => {
    const { handleCloseDialogDistress, handleCloseDialog, handleEditDataSubmit, handleResetBbox, proid, project } = props;
    const { updateDistressArchiveMessage, showUpdateDistressArchiveLoader, isEditModalClose, updateDistressArchiveIsDoneAndSent } = project;
    // //////console.log(handleCloseDialog, 'fast123')
    // //////console.log(handleCloseDialogDistress, 'slow123')
    const topbarSection1Width = 90;
    const topbarSection2Width = 70;

    const handleClick = (event, key) => {
        if (event.target.getAttribute('href') === '') {
            event.preventDefault();
            if (key === 1) {
                handleCloseDialog();
            }
            else if (key === 2) {
                handleCloseDialogDistress();
            }

        }
    };

    useEffect(() => {
        if (isEditModalClose) {
            handleCloseDialogDistress();
        }
    }, [isEditModalClose]);


    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={11} sm={10} md={6} lg={6} xl={6} sx={{ backgroundColor: '', borderRadius: '15px' }}>
                    <div style={{ height: '100%', width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                        {/* <Box sx={{ backgroundColor: '', display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' }, width: { xs: '0%', md: `${topbarSection1Width}%` } }} style={{ height: '100%', backgroundColor: '', float: 'left', display: 'flex', alignItems: 'center' }}> */}
                        <CustomSeparator
                            icon={<NavigateBeforeIcon fontSize="small" />}
                            widget={[
                                <Link
                                    underline="hover"
                                    href=""
                                    key="1"
                                    color="text.primary"
                                    onClick={(e) => { handleClick(e, 1) }}
                                    sx={{
                                        cursor: 'pointer', color: 'black',
                                        '&:hover': {
                                            color: 'black',

                                        }
                                    }}
                                >
                                    Dashboard
                                </Link>,

                                <Link
                                    underline="hover"
                                    href=""
                                    key="2"
                                    color="text.primary"
                                    onClick={(e) => { handleClick(e, 2) }}
                                    // onClick={handleCloseDialogDistress}
                                    sx={{
                                        cursor: 'pointer', color: 'black',
                                        '&:hover': {
                                            color: 'black',

                                        }
                                    }}
                                >
                                    Section details
                                </Link>,
                                <Typography key="3" color="text.primary"
                                    sx={{ textDecoration: 'underline' }}
                                >
                                    Edit distress
                                </Typography>,]} />
                        {/* </Box> */}
                    </div>
                </Grid>
                <Grid item xs={1} sm={2} md={6} lg={6} xl={6} sx={{ backgroundColor: '', borderRadius: '15px' }}>
                    {/* <Card elevation={4} sx={{ backgroundColor: '#FAFAFA', borderRadius: '15px', padding: '0px' }}> */}
                    <div style={{ height: '100%', width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <Tooltip title={'Reset edit changes'} arrow placement='bottom'>
                            <Typography onClick={handleResetBbox} sx={{  display: { xs: "none", sm: "none", md: 'flex' }, marginInline: '15px', alignItems: 'center', textDecoration: 'underline', color: 'black', cursor: 'pointer' }}>Reset changes</Typography>
                        </Tooltip>

                        <Tooltip title={'Reset edit changes'} arrow placement='bottom' sx={{ display: { xs: "flex", sm: "flex", md: 'none' }, }}>
                                    <RestartAltIcon
                                        sx={{
                                            fontSize: { xs: '16px', sm: '24px' },
                                            display: { xs: "flex", sm: "flex", md: 'none' },
                                            color: "#1E1E1E",
                                            cursor: "pointer",
                                            marginRight: '10px'
                                        }}
                                        onClick={handleResetBbox}
                                    />
                                </Tooltip>

                        {showUpdateDistressArchiveLoader ?
                            <>
                                <Button size="small" variant="contained" disabled sx={{
                                    display: { xs: "none", sm: "none", md: 'flex' },
                                    textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                    "&.Mui-disabled": {
                                        backgroundColor: '#025196',
                                        color: '#ffffff'
                                    }
                                }}>
                                    <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Applying...</span>
                                </Button>

                                <CircularProgress variant="indeterminate" size={15} sx={{ display: { xs: "flex", sm: "flex", md: 'none' },color: 'white' }} />
                                

                            </>
                            :
                            <>
                                <Tooltip title={'Update the edit distress changes'} arrow placement='bottom'>
                                    <Button size="small" variant="contained"
                                        onClick={handleEditDataSubmit}
                                        sx={{
                                            display: { xs: "none", sm: "none", md: 'flex' },
                                            textTransform: 'initial', backgroundColor: '#025196', color: '#ffffff',
                                            '&:hover': {
                                                backgroundColor: '#025196',
                                                color: '#ffffff'
                                            }
                                        }}>Accept changes</Button>
                                </Tooltip>

                                <Tooltip title={'Update the edit distress changes'} arrow placement='bottom' sx={{ display: { xs: "flex", sm: "flex", md: 'none' }, }}>
                                    <SaveIcon
                                        sx={{
                                            fontSize: { xs: '16px', sm: '24px' },
                                            display: { xs: "flex", sm: "flex", md: 'none' },
                                            color: "#1E1E1E",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleEditDataSubmit}
                                    />
                                </Tooltip>
                            </>
                        }
                        {/* <Button variant="contained" size="small"
                            onClick={handleEditDataSubmit}
                            sx={{
                                // marginInline: '10px',
                                textTransform: 'initial',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                },
                            }}>Accept changes</Button> */}
                        {/* <Box sx={{ width: { xs: `${100}%`, md: `${topbarSection2Width}%` }, justifyContent: { xs: 'space-evenly', md: 'end' } }} style={{ marginRight: { xs: '20px', sm: '20px', md: '0px' }, height: '100%', backgroundColor: '', float: 'left', display: 'flex', alignItems: 'center', }}>

                            <Box sx={{ float: 'left', marginLeft: '15px', display: { xs: "none", sm: "none", md: 'none', lg: "flex", } }}>
                                <Typography onClick={handleResetBbox} sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline', color: 'black', cursor: 'pointer' }}>Reset changes</Typography>
                                <Button variant="contained" size="small"
                                    onClick={handleEditDataSubmit}
                                    sx={{
                                        marginInline: '10px',
                                        textTransform: 'initial',
                                        backgroundColor: '#025196', ':hover': {
                                            backgroundColor: '#025196'
                                        },
                                    }}>Accept changes</Button>
                            </Box>


                        </Box> */}
                    </div>
                    {/* </Card> */}
                </Grid>
            </Grid>
        </>
    )
}
const mapStateToProps = (state) => {
    // //////console.log(state.datastore, "datastore")
    return {
        project: state.project,
    };
};


export default connect(mapStateToProps)(DistressEditorTopbar);
