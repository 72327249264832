import { Card, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';

const PotholeAddNewFields = ({ fieldValues, handleChange, basicImagingDataInfo }) => {

    const divFieldStyles = {
        "border": '1px solid rgba(0, 0, 0, 0.23)',
        "borderRadius": "4px",
        "padding": '10px 8px',
        "fontSize": '0.95rem',
        "textTransform": 'capitalize',
        "overflow": 'auto',
        // "backgroundColor": '#f5f5f5',
        "color": 'rgba(0,0,0,1)',
    };
    const Item = styled(Paper)(({ theme }) => ({
        boxShadow: '0px 0px transparent',
        width: '100%',
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
    }));

    const basicImagingClassesMap = {
        "map": (() => {
            const usefulKeys = ['0'];
            return usefulKeys.reduce((result, key) => {
                result[key] = basicImagingDataInfo['defectName'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(basicImagingDataInfo['defectName']),
        "values": Object.values(basicImagingDataInfo['defectName']).map(value => value.name),
        "usefulKeys": ['0'],

    }
    const priorityMap = {
        "map": (() => {
            const usefulKeys = ['0', '1', '2'];
            return usefulKeys.reduce((result, key) => {
                result[key] = basicImagingDataInfo['priority'][key]['name'];
                return result;
            }, {});
        })(),
        "keys": Object.keys(basicImagingDataInfo['priority']),
        "values": Object.values(basicImagingDataInfo['priority']).map(value => value.name),
        "usefulKeys": ['0', '1', '2'],
    }
    const priorityClasses = priorityMap.keys.filter(e => priorityMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${priorityMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })
    const BasicImagingClasses = basicImagingClassesMap.keys.filter(e => basicImagingClassesMap.usefulKeys.includes(e)).map((e, i) => {
        return <MenuItem key={e} value={e} sx={{ textTransform: 'capitalize' }}>
            {`${basicImagingClassesMap.values[i]}`.replace('_', ' ')}
        </MenuItem>
    })
    return (
        <div style={{ backgroundColor: 'transparent', overflow: 'auto' }}>
            <Item>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }} >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Feature category:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            id="asset class"
                            select
                            value={fieldValues.basicImagingClassState}
                            onChange={handleChange("basicImagingClassState")}
                            size="small"
                            sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                        >
                            {BasicImagingClasses}
                        </TextField>
                    </Grid>
                </Grid>
            </Item>
            <Item>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }} >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Priority:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                            id="asset class"
                            select
                            value={fieldValues.priorityState}
                            onChange={handleChange("priorityState")}
                            size="small"
                            sx={{ overflow: 'auto', textTransform: 'capitalize', width: '100%' }}
                        >
                            {priorityClasses}
                        </TextField>
                    </Grid>
                </Grid>
            </Item>
            <Item>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
                        sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }} >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            GPS location:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}  >
                        <div style={divFieldStyles}>
                            <Typography variant="inherit">
                                {fieldValues.gpsCoordinatesState}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Item>
        </div>
    )
}

export default PotholeAddNewFields