import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MapContainer, Marker, Polyline, Popup, TileLayer, ZoomControl, useMap } from 'react-leaflet'
import L, { Map } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Button } from '@mui/material';

const RoutePlannerDisabled =
    ({ userLocation, handleCreatingNewRoute }) => {
        const blurredMapStyle = {
            height: '100vh',
            width: '100vw',
            pointerEvents: 'none',
            filter: 'blur(5px)',
        };
        const centeredContainerStyle = {
            position: 'absolute',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
        };
        const buttonStyles = {
            background: 'transparent',
            borderRadius: '20px',
            border: '2px solid #1F0851',
            color: '#1F0851',
            padding: '10px 20px',
        };
        return (
            <>
                <div style={centeredContainerStyle}>
                    <Button variant='outlined' style={buttonStyles} onClick={handleCreatingNewRoute}>Create new route</Button>
                </div>
                <MapContainer
                    center={[userLocation.lat, userLocation.lng]}
                    style={blurredMapStyle}
                    zoom={18}
                    zoomControl={false}
                    doubleClickZoom={false}

                >

                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />


                </MapContainer>
            </>
        )
    }

export default RoutePlannerDisabled